import React from 'react'
import {
  OptimizelyProvider,
  createInstance,
  setLogger,
  enums,
} from '@optimizely/react-sdk'
import { useCookies } from '@src/utils/cookies'
import { useUserSession } from '@utils/useUserSession'
import { useCurrentUser } from '@src/utils/useCurrentUser'
import { isServer } from '@src/utils/isServer'
import { sendEvent } from '@utils/analytics/coreAnalytics'
import { GTMEventTypes } from '@sial/common-utils'
import { useRouter } from '@src/routes'
import { useMediaQuery } from '@material-ui/core'
import { theme } from '@src/styles/theme'
import { parseRegionalUrl } from '@src/utils/regional'

const SIALOptimizelyProvider: React.FC<{
  datafile: any
  children: React.ReactNode
}> = ({ datafile, children }) => {
  const [cookies] = useCookies(['GUID'])
  const { userIsLoggedIn } = useCurrentUser({})
  const { userSession } = useUserSession()
  const router = useRouter()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  // If we don't have a datafile, we don't load the Optimizely SDK, but we can't prevent `useDecision`
  // hooks from running, and they throw console.log warnings when the SDK isn't initialized.
  // So we're hiding any Optimizely logs if no datafile.
  // We're also hiding any Optimizely logs in non dev environments
  if (!datafile || process.env.NODE_ENV !== 'development') {
    setLogger(null)
    if (process.env.NODE_ENV === 'development' && !isServer()) {
      console.info('Optimizely logs silenced in SIALOptimizelyProvider.')
    }
  }

  if (!datafile) return <>{children}</>

  const optimizelyInstance = createInstance({
    datafile,
    eventBatchSize: 10,
    eventFlushInterval: 1000,
  })
  const guidCookie = cookies['GUID'] || 'default_user'

  const { pathAfterBasename } = parseRegionalUrl(router.asPath)

  /**
   * UIPT-406 - Optly Feature Flags Connection to GA4
   * https://docs.developers.optimizely.com/feature-experimentation/docs/set-up-google-analytics-4-ga4
   */
  const onDecision = ({ type, decisionInfo }) => {
    // Add a DECISION Notification Listener for type FLAG
    if (type === 'flag') {
      sendEvent({
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: {
          event: 'optimizely-decision-fs',
          'optimizely-flagKey': decisionInfo.flagKey,
          'optimizely-ruleKey': decisionInfo.ruleKey,
          'optimizely-variationKey': decisionInfo.variationKey,
        },
      })
    }
  }

  optimizelyInstance?.notificationCenter?.addNotificationListener(
    enums.NOTIFICATION_TYPES.DECISION,
    // @ts-ignore
    onDecision
  )

  return (
    <OptimizelyProvider
      isServerSide={isServer()}
      optimizely={optimizelyInstance}
      user={{
        id: guidCookie,
        attributes: {
          userIsLoggedIn: userIsLoggedIn,
          locale: `${userSession.language}_${userSession.country}`,
          country: userSession.country,
          language: userSession.language,
          route: router.asPath,
          device: isDesktop ? 'desktop' : 'mobile',
          pathAfterBasename: pathAfterBasename || '',
        },
      }}
    >
      {children}
    </OptimizelyProvider>
  )
}

export default SIALOptimizelyProvider
