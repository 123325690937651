import { defineMessages } from 'react-intl'

export interface Message {
  id: string
  defaultMessage: string
}

export const messages = defineMessages({
  PRICING_AND_AVAILABILITY_UNAVAILABLE: {
    id: 'PRICING_AND_AVAILABILITY_UNAVAILABLE',
    defaultMessage: 'Pricing and availability is not currently available.',
  },
  PRODUCT_DISCONTINUED_BUT_AVAILABLE_FOR_PURCHASE: {
    id: 'PRODUCT_DISCONTINUED_BUT_AVAILABLE_FOR_PURCHASE',
    defaultMessage:
      'One or more pack size(s) of this product have been discontinued but those shown are still available for purchase.',
  },
  THIS_PRODUCT_HAS_BEEN_DISCONTINUED: {
    id: 'THIS_PRODUCT_HAS_BEEN_DISCONTINUED',
    defaultMessage: 'Product {productNumber} has been discontinued.',
  },
  THIS_PRODUCT_DISCONTINUED: {
    id: 'THIS_PRODUCT_DISCONTINUED',
    defaultMessage: 'This product is discontinued.',
  },
  NO_PRODUCTS_TO_COMPARE: {
    id: 'NO_PRODUCTS_TO_COMPARE',
    defaultMessage: 'No Products To Compare',
  },
  PRODUCT_IS_NOT_SOLD_IN_YOUR_COUNTRY: {
    id: 'PRODUCT_IS_NOT_SOLD_IN_YOUR_COUNTRY',
    defaultMessage:
      'Product {productNumber} is not currently sold in your country.',
  },
  PRODUCT_CANNOT_BE_ORDERED: {
    id: 'PRODUCT_CANNOT_BE_ORDERED',
    defaultMessage: 'Product {productNumber} cannot be ordered.',
  },
  PRODUCT_IS_ON_DEMAND_AVAILABILITY_MAY_VARY_BASED_ON_CAPACITY: {
    id: 'PRODUCT_IS_ON_DEMAND_AVAILABILITY_MAY_VARY_BASED_ON_CAPACITY',
    defaultMessage:
      'Product {productNumber} is an on demand item. Availability times will vary based on capacity.',
  },
  PRODUCT_IS_CUSTOMER_SPECIFIC_AND_NOT_AVAILABLE_TO_BE_SOLD_ON_INTERNET: {
    id: 'PRODUCT_IS_CUSTOMER_SPECIFIC_AND_NOT_AVAILABLE_TO_BE_SOLD_ON_INTERNET',
    defaultMessage:
      'Product {productNumber} is a customer specific product and is not available to be sold on the internet.',
  },
  TO_INQUIRE_ABOUT_THIS_PRODUCT_PLEASE_CONTACT_YOUR_LOCAL_MERCK_OFFICE_OR_DEALER:
    {
      id: 'TO_INQUIRE_ABOUT_THIS_PRODUCT_PLEASE_CONTACT_YOUR_LOCAL_MERCK_OFFICE_OR_DEALER',
      defaultMessage:
        ' To inquire about this product {productNumber}, please contact your local Merck office or dealer.',
    },
  TO_INQUIRE_ABOUT_THIS_PRODUCT_PLEASE_CONTACT_YOUR_LOCAL_MILLIPORE_SIGMA_OFFICE_OR_DEALER:
    {
      id: 'TO_INQUIRE_ABOUT_THIS_PRODUCT_PLEASE_CONTACT_YOUR_LOCAL_MILLIPORE_SIGMA_OFFICE_OR_DEALER',
      defaultMessage:
        'To inquire about this product {productNumber}, please contact your local MilliporeSigma office or dealer.',
    },
  PRODUCT_REQUIRES_SPECIAL_PROCESSING: {
    id: 'PRODUCT_REQUIRES_SPECIAL_PROCESSING',
    defaultMessage:
      'Product {productNumber} requires special processing and cannot be ordered through the web site. Please contact customer service to order this item.',
  },
  PRODUCT_NOT_AVAILABLE_FOR_PURCHASE: {
    id: 'PRODUCT_NOT_AVAILABLE_FOR_PURCHASE',
    defaultMessage:
      'Product {productNumber} is currently not available for purchase.',
  },
  EMD_MILLIPORE_PRICING_AND_INVENTORY_UNAVAILABLE: {
    id: 'EMD_MILLIPORE_PRICING_AND_INVENTORY_UNAVAILABLE',
    defaultMessage:
      'Pricing and Inventory is not available. Please check the {link} website for more information.',
  },
  MERCK_MILLIPORE_PRICING_AND_INVENTORY_UNAVAILABLE: {
    id: 'MERCK_MILLIPORE_PRICING_AND_INVENTORY_UNAVAILABLE',
    defaultMessage: 'This item can only be ordered on the {link} website.',
  },
})

export default messages
