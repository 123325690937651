export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: number;
  JSONObject: Record<string, unknown>;
  Upload: any;
};

export type Abilities = {
  __typename?: 'Abilities';
  canAddToCart: Scalars['Boolean'];
  canViewInventory: Scalars['Boolean'];
  canViewOnlineOrder: Scalars['Boolean'];
  canViewOnlineQuote: Scalars['Boolean'];
};

export type AccessoryServicePowerCordItem = {
  __typename?: 'AccessoryServicePowerCordItem';
  materialDescription?: Maybe<Scalars['String']>;
  materialNumber?: Maybe<Scalars['String']>;
  netPrice?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type AccessoryServicePowerCordItemInput = {
  currency?: InputMaybe<Scalars['String']>;
  materialDescription?: InputMaybe<Scalars['String']>;
  materialNumber?: InputMaybe<Scalars['String']>;
  netPrice?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

export type AddCoworkerToGroupInput = {
  groupId: Scalars['String'];
  userId: Scalars['String'];
};

export type AddItemsToCartInput = {
  action?: InputMaybe<CartRequestAction>;
  items: Array<CartItemInput>;
  marketplace?: InputMaybe<Scalars['Boolean']>;
};

export type AddQuickOrderItemsInput = {
  quickOrderItems: Array<QuickOrderItem>;
};

export type AddressInfoType = {
  email?: InputMaybe<Scalars['String']>;
  extension?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};

export type AddressInput = {
  addressId: Scalars['String'];
  attentionTo: Scalars['String'];
  buildingRoom: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  countryName: Scalars['String'];
  department: Scalars['String'];
  district: Scalars['String'];
  email: Scalars['String'];
  extension: Scalars['String'];
  fax: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName: Scalars['String'];
  mobilePhone: Scalars['String'];
  officeRegistrationNumber: Scalars['String'];
  organizationName: Scalars['String'];
  organizationProperty?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  postOfficeBox: Scalars['String'];
  principalInvestigator?: InputMaybe<Scalars['String']>;
  salesRepresentative?: InputMaybe<Scalars['String']>;
  salutation: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  street2: Scalars['String'];
  street3: Scalars['String'];
  street4: Scalars['String'];
  telephone: Scalars['String'];
  title: Scalars['String'];
};

export type AddressPartners = {
  __typename?: 'AddressPartners';
  billTo?: Maybe<PartnerObject>;
  contact?: Maybe<PartnerObject>;
  distChannel?: Maybe<Scalars['String']>;
  erpSystemId?: Maybe<Scalars['String']>;
  partnersIndexId?: Maybe<Scalars['String']>;
  payer?: Maybe<PartnerObject>;
  salesOrg?: Maybe<Scalars['String']>;
  shipTo?: Maybe<PartnerObject>;
  soldTo?: Maybe<PartnerObject>;
};

export type AddressPartnersInput = {
  billTo?: InputMaybe<PartnerObjectInput>;
  contact?: InputMaybe<PartnerObjectInput>;
  distChannel?: InputMaybe<Scalars['String']>;
  erpSystemId?: InputMaybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  partnersIndexId?: InputMaybe<Scalars['String']>;
  payer?: InputMaybe<PartnerObjectInput>;
  salesOrg?: InputMaybe<Scalars['String']>;
  shipTo?: InputMaybe<PartnerObjectInput>;
  soldTo?: InputMaybe<PartnerObjectInput>;
  storeId: Scalars['Int'];
};

export type AddSavedCartItemsInput = {
  cartId: Scalars['ID'];
  items: Array<SavedCartItemInput>;
};

export type AddSoftwareSavedCartItemsInput = {
  items: Array<SavedCartItemInput>;
};

export type AddToCartAssay = {
  Amplicon?: InputMaybe<Scalars['String']>;
  amplicon_size?: InputMaybe<Scalars['Int']>;
  assay_id?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  currencySymbol?: InputMaybe<Scalars['String']>;
  exons?: InputMaybe<Scalars['String']>;
  gene_id?: InputMaybe<Scalars['Int']>;
  gene_sym?: InputMaybe<Scalars['String']>;
  i_seq?: InputMaybe<Scalars['String']>;
  l_seq?: InputMaybe<Scalars['String']>;
  material?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Int']>;
  r_seq?: InputMaybe<Scalars['String']>;
  rank?: InputMaybe<Scalars['String']>;
  ref_seq?: InputMaybe<Scalars['String']>;
  search_by?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<Scalars['String']>;
};

export type AddToProtocolsLibraryInput = {
  title: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
};

export type AdminPartnersInfo = {
  __typename?: 'AdminPartnersInfo';
  isDefault: Scalars['Boolean'];
  partners?: Maybe<Array<Maybe<AddressPartners>>>;
  storeId: Scalars['Int'];
};

export type AdminPartnersInfoInput = {
  adminPartnersInfo?: InputMaybe<AdminPartnersInput>;
};

export type AdminPartnersInput = {
  partners?: InputMaybe<Array<InputMaybe<AddressPartnersInput>>>;
};

export type AemFooter = {
  __typename?: 'AemFooter';
  brandLogo?: Maybe<AemFooterBrandLogo>;
  footerLeftContent?: Maybe<Array<Maybe<AemFooterLeftContent>>>;
  footerRightContent?: Maybe<Array<Maybe<AemFooterRightContent>>>;
  legalLinks?: Maybe<Array<Maybe<AemFooterLinksItem>>>;
  portfolioLinks?: Maybe<Array<Maybe<AemFooterLinksItem>>>;
  socialContent?: Maybe<Array<Maybe<AemFooterSocialContent>>>;
  trademarkBoldText?: Maybe<Scalars['String']>;
  trademarkSmallText?: Maybe<Scalars['String']>;
};

export type AemFooterBrandLogo = {
  __typename?: 'AemFooterBrandLogo';
  alt?: Maybe<Scalars['String']>;
  externalUrl?: Maybe<Scalars['Boolean']>;
  linkUrl?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  widths?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type AemFooterLeftContent = {
  __typename?: 'AemFooterLeftContent';
  items?: Maybe<Array<Maybe<AemFooterLinksItem>>>;
  title?: Maybe<Scalars['String']>;
};

export type AemFooterLinksItem = {
  __typename?: 'AemFooterLinksItem';
  externalUrl?: Maybe<Scalars['Boolean']>;
  linkLabel?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['String']>;
};

export type AemFooterRightContent = {
  __typename?: 'AemFooterRightContent';
  contentType?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  rightText?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AemFooterSocialContent = {
  __typename?: 'AemFooterSocialContent';
  contentType?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<AemFooterBrandLogo>>>;
  socialLinks?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
};

export type AemHeader = {
  __typename?: 'AemHeader';
  topnav?: Maybe<Array<Maybe<AemTopNav>>>;
};

export type AemHeaderFooter = {
  __typename?: 'AemHeaderFooter';
  footer?: Maybe<AemFooter>;
  header?: Maybe<AemHeader>;
};

export type AemTopNav = {
  __typename?: 'AemTopNav';
  additionalColumnHeading?: Maybe<Scalars['String']>;
  additionalLinks?: Maybe<Array<Maybe<AemTopNavAdditionalLink>>>;
  fullWidthDrawer?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<AemTopNavItem>>>;
  title?: Maybe<Scalars['String']>;
};

export type AemTopNavAdditionalLink = {
  __typename?: 'AemTopNavAdditionalLink';
  altText?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  openNewTab?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type AemTopNavItem = {
  __typename?: 'AemTopNavItem';
  childrens?: Maybe<Array<Maybe<AemTopNavItem>>>;
  menuItem?: Maybe<Scalars['Boolean']>;
  openNewTab?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type AffiliateAccountDetails = {
  __typename?: 'AffiliateAccountDetails';
  attention?: Maybe<Scalars['String']>;
  buildingRooms?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  customerAccountGroup?: Maybe<Scalars['String']>;
  customerAdditionalName?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  organisationName?: Maybe<Scalars['String']>;
  partnerNumber?: Maybe<Scalars['String']>;
  partnerType?: Maybe<Scalars['String']>;
  poBox?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  salesOrg?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateName?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
  street4?: Maybe<Scalars['String']>;
  street5?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  telephoneNumber?: Maybe<Scalars['String']>;
};

export type AffiliateAccountResponse = {
  __typename?: 'AffiliateAccountResponse';
  billToAddress: AffiliateAccountDetails;
  payerAddress: AffiliateAccountDetails;
  shipToAddress: AffiliateAccountDetails;
  soldToAddress: AffiliateAccountDetails;
};

export type Alias = {
  __typename?: 'Alias';
  key: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type Aliquot = {
  __typename?: 'Aliquot';
  Mul100X20?: Maybe<Scalars['String']>;
  Mul100X50?: Maybe<Scalars['String']>;
  Mul10X100?: Maybe<Scalars['String']>;
  Mul10X20?: Maybe<Scalars['String']>;
  Mul10X200?: Maybe<Scalars['String']>;
  Mul1X200?: Maybe<Scalars['String']>;
  Mul200X25?: Maybe<Scalars['String']>;
  Mul20X100?: Maybe<Scalars['String']>;
  Mul20X50?: Maybe<Scalars['String']>;
  Mul25X200?: Maybe<Scalars['String']>;
  Mul2X100?: Maybe<Scalars['String']>;
  Mul2X50?: Maybe<Scalars['String']>;
  Mul40X25?: Maybe<Scalars['String']>;
  Mul40X50?: Maybe<Scalars['String']>;
  Mul4X25?: Maybe<Scalars['String']>;
  Mul4X50?: Maybe<Scalars['String']>;
  Mul50X100?: Maybe<Scalars['String']>;
  Mul50X20?: Maybe<Scalars['String']>;
  Mul5X20?: Maybe<Scalars['String']>;
  Mul5X200?: Maybe<Scalars['String']>;
  Mul80X25?: Maybe<Scalars['String']>;
  Mul8X25?: Maybe<Scalars['String']>;
};

export type AlternateProduct = {
  __typename?: 'AlternateProduct';
  brand: Scalars['String'];
  productNumber: Scalars['String'];
};

export type Amount = {
  __typename?: 'Amount';
  hundred?: Maybe<Scalars['String']>;
  one?: Maybe<Scalars['String']>;
  ten?: Maybe<Scalars['String']>;
  twentyFive?: Maybe<Scalars['String']>;
};

export type AmsQuotesInput = {
  quoteNumber: Scalars['String'];
};

export type AmsQuotesPayload = {
  __typename?: 'AmsQuotesPayload';
  avgPrice: Scalars['Float'];
  avgPriceInDollar: Scalars['Float'];
  cartId: Scalars['String'];
  country: Scalars['String'];
  currency: Scalars['String'];
  email: Scalars['String'];
  exchangeRate: Scalars['Float'];
  expiryDate: Scalars['Date'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  lastUpdatedDate: Scalars['Date'];
  partnumber?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  quoteId: Scalars['String'];
  quoteNumber: Scalars['String'];
  sampleCount: Scalars['Int'];
  shipWindow: Scalars['Int'];
  soldTo: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  totalPrice: Scalars['Float'];
  totalPriceInDollar: Scalars['Float'];
};

export enum AnalyticsDispType {
  BestSellers = 'best_sellers',
  BiaSc = 'biaSC',
  CompareTablePdp = 'compareTablePDP',
  Cp007 = 'CP007',
  CustomersAlsoViewedApi = 'customersAlsoViewedAPI',
  MTrackApi = 'mTrackAPI',
  MTrackApIv1 = 'mTrackAPIv1',
  NewProducts = 'new_products',
  PopularProducts = 'popular_products',
  QuickOrderApi = 'quickOrderAPI',
  RecentlyViewedAccountDashboard = 'recentlyViewedAccountDashboard',
  RecentlyViewedAdvancedSearch = 'recentlyViewedAdvancedSearch',
  RecentlyViewedDocumentsSearch = 'recentlyViewedDocumentsSearch',
  RecentlyViewedPdp = 'recentlyViewedPDP',
  RecentlyViewedQuickOrder = 'recentlyViewedQuickOrder',
  RecentlyViewedRchp = 'recentlyViewedRCHP',
  RecentlyViewedSrp = 'recentlyViewedSRP',
  RecommendedProductsPdp = 'recommendedProductsPDP',
  S002 = 'S002',
  S003 = 'S003',
  S007 = 'S007',
  S012 = 'S012',
  S015 = 'S015',
  S017 = 'S017',
  S020 = 'S020',
  S022 = 'S022',
  S029 = 'S029',
  S053 = 'S053',
  S064 = 'S064',
  S071 = 'S071',
  S103 = 'S103',
  S107 = 'S107',
  S111 = 'S111',
  S114 = 'S114',
  S125 = 'S125',
  S138 = 'S138',
  S140 = 'S140',
  S151 = 'S151',
  S194 = 'S194',
  S198 = 'S198',
  S199 = 'S199',
  S241 = 'S241',
  S280 = 'S280',
  S307 = 'S307',
  S315 = 'S315',
  S319 = 'S319',
  S324 = 'S324',
  S348 = 'S348',
  S366 = 'S366',
  S372 = 'S372',
  S395 = 'S395',
  S402 = 'S402',
  S863 = 'S863',
  SrpPromoApi = 'srpPromoAPI',
  SrpRecsApi = 'srpRecsAPI',
  StratosScFbtApi = 'stratosScFbtAPI'
}

export type AnalyticsProductAttribute = {
  __typename?: 'AnalyticsProductAttribute';
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type AntisenceSeq = {
  __typename?: 'AntisenceSeq';
  antisenceSeq?: Maybe<Scalars['String']>;
  serverError?: Maybe<ListError>;
};

export type AntiseraPeptideModel = {
  amount?: InputMaybe<Scalars['String']>;
  baseCount?: InputMaybe<Scalars['Int']>;
  cterminalMod?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  listError?: InputMaybe<Array<InputMaybe<ListErrorInput>>>;
  listPeptideCarrierProtein?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nterminalMod?: InputMaybe<Scalars['String']>;
  peptideAddSpecification: Scalars['String'];
  peptideCarrierProtein?: InputMaybe<Scalars['String']>;
  peptideName: Scalars['String'];
  projectType?: InputMaybe<Scalars['String']>;
  purityLevel?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['String']>;
  terms?: InputMaybe<Scalars['Boolean']>;
};

export type AntiseraPeptideModelResponse = {
  __typename?: 'AntiseraPeptideModelResponse';
  amount?: Maybe<Scalars['String']>;
  baseCount?: Maybe<Scalars['Int']>;
  cterminalMod?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  listError?: Maybe<Array<Maybe<ListError>>>;
  listPeptideCarrierProtein?: Maybe<Array<Maybe<Scalars['String']>>>;
  nterminalMod?: Maybe<Scalars['String']>;
  peptideAddSpecification?: Maybe<Scalars['String']>;
  peptideCarrierProtein?: Maybe<Scalars['String']>;
  peptideName?: Maybe<Scalars['String']>;
  projectType?: Maybe<Scalars['String']>;
  purityLevel?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['Boolean']>;
};

export type AntiseraPrice = {
  __typename?: 'AntiseraPrice';
  adsorptionFee?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  colConstructionFee?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  elisaFee?: Maybe<Scalars['String']>;
  listError?: Maybe<Array<Maybe<ListError>>>;
  listPeptideError?: Maybe<Array<Maybe<PeptideErrors>>>;
  material?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  priceHeaderError?: Maybe<Scalars['String']>;
  projectBaseFee?: Maybe<Scalars['String']>;
  purificationFee?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['String']>;
};

export type AntiseraPriceResponse = {
  __typename?: 'AntiseraPriceResponse';
  response?: Maybe<Array<Maybe<AntiseraPrice>>>;
  serverError?: Maybe<ListError>;
};

export type AntiseraSessionData = {
  __typename?: 'AntiseraSessionData';
  absorption?: Maybe<Scalars['String']>;
  absorptionColQuantity?: Maybe<Scalars['String']>;
  absorptionColumn?: Maybe<Scalars['String']>;
  absorptionType?: Maybe<Scalars['String']>;
  additionalServices?: Maybe<Array<Maybe<Scalars['String']>>>;
  adsorptionFee?: Maybe<Scalars['String']>;
  animalType?: Maybe<Scalars['String']>;
  applyToAll?: Maybe<Scalars['Boolean']>;
  cartId?: Maybe<Scalars['String']>;
  colConstructionFee?: Maybe<Scalars['String']>;
  columnConstruction?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  elisaAnalysis?: Maybe<Scalars['String']>;
  elisaFee?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  immunizationTerm?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  listAntigenForColumn?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  listAntiseraPeptideModel: Array<Maybe<AntiseraPeptideModelResponse>>;
  listError?: Maybe<Array<Maybe<ListError>>>;
  name?: Maybe<Scalars['String']>;
  noOfColumns?: Maybe<Scalars['String']>;
  numberOfAnimals?: Maybe<Scalars['String']>;
  numberOfPeptides?: Maybe<Scalars['String']>;
  oligoId?: Maybe<Scalars['Int']>;
  orgName?: Maybe<Scalars['String']>;
  peptide?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  projectBaseFee?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  projectType?: Maybe<Scalars['String']>;
  purificationColQuantity?: Maybe<Scalars['String']>;
  purificationColumn?: Maybe<Scalars['String']>;
  purificationFee?: Maybe<Scalars['String']>;
  purificationOption?: Maybe<Scalars['String']>;
  purificationType?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['String']>;
};

export type AntiseraSessionDataResponse = {
  __typename?: 'AntiseraSessionDataResponse';
  antiseraData?: Maybe<Array<Maybe<AntiseraSessionData>>>;
};

export type AntiseraSessionInput = {
  absorption?: InputMaybe<Scalars['String']>;
  absorptionColQuantity?: InputMaybe<Scalars['String']>;
  absorptionColumn?: InputMaybe<Scalars['String']>;
  absorptionType?: InputMaybe<Scalars['String']>;
  additionalServices?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adsorptionFee?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['String']>;
  animalType?: InputMaybe<Scalars['String']>;
  applyToAll?: InputMaybe<Scalars['Boolean']>;
  baseCount?: InputMaybe<Scalars['Int']>;
  cartId?: InputMaybe<Scalars['String']>;
  colConstructionFee?: InputMaybe<Scalars['String']>;
  columnConstruction?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  currencySymbol?: InputMaybe<Scalars['String']>;
  elisaAnalysis?: InputMaybe<Scalars['String']>;
  elisaFee?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  immunizationTerm?: InputMaybe<Scalars['String']>;
  itemId?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  listAntigenForColumn?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['String']>>>>>;
  listAntiseraPeptideModel: Array<InputMaybe<AntiseraPeptideModel>>;
  listError?: InputMaybe<Array<InputMaybe<ListErrorInput>>>;
  name?: InputMaybe<Scalars['String']>;
  noOfColumns?: InputMaybe<Scalars['String']>;
  numberOfAnimals?: InputMaybe<Scalars['String']>;
  numberOfPeptides?: InputMaybe<Scalars['String']>;
  oligoId?: InputMaybe<Scalars['Int']>;
  orgName?: InputMaybe<Scalars['String']>;
  peptide?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  projectBaseFee?: InputMaybe<Scalars['String']>;
  projectName?: InputMaybe<Scalars['String']>;
  projectType?: InputMaybe<Scalars['String']>;
  purificationAntisera?: InputMaybe<Scalars['String']>;
  purificationBuffer?: InputMaybe<Scalars['String']>;
  purificationColQuantity?: InputMaybe<Scalars['String']>;
  purificationColumn?: InputMaybe<Scalars['String']>;
  purificationFee?: InputMaybe<Scalars['String']>;
  purificationGlycAddition?: InputMaybe<Scalars['String']>;
  purificationOption?: InputMaybe<Scalars['String']>;
  purificationProcAddition?: InputMaybe<Scalars['String']>;
  purificationType?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['String']>;
  services?: InputMaybe<Scalars['String']>;
  subtotal?: InputMaybe<Scalars['String']>;
};

export type AntiseraTotalValidationResponse = {
  __typename?: 'AntiseraTotalValidationResponse';
  listAbsorptionColumn?: Maybe<Array<Maybe<Scalars['String']>>>;
  listAbsorptionType?: Maybe<Array<Maybe<Scalars['String']>>>;
  listAnimalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  listAntiseraPeptideModel?: Maybe<Array<Maybe<AntiseraPeptideModelResponse>>>;
  listError?: Maybe<Array<Maybe<ListError>>>;
  listImmunizationTerm?: Maybe<Array<Maybe<Scalars['String']>>>;
  listNoOfColumns?: Maybe<Array<Maybe<Scalars['String']>>>;
  listNumberOfAnimals?: Maybe<Array<Maybe<Scalars['String']>>>;
  listPeptide?: Maybe<Array<Maybe<Scalars['String']>>>;
  listPurificationColumn?: Maybe<Array<Maybe<Scalars['String']>>>;
  listPurificationType?: Maybe<Array<Maybe<Scalars['String']>>>;
  peptide?: Maybe<Scalars['String']>;
  projectType?: Maybe<Scalars['String']>;
  serverError?: Maybe<ListError>;
};

export type ApprovalConsolidationModel = {
  __typename?: 'ApprovalConsolidationModel';
  activate?: Maybe<Scalars['Boolean']>;
  delegateApproverId?: Maybe<Scalars['String']>;
  delegationApprovalExpirationDate?: Maybe<Scalars['String']>;
  isDelegateEnabled?: Maybe<Scalars['Boolean']>;
  participantApprovers?: Maybe<Array<Maybe<ParticipantApprover>>>;
};

export enum ApprovalTypeId {
  /** General Approval */
  Ga = 'GA',
  /** Item Amount Approval */
  Ia = 'IA',
  /** Transaction Amount Approval */
  Ta = 'TA'
}

export type ApprovalTypes = {
  __typename?: 'ApprovalTypes';
  approvalTypeDesc: Scalars['String'];
  approvalTypeId: ApprovalTypeId;
  approvalTypeKey: Scalars['String'];
};

export type Approver = {
  __typename?: 'Approver';
  approverId: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['String'];
};

export type Assay = {
  __typename?: 'Assay';
  Amplicon?: Maybe<Scalars['String']>;
  amplicon_size?: Maybe<Scalars['Int']>;
  assay_id?: Maybe<Scalars['String']>;
  exons?: Maybe<Scalars['String']>;
  gene_id?: Maybe<Scalars['Int']>;
  gene_sym?: Maybe<Scalars['String']>;
  i_seq?: Maybe<Scalars['String']>;
  l_seq?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  r_seq?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  ref_seq?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
};

export type AssayModel = {
  allAnalytesSelected?: InputMaybe<Scalars['String']>;
  analytes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  assayType?: InputMaybe<Scalars['String']>;
  customPremixedAvailable?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  customPremixSelection?: InputMaybe<Scalars['String']>;
  decisionTableVer?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  listAnalytes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listSampleType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listSearchResultModelErrors?: InputMaybe<Array<InputMaybe<ListErrorInput>>>;
  listWarnings?: InputMaybe<Array<InputMaybe<ListWarningInput>>>;
  material?: InputMaybe<Scalars['String']>;
  mutualAnalytesList?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['String']>>>>>;
  name?: InputMaybe<Scalars['String']>;
  panelId?: InputMaybe<Scalars['String']>;
  panelName?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  productTotal?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['String']>;
  requireSample?: InputMaybe<Scalars['String']>;
  researchAreaOracle?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  researchAreas?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sampleType?: InputMaybe<Scalars['String']>;
  selectedAnalytes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  spaceSaver?: InputMaybe<Scalars['String']>;
  spaceSaverSelection?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type AssayTypeItem = {
  __typename?: 'AssayTypeItem';
  assayType: Scalars['String'];
  plateType: Array<Scalars['String']>;
  speciesResearchArea: Array<SpeciesItem>;
};

export type Assessment = {
  code: Scalars['String'];
  response: Scalars['String'];
};

export type Assessments = {
  assessments: Array<InputMaybe<Assessment>>;
  comment: Scalars['String'];
  grade: Scalars['Int'];
  visible: Scalars['Boolean'];
};

export type AssistantInput = {
  messages: Array<UserMessage>;
};

export type AssistantResponse = {
  __typename?: 'AssistantResponse';
  alert?: Maybe<Scalars['String']>;
  recommendedProducts?: Maybe<Array<RecommendedProduct>>;
  response: Scalars['String'];
  suggestedQuestions?: Maybe<Array<Scalars['String']>>;
};

export type AssociatedProducts = {
  __typename?: 'AssociatedProducts';
  products: Array<Product>;
};

export type AutoCompleteCertificateInput = {
  brand?: InputMaybe<Scalars['String']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  productNumber?: InputMaybe<Scalars['String']>;
  term: Scalars['String'];
  type: Scalars['String'];
};

export type AutoCompleteCertificateItem = {
  __typename?: 'AutoCompleteCertificateItem';
  count?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type AutoCompleteCertificateResponse = {
  __typename?: 'AutoCompleteCertificateResponse';
  results?: Maybe<Array<AutoCompleteCertificateItem>>;
  term: Scalars['String'];
};

export type AvailabilityOverwriteMessage = {
  __typename?: 'AvailabilityOverwriteMessage';
  messageKey?: Maybe<Scalars['String']>;
  messageValue?: Maybe<Scalars['String']>;
  messageVariable1?: Maybe<Scalars['String']>;
  messageVariable2?: Maybe<Scalars['String']>;
  messageVariable3?: Maybe<Scalars['String']>;
};

export type AzureTokenInput = {
  code: Scalars['String'];
  session_state: Scalars['String'];
  state: Scalars['String'];
};

export type AzureTokenResponse = {
  __typename?: 'AzureTokenResponse';
  access_token?: Maybe<Scalars['String']>;
};

export type B2BChannelInformation = {
  __typename?: 'B2BChannelInformation';
  isB2B: Scalars['Boolean'];
  logoDirectory?: Maybe<Scalars['String']>;
};

export type B2bShoppingCartDataResponse = {
  __typename?: 'B2bShoppingCartDataResponse';
  isDecodeNeeded?: Maybe<Scalars['Boolean']>;
  isSapOci?: Maybe<Scalars['Boolean']>;
  postBackURL?: Maybe<Scalars['String']>;
  punchoutResponseMsg?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
};

export type B2bUserMetadata = {
  __typename?: 'B2bUserMetadata';
  country?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type B2BUserParticipantSitePreference = {
  __typename?: 'B2BUserParticipantSitePreference';
  websiteLocale?: Maybe<Scalars['String']>;
};

export type B2bUserResponse = {
  __typename?: 'B2bUserResponse';
  metadata?: Maybe<B2bUserMetadata>;
  participantSitePreference?: Maybe<B2BUserParticipantSitePreference>;
  userProfile?: Maybe<B2bUserResponseProfile>;
};

export type B2bUserResponseProfile = {
  __typename?: 'B2bUserResponseProfile';
  logonId?: Maybe<Scalars['String']>;
  profileType?: Maybe<Scalars['String']>;
};

export type BankDetails = {
  __typename?: 'BankDetails';
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  legalEntityName?: Maybe<Scalars['String']>;
  salesOrg?: Maybe<Scalars['String']>;
};

export type BasePrice = {
  __typename?: 'BasePrice';
  brand?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  listPrice?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  productName?: Maybe<Scalars['String']>;
  productNumber?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type BbeMaterialAvailability = {
  __typename?: 'BBEMaterialAvailability';
  messageKey?: Maybe<Scalars['String']>;
  messageValue?: Maybe<Scalars['String']>;
  messageVariable1?: Maybe<Scalars['String']>;
  messageVariable2?: Maybe<Scalars['String']>;
  messageVariable3?: Maybe<Scalars['String']>;
};

export type BigDataQueryInput = {
  brand?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  document_type: Scalars['String'];
  document_url: Scalars['String'];
  email_domain?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['String']>;
  portfolio_brand?: InputMaybe<Scalars['String']>;
  product_name?: InputMaybe<Scalars['String']>;
  product_name_suffix?: InputMaybe<Scalars['String']>;
  product_number?: InputMaybe<Scalars['String']>;
  user_id: Scalars['String'];
};

export type BiTubeData = {
  __typename?: 'BiTubeData';
  data: Array<DuplexDetails>;
};

export type BitubeTotalValidationResponse = {
  __typename?: 'BitubeTotalValidationResponse';
  brand?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  listErrors?: Maybe<Array<Maybe<ListError>>>;
  material?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  priceHeaderError?: Maybe<Scalars['String']>;
  serverError?: Maybe<ListError>;
};

export type BiTubeWellList = {
  __typename?: 'BiTubeWellList';
  additionalComments?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  antisenceBaseCount?: Maybe<Scalars['Int']>;
  antisenceFiveMod?: Maybe<Scalars['String']>;
  antisenceName?: Maybe<Scalars['String']>;
  antisenceSequence?: Maybe<Scalars['String']>;
  antisenceThreeMod?: Maybe<Scalars['String']>;
  antisenseGcContent?: Maybe<Scalars['String']>;
  antisenseMeltingTemparature?: Maybe<Scalars['String']>;
  antisenseMolecularWeight?: Maybe<Scalars['String']>;
  antisensePrimerDimer?: Maybe<Scalars['String']>;
  antisenseSecondaryStructure?: Maybe<Scalars['String']>;
  autoGenerateOligo?: Maybe<Scalars['Boolean']>;
  autoValidation?: Maybe<Scalars['Boolean']>;
  baseCount?: Maybe<Scalars['Int']>;
  column: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  concentration?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  customerAntisenseOverHang?: Maybe<Scalars['String']>;
  customerOverhang?: Maybe<Scalars['String']>;
  fiveMod?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  gcContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isOverridden?: Maybe<Scalars['Boolean']>;
  listError?: Maybe<Array<Maybe<ListError>>>;
  listWarning?: Maybe<Array<Maybe<ListWarning>>>;
  meltingTemparature?: Maybe<Scalars['String']>;
  molecularWeight?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderItemId?: Maybe<Scalars['String']>;
  packageType?: Maybe<Scalars['String']>;
  pageNo?: Maybe<Scalars['Int']>;
  primerDimer?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  purification?: Maybe<Scalars['String']>;
  row: Scalars['String'];
  scale?: Maybe<Scalars['String']>;
  secondaryStructure?: Maybe<Scalars['String']>;
  senseNameCheck?: Maybe<Scalars['Boolean']>;
  sequence?: Maybe<Scalars['String']>;
  shipping?: Maybe<Scalars['String']>;
  technicalAntisenceSequence?: Maybe<Scalars['String']>;
  technicalSequence?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['Boolean']>;
  threeMod?: Maybe<Scalars['String']>;
  tubes?: Maybe<Scalars['Int']>;
};

export type BiTubeWellListInput = {
  additionalComments?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['String']>;
  antisenceBaseCount?: InputMaybe<Scalars['Int']>;
  antisenceFiveMod?: InputMaybe<Scalars['String']>;
  antisenceName?: InputMaybe<Scalars['String']>;
  antisenceSequence?: InputMaybe<Scalars['String']>;
  antisenceThreeMod?: InputMaybe<Scalars['String']>;
  antisenseGcContent?: InputMaybe<Scalars['String']>;
  antisenseMeltingTemparature?: InputMaybe<Scalars['String']>;
  antisenseMolecularWeight?: InputMaybe<Scalars['String']>;
  antisensePrimerDimer?: InputMaybe<Scalars['String']>;
  antisenseSecondaryStructure?: InputMaybe<Scalars['String']>;
  autoGenerateOligo?: InputMaybe<Scalars['Boolean']>;
  autoValidation?: InputMaybe<Scalars['Boolean']>;
  baseCount?: InputMaybe<Scalars['Int']>;
  column: Scalars['String'];
  comments?: InputMaybe<Scalars['String']>;
  concentration?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  customerAntisenseOverHang?: InputMaybe<Scalars['String']>;
  customerOverhang?: InputMaybe<Scalars['String']>;
  fiveMod?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  gcContent?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isOverridden?: InputMaybe<Scalars['Boolean']>;
  listError?: InputMaybe<Array<InputMaybe<ListErrorInput>>>;
  listWarning?: InputMaybe<Array<InputMaybe<ListWarningInput>>>;
  meltingTemparature?: InputMaybe<Scalars['String']>;
  molecularWeight?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orderItemId?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  pageNo?: InputMaybe<Scalars['Int']>;
  primerDimer?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  purification?: InputMaybe<Scalars['String']>;
  row: Scalars['String'];
  scale?: InputMaybe<Scalars['String']>;
  secondaryStructure?: InputMaybe<Scalars['String']>;
  senseNameCheck?: InputMaybe<Scalars['Boolean']>;
  sequence?: InputMaybe<Scalars['String']>;
  shipping?: InputMaybe<Scalars['String']>;
  technicalAntisenceSequence?: InputMaybe<Scalars['String']>;
  technicalSequence?: InputMaybe<Scalars['String']>;
  terms?: InputMaybe<Scalars['Boolean']>;
  threeMod?: InputMaybe<Scalars['String']>;
  tubes?: InputMaybe<Scalars['Int']>;
};

export type BiTubeWelllistResponse = {
  __typename?: 'BiTubeWelllistResponse';
  countryCode?: Maybe<Scalars['String']>;
  errorType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  listError?: Maybe<Array<Maybe<ListError>>>;
  listWarning?: Maybe<Array<Maybe<ListWarning>>>;
  oligoId?: Maybe<Scalars['Int']>;
};

export type BiTubeWellListUploadInput = {
  antisenceFiveMod?: InputMaybe<Scalars['String']>;
  antisenceName?: InputMaybe<Scalars['String']>;
  antisenceSequence?: InputMaybe<Scalars['String']>;
  antisenceThreeMod?: InputMaybe<Scalars['String']>;
  column?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  customerAntisenseOverHang?: InputMaybe<Scalars['String']>;
  customerOverhang?: InputMaybe<Scalars['String']>;
  fiveMod?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  row?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['String']>;
  threeMod?: InputMaybe<Scalars['String']>;
};

export enum BlueSystemSysIds {
  Emerald = 'emerald',
  Iscala = 'iscala',
  Orc = 'ORC',
  TempoEu = 'TEMPO_EU',
  TempoLa = 'TEMPO_LA',
  TempoNa = 'TEMPO_NA'
}

export type Brand = {
  __typename?: 'Brand';
  cells?: Maybe<CatalogImage>;
  color?: Maybe<Scalars['String']>;
  erpKey: Scalars['String'];
  key: Scalars['ID'];
  logo?: Maybe<CatalogImage>;
  name?: Maybe<Scalars['String']>;
};

export type BrowserMetadata = {
  __typename?: 'BrowserMetadata';
  description?: Maybe<Scalars['String']>;
  keywords?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type CalculatedValues = {
  __typename?: 'CalculatedValues';
  amount?: Maybe<Scalars['String']>;
  daughterPlateRange?: Maybe<Scalars['Int']>;
  maxAmount?: Maybe<Scalars['Float']>;
  maxConcentration?: Maybe<Scalars['Float']>;
  maxVolume?: Maybe<Scalars['Float']>;
  minVolume?: Maybe<Scalars['Float']>;
  scale?: Maybe<Scalars['String']>;
};

export type CardAttribute = {
  __typename?: 'CardAttribute';
  application: Array<Scalars['String']>;
  citationCount?: Maybe<Scalars['String']>;
};

export enum CarrierId {
  Dpd = 'dpd',
  Fedex = 'fedex'
}

export type Cart = {
  __typename?: 'Cart';
  approvers: Array<Approver>;
  bankDetails?: Maybe<BankDetails>;
  billingAddress?: Maybe<CartAddress>;
  blanketPoNumber?: Maybe<Scalars['String']>;
  cartEligibleForBuyItNow: Scalars['Boolean'];
  cartIdAlias?: Maybe<Scalars['String']>;
  cartType?: Maybe<Scalars['String']>;
  chargebackCodes: Array<ChargebackCode>;
  contractNumber?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
  creditCardTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  currency?: Maybe<Scalars['String']>;
  customerInfo?: Maybe<CustomerInfo>;
  customerRequestId?: Maybe<Scalars['String']>;
  customerServiceInstructions?: Maybe<Scalars['String']>;
  deaItemExists: Scalars['Boolean'];
  dealers: Array<Dealer>;
  deaMaterials: Array<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  displayDirectShipmentCheckbox: Scalars['Boolean'];
  displayPromoCode: Scalars['Boolean'];
  displayRejectAllRequestButton: Scalars['Boolean'];
  displayRejectThisRequestButton: Scalars['Boolean'];
  displaySaveCartButton: Scalars['Boolean'];
  displaySubmitCartButton: Scalars['Boolean'];
  displaySubmitQuoteButton: Scalars['Boolean'];
  displaySubmitRequestButton: Scalars['Boolean'];
  eurVolatileMassCharge?: Maybe<Scalars['Float']>;
  hasHazardousMaterial?: Maybe<Scalars['Boolean']>;
  hasQuotePriceItem: Scalars['Boolean'];
  headerSAPError?: Maybe<SapError>;
  hidePrice: Scalars['Boolean'];
  id: Scalars['ID'];
  isDirectShipment: Scalars['Boolean'];
  isEmproveProductExists: Scalars['Boolean'];
  items: Array<CartItem>;
  merchantLocation?: Maybe<Scalars['String']>;
  netTotal?: Maybe<Scalars['Float']>;
  orderingPartyAddress?: Maybe<CartAddress>;
  packingSlipNote?: Maybe<Scalars['String']>;
  partners: CartPartners;
  paymentMethods?: Maybe<CartPaymentMethods>;
  pin?: Maybe<Scalars['String']>;
  salesOrderSource?: Maybe<SalesOrderSource>;
  selectedPaymentMethod?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<CartAddress>;
  shippingPreference?: Maybe<ShippingPreference>;
  supportedPaymentMethods: Array<Scalars['String']>;
  totals?: Maybe<CartTotals>;
  transSurchargeMessage?: Maybe<Scalars['String']>;
};

export type CartAdditionalInfo = {
  __typename?: 'CartAdditionalInfo';
  carrierRestriction?: Maybe<Scalars['String']>;
  casNumber?: Maybe<Scalars['String']>;
  jfcCode?: Maybe<Scalars['String']>;
  pdcCode?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  tariff?: Maybe<Scalars['String']>;
  unNumber?: Maybe<Scalars['String']>;
};

export type CartAddress = {
  __typename?: 'CartAddress';
  attentionTo?: Maybe<LockableValue>;
  buildingRoom?: Maybe<LockableValue>;
  ccForOrderConfEmailIds?: Maybe<LockableValue>;
  city?: Maybe<LockableValue>;
  country?: Maybe<LockableValue>;
  department?: Maybe<LockableValue>;
  district?: Maybe<LockableValue>;
  email?: Maybe<LockableValue>;
  extension?: Maybe<LockableValue>;
  fax?: Maybe<LockableValue>;
  firstName?: Maybe<LockableValue>;
  lastName?: Maybe<LockableValue>;
  mobile?: Maybe<LockableValue>;
  organizationName?: Maybe<LockableValue>;
  postalCode?: Maybe<LockableValue>;
  postOfficeBox?: Maybe<LockableValue>;
  recipientName?: Maybe<LockableValue>;
  state?: Maybe<LockableValue>;
  street1?: Maybe<LockableValue>;
  street3?: Maybe<LockableValue>;
  street4?: Maybe<LockableValue>;
  street5?: Maybe<LockableValue>;
  telephone?: Maybe<LockableValue>;
};

export type CartAddressInput = {
  attentionTo?: InputMaybe<Scalars['String']>;
  buildingRoom?: InputMaybe<Scalars['String']>;
  ccForOrderConfEmailIds?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  customerNumber?: InputMaybe<Scalars['String']>;
  department?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  extension?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  organizationName?: InputMaybe<Scalars['String']>;
  partnerNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  postOfficeBox?: InputMaybe<Scalars['String']>;
  recipientName?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  street3?: InputMaybe<Scalars['String']>;
  street4?: InputMaybe<Scalars['String']>;
  street5?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};

export type CartItem = {
  __typename?: 'CartItem';
  additionalInfo?: Maybe<CartAdditionalInfo>;
  availabilityMessages: Array<Maybe<CartItemAvailabilityMessages>>;
  availabilityOverwriteMessage?: Maybe<BbeMaterialAvailability>;
  availableQtyInStock?: Maybe<Scalars['Int']>;
  /** The lot/batch number if this item was added from a quote */
  batchNumber?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  bundleItems: Array<PromotionalBundleItemPricing>;
  carrierName?: Maybe<Scalars['String']>;
  complianceMessages: Array<Scalars['String']>;
  complianceRegulationMappingList: Array<ComplianceObj>;
  configuration: Array<ProductConfiguration>;
  configurationId?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  customerOrderInfo?: Maybe<CustomerOrderInfo>;
  dealerId?: Maybe<Scalars['String']>;
  deviceSerialNumber?: Maybe<Array<Maybe<Scalars['String']>>>;
  discontinuedPricingInfo?: Maybe<DiscontinuedMaterialPricing>;
  displayEditButton: Scalars['Boolean'];
  distrChainStatus?: Maybe<Scalars['String']>;
  emproveDossierInfo?: Maybe<EmproveDossierItem>;
  errors: Array<SapError>;
  expirationDate?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  hidePriceMessageKey?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  importLicense?: Maybe<Scalars['String']>;
  isBlockedProduct: Scalars['Boolean'];
  isKit: Scalars['Boolean'];
  isQuotePriceItem?: Maybe<Scalars['Boolean']>;
  isSingleLotSelected?: Maybe<Scalars['Boolean']>;
  itemCategory?: Maybe<Scalars['String']>;
  kitItems: Array<CartKitItem>;
  leadTime?: Maybe<Scalars['Int']>;
  listPriceCurrency?: Maybe<Scalars['String']>;
  marketplaceOfferId?: Maybe<Scalars['String']>;
  marketplaceShippingMethod?: Maybe<Scalars['String']>;
  marketplaceShippingPreference?: Maybe<MarketplaceShippingPreference>;
  material: Material;
  minimumOrderQuantity?: Maybe<Scalars['Int']>;
  plant?: Maybe<Scalars['String']>;
  pricing?: Maybe<CartItemPricing>;
  product?: Maybe<Scalars['String']>;
  productNote?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  promoCode?: Maybe<Scalars['String']>;
  promotionalMessage?: Maybe<CartItemPromotionalMessage>;
  quantity: Scalars['Int'];
  /** Only returned if this item was added from a quote */
  quoteItemNumber?: Maybe<Scalars['String']>;
  /** Only returned if this item was added from a quote */
  quoteNumber?: Maybe<Scalars['String']>;
  redirectLinkForEdit?: Maybe<Scalars['String']>;
  /** The remaining qty that can be added from the quote this was added from */
  remainingReserveQuantity?: Maybe<Scalars['Int']>;
  requestedDeliveryDate?: Maybe<Scalars['String']>;
  routeInfo?: Maybe<Scalars['String']>;
  sharedListData?: Maybe<CartItemSharedListInfoOutput>;
  shipMode?: Maybe<Scalars['String']>;
  supplementaryMessage?: Maybe<SupplementaryMessage>;
  /** The total qty originally quoted */
  totalReserveQuantity?: Maybe<Scalars['Int']>;
  type: CartItemType;
  vendorNumber?: Maybe<Scalars['String']>;
  yourReference?: Maybe<Scalars['String']>;
};

export type CartItemAvailabilityMessages = {
  __typename?: 'CartItemAvailabilityMessages';
  date?: Maybe<Scalars['Date']>;
  key: Scalars['String'];
  plantLoc?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type CartItemInput = {
  /** Used only when converting a quote to an order in Aldrich Market Select */
  amsQuoteNumber?: InputMaybe<Scalars['String']>;
  /** Used only for materials where the material endpoint returns nothing */
  brand?: InputMaybe<Scalars['String']>;
  /** Used only for deleting an item */
  cartItemId?: InputMaybe<Scalars['String']>;
  /** Used only for configurable cart items (type must be Configurator) */
  configurationId?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  /** Used only for configurable cart items in quote to order conversion */
  customerPrice?: InputMaybe<Scalars['Float']>;
  /** Used only for dtaq customers */
  dealerId?: InputMaybe<Scalars['String']>;
  /** Used only for Emprove documents */
  emproveDossierInfo?: InputMaybe<EmproveDossierInfo>;
  /** Used to associate items in a standing order (cart item id) */
  groupId?: InputMaybe<Scalars['String']>;
  isQuotePriceItem?: InputMaybe<Scalars['Boolean']>;
  isSingleLotSelected?: InputMaybe<Scalars['Boolean']>;
  itemDeletionFlag?: InputMaybe<Scalars['Boolean']>;
  marketplaceOfferId?: InputMaybe<Scalars['String']>;
  materialDescription?: InputMaybe<Scalars['String']>;
  materialName?: InputMaybe<Scalars['String']>;
  materialNumber: Scalars['String'];
  productNote?: InputMaybe<Scalars['String']>;
  promoCode?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Int'];
  /** Used only when converting a quote to an order */
  quoteDocType?: InputMaybe<Scalars['String']>;
  /** Used only when converting a quote to an order */
  quoteItemNumber?: InputMaybe<Scalars['String']>;
  /** Used only when converting a quote to an order */
  quoteNumber?: InputMaybe<Scalars['String']>;
  requestedDeliveryDate?: InputMaybe<Scalars['String']>;
  /** Used only adding an item from a Shared List */
  sharedListData?: InputMaybe<CartItemSharedListInfo>;
  /** Used only when converting a quote to an order */
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CartItemType>;
  yourReference?: InputMaybe<Scalars['String']>;
};

export type CartItemPricing = {
  __typename?: 'CartItemPricing';
  listPrice?: Maybe<Scalars['Float']>;
  netPrice?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

export type CartItemPromotionalMessage = {
  __typename?: 'CartItemPromotionalMessage';
  messageKey?: Maybe<Scalars['String']>;
  messageValue?: Maybe<Scalars['String']>;
  messageVariable1?: Maybe<Scalars['String']>;
  messageVariable2?: Maybe<Scalars['String']>;
  messageVariable3?: Maybe<Scalars['String']>;
};

export type CartItemSharedListInfo = {
  sharedListId: Scalars['String'];
  sharedListItemId: Scalars['String'];
};

export type CartItemSharedListInfoOutput = {
  __typename?: 'CartItemSharedListInfoOutput';
  sharedListId: Scalars['String'];
  sharedListItemId: Scalars['String'];
};

export enum CartItemType {
  Ams = 'AMS',
  Configurator = 'Configurator',
  Contract = 'Contract',
  Emprove = 'Emprove',
  License = 'License',
  Prepack = 'Prepack',
  PromotionalBundle = 'PromotionalBundle',
  ThirdPartyProvider = 'ThirdPartyProvider'
}

export type CartItemUpdateInput = {
  cartItemId: Scalars['ID'];
  productNote?: InputMaybe<Scalars['String']>;
  promoCode: Scalars['String'];
  quantity: Scalars['Int'];
  yourReference?: InputMaybe<Scalars['String']>;
};

export type CartKitItem = {
  __typename?: 'CartKitItem';
  listPrice: Scalars['Float'];
  materialDescription: Scalars['String'];
  materialNumber: Scalars['String'];
  netPrice: Scalars['Float'];
  price: Scalars['Float'];
  quantity: Scalars['Int'];
};

export type CartPartners = {
  __typename?: 'CartPartners';
  billTo?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  payer?: Maybe<Scalars['String']>;
  SalesOrg?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  soldTo?: Maybe<Scalars['String']>;
};

export type CartPartnersInput = {
  billTo?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  payer?: InputMaybe<Scalars['String']>;
  SalesOrg?: InputMaybe<Scalars['String']>;
  shipTo?: InputMaybe<Scalars['String']>;
  soldTo?: InputMaybe<Scalars['String']>;
};

export type CartPaymentMethods = {
  __typename?: 'CartPaymentMethods';
  creditCard?: Maybe<CreditCardPaymentMethod>;
  labContract?: Maybe<PaymentMethod>;
  purchaseOrder?: Maybe<PaymentMethod>;
  requisition?: Maybe<PaymentMethod>;
};

export type CartPaymentMethodsInput = {
  labContract?: InputMaybe<Scalars['String']>;
  purchaseOrder?: InputMaybe<Scalars['String']>;
  requisition?: InputMaybe<Scalars['String']>;
};

export type CartPreferences = {
  __typename?: 'CartPreferences';
  cartAddresses: CartValues;
  cartOrderText: CartValues;
  cartPaymentInfo: CartValues;
  cartShippingMethods: CartValues;
  defaultOpenLines: Scalars['Int'];
  defaultOpenLinesEnabled: Scalars['Boolean'];
  defaultOpenLinesLocked: Scalars['Boolean'];
  enableFDDForB2B?: Maybe<Scalars['Boolean']>;
  enableProductBlocking: Scalars['Boolean'];
  enableSingleLotB2B?: Maybe<Scalars['Boolean']>;
  fddLevelB2B?: Maybe<Scalars['String']>;
  orderText: CartValues;
  participantId: Scalars['String'];
  promotionalCodeField: CartValues;
  tariffCode: CartValues;
  unNumber: CartValues;
  yourReferenceField: CartValues;
  yourReferenceLabel?: Maybe<Scalars['String']>;
};

export enum CartRequestAction {
  Bluecart = 'bluecart',
  Bluecartmini = 'bluecartmini',
  Bluesavedcart = 'bluesavedcart',
  Buyitnowcart = 'buyitnowcart',
  Buyitnowcartmini = 'buyitnowcartmini',
  Contractcart = 'contractcart',
  Dealercartmini = 'dealercartmini',
  Marketplace = 'marketplace',
  Marketplacebuyitnowcart = 'marketplacebuyitnowcart',
  Mini = 'mini',
  Orc = 'orc',
  Orcmini = 'orcmini',
  Quotefullyconvertcart = 'quotefullyconvertcart',
  Quotefullyconvertcartmini = 'quotefullyconvertcartmini',
  Savedcart = 'savedcart',
  Savedcartmini = 'savedcartmini'
}

export type CartSubmission = {
  __typename?: 'CartSubmission';
  errors: Array<SapError>;
  orderId?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderStatus?: Maybe<Scalars['String']>;
  orderTimedOut: Scalars['Boolean'];
  paymentRedirectURL?: Maybe<Scalars['String']>;
};

export type CartTotals = {
  __typename?: 'CartTotals';
  alcTax: Scalars['Float'];
  complianceFee: Scalars['Float'];
  dutyTotal: Scalars['Float'];
  electricalFee: Scalars['Float'];
  eurVolatileMassCharge?: Maybe<Scalars['Float']>;
  iceCharge: Scalars['Float'];
  packageCharge: Scalars['Float'];
  shipping: Scalars['Float'];
  subtotal: Scalars['Float'];
  tax: Scalars['Float'];
  total: Scalars['Float'];
};

export type CartValues = {
  __typename?: 'CartValues';
  isEnabled?: Maybe<Scalars['Boolean']>;
  isLocked?: Maybe<Scalars['Boolean']>;
};

export type CatalogImage = {
  __typename?: 'CatalogImage';
  altText?: Maybe<Scalars['String']>;
  brandKey?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  largeUrl: Scalars['String'];
  mediumUrl: Scalars['String'];
  productKey?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  smallUrl: Scalars['String'];
  videoUrl?: Maybe<Scalars['String']>;
};

export enum CatalogType {
  B2b = 'b2b',
  Buildingblocks = 'buildingblocks',
  Marketplace = 'marketplace',
  Pm = 'pm',
  Sial = 'sial',
  Sialblue = 'sialblue',
  Sialpurple = 'sialpurple'
}

export type Category = {
  __typename?: 'Category';
  categoryDisplayName?: Maybe<Scalars['String']>;
  categoryName: Scalars['String'];
  categoryType?: Maybe<Scalars['String']>;
  helpText?: Maybe<Scalars['String']>;
  modifications: Array<CategoryModifications>;
  order?: Maybe<Scalars['String']>;
};

export type CategoryModifications = {
  __typename?: 'CategoryModifications';
  displayName: Scalars['String'];
  displayType: Scalars['String'];
  isDefault: Scalars['String'];
  name: Scalars['String'];
  order?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type CategoryOption = {
  __typename?: 'CategoryOption';
  displayName: Scalars['String'];
  displayType: Scalars['String'];
  isDefault?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  value: Scalars['String'];
};

export type Certificate = CertificateText | CertificateUrl;

export type CertificateContent = {
  __typename?: 'CertificateContent';
  content?: Maybe<Array<Maybe<DocumentationContent>>>;
  documentType?: Maybe<Scalars['String']>;
  text?: Maybe<Content>;
};

export type CertificateContentInput = {
  brand?: InputMaybe<Scalars['String']>;
  documentType?: InputMaybe<Scalars['String']>;
  productNumber: Scalars['String'];
};

export type CertificateInput = {
  brand?: InputMaybe<Scalars['String']>;
  control?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  documentType?: InputMaybe<Scalars['String']>;
  lotNumber: Scalars['String'];
  productNumber: Scalars['String'];
  sendLotNumber?: InputMaybe<Scalars['Boolean']>;
  useCount?: InputMaybe<Scalars['Boolean']>;
  useFilters?: InputMaybe<Scalars['Boolean']>;
};

export type CertificateText = {
  __typename?: 'CertificateText';
  brand: Scalars['String'];
  lotNumber: Scalars['String'];
  owner?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  signatureUrl: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  testSpecifications?: Maybe<Array<TestSpec>>;
  unit?: Maybe<Scalars['String']>;
};

export enum CertificateType {
  Coa = 'COA',
  Coo = 'COO',
  Coq = 'COQ'
}

export type CertificateUrl = {
  __typename?: 'CertificateUrl';
  brand: Scalars['String'];
  product: Product;
  streamData?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type ChangePasswordInput = {
  newPassword: Scalars['String'];
  newPasswordConfirm: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
};

export type ChargebackCode = {
  __typename?: 'ChargebackCode';
  chargebackCode?: Maybe<Scalars['String']>;
  chargebackLabel?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  mask?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type ChargebackCodeInput = {
  chargebackCode?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type ChargebackInputList = {
  chargebackCode?: InputMaybe<Scalars['String']>;
  chargebackLabel?: InputMaybe<Scalars['String']>;
  isDisplay?: InputMaybe<Scalars['Boolean']>;
  isEditable?: InputMaybe<Scalars['Boolean']>;
  isOnEmail?: InputMaybe<Scalars['Boolean']>;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  partners?: InputMaybe<ChargebackPartnerInput>;
  value?: InputMaybe<Scalars['String']>;
};

export type ChargebackPartnerInput = {
  chargeback_bill_to?: InputMaybe<Scalars['String']>;
  chargeback_payer?: InputMaybe<Scalars['String']>;
  chargeback_ship_to?: InputMaybe<Scalars['String']>;
  chargeback_sold_to?: InputMaybe<Scalars['String']>;
};

export type ChatConfigResponse = {
  __typename?: 'ChatConfigResponse';
  baseCoreURL?: Maybe<Scalars['String']>;
  baseLiveAgentContentURL?: Maybe<Scalars['String']>;
  baseLiveAgentURL?: Maybe<Scalars['String']>;
  buttonId?: Maybe<Scalars['String']>;
  chatType?: Maybe<Scalars['String']>;
  communityEndpointURL?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  defaultMinimizedText?: Maybe<Scalars['String']>;
  deploymentId?: Maybe<Scalars['String']>;
  disabledMinimizedText?: Maybe<Scalars['String']>;
  displayHelpButton?: Maybe<Scalars['String']>;
  enabledFeatures?: Maybe<Scalars['String']>;
  entryFeature?: Maybe<Scalars['String']>;
  environments?: Maybe<Scalars['String']>;
  eswLiveAgentDevName?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  loadingText?: Maybe<Scalars['String']>;
  offlineSupportMinimizedText?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  routes: Array<Scalars['String']>;
  storeId?: Maybe<Scalars['Int']>;
};

export type CloneInformation = {
  __typename?: 'CloneInformation';
  cellType?: Maybe<Scalars['String']>;
  cloneName?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  geneId?: Maybe<Scalars['String']>;
  geneSymbol?: Maybe<Scalars['String']>;
  knockdown?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  oligoSeq?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  refSeq?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  searchTrcVersion?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  synonyms?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetSeq?: Maybe<Scalars['String']>;
  trcId?: Maybe<Scalars['String']>;
  trcVersion?: Maybe<Scalars['String']>;
  validated?: Maybe<Scalars['String']>;
};

export type CompanionProducts = {
  __typename?: 'CompanionProducts';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Compliance = {
  __typename?: 'Compliance';
  images: Array<CatalogImage>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ComplianceDocument = {
  __typename?: 'ComplianceDocument';
  itemComplianceDocuments?: Maybe<Array<Maybe<ItemComplianceDocument>>>;
  sku?: Maybe<Scalars['String']>;
};

export type ComplianceJp = {
  __typename?: 'ComplianceJp';
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  seq?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type ComplianceObj = {
  __typename?: 'ComplianceObj';
  actionRequired: Scalars['String'];
  controlClass: Scalars['String'];
  description: Scalars['String'];
  regulationCode: Scalars['String'];
};

export type ComplianceReach = {
  __typename?: 'ComplianceReach';
  casNos?: Maybe<Array<Maybe<Scalars['String']>>>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum ComponentType {
  Bulletin = 'bulletin',
  KitOnly = 'kitOnly',
  KitSoldSeparate = 'kitSoldSeparate'
}

export type ConfContactDetailsResponse = {
  __typename?: 'ConfContactDetailsResponse';
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ConfigurableOption = {
  __typename?: 'ConfigurableOption';
  five_prime?: Maybe<FivePrime>;
};

export type Configurations = {
  __typename?: 'Configurations';
  errorType?: Maybe<Scalars['String']>;
  modifications: Array<Modifications>;
  oligoComplementName?: Maybe<Scalars['String']>;
  oligoComplementSequence?: Maybe<Scalars['String']>;
  productDetails?: Maybe<Scalars['String']>;
  specifications: Array<Specifications>;
};

export type ConfiguratorAddToMiniCartInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  dealerId?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  sapNextEnabled?: InputMaybe<Scalars['Boolean']>;
  store?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
};

export type ConfiguratorMilliplexAddToCartInput = {
  analytes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  appendToMaterialNumber?: InputMaybe<Scalars['String']>;
  assayType?: InputMaybe<Scalars['String']>;
  customPremixedAvailable?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  customPremixSelection?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  listSampleType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  material?: InputMaybe<Scalars['String']>;
  panelId?: InputMaybe<Scalars['String']>;
  panelName?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  requireSample?: InputMaybe<Scalars['String']>;
  researchAreas?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sampleType?: InputMaybe<Scalars['String']>;
  selectedAnalytes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  spaceSaver?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type ConfiguratorMilliplexAddToCartResponse = {
  __typename?: 'ConfiguratorMilliplexAddToCartResponse';
  result?: Maybe<Scalars['String']>;
  serverError?: Maybe<ListError>;
};

export type ConfiguratorMiniCartResponse = {
  __typename?: 'ConfiguratorMiniCartResponse';
  result?: Maybe<Scalars['String']>;
  serverError?: Maybe<ListError>;
};

export type ConfiguratorPlates = {
  __typename?: 'ConfiguratorPlates';
  modifications: Array<Modifications>;
};

export type ConfiguratorProductInput = {
  cartId?: InputMaybe<Scalars['String']>;
  cartItemId?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  guestParticipantId?: InputMaybe<Scalars['String']>;
  isCSR?: InputMaybe<Scalars['Boolean']>;
  isCSRSupervisor?: InputMaybe<Scalars['Boolean']>;
  isRegUser?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  sapNextEnabled?: InputMaybe<Scalars['Boolean']>;
  store?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type ConfiguratorProductUserInput = {
  isCSR?: InputMaybe<Scalars['Boolean']>;
  isCSRSupervisor?: InputMaybe<Scalars['Boolean']>;
  isRegUser?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
};

export type ConfirmAffiliationInput = {
  billTo?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['String']>;
  payer?: InputMaybe<Scalars['String']>;
  salesOrg?: InputMaybe<Scalars['String']>;
  shipTo?: InputMaybe<Scalars['String']>;
  soldTo?: InputMaybe<Scalars['String']>;
};

export type ConfirmOrderQuoteInput = {
  action?: InputMaybe<Scalars['String']>;
  identifier?: InputMaybe<Scalars['String']>;
  sapOrderNo?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type ConsolidateOrdersIntoActiveCartInput = {
  orderIds: Array<Scalars['String']>;
};

export type ConsolidationHistory = {
  __typename?: 'ConsolidationHistory';
  billTo: Scalars['String'];
  consolidatorId: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
  numberOfItems: Scalars['Int'];
  orderId: Scalars['String'];
  orderNumber: Scalars['String'];
  orderTotal: Scalars['Float'];
  participantId: Scalars['String'];
  requester: Scalars['String'];
  shipTo: Scalars['String'];
  status: Scalars['String'];
  storeId: Scalars['Int'];
  submittedDate: Scalars['Date'];
};

export type ConsolidationOrder = {
  __typename?: 'ConsolidationOrder';
  billTo?: Maybe<Scalars['String']>;
  billToAddress: CartAddress;
  consolidationHistoryList: Array<ConsolidationHistory>;
  shipTo?: Maybe<Scalars['String']>;
  shipToAddress: CartAddress;
};

export type ContactInformation = {
  __typename?: 'ContactInformation';
  email: Scalars['String'];
  extension?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  telephone: Scalars['String'];
};

export type ContactSellerInput = {
  offerMessage?: InputMaybe<OfferMessage>;
  orderMessage?: InputMaybe<OrderMessage>;
  sellerMessage?: InputMaybe<SellerMessage>;
};

export type ContactSellerResponse = {
  __typename?: 'ContactSellerResponse';
  id?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  threadId?: Maybe<Scalars['String']>;
};

export type Content = {
  __typename?: 'Content';
  owner?: Maybe<Scalars['String']>;
  productNumber?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export type ContentDocumentSearchResponse = SearchResults & {
  __typename?: 'ContentDocumentSearchResponse';
  facets: Array<Facet>;
  items: Array<TechnicalDocument>;
  metadata: SearchResultsMetadata;
};

export type ContentSearchInput = {
  contentSearchType: ContentSearchType;
  facets?: InputMaybe<Array<FacetInput>>;
  facetSet?: InputMaybe<Array<FacetSetInput>>;
  filterSite?: InputMaybe<Scalars['String']>;
  pagination?: Pagination;
  searchTerm?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
  type?: InputMaybe<Scalars['String']>;
};

export enum ContentSearchType {
  Documents = 'documents',
  SiteContent = 'siteContent'
}

export type ContractCardTransactionsInput = {
  cardType: ContractType;
  contractNumber: Scalars['String'];
  pin: Scalars['String'];
};

export type ContractCardTransactionsPayload = {
  __typename?: 'ContractCardTransactionsPayload';
  balance: Scalars['Float'];
  currency: Scalars['String'];
  transactionHistory: Array<TransactionHistory>;
  validTo: Scalars['Date'];
};

export type ContractPaymentMethod = {
  __typename?: 'ContractPaymentMethod';
  amounts?: Maybe<Array<Maybe<Scalars['String']>>>;
  contactInformation?: Maybe<ContactInformation>;
  contractType?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
};

export enum ContractType {
  Blanketpo = 'BLANKETPO',
  Mpower = 'MPOWER',
  Rapidred = 'RAPIDRED'
}

export type Controls = {
  __typename?: 'Controls';
  currency?: Maybe<Scalars['String']>;
  efficiencyScore?: Maybe<Scalars['String']>;
  geneId?: Maybe<Scalars['String']>;
  geneSymbol?: Maybe<Scalars['String']>;
  grnaId?: Maybe<Scalars['String']>;
  moreInformation?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  recommendedStatus?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  specificityScore?: Maybe<Scalars['String']>;
};

export type CountryAccountSetup = {
  __typename?: 'CountryAccountSetup';
  guestShoppingType?: Maybe<Scalars['String']>;
  salesOfficeCode?: Maybe<Scalars['String']>;
  salesOrg?: Maybe<Scalars['String']>;
};

export type CountryResponse = {
  __typename?: 'CountryResponse';
  countryCode: Scalars['String'];
  displayName: Scalars['String'];
  guestShoppingType?: Maybe<Scalars['String']>;
  regionCode: Scalars['String'];
  regionName: Scalars['String'];
};

export type CountryStates = {
  __typename?: 'CountryStates';
  countryCode?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  stateName?: Maybe<Scalars['String']>;
};

export type CoworkerGroupDetailResponse = {
  __typename?: 'CoworkerGroupDetailResponse';
  coWorkers?: Maybe<Array<Maybe<CoworkersResponse>>>;
  description?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  partners?: Maybe<CoworkerGroupPartner>;
};

export type CoworkerGroupPartner = {
  __typename?: 'CoworkerGroupPartner';
  billTo: Array<CoworkerGroupPartnerType>;
  payer: Array<CoworkerGroupPartnerType>;
  shipTo: Array<CoworkerGroupPartnerType>;
  soldTo: Scalars['String'];
};

export type CoworkerGroupPartnerType = {
  __typename?: 'CoworkerGroupPartnerType';
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
};

export type CoworkerGroupResponse = {
  __typename?: 'CoworkerGroupResponse';
  coWorkers?: Maybe<Array<Maybe<CoworkersResponse>>>;
  description?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
};

export type CoworkersResponse = {
  __typename?: 'CoworkersResponse';
  coworkerIds?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type CreateApproverCartInput = {
  orderId: Scalars['String'];
};

export type CreateContractCartInput = {
  billTo?: InputMaybe<Scalars['String']>;
  contractAmount: Scalars['String'];
  contractNumber?: InputMaybe<Scalars['String']>;
  contractPin: Scalars['String'];
  isRefill?: InputMaybe<Scalars['Boolean']>;
  payer?: InputMaybe<Scalars['String']>;
  soldTo?: InputMaybe<Scalars['String']>;
};

export type CreateGroupInput = {
  description?: InputMaybe<Scalars['String']>;
  groupName?: InputMaybe<Scalars['String']>;
  partners?: InputMaybe<GroupPartner>;
};

export type CreateGroupResponse = {
  __typename?: 'CreateGroupResponse';
  description?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
};

export type CreateSharedListInput = {
  coOwners?: InputMaybe<Array<Scalars['String']>>;
  deadline?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  invitations: Array<Scalars['String']>;
  name: Scalars['String'];
  reminderFrequencyDays?: InputMaybe<Array<Scalars['Int']>>;
  sharedListItems?: InputMaybe<Array<SharedListItemInput>>;
};

export type CreditCard = {
  __typename?: 'CreditCard';
  customerType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentUrl?: Maybe<Scalars['String']>;
};

export type CreditCardInput = {
  cardExpiryDate?: InputMaybe<Scalars['String']>;
  cardNameValue?: InputMaybe<Scalars['String']>;
  cardNumber?: InputMaybe<Scalars['String']>;
  cardType?: InputMaybe<Scalars['String']>;
  ccLegalTermsAcceptance?: InputMaybe<Scalars['Boolean']>;
  maskedCardNumber?: InputMaybe<Scalars['String']>;
  tokenizedCCNumber?: InputMaybe<Scalars['String']>;
  transientCCToken?: InputMaybe<Scalars['String']>;
};

export type CreditCardPaymentInvoiceResponse = {
  __typename?: 'CreditCardPaymentInvoiceResponse';
  lineItemList: Array<Invoice>;
};

export type CreditCardPaymentMethod = {
  __typename?: 'CreditCardPaymentMethod';
  cardExpiryDate: Scalars['String'];
  cardNameLabel?: Maybe<Scalars['String']>;
  cardNameValue: Scalars['String'];
  cardNumber?: Maybe<Scalars['String']>;
  cardType: Scalars['String'];
  displayableInCart?: Maybe<Scalars['Boolean']>;
  displayableInEmail?: Maybe<Scalars['Boolean']>;
  editableInCart?: Maybe<Scalars['Boolean']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  mask?: Maybe<Scalars['String']>;
  maskedCardNumber: Scalars['String'];
  participantId?: Maybe<Scalars['String']>;
  tokenizedCCNumber?: Maybe<Scalars['String']>;
  verificationValue?: Maybe<Scalars['String']>;
};

export type CreditCardPaymentSearchInput = {
  fromDate?: InputMaybe<Scalars['Date']>;
  payer?: InputMaybe<Scalars['String']>;
  salesOrg?: InputMaybe<Scalars['String']>;
  soldTo?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['Date']>;
};

/** Field names coming from 3rd party system and need to pass same fileds to BE */
export type CreditCardPaymentUpdateInput = {
  approval_no?: InputMaybe<Scalars['String']>;
  approval_time?: InputMaybe<Scalars['String']>;
  cancel?: InputMaybe<Scalars['String']>;
  FLAG?: InputMaybe<Scalars['String']>;
  invNoList?: InputMaybe<Scalars['String']>;
  result: Scalars['Int'];
  result_date?: InputMaybe<Scalars['String']>;
  sequence_no?: InputMaybe<Scalars['String']>;
  sigma_businessid?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  totamount?: InputMaybe<Scalars['String']>;
  tranxno: Scalars['String'];
};

export type CreditCardPaymentUpdateResponse = {
  __typename?: 'CreditCardPaymentUpdateResponse';
  approvalNumber?: Maybe<Scalars['String']>;
  transactionNumber?: Maybe<Scalars['String']>;
};

export type CreditCardResponse = {
  __typename?: 'CreditCardResponse';
  creditCards: Array<CreditCard>;
};

export type CrisprgrnaAddToCartInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  physicalFormat?: InputMaybe<PhysicalFormatInput>;
  reagents?: InputMaybe<Array<InputMaybe<ReagentsInput>>>;
  sapNextEnabled?: InputMaybe<Scalars['Boolean']>;
  store?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
};

export type CrisprgrnaAddToCartResponse = {
  __typename?: 'CrisprgrnaAddToCartResponse';
  result?: Maybe<Scalars['String']>;
  serverError?: Maybe<ListError>;
};

export type CrisprgRnaPriceCall = {
  __typename?: 'CrisprgRnaPriceCall';
  result?: Maybe<Array<Maybe<CrisprgRnaPriceCallResponse>>>;
  serverError?: Maybe<ServerError>;
};

export type CrisprgRnaPriceCallInput = {
  country?: InputMaybe<Scalars['String']>;
  physicalFormat?: InputMaybe<PhysicalFormatInput>;
  reagents?: InputMaybe<Array<InputMaybe<ReagentsInput>>>;
};

export type CrisprgRnaPriceCallResponse = {
  __typename?: 'CrisprgRnaPriceCallResponse';
  brand?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  efficiencyScore?: Maybe<Scalars['String']>;
  geneId?: Maybe<Scalars['String']>;
  geneSymbol?: Maybe<Scalars['String']>;
  grnaId?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  moreInformation?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  recommendedStatus?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  specificityScore?: Maybe<Scalars['String']>;
};

export type CrisprPrice = {
  __typename?: 'CrisprPrice';
  brand?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  listSubModels?: Maybe<Array<Maybe<ListSubModel>>>;
  material?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
};

export type CrisprPriceResponse = {
  __typename?: 'CrisprPriceResponse';
  response?: Maybe<Array<Maybe<CrisprPrice>>>;
  serverError?: Maybe<ListError>;
};

export type CrisprSessionData = {
  __typename?: 'CrisprSessionData';
  aliquoting?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  cartId?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isOverridden?: Maybe<Scalars['Boolean']>;
  itemId?: Maybe<Scalars['String']>;
  lentiviralBackbone?: Maybe<Scalars['String']>;
  listAliquotings?: Maybe<Array<Maybe<Scalars['String']>>>;
  listAmount?: Maybe<Array<Maybe<Scalars['String']>>>;
  listError?: Maybe<Array<Maybe<ListError>>>;
  listLentiviralBackbones?: Maybe<Array<Maybe<Scalars['String']>>>;
  listModification?: Maybe<Array<Maybe<Scalars['String']>>>;
  listPhysicalMaterials?: Maybe<Array<Maybe<Scalars['String']>>>;
  listPurification?: Maybe<Array<Maybe<Scalars['String']>>>;
  listScale?: Maybe<Array<Maybe<Scalars['String']>>>;
  listSpecies?: Maybe<Array<Maybe<Scalars['String']>>>;
  listStructure?: Maybe<Array<Maybe<Scalars['String']>>>;
  listSubModels?: Maybe<Array<Maybe<ListSubModel>>>;
  listVectors?: Maybe<Array<Maybe<Scalars['String']>>>;
  listViralTiters?: Maybe<Array<Maybe<Scalars['String']>>>;
  listVolumes?: Maybe<Array<Maybe<Scalars['String']>>>;
  material?: Maybe<Scalars['String']>;
  mergedSession?: Maybe<Scalars['Boolean']>;
  modification?: Maybe<Scalars['String']>;
  modifiedSequence?: Maybe<Scalars['String']>;
  modifiedTechnicalSequence?: Maybe<Scalars['String']>;
  pageNo?: Maybe<Scalars['Int']>;
  physicalMaterial?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  purification?: Maybe<Scalars['String']>;
  scale?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['Boolean']>;
  vector?: Maybe<Scalars['String']>;
  viralTiter?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['String']>;
};

export type CrisprSessionResponse = {
  __typename?: 'CrisprSessionResponse';
  data: Array<CrisprSessionData>;
};

export type CrisprSpecificatios = {
  __typename?: 'CrisprSpecificatios';
  aliquot?: Maybe<Array<Maybe<Aliquot>>>;
  amount?: Maybe<Array<Maybe<Amount>>>;
  companionProducts?: Maybe<Array<Maybe<CompanionProducts>>>;
  format?: Maybe<Array<Maybe<Format>>>;
  lentivirusBackbone?: Maybe<Array<Maybe<LentivirusBackbone>>>;
  modification?: Maybe<Array<Maybe<Modification>>>;
  physicalMaterial?: Maybe<Array<Maybe<PhysicalMaterial>>>;
  purification?: Maybe<Array<Maybe<Purification>>>;
  scale?: Maybe<Array<Maybe<Scale>>>;
  serverError?: Maybe<ServerError>;
  species?: Maybe<Array<Maybe<Species>>>;
  structure?: Maybe<Array<Maybe<Structure>>>;
  titer?: Maybe<Array<Maybe<Titer>>>;
  vector?: Maybe<Array<Maybe<Vector>>>;
  volume?: Maybe<Array<Maybe<Volume>>>;
};

export type CustomBundleItem = {
  materialNumber: Scalars['String'];
  quantity: Scalars['Int'];
};

export type CustomCrisprSessionInput = {
  aliquoting?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['String']>;
  cartId?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  currencySymbol?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isOverridden?: InputMaybe<Scalars['Boolean']>;
  itemId?: InputMaybe<Scalars['String']>;
  lentiviralBackbone?: InputMaybe<Scalars['String']>;
  listAliquotings?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listAmount?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listError?: InputMaybe<Array<InputMaybe<ListErrorInput>>>;
  listLentiviralBackbones?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listModification?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listPhysicalMaterials?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listPurification?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listScale?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listSpecies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listStructure?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listSubModels?: InputMaybe<Array<InputMaybe<ListSubModelInput>>>;
  listVectors?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listViralTiters?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listVolumes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  material?: InputMaybe<Scalars['String']>;
  modification?: InputMaybe<Scalars['String']>;
  modifiedSequence?: InputMaybe<Scalars['String']>;
  modifiedTechnicalSequence?: InputMaybe<Scalars['String']>;
  pageNo?: InputMaybe<Scalars['Int']>;
  physicalMaterial?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  purification?: InputMaybe<Scalars['String']>;
  scale?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Scalars['String']>;
  structure?: InputMaybe<Scalars['String']>;
  terms?: InputMaybe<Scalars['Boolean']>;
  vector?: InputMaybe<Scalars['String']>;
  viralTiter?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['String']>;
};

export type CustomCrisprTotalValidationResponse = {
  __typename?: 'CustomCrisprTotalValidationResponse';
  aliquoting?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  cartId?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['String']>;
  lentiviralBackbone?: Maybe<Scalars['String']>;
  listAliquotings?: Maybe<Array<Maybe<Scalars['String']>>>;
  listAmount?: Maybe<Array<Maybe<Scalars['String']>>>;
  listCompanionProducts?: Maybe<Array<Maybe<ListCompanionProducts>>>;
  listError?: Maybe<Array<Maybe<ListError>>>;
  listFormat?: Maybe<Array<Maybe<Scalars['String']>>>;
  listLentiviralBackbones?: Maybe<Array<Maybe<Scalars['String']>>>;
  listModification?: Maybe<Array<Maybe<Scalars['String']>>>;
  listPhysicalMaterials?: Maybe<Array<Maybe<Scalars['String']>>>;
  listPurification?: Maybe<Array<Maybe<Scalars['String']>>>;
  listScale?: Maybe<Array<Maybe<Scalars['String']>>>;
  listSpecies?: Maybe<Array<Maybe<Scalars['String']>>>;
  listStructure?: Maybe<Array<Maybe<Scalars['String']>>>;
  listSubModels?: Maybe<Array<Maybe<ListSubModel>>>;
  listVectors?: Maybe<Array<Maybe<Scalars['String']>>>;
  listViralTiters?: Maybe<Array<Maybe<Scalars['String']>>>;
  listVolumes?: Maybe<Array<Maybe<Scalars['String']>>>;
  material?: Maybe<Scalars['String']>;
  modification?: Maybe<Scalars['String']>;
  modifiedSequence?: Maybe<Scalars['String']>;
  modifiedTechnicalSequence?: Maybe<Scalars['String']>;
  pageNo?: Maybe<Scalars['Int']>;
  physicalMaterial?: Maybe<Scalars['String']>;
  purification?: Maybe<Scalars['String']>;
  scale?: Maybe<Scalars['String']>;
  serverError?: Maybe<ListError>;
  species?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
  vector?: Maybe<Scalars['String']>;
  viralTiter?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['String']>;
};

export type CustomerInfo = {
  __typename?: 'CustomerInfo';
  billingAddress?: Maybe<CustomerInfoAddress>;
  contactInfo?: Maybe<CustomerInfoAddress>;
  customerPONumber?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<CustomerInfoAddress>;
};

export type CustomerInfoAddress = {
  __typename?: 'CustomerInfoAddress';
  attentionTo?: Maybe<Scalars['String']>;
  buildingRoom?: Maybe<Scalars['String']>;
  ccForOrderConfEmailIds?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postOfficeBox?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateName?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
  street4?: Maybe<Scalars['String']>;
  street5?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

export type CustomerOrderInfo = {
  __typename?: 'CustomerOrderInfo';
  currency?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  totalPrice?: Maybe<Scalars['Float']>;
};

export type CustomerSearchInput = {
  attentionTo?: InputMaybe<Scalars['String']>;
  buildingRoom?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  contactFirstName?: InputMaybe<Scalars['String']>;
  contactLastName?: InputMaybe<Scalars['String']>;
  customerNumber?: InputMaybe<Scalars['String']>;
  department?: InputMaybe<Scalars['String']>;
  organizationName?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  requestType: RequestType;
  salesOrderSource?: InputMaybe<SalesOrderSource>;
  searchTerm?: InputMaybe<Scalars['String']>;
  soldToCountry: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
};

export type CustomerServiceContactInfo = {
  __typename?: 'CustomerServiceContactInfo';
  contactEmail?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  salesOfficeCode?: Maybe<Scalars['String']>;
};

export type CustomOligoProductResponse = {
  __typename?: 'CustomOligoProductResponse';
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CustomPromotionalBundleInput = {
  bundleDescription: Scalars['String'];
  bundleItems: Array<CustomBundleItem>;
};

export type Dealer = {
  __typename?: 'Dealer';
  address?: Maybe<Scalars['String']>;
  approvalNeeded?: Maybe<Scalars['Boolean']>;
  approverDealerLogonid?: Maybe<Scalars['String']>;
  attentionTo?: Maybe<Scalars['String']>;
  availableProducts?: Maybe<Scalars['String']>;
  billToPartner?: Maybe<Scalars['String']>;
  buildingRoom?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  dealerReadableOrderId?: Maybe<Scalars['String']>;
  dealerType?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPreferredDealer?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  lineItemCount?: Maybe<Scalars['Int']>;
  orderStatus?: Maybe<DealerOrderStatus>;
  organizationName?: Maybe<Scalars['String']>;
  payerPartner?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  poNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  productGroup?: Maybe<Array<Scalars['String']>>;
  salesOrg?: Maybe<Scalars['String']>;
  shipToPartner?: Maybe<Scalars['String']>;
  soldToPartner?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  stateName?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
  street4?: Maybe<Scalars['String']>;
  street5?: Maybe<Scalars['String']>;
};

export type DealerApprover = {
  __typename?: 'DealerApprover';
  approvalAmount?: Maybe<Scalars['Float']>;
  approvalProductType: Scalars['String'];
  approvalTypeId: ApprovalTypeId;
  emailId: Scalars['String'];
  id: Scalars['String'];
};

export type DealerInput = {
  approvalNeeded?: InputMaybe<Scalars['Boolean']>;
  approverDealerLogonid?: InputMaybe<Scalars['String']>;
  attentionTo?: InputMaybe<Scalars['String']>;
  availableProducts?: InputMaybe<Scalars['String']>;
  billToPartner?: InputMaybe<Scalars['String']>;
  buildingRoom?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  dealerType?: InputMaybe<Scalars['String']>;
  department?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isPreferredDealer?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  organizationName?: InputMaybe<Scalars['String']>;
  payerPartner?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  poNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  productGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  salesOrg?: InputMaybe<Scalars['String']>;
  shipToPartner?: InputMaybe<Scalars['String']>;
  soldToPartner?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  street3?: InputMaybe<Scalars['String']>;
  street4?: InputMaybe<Scalars['String']>;
  street5?: InputMaybe<Scalars['String']>;
};

export enum DealerOrderStatus {
  Approved = 'Approved',
  LocallyRejected = 'LocallyRejected',
  PartiallyApproved = 'PartiallyApproved',
  Pending = 'Pending',
  PendingFromApprover = 'PendingFromApprover',
  PendingReview = 'PendingReview',
  Rejected = 'Rejected'
}

export type DealerRequest = {
  __typename?: 'DealerRequest';
  address?: Maybe<Scalars['String']>;
  createdDate: Scalars['Date'];
  currency?: Maybe<Scalars['String']>;
  customerRequestNumber: Scalars['String'];
  orderNumber: Scalars['String'];
  orderTotal: Scalars['String'];
  sapOrderNumber?: Maybe<Scalars['String']>;
  status: DealerOrderStatus;
  userOrganizationName?: Maybe<Scalars['String']>;
  userPO?: Maybe<Scalars['String']>;
};

export type DealerRequestsSearchInput = {
  createdOn?: InputMaybe<Scalars['Date']>;
  expiresOn?: InputMaybe<Scalars['Date']>;
  requestId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DealerOrderStatus>;
};

export type DealerTermsResponse = {
  __typename?: 'DealerTermsResponse';
  dealerId?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  orgName?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  soldTo?: Maybe<Scalars['String']>;
  tc?: Maybe<Scalars['String']>;
};

export type DelegateApproverInput = {
  activate: Scalars['Boolean'];
  delegateApproverId: Scalars['String'];
  delegationApprovalExpirationDate?: InputMaybe<Scalars['String']>;
  isDelegateEnabled: Scalars['Boolean'];
  userId: Scalars['String'];
};

export type DeleteCoworkerGroupInput = {
  groupId: Scalars['String'];
  userId: Scalars['String'];
};

export type DeleteMyNetworkGroupInput = {
  groupId: Scalars['String'];
};

export type DeleteQuickOrderItemInput = {
  itemId: Scalars['String'];
};

export type DeleteSaveForLaterInput = {
  cartType: Scalars['String'];
  materialNumber: Scalars['String'];
};

export type DependentCatalogQuantity = {
  __typename?: 'DependentCatalogQuantity';
  catalogId?: Maybe<Scalars['String']>;
  increment?: Maybe<Scalars['Int']>;
  productDetails: Product;
};

export type DidYouMeanTerm = {
  __typename?: 'DidYouMeanTerm';
  count: Scalars['Int'];
  term: Scalars['String'];
};

export type DiscontinuedMaterialPricing = {
  __typename?: 'DiscontinuedMaterialPricing';
  alternateMaterials?: Maybe<Array<Maybe<Material>>>;
  displaySimilarProductLabel?: Maybe<Scalars['Boolean']>;
  errorMsg: Scalars['String'];
  hideReplacementProductLink?: Maybe<Scalars['Boolean']>;
  hideTechnicalServiceLink?: Maybe<Scalars['Boolean']>;
  paramList?: Maybe<Array<Maybe<Scalars['String']>>>;
  replacementProducts?: Maybe<Array<Product>>;
};

export type DocLibInput = {
  count?: InputMaybe<Scalars['Int']>;
  daterangeindays?: InputMaybe<Scalars['String']>;
  lotnumber?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  ordernumber?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perpage?: InputMaybe<Scalars['Int']>;
  ponumber?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Scalars['String']>;
  sapOrderNumber?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sortColumn?: InputMaybe<Scalars['String']>;
};

export type DocLibPayload = {
  __typename?: 'DocLibPayload';
  count?: Maybe<Scalars['Int']>;
  documents: Array<DocLibScheduleLine>;
  orderBy?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  perpage?: Maybe<Scalars['Int']>;
  sapOrderNumber?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortColumn?: Maybe<Scalars['String']>;
};

export type DocLibSavedDocsItemResponse = {
  __typename?: 'DocLibSavedDocsItemResponse';
  brand: Scalars['String'];
  certificateOfAnalysis?: Maybe<Certificate>;
  certificateOfOrigin?: Maybe<Certificate>;
  lotNumber?: Maybe<Scalars['String']>;
  product: Scalars['String'];
  productName?: Maybe<Scalars['String']>;
  sapOrderNumber?: Maybe<Scalars['String']>;
  sdsLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  specificationSheet?: Maybe<SpecificationSheet>;
};

export type DocLibSavedDocsResponse = {
  __typename?: 'DocLibSavedDocsResponse';
  count: Scalars['Int'];
  documents: Array<DocLibSavedDocsItemResponse>;
  orderBy?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sapOrderNumber?: Maybe<Scalars['String']>;
  sortColumn?: Maybe<Scalars['String']>;
};

export type DocLibScheduleLine = {
  __typename?: 'DocLibScheduleLine';
  brand?: Maybe<Scalars['String']>;
  certificateOfAnalysis?: Maybe<Certificate>;
  certificateOfOrigin?: Maybe<Certificate>;
  createdDate: Scalars['Date'];
  deliveryItemNumber?: Maybe<Scalars['String']>;
  deliveryNumber?: Maybe<Scalars['String']>;
  dsSalesDocument?: Maybe<Scalars['String']>;
  dsSalesOrg?: Maybe<Scalars['String']>;
  dsSystemId?: Maybe<Scalars['String']>;
  itemNumber?: Maybe<Scalars['String']>;
  lotNumber?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  materialDesc?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  salesOrg?: Maybe<Scalars['String']>;
  sapOrderNumber?: Maybe<Scalars['String']>;
  sdsLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  specificationSheet?: Maybe<SpecificationSheet>;
  status?: Maybe<Scalars['String']>;
  systemId?: Maybe<Scalars['String']>;
  trackingDetails?: Maybe<DocLibTrackingDetail>;
};

export type DocLibTrackingDetail = {
  __typename?: 'DocLibTrackingDetail';
  trackingNumber?: Maybe<Scalars['String']>;
  trackingUrl?: Maybe<Scalars['String']>;
};

export type Document = {
  __typename?: 'Document';
  label: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type DocumentationContent = {
  __typename?: 'DocumentationContent';
  brand?: Maybe<Scalars['String']>;
  documentType?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lotNumber?: Maybe<Scalars['String']>;
  text?: Maybe<Content>;
  url?: Maybe<Scalars['String']>;
};

export type DocumentLink = {
  __typename?: 'DocumentLink';
  anchorTag: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  label: Scalars['String'];
};

export type DossierInfo = {
  __typename?: 'DossierInfo';
  dossierId: Scalars['String'];
  dossierTitle: Scalars['String'];
  materialNo: Scalars['String'];
  skuId: Scalars['String'];
  subscriptionStatus?: Maybe<Scalars['String']>;
  subscriptionType?: Maybe<Scalars['String']>;
};

export type DropDownListInput = {
  countryCode: Scalars['String'];
  modification?: InputMaybe<Scalars['String']>;
  physicalMaterial?: InputMaybe<Scalars['String']>;
  purification?: InputMaybe<Scalars['String']>;
  scale?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<Scalars['String']>;
  structure?: InputMaybe<Scalars['String']>;
  titer?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['String']>;
};

export type DuplexDetails = {
  __typename?: 'DuplexDetails';
  additionalComments?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  antisenceBaseCount?: Maybe<Scalars['Int']>;
  antisenceFiveMod?: Maybe<Scalars['String']>;
  antisenceName?: Maybe<Scalars['String']>;
  antisenceSequence?: Maybe<Scalars['String']>;
  antisenceThreeMod?: Maybe<Scalars['String']>;
  applyToAll?: Maybe<Scalars['Boolean']>;
  autoGenerateOligo?: Maybe<Scalars['Boolean']>;
  autoValidation?: Maybe<Scalars['String']>;
  baseCount?: Maybe<Scalars['Float']>;
  cartId?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  concentration?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  customerAntisenseOverHang?: Maybe<Scalars['String']>;
  customerOverhang?: Maybe<Scalars['String']>;
  fiveMod?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isOverridden?: Maybe<Scalars['Boolean']>;
  itemId?: Maybe<Scalars['String']>;
  listError?: Maybe<Array<Maybe<ListError>>>;
  name?: Maybe<Scalars['String']>;
  oligoId?: Maybe<Scalars['Int']>;
  orderItemId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  purification?: Maybe<Scalars['String']>;
  scale?: Maybe<Scalars['String']>;
  senseNameCheck?: Maybe<Scalars['Boolean']>;
  sequence?: Maybe<Scalars['String']>;
  threeMod?: Maybe<Scalars['String']>;
  tubes?: Maybe<Scalars['String']>;
};

export type EmailCartInput = {
  cartId: Scalars['String'];
  message: Scalars['String'];
  savedCart?: InputMaybe<Scalars['Boolean']>;
  sendMeCopy: Scalars['Boolean'];
  toEmail: Array<Scalars['String']>;
};

export type EmproveDoc = {
  __typename?: 'EmproveDoc';
  assetId: Scalars['String'];
  brand?: Maybe<Scalars['String']>;
  displayType: Scalars['String'];
  fileName: Scalars['String'];
  product: Scalars['String'];
  productBrand: Scalars['String'];
  productName?: Maybe<Scalars['String']>;
  productNumber?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['String'];
};

export enum EmproveDomainStatus {
  Approved = 'APPROVED',
  Blocked = 'BLOCKED',
  New = 'NEW',
  Pending = 'PENDING'
}

export type EmproveDossierInfo = {
  dossierId?: InputMaybe<Scalars['String']>;
  dossierMaterialNumber?: InputMaybe<Scalars['String']>;
  dossierSubLength?: InputMaybe<Scalars['Int']>;
  dossierSubType?: InputMaybe<Scalars['String']>;
};

export type EmproveDossierItem = {
  __typename?: 'EmproveDossierItem';
  dossierId?: Maybe<Scalars['String']>;
  dossierMaterialNumber?: Maybe<Scalars['String']>;
  dossierSubLength?: Maybe<Scalars['Int']>;
  dossierSubType?: Maybe<Scalars['String']>;
};

export enum EmproveItemType {
  All = 'all',
  Single = 'single'
}

export type EmproveProductDocsInput = {
  term: Scalars['String'];
};

export enum EmproveSubscriptionType {
  All = 'ALL',
  Single = 'SINGLE'
}

export type EmproveSuiteDoc = {
  __typename?: 'EmproveSuiteDoc';
  date: Scalars['Date'];
  fileInfo: EmproveDoc;
  materialDescription: Scalars['String'];
  materialNumber: Scalars['String'];
  materialType: Scalars['String'];
  type: Scalars['String'];
};

export type EmproveSuiteDocsInput = {
  count?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<EmproveSuiteFilter>;
  page: Scalars['Int'];
  sort?: InputMaybe<Scalars['String']>;
  term?: InputMaybe<Scalars['String']>;
};

export type EmproveSuiteDocsResponse = {
  __typename?: 'EmproveSuiteDocsResponse';
  docsPerPage: Scalars['Int'];
  emproveSuiteDocs: Array<EmproveSuiteDoc>;
  totalDocs: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export enum EmproveSuiteFilter {
  Cedo1 = 'CEDO1',
  Cedo2 = 'CEDO2',
  Cedo3 = 'CEDO3',
  Cedo4 = 'CEDO4',
  Cedo5 = 'CEDO5',
  Edo = 'EDO'
}

export type EmproveUserData = {
  __typename?: 'EmproveUserData';
  activeSubscription: Scalars['Boolean'];
  companyId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  domainId: Scalars['String'];
  domainStatus: Scalars['String'];
  dossierItemInfo?: Maybe<Array<Maybe<DossierInfo>>>;
  subscriptionType?: Maybe<Scalars['String']>;
};

export type EmproveUserDataInput = {
  userDomain: Scalars['String'];
};

export enum EncodingType {
  Base64 = 'base64',
  Binary = 'binary'
}

export enum ErrorCodes {
  AddressFirstNameEmpty = 'ADDRESS_FIRST_NAME_EMPTY',
  CatalogHttpError = 'CATALOG_HTTP_ERROR',
  ContactTelephoneEmpty = 'CONTACT_TELEPHONE_EMPTY',
  EcommerceHttpError = 'ECOMMERCE_HTTP_ERROR',
  HttpError = 'HTTP_ERROR',
  PaymentPoNumberEmpty = 'PAYMENT_PO_NUMBER_EMPTY',
  PaymentRequisitionNumberEmpty = 'PAYMENT_REQUISITION_NUMBER_EMPTY',
  PricingAndAvailabilityUnavailable = 'PRICING_AND_AVAILABILITY_UNAVAILABLE',
  SelectTermsAndConditionsOfSales = 'SELECT_TERMS_AND_CONDITIONS_OF_SALES',
  ShippingAttentionToEmpty = 'SHIPPING_ATTENTION_TO_EMPTY',
  UserDuplicateLogonId = 'USER_DUPLICATE_LOGON_ID',
  UserNotAuthorizedOrTokenExpired = 'USER_NOT_AUTHORIZED_OR_TOKEN_EXPIRED'
}

export type ErrorValues = {
  __typename?: 'ErrorValues';
  key?: Maybe<Scalars['String']>;
  valid?: Maybe<Scalars['String']>;
  validationType?: Maybe<Scalars['String']>;
};

export type Facet = {
  __typename?: 'Facet';
  isCollapsed: Scalars['Boolean'];
  isHidden?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  multiSelect: Scalars['Boolean'];
  numToDisplay: Scalars['Int'];
  options: Array<FacetOptions>;
  prefix?: Maybe<Scalars['String']>;
};

export type FacetInput = {
  key: Scalars['String'];
  options: Array<Scalars['String']>;
};

export type FacetOptions = {
  __typename?: 'FacetOptions';
  count: Scalars['Int'];
  value: Scalars['String'];
};

export type FacetSearchInput = {
  catalogType?: InputMaybe<CatalogType>;
  facets?: InputMaybe<Array<InputMaybe<FacetInput>>>;
  facetSet?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  orgId?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ProductSearchType>;
};

export type FacetSearchResponse = {
  __typename?: 'FacetSearchResponse';
  facets: Array<Facet>;
};

export type FacetSetInput = {
  facets: Array<Scalars['String']>;
};

export type Fapiao = {
  __typename?: 'Fapiao';
  customerName: Scalars['String'];
  expressCompany?: Maybe<Scalars['String']>;
  expressNumber?: Maybe<Scalars['String']>;
  fapiaoDate: Scalars['Date'];
  fapiaoNumber: Scalars['String'];
  fapiaoPdfUrl?: Maybe<Scalars['String']>;
  fapiaoTotal: Scalars['Float'];
  fapiaoType: Scalars['String'];
  invoiceNumbers: Array<Scalars['String']>;
  orderNumbers: Array<Scalars['String']>;
  sendTime?: Maybe<Scalars['String']>;
  taxNumber: Scalars['String'];
};

export type FapiaoSearchInput = {
  fapiaoNo?: InputMaybe<Scalars['String']>;
  invoiceNo?: InputMaybe<Scalars['String']>;
  issueFrom: Scalars['String'];
  issueTo: Scalars['String'];
  orderNo?: InputMaybe<Scalars['String']>;
};

export type FapiaoSearchPayload = {
  __typename?: 'FapiaoSearchPayload';
  fapiaoSearchList: Array<Fapiao>;
};

export type Faq = {
  __typename?: 'FAQ';
  answer: Scalars['String'];
  question: Scalars['String'];
};

export type FastDuplexValidateInput = {
  amount?: InputMaybe<Scalars['String']>;
  antisenceBaseCount?: InputMaybe<Scalars['Int']>;
  antisenceFiveMod?: InputMaybe<Scalars['String']>;
  antisenceName?: InputMaybe<Scalars['String']>;
  antisenceSequence?: InputMaybe<Scalars['String']>;
  antisenceThreeMod?: InputMaybe<Scalars['String']>;
  autoGenerateOligo?: InputMaybe<Scalars['Boolean']>;
  concentration?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  customerAntisenseOverHang?: InputMaybe<Scalars['String']>;
  customerOverhang?: InputMaybe<Scalars['String']>;
  fiveMod?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  isOverridden?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  purification?: InputMaybe<Scalars['String']>;
  researcher?: InputMaybe<Scalars['String']>;
  scale?: InputMaybe<Scalars['String']>;
  senseNameCheck?: InputMaybe<Scalars['Boolean']>;
  sequence?: InputMaybe<Scalars['String']>;
  threeMod?: InputMaybe<Scalars['String']>;
  tubes?: InputMaybe<Scalars['String']>;
};

export type FastDuplexValidateResponse = {
  __typename?: 'FastDuplexValidateResponse';
  antisenseBaseCount?: Maybe<Scalars['String']>;
  antisenseGcContent?: Maybe<Scalars['String']>;
  antisenseMeltingTemperature?: Maybe<Scalars['String']>;
  antisenseMolecularWeight?: Maybe<Scalars['String']>;
  antisensePrimerDimer?: Maybe<Scalars['String']>;
  antisenseSecondaryStructure?: Maybe<Scalars['String']>;
  baseCount?: Maybe<Scalars['String']>;
  gcContent?: Maybe<Scalars['String']>;
  listAmount?: Maybe<Array<Maybe<Scalars['String']>>>;
  listConcentration?: Maybe<Array<Maybe<Scalars['String']>>>;
  listCterminalMod?: Maybe<Array<Maybe<Scalars['String']>>>;
  listErrors?: Maybe<Array<Maybe<ListError>>>;
  listFiveMod?: Maybe<Array<Maybe<Scalars['String']>>>;
  listFormat?: Maybe<Array<Maybe<Scalars['String']>>>;
  listInternalMod?: Maybe<Array<Maybe<Scalars['String']>>>;
  listMinimumYield?: Maybe<Scalars['Float']>;
  listModification?: Maybe<Array<Maybe<Scalars['String']>>>;
  listNterminalMod?: Maybe<Array<Maybe<Scalars['String']>>>;
  listOverhang?: Maybe<Array<Maybe<Scalars['String']>>>;
  listPurification?: Maybe<Array<Maybe<Scalars['String']>>>;
  listPurityLevel?: Maybe<Array<Maybe<Scalars['String']>>>;
  listQuantitation?: Maybe<Array<Maybe<Scalars['String']>>>;
  listScale?: Maybe<Array<Maybe<Scalars['String']>>>;
  listThreeMod?: Maybe<Array<Maybe<Scalars['String']>>>;
  listTubes?: Maybe<Array<Maybe<Scalars['String']>>>;
  meltingTemperature?: Maybe<Scalars['String']>;
  molecularWeight?: Maybe<Scalars['String']>;
  primerDimer?: Maybe<Scalars['String']>;
  secondaryStructure?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['String']>;
  serverError?: Maybe<ListError>;
};

export type Features = {
  __typename?: 'Features';
  featureId?: Maybe<Scalars['String']>;
  labWaterSystemAttributes: Array<Maybe<LabWaterSystemAttribute>>;
  overAllRating?: Maybe<Scalars['String']>;
};

export type FedexNotificationSubscriptionResponse = {
  __typename?: 'FedexNotificationSubscriptionResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type FetchOrganizationResponse = {
  __typename?: 'FetchOrganizationResponse';
  organizationApprovers: Array<OrganizationApprover>;
};

export type FfCertificateProduct = {
  __typename?: 'FFCertificateProduct';
  aliases?: Maybe<Array<Maybe<FfMetadata>>>;
  attributes?: Maybe<Array<Maybe<FfMetadata>>>;
  brand?: Maybe<Scalars['String']>;
  logo?: Maybe<CatalogImage>;
  productName?: Maybe<Scalars['String']>;
  productNameSuffix?: Maybe<Scalars['String']>;
  productNumber?: Maybe<Scalars['String']>;
};

export type FfMetadata = {
  __typename?: 'FFMetadata';
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type FiltersResponse = {
  __typename?: 'FiltersResponse';
  fullRegistrationOrganizationType: Array<LabelValue>;
  fullRegistrationYourPosition: Array<LabelValue>;
  language: Array<LabelValue>;
  productEntryPreference: Array<LabelValue>;
  sdsLanguage: Array<LabelValue>;
};

export type FivePrime = {
  __typename?: 'FivePrime';
  KSPRA2015_FAM?: Maybe<Scalars['String']>;
  KSPRA2015_HEX?: Maybe<Scalars['String']>;
};

export type ForgotPasswordResponseType = {
  __typename?: 'ForgotPasswordResponseType';
  message?: Maybe<Scalars['String']>;
};

export type ForgotPinInput = {
  contractNumber: Scalars['String'];
  contractType: ContractType;
};

export type Form = {
  __typename?: 'Form';
  label: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
};

export type Format = {
  __typename?: 'Format';
  dry?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['String']>;
};

export type FullRegistrationInput = {
  billingAddress?: InputMaybe<AddressInput>;
  contactAddress?: InputMaybe<AddressInput>;
  officeAddress?: InputMaybe<AddressInput>;
  participantCommunicationSetting?: InputMaybe<InvoiceEmailInput>;
  participantComplianceInformation?: InputMaybe<UserParticipantComplianceInformationInput>;
  participantSitePreference?: InputMaybe<ParticipantSitePreferenceInput>;
  paymentAddress?: InputMaybe<AddressInput>;
  shippingAddress?: InputMaybe<AddressInput>;
  userProfile?: InputMaybe<FullRegistrationUserProfileInput>;
};

export type FullRegistrationUserProfileInput = {
  accountNumber?: InputMaybe<Scalars['String']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
};

export type Gene = {
  __typename?: 'Gene';
  name?: Maybe<Scalars['String']>;
  peerPapers: Papers;
  relatedCategories: Array<GeneCategory>;
  speciesReactivityFacets: Array<FacetOptions>;
  summary?: Maybe<Scalars['String']>;
  swissProt: Array<Scalars['String']>;
  symbol: Scalars['String'];
  synonyms: Array<Scalars['String']>;
  variants: Array<GeneVariant>;
};


export type GenePeerPapersArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type GeneArrayInputParams = {
  facet?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<Scalars['String']>;
};

export type GeneArrayPlateList = {
  __typename?: 'GeneArrayPlateList';
  plateName?: Maybe<Scalars['String']>;
  wells?: Maybe<Array<Maybe<GeneArrayWellData>>>;
};

export type GeneArrayPlateListInput = {
  plateName?: InputMaybe<Scalars['String']>;
  wells?: InputMaybe<Array<InputMaybe<GeneArrayWellDataInput>>>;
};

export type GeneArrayPriceDetails = {
  __typename?: 'GeneArrayPriceDetails';
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  variant?: Maybe<Scalars['String']>;
};

export type GeneArrayPriceDetailsInput = {
  currency?: InputMaybe<Scalars['String']>;
  currencySymbol?: InputMaybe<Scalars['String']>;
  material?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  variant?: InputMaybe<Scalars['String']>;
};

export type GeneArraySearchData = {
  __typename?: 'GeneArraySearchData';
  arrayName?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  isArrayModified?: Maybe<Scalars['Boolean']>;
  pageNo?: Maybe<Scalars['Int']>;
  plates?: Maybe<Array<Maybe<GeneArrayPlateList>>>;
  pricing?: Maybe<GeneArrayPriceDetails>;
  serverError?: Maybe<ServerError>;
  species?: Maybe<Scalars['String']>;
  speciesErrorMessage?: Maybe<Scalars['String']>;
  stepNo?: Maybe<Scalars['Int']>;
  wellType?: Maybe<Scalars['Int']>;
  wellTypeErrorMessage?: Maybe<Scalars['String']>;
};

export type GeneArraySearchInput = {
  arrayName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<Scalars['String']>;
  wellType?: InputMaybe<Scalars['Int']>;
};

export type GeneArrayWellData = {
  __typename?: 'GeneArrayWellData';
  ampliconSize?: Maybe<Scalars['Int']>;
  arrayName?: Maybe<Scalars['String']>;
  arrayType?: Maybe<Scalars['String']>;
  column?: Maybe<Scalars['String']>;
  exons?: Maybe<Scalars['String']>;
  geneId?: Maybe<Scalars['String']>;
  geneSymbol?: Maybe<Scalars['String']>;
  primerPairId?: Maybe<Scalars['String']>;
  primerSeqFwd?: Maybe<Scalars['String']>;
  primerSeqRev?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['Int']>;
  refSeq?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  wellId?: Maybe<Scalars['Int']>;
};

export type GeneArrayWellDataInput = {
  ampliconSize?: InputMaybe<Scalars['Int']>;
  arrayName?: InputMaybe<Scalars['String']>;
  arrayType?: InputMaybe<Scalars['String']>;
  column?: InputMaybe<Scalars['String']>;
  exons?: InputMaybe<Scalars['String']>;
  geneId?: InputMaybe<Scalars['String']>;
  geneSymbol?: InputMaybe<Scalars['String']>;
  primerPairId?: InputMaybe<Scalars['String']>;
  primerSeqFwd?: InputMaybe<Scalars['String']>;
  primerSeqRev?: InputMaybe<Scalars['String']>;
  rank?: InputMaybe<Scalars['Int']>;
  refSeq?: InputMaybe<Scalars['String']>;
  row?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Scalars['String']>;
  wellId?: InputMaybe<Scalars['Int']>;
};

export type GeneArrayWellSearchData = {
  __typename?: 'GeneArrayWellSearchData';
  count?: Maybe<Scalars['Int']>;
  pricing?: Maybe<GeneArrayPriceDetails>;
  results?: Maybe<Array<Maybe<GeneArrayWellsResponse>>>;
  serverError?: Maybe<ServerError>;
  term?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GeneArrayWellSearchInput = {
  geneId?: InputMaybe<Scalars['String']>;
  geneSymbol?: InputMaybe<Scalars['String']>;
  params?: InputMaybe<GeneArrayInputParams>;
  sequence?: InputMaybe<Scalars['String']>;
};

export type GeneArrayWellsResponse = {
  __typename?: 'GeneArrayWellsResponse';
  ampliconSize?: Maybe<Scalars['String']>;
  exons?: Maybe<Scalars['String']>;
  geneId?: Maybe<Scalars['String']>;
  geneSymbol?: Maybe<Scalars['String']>;
  materialNumber?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  primerPairId?: Maybe<Scalars['String']>;
  primerSeqFwd?: Maybe<Scalars['String']>;
  primerSeqRev?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  refSeq?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
};

export type GeneCategory = {
  __typename?: 'GeneCategory';
  name: Scalars['String'];
  products: Array<Product>;
  type?: Maybe<Scalars['ID']>;
};

export type GeneSearchInput = {
  facets?: InputMaybe<Array<FacetInput>>;
  facetSet?: InputMaybe<Array<FacetSetInput>>;
  pagination?: Pagination;
  searchTerm?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
  type?: InputMaybe<GeneSearchType>;
};

export type GeneSearchResults = SearchResults & {
  __typename?: 'GeneSearchResults';
  facets: Array<Facet>;
  items: Array<Gene>;
  metadata: SearchResultsMetadata;
};

export enum GeneSearchType {
  Gene = 'GENE',
  GeneDesc = 'GENE_DESC',
  GeneId = 'GENE_ID',
  GeneProduct = 'GENE_PRODUCT',
  GeneSymbol = 'GENE_SYMBOL',
  Refseq = 'REFSEQ',
  TrcNumber = 'TRC_NUMBER'
}

export type GeneVariant = {
  __typename?: 'GeneVariant';
  commonName: Scalars['String'];
  description: Scalars['String'];
  geneClass?: Maybe<Scalars['String']>;
  geneId: Scalars['Int'];
  symbol: Scalars['String'];
  taxonId: Scalars['Int'];
};

export type Get2c2pPaymentResponse = {
  __typename?: 'Get2c2pPaymentResponse';
  /** UUID format */
  orderId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type GetAemDiscountedProductsInput = {
  disp?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['String']>;
};

export type GetBestSellerProductsByRegionInput = {
  disp?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['String']>;
};

export type GetBestSellerProductsInput = {
  disp?: InputMaybe<AnalyticsDispType>;
  erpType?: InputMaybe<Scalars['String']>;
  participant_id?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['String']>;
};

export type GetBuyItAgainProductsInput = {
  participantId: Scalars['String'];
};

export type GetCartRecommendedProductsInput = {
  erpType?: InputMaybe<Scalars['String']>;
  materialNumbers?: InputMaybe<Array<Scalars['String']>>;
};

export type GetComplianceRegMappingListInput = {
  materialIds: Array<Scalars['String']>;
};

export type GetCoworkerDetailsInput = {
  userId?: InputMaybe<Scalars['String']>;
};

export type GetCustomerPricingForProductInput = {
  products: Array<GetPricingCustomerSpecificProductInput>;
};

export type GetCustomersAlsoViewedProductsInput = {
  brand: Scalars['String'];
  erpType?: InputMaybe<Scalars['String']>;
  productNumber: Scalars['String'];
};

export type GetDealerRequestsPayload = {
  __typename?: 'GetDealerRequestsPayload';
  count?: Maybe<Scalars['Int']>;
  dealerOrderSearchModels: Array<DealerRequest>;
};

/** input for getting Chinese FapiaoList */
export type GetFapiaoListInput = {
  invoiceNumber: Scalars['String'];
};

/** payload of Chinese FapiaoList */
export type GetFapiaoListPayload = {
  __typename?: 'GetFapiaoListPayload';
  fapiaoList: Array<Maybe<Fapiao>>;
  hasFapiaoList: Scalars['Boolean'];
};

export type GetGroupInput = {
  groupId?: InputMaybe<Scalars['String']>;
};

export type GetMaterialDetailInput = {
  catalogType?: InputMaybe<CatalogType>;
  filter?: InputMaybe<Scalars['String']>;
  materialNumber: Scalars['String'];
};

export type GetMaterialsDetailInput = {
  catalogType?: InputMaybe<CatalogType>;
  filter?: InputMaybe<Scalars['String']>;
  materialNumbers: Array<Scalars['String']>;
};

export type GetMilliqSqSessionInput = {
  guestParticipantId?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['String']>;
};

export type GetMynetworkInput = {
  group?: InputMaybe<Scalars['String']>;
};

export type GetOrderDetailInput = {
  checkDeliveryDate?: InputMaybe<Scalars['Boolean']>;
  isAcknowledgement: Scalars['Boolean'];
  orderIdentifier: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
  sharedToken?: InputMaybe<Scalars['String']>;
  soldTo?: InputMaybe<Scalars['String']>;
  sysId?: InputMaybe<Scalars['String']>;
};

export type GetOrderDetailPayload = {
  __typename?: 'GetOrderDetailPayload';
  order: OrderDetails;
};

export type GetPartnersInput = {
  billTos?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  payer?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  requestType?: InputMaybe<RequestType>;
  salesOrderSource?: InputMaybe<SalesOrderSource>;
  shipTos?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  soldTos?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GetPricingCustomerSpecificProductInput = {
  dealerId: Scalars['String'];
  items: Array<GetProductCustomerSpecificItemInput>;
};

export type GetPricingForMaterialInput = {
  brand?: InputMaybe<Scalars['String']>;
  catalogType?: InputMaybe<CatalogType>;
  checkBuyNow?: InputMaybe<Scalars['Boolean']>;
  countryCode?: InputMaybe<Scalars['String']>;
  materialNumber: Scalars['String'];
  orgId?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Int'];
  useSimulate?: InputMaybe<Scalars['Boolean']>;
};

export type GetPricingForMultipleProductInput = {
  productDetails: Array<GetPricingForProductInput>;
};

export type GetPricingForProductInput = {
  brand?: InputMaybe<Scalars['String']>;
  cachedPriceOnly?: InputMaybe<Scalars['Boolean']>;
  catalogType?: InputMaybe<CatalogType>;
  checkBuyNow?: InputMaybe<Scalars['Boolean']>;
  checkForPb?: InputMaybe<Scalars['Boolean']>;
  dealerId?: InputMaybe<Scalars['String']>;
  displaySDS?: InputMaybe<Scalars['Boolean']>;
  erp_type?: InputMaybe<Array<Scalars['String']>>;
  materialIds?: InputMaybe<Array<Scalars['String']>>;
  orgId?: InputMaybe<Scalars['String']>;
  productKey?: InputMaybe<Scalars['String']>;
  productNumber: Scalars['String'];
  quantity: Scalars['Int'];
  useSimulate?: InputMaybe<Scalars['Boolean']>;
};

export type GetPricingForPromotionalBundle = {
  promotionalBundleId: Scalars['String'];
};

export type GetProductComparisonInput = {
  brand: Scalars['String'];
  count: Scalars['Int'];
  erpType?: InputMaybe<Scalars['String']>;
  productNumber: Scalars['String'];
};

export type GetProductCustomerSpecificItemInput = {
  brand: Scalars['String'];
  listPrice: Scalars['Float'];
  product?: InputMaybe<Scalars['String']>;
  qty: Scalars['Int'];
  sku: Scalars['String'];
};

export type GetProductFaqsInput = {
  productKey: Scalars['String'];
};

export type GetProductInformationInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  hideOligoPeptideDesign?: InputMaybe<Scalars['Boolean']>;
  isCsr?: InputMaybe<Scalars['Boolean']>;
  isCsrSupervisor?: InputMaybe<Scalars['Boolean']>;
  isOrderableCustomer?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  regUser?: InputMaybe<Scalars['Boolean']>;
  sapNextEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type GetProductInput = {
  brandKey: Scalars['String'];
  catalogType?: InputMaybe<CatalogType>;
  displayLanguage?: InputMaybe<Scalars['String']>;
  isMarketplaceCatalogEnabled?: InputMaybe<Scalars['Boolean']>;
  orgId?: InputMaybe<Scalars['String']>;
  productKey: Scalars['String'];
};

export type GetProductNumberSearchSuggestions = {
  __typename?: 'GetProductNumberSearchSuggestions';
  count: Scalars['Int'];
  suggestions: Array<ProductNumberSuggestions>;
};

export type GetProductNumberSearchSuggestionsInput = {
  overrideMarketplaceByTag?: InputMaybe<Scalars['String']>;
  term: Scalars['String'];
};

export type GetProductPapersInput = {
  productId: Scalars['String'];
};

export type GetProductProtocolsAndArticlesInput = {
  term: Scalars['String'];
};

export type GetPromotionalBundlesForProduct = {
  brand: Scalars['String'];
  catalogType?: InputMaybe<CatalogType>;
  erp_type?: InputMaybe<Array<Scalars['String']>>;
  materialIds?: InputMaybe<Array<Scalars['String']>>;
  orgId?: InputMaybe<Scalars['String']>;
  productNumber: Scalars['String'];
};

export type GetQuickOrderRecommendedProductsInput = {
  erpType?: InputMaybe<Scalars['String']>;
  participantId: Scalars['String'];
};

export type GetQuotesCountPayload = {
  __typename?: 'GetQuotesCountPayload';
  count?: Maybe<Scalars['Int']>;
};

export type GetRecentlyViewedProductsInput = {
  brand: Scalars['String'];
  dispType: AnalyticsDispType;
  erpType?: InputMaybe<Scalars['String']>;
  productKey: Scalars['String'];
};

export type GetRecentOrdersCountPayload = {
  __typename?: 'GetRecentOrdersCountPayload';
  count?: Maybe<Scalars['Int']>;
};

export type GetRecommendedProductsAltInput = {
  brand?: InputMaybe<Scalars['String']>;
  get?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  participant_id?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Scalars['String']>;
};

export type GetRecommendedProductsInput = {
  brand: Scalars['String'];
  erpType?: InputMaybe<Scalars['String']>;
  productNumber: Scalars['String'];
};

export type GetRequestersInput = {
  page?: InputMaybe<Scalars['Int']>;
};

export type GetRequestersPayload = {
  __typename?: 'GetRequestersPayload';
  approvalTypes: Array<ApprovalTypes>;
  count: Scalars['Int'];
  perPage?: Maybe<Scalars['Int']>;
  requesters?: Maybe<Array<Maybe<Requester>>>;
};

export type GetSearchSuggestionResponse = {
  __typename?: 'GetSearchSuggestionResponse';
  count: Scalars['Int'];
  suggestions: GroupedSuggestions;
};

export type GetSearchSuggestionsInput = {
  catalogType?: InputMaybe<CatalogType>;
  filter?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['String']>;
  overrideMarketplaceByTag?: InputMaybe<Scalars['String']>;
  term: Scalars['String'];
  types?: InputMaybe<Array<SearchSuggestionType>>;
};

export type GetSharedListsInput = {
  orderby?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perpage?: InputMaybe<Scalars['Int']>;
  sortcolumn?: InputMaybe<Scalars['String']>;
};

export type GetShipmentTrackingRecommendedProductsInput = {
  materialNumbers: Array<Scalars['String']>;
};

export type GetSrpFeaturedProductsInput = {
  erpType?: InputMaybe<Scalars['String']>;
  searchKey: Scalars['String'];
};

export type GetSrpRecommendedProductsInput = {
  erpType?: InputMaybe<Scalars['String']>;
  searchKey: Scalars['String'];
};

export type GetSubstanceByIdInput = {
  catalogType?: InputMaybe<CatalogType>;
  facets?: InputMaybe<Array<InputMaybe<FacetInput>>>;
  id: Scalars['String'];
  orgId?: InputMaybe<Scalars['String']>;
};

/** input for getting Chinese logitics info */
export type GetTrackingInfoInput = {
  shipperCode?: InputMaybe<Scalars['String']>;
  trackingId: Scalars['String'];
};

/** payload of Chinese logitics info */
export type GetTrackingInfoPayload = {
  __typename?: 'GetTrackingInfoPayload';
  ebusinessID: Scalars['String'];
  location: Scalars['String'];
  logisticCode: Scalars['String'];
  reason: Scalars['String'];
  shipperCode: Scalars['String'];
  state: Scalars['String'];
  stateEx: Scalars['String'];
  success: Scalars['Boolean'];
  trace: Array<TrackingTracesDetail>;
};

export type GetUserByToken = {
  token: Scalars['String'];
};

export type GqlUserInfoInput = {
  accessToken: Scalars['String'];
  country: Scalars['String'];
  guid: Scalars['String'];
  isMarketplaceCartEnabled: Scalars['Boolean'];
  isMarketplaceCatalogEnabled: Scalars['Boolean'];
  language: Scalars['String'];
  locale: Scalars['String'];
  store: Scalars['String'];
};

export type GroupedSuggestions = {
  __typename?: 'GroupedSuggestions';
  casNumber: Array<Suggestion>;
  materialNumber: Array<Suggestion>;
  pinyin: Array<Suggestion>;
  productName: Array<Suggestion>;
  productNumber: Array<Suggestion>;
  relatedCategories: Array<RelatedCategory>;
};

export type GroupPartner = {
  allPartnerSelected: Scalars['Boolean'];
  billTo?: InputMaybe<Array<InputMaybe<PartnerType>>>;
  payer?: InputMaybe<Array<InputMaybe<PartnerType>>>;
  shipTo?: InputMaybe<Array<InputMaybe<PartnerType>>>;
};

export type GtinMaterialInput = {
  searchTerm?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type GtinMaterialsSearchInput = {
  searchTerm?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type GuestUser = {
  __typename?: 'GuestUser';
  abilities: Abilities;
  metadata: UserMetadata;
  type: UserType;
  userErpType: UserErpType;
};

export type HolidayCalendar = {
  __typename?: 'HolidayCalendar';
  holidayData?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  plant?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type HwSelection = {
  __typename?: 'HwSelection';
  categoryDisplayName: Scalars['String'];
  categoryName: Scalars['String'];
  categoryOptions: Array<CategoryOption>;
  categoryType?: Maybe<Scalars['String']>;
  helpText?: Maybe<Scalars['String']>;
};

export type IncidentMessageInput = {
  openIncidentMessages: Array<InputMaybe<OpenIncidentMessage>>;
};

export type IncidentMessageResponse = {
  __typename?: 'IncidentMessageResponse';
  messageId?: Maybe<Scalars['String']>;
  threadId?: Maybe<Scalars['String']>;
};

export type InstrumentTypeItem = {
  __typename?: 'InstrumentTypeItem';
  instrumentType: Array<Scalars['String']>;
  plateType: Scalars['String'];
};

export type InventoryDetail = {
  __typename?: 'InventoryDetail';
  availableQuantity: Scalars['Int'];
  batch: Scalars['String'];
  expirationDate: Scalars['Date'];
  glbInvDesc: Scalars['String'];
  glbInvStatus?: Maybe<Scalars['String']>;
  plant: Scalars['String'];
  shelfLifeDays: Scalars['Int'];
};

export type InvitationPartner = {
  allPartnerSelected: Scalars['Boolean'];
  billTo?: InputMaybe<Array<InputMaybe<PartnerType>>>;
  payer?: InputMaybe<Array<InputMaybe<PartnerType>>>;
  shipTo?: InputMaybe<Array<InputMaybe<PartnerType>>>;
  soldTo: Scalars['String'];
};

export type InvitationRequestBody = {
  emailId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  inviterParticipantId?: InputMaybe<Scalars['String']>;
  inviteToMyNetwork?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  partners?: InputMaybe<InvitationPartner>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type InviteCoworkerResponse = {
  __typename?: 'InviteCoworkerResponse';
  countryCode: Scalars['String'];
  emailId: Scalars['String'];
  firstName: Scalars['String'];
  inviteId: Scalars['String'];
  isNewInvitee: Scalars['Boolean'];
  lastName: Scalars['String'];
  locale: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type InviteEligibilityInput = {
  email?: InputMaybe<Scalars['String']>;
  salesOrg?: InputMaybe<Scalars['String']>;
  soldTo?: InputMaybe<Scalars['String']>;
  soldToCountry?: InputMaybe<Scalars['String']>;
};

export type InviteRequesterInput = {
  approvalAmount?: InputMaybe<Scalars['Int']>;
  approvalTypeId?: InputMaybe<ApprovalTypeId>;
  emailId: Scalars['String'];
};

export type Invoice = {
  __typename?: 'Invoice';
  amount: Scalars['Float'];
  currency?: Maybe<Scalars['String']>;
  dbCrIND?: Maybe<Scalars['String']>;
  formattedAmount?: Maybe<Scalars['String']>;
  invoiceDate: Scalars['Date'];
  invoiceNo: Scalars['String'];
  migratedInvoiceNo?: Maybe<Scalars['String']>;
  migratedTaxInvoiceNo?: Maybe<Scalars['String']>;
  payer?: Maybe<Scalars['String']>;
  poNumber?: Maybe<Scalars['String']>;
  taxInvoiceNo?: Maybe<Scalars['String']>;
};

export type InvoiceAutoSuggestResponse = {
  __typename?: 'InvoiceAutoSuggestResponse';
  companyVatInfoModelList: Array<VatInfoItem>;
  pageSize: Scalars['Int'];
};

export type InvoiceEmailInput = {
  invoiceEmail?: InputMaybe<Scalars['String']>;
};

export type InvoiceInput = {
  transactionNumber: Scalars['String'];
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  amount: Scalars['Int'];
  invoiceDate: Scalars['Date'];
  invoiceNo: Scalars['Int'];
  payer: Scalars['Int'];
  soldTo: Scalars['Int'];
  status: Scalars['String'];
  transactionNo: Scalars['String'];
};

export type InvoiceLineItem = {
  __typename?: 'InvoiceLineItem';
  accountingDocumentNumber: Scalars['String'];
  arrears: Scalars['String'];
  currency: Scalars['String'];
  dbCrIndicator: Scalars['String'];
  docType: Scalars['String'];
  invoiceAmount: Scalars['String'];
  invoiceDate: Scalars['String'];
  invoiceNumber: Scalars['String'];
  payerName: Scalars['String'];
  paymentTerms: Scalars['String'];
  postingDate: Scalars['String'];
  sapOrderNumber?: Maybe<Scalars['String']>;
};

export type InvoiceListInput = {
  fromdate?: InputMaybe<Scalars['String']>;
  invoicenumber?: InputMaybe<Scalars['String']>;
  ordernumber?: InputMaybe<Scalars['String']>;
  payer?: InputMaybe<Scalars['String']>;
  ponumber?: InputMaybe<Scalars['String']>;
  soldTo?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  todate?: InputMaybe<Scalars['String']>;
};

export type InvoiceListItem = {
  __typename?: 'InvoiceListItem';
  currency?: Maybe<Scalars['String']>;
  dueDate: Scalars['Date'];
  invoiceAmount?: Maybe<Scalars['Float']>;
  invoiceDate: Scalars['Date'];
  invoiceNumber?: Maybe<Scalars['String']>;
  poNumber?: Maybe<Scalars['String']>;
  salesDocNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['String']>;
};

export type InvoiceListResponse = {
  __typename?: 'InvoiceListResponse';
  count?: Maybe<Scalars['Int']>;
  fromDate?: Maybe<Scalars['String']>;
  invoiceList: Array<InvoiceListItem>;
  orderNumber?: Maybe<Scalars['String']>;
  payer?: Maybe<Scalars['String']>;
  poNumber?: Maybe<Scalars['String']>;
  soldTo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
};

export type InvoicePdfInput = {
  invoiceNumber: Scalars['String'];
  logisticId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  soldTo?: InputMaybe<Scalars['String']>;
};

export type InvoiceResponse = {
  __typename?: 'InvoiceResponse';
  invoices: Array<InvoiceItem>;
};

export type ItemComplianceDocument = {
  __typename?: 'ItemComplianceDocument';
  classification?: Maybe<Scalars['String']>;
  documentName?: Maybe<Scalars['String']>;
  formLabel?: Maybe<Scalars['String']>;
  legalRegulationID?: Maybe<Scalars['String']>;
};

export type ItemToQuote = {
  ccForOrderConfEmailIds?: InputMaybe<Scalars['String']>;
  configurationDetail?: InputMaybe<Scalars['JSONObject']>;
  configurationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  extension?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  materialDescription?: InputMaybe<Scalars['String']>;
  materialName?: InputMaybe<Scalars['String']>;
  materialNumber: Scalars['String'];
  mobile?: InputMaybe<Scalars['String']>;
  productConfiguration?: InputMaybe<Scalars['JSONObject']>;
  quantity: Scalars['Int'];
  telephone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CartItemType>;
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type KicqStartAddToCartInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  assays?: InputMaybe<Array<InputMaybe<AddToCartAssay>>>;
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  sapNextEnabled?: InputMaybe<Scalars['Boolean']>;
  store?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
};

export type KicqStartAddToCartResponse = {
  __typename?: 'KicqStartAddToCartResponse';
  result?: Maybe<Scalars['String']>;
  serverError?: Maybe<ListError>;
};

export type KicqStartGeneArraySessionInput = {
  arrayName?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  isArrayModified?: InputMaybe<Scalars['Boolean']>;
  pageNo?: InputMaybe<Scalars['Int']>;
  plates?: InputMaybe<Array<InputMaybe<GeneArrayPlateListInput>>>;
  pricing?: InputMaybe<GeneArrayPriceDetailsInput>;
  species?: InputMaybe<Scalars['String']>;
  speciesErrorMessage?: InputMaybe<Scalars['String']>;
  stepNo?: InputMaybe<Scalars['Int']>;
  wellType?: InputMaybe<Scalars['Int']>;
  wellTypeErrorMessage?: InputMaybe<Scalars['String']>;
};

export type KicqStartPrimerData = {
  __typename?: 'KicqStartPrimerData';
  currency?: Maybe<Scalars['String']>;
  inputSearchTerm?: Maybe<Array<Maybe<Scalars['String']>>>;
  mergedSession?: Maybe<Scalars['Boolean']>;
  results?: Maybe<Array<Maybe<SessionResponseResults>>>;
  resultsFilter?: Maybe<Array<Maybe<Scalars['String']>>>;
  resultsPage?: Maybe<Scalars['Int']>;
  resultsPerPage?: Maybe<Scalars['Int']>;
  resultsSortById?: Maybe<Scalars['String']>;
  resultsSortByOrder?: Maybe<Scalars['String']>;
  stepNo?: Maybe<Scalars['Int']>;
  totalResults?: Maybe<Scalars['Int']>;
};

export type KicqStartPrimerSessionData = {
  __typename?: 'KicqStartPrimerSessionData';
  data: Array<KicqStartPrimerData>;
};

export type KicqStartPrimerSessionInput = {
  currency?: InputMaybe<Scalars['String']>;
  inputSearchTerm?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mergedSession?: InputMaybe<Scalars['Boolean']>;
  results?: InputMaybe<Array<InputMaybe<SessionResults>>>;
  resultsFilter?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  resultsPage?: InputMaybe<Scalars['Int']>;
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  resultsSortById?: InputMaybe<Scalars['String']>;
  resultsSortByOrder?: InputMaybe<Scalars['String']>;
  stepNo?: InputMaybe<Scalars['Int']>;
  totalResults?: InputMaybe<Scalars['Int']>;
};

export type KitsItem = {
  __typename?: 'KitsItem';
  allAnalytesSelected?: Maybe<Scalars['String']>;
  analytes?: Maybe<Array<Maybe<Scalars['String']>>>;
  assayType?: Maybe<Scalars['String']>;
  customPremixedAvailable?: Maybe<Array<Maybe<Scalars['String']>>>;
  customPremixSelection?: Maybe<Scalars['String']>;
  decisionTableVer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  listAnalytes?: Maybe<Array<Maybe<Scalars['String']>>>;
  listSampleType?: Maybe<Array<Maybe<Scalars['String']>>>;
  listSearchResultModelErrors?: Maybe<Array<Maybe<ListError>>>;
  listWarnings?: Maybe<Array<Maybe<ListWarning>>>;
  material?: Maybe<Scalars['String']>;
  mutualAnalytesList?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  name?: Maybe<Scalars['String']>;
  panelId?: Maybe<Scalars['String']>;
  panelName?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  productTotal?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  requireSample?: Maybe<Scalars['String']>;
  researchAreaOracle?: Maybe<Array<Maybe<Scalars['String']>>>;
  researchAreas?: Maybe<Array<Maybe<Scalars['String']>>>;
  sampleType?: Maybe<Scalars['String']>;
  selectedAnalytes?: Maybe<Array<Maybe<Scalars['String']>>>;
  spaceSaver?: Maybe<Scalars['String']>;
  spaceSaverSelection?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type LabelValue = {
  __typename?: 'LabelValue';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type LabWaterAccessoriesConsumablesServices = {
  __typename?: 'LabWaterAccessoriesConsumablesServices';
  configuratorData: LabWaterAccessoriesConsumablesServicesConfiguratorData;
  hardwareSku: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  productDetails: Array<Maybe<Product>>;
  topFeatures?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LabWaterAccessoriesConsumablesServicesConfiguratorData = {
  __typename?: 'LabWaterAccessoriesConsumablesServicesConfiguratorData';
  accessories: Array<LabWaterAccessoriesConsumablesServicesData>;
  consumables: Array<LabWaterAccessoriesConsumablesServicesData>;
  services: Array<LabWaterAccessoriesConsumablesServicesData>;
};

export type LabWaterAccessoriesConsumablesServicesData = {
  __typename?: 'LabWaterAccessoriesConsumablesServicesData';
  catalogNo: Scalars['String'];
  dependentCatalogQuantityList: Array<Maybe<DependentCatalogQuantity>>;
  maxQuantity?: Maybe<Scalars['Int']>;
  pairedWithCatalog?: Maybe<Scalars['String']>;
  price?: Maybe<MaterialPricing>;
  productDetails?: Maybe<Product>;
  productGroup: Scalars['String'];
  productSubGroup: Scalars['String'];
  requirement: Scalars['String'];
  sbuCode: Scalars['Int'];
  sfdcQuantity: Scalars['Int'];
};

export type LabWaterAccessoriesConsumablesServicesInput = {
  hardwareSkus: Scalars['String'];
  participantId: Scalars['String'];
};

export type LabWaterAccessoryConsumableAndService = {
  catalogDescription?: InputMaybe<Scalars['String']>;
  catalogNo?: InputMaybe<Scalars['String']>;
  catalogQuantity?: InputMaybe<Scalars['String']>;
  customerPrice?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
};

export type LabWaterAccessoryConsumableAndServiceOut = {
  __typename?: 'LabWaterAccessoryConsumableAndServiceOut';
  catalogDescription?: Maybe<Scalars['String']>;
  catalogNo?: Maybe<Scalars['String']>;
  catalogQuantity?: Maybe<Scalars['String']>;
};

export type LabWaterSelection = {
  __typename?: 'LabWaterSelection';
  hwSelections: Array<HwSelection>;
  productType: Scalars['String'];
};

export type LabWaterSendEmailInput = {
  participantId?: InputMaybe<Scalars['String']>;
};

export type LabWaterSession = {
  __typename?: 'LabWaterSession';
  accessories?: Maybe<Array<Maybe<LabWaterAccessoryConsumableAndServiceOut>>>;
  applications?: Maybe<Array<Maybe<LabWaterSessionApplications>>>;
  commentForLWSpecialist?: Maybe<Scalars['String']>;
  consumables?: Maybe<Array<Maybe<LabWaterAccessoryConsumableAndServiceOut>>>;
  countryCode?: Maybe<Scalars['String']>;
  dailyWaterUsage?: Maybe<Scalars['String']>;
  feedWaterType?: Maybe<Scalars['String']>;
  listApplication?: Maybe<Array<Maybe<Scalars['String']>>>;
  listErrors?: Maybe<Array<Maybe<Scalars['String']>>>;
  listfeed?: Maybe<Array<Maybe<Scalars['String']>>>;
  listMaxwaterUsage?: Maybe<Array<Maybe<Scalars['String']>>>;
  listOutputWater?: Maybe<Array<Maybe<Scalars['String']>>>;
  listRegulated?: Maybe<Array<Maybe<Scalars['String']>>>;
  listSubapplication?: Maybe<Array<Maybe<Scalars['String']>>>;
  listSystem?: Maybe<Array<Maybe<Scalars['String']>>>;
  listWarnings?: Maybe<Array<Maybe<Scalars['String']>>>;
  otherApplication?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  regulatedEnvironment?: Maybe<Scalars['String']>;
  regulatoryRequirement?: Maybe<Scalars['String']>;
  services?: Maybe<Array<Maybe<LabWaterAccessoryConsumableAndServiceOut>>>;
  subApplication?: Maybe<Array<Maybe<Scalars['String']>>>;
  system?: Maybe<Scalars['String']>;
  typeOfWater?: Maybe<Scalars['String']>;
};

export type LabWaterSessionApplications = {
  __typename?: 'LabWaterSessionApplications';
  application?: Maybe<Scalars['String']>;
  subApplications?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LabWaterSessionApplicationsInput = {
  application?: InputMaybe<Scalars['String']>;
  subApplications?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LabWaterSessionInput = {
  guestParticipantId?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['String']>;
};

export type LabWaterSubApplications = {
  __typename?: 'LabWaterSubApplications';
  application: Scalars['String'];
  subApplications: Array<Scalars['String']>;
};

export type LabWaterSystemAttribute = {
  __typename?: 'LabWaterSystemAttribute';
  labWaterSystemAttributeId?: Maybe<Scalars['String']>;
  labWaterSystemAttributeValue?: Maybe<Scalars['String']>;
};

export type LabWaterSystemFeatures = {
  __typename?: 'LabWaterSystemFeatures';
  features?: Maybe<Array<Maybe<Features>>>;
  productId?: Maybe<Scalars['String']>;
};

export type LabWaterSystemFeaturesInput = {
  hardwareSkus: Array<InputMaybe<Scalars['String']>>;
};

export type LabWaterValidation = {
  __typename?: 'LabWaterValidation';
  listDefaultApplicationPack?: Maybe<Array<Maybe<Scalars['String']>>>;
  listDefaultTank?: Maybe<Array<Maybe<Scalars['String']>>>;
  listErrors?: Maybe<Array<Maybe<Scalars['String']>>>;
  listfeed?: Maybe<Array<Maybe<Scalars['String']>>>;
  listMaxwaterUsage?: Maybe<Array<Maybe<Scalars['String']>>>;
  listOutputWater?: Maybe<Array<Maybe<Scalars['String']>>>;
  listRegulated?: Maybe<Array<Maybe<Scalars['String']>>>;
  listSubapplication?: Maybe<Array<Maybe<Scalars['String']>>>;
  listSystem?: Maybe<Array<Maybe<Scalars['String']>>>;
  listSystems: Array<ListSystem>;
  listTopFeatures?: Maybe<Array<Maybe<ListTopFeatures>>>;
  listWarnings?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LabWaterValidationInput = {
  application: Array<InputMaybe<Scalars['String']>>;
  dailyWaterUsage?: InputMaybe<Scalars['String']>;
  feedWaterType?: InputMaybe<Scalars['String']>;
  regulatedEnvironment?: InputMaybe<Scalars['String']>;
  regulatoryRequirement?: InputMaybe<Scalars['String']>;
  subApplication: Array<InputMaybe<Scalars['String']>>;
  typeOfWater?: InputMaybe<Scalars['String']>;
};

export type LentivirusBackbone = {
  __typename?: 'LentivirusBackbone';
  LV01?: Maybe<Scalars['String']>;
  LV02?: Maybe<Scalars['String']>;
  LV03?: Maybe<Scalars['String']>;
  LV04?: Maybe<Scalars['String']>;
  LV05?: Maybe<Scalars['String']>;
  LV08?: Maybe<Scalars['String']>;
  LV13?: Maybe<Scalars['String']>;
  LV14?: Maybe<Scalars['String']>;
  LV15?: Maybe<Scalars['String']>;
  LV16?: Maybe<Scalars['String']>;
  LV17?: Maybe<Scalars['String']>;
};

export type ListCompanionProducts = {
  __typename?: 'ListCompanionProducts';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ListCompanionProductsInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type ListError = {
  __typename?: 'ListError';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ListErrorInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type ListKits = {
  __typename?: 'ListKits';
  analytes: Array<Scalars['String']>;
  appendToMaterialNumber?: Maybe<Scalars['String']>;
  assayType: Scalars['String'];
  assayTypeSelection?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  customPremixedAvailable?: Maybe<Array<Scalars['String']>>;
  listSampleType?: Maybe<Array<Scalars['String']>>;
  listWarnings?: Maybe<Array<Maybe<ListWarning>>>;
  minimumAnalytesCount?: Maybe<Scalars['Int']>;
  panelId: Scalars['String'];
  panelName: Scalars['String'];
  plateType?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  productTotal?: Maybe<Scalars['String']>;
  requireSample?: Maybe<Scalars['Boolean']>;
  researchAreas: Array<Maybe<Scalars['String']>>;
  sampleType?: Maybe<Scalars['String']>;
  spaceSaver?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ListSpecies = {
  __typename?: 'ListSpecies';
  displayName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ListSubModel = {
  __typename?: 'ListSubModel';
  baseCount?: Maybe<Scalars['Int']>;
  cartId?: Maybe<Scalars['String']>;
  geneId?: Maybe<Scalars['String']>;
  geneSymbol?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isCurrentForm?: Maybe<Scalars['Boolean']>;
  isOverridden?: Maybe<Scalars['Boolean']>;
  itemId?: Maybe<Scalars['String']>;
  listSubModelErrors?: Maybe<Array<Maybe<ListError>>>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['String']>;
};

export type ListSubModelInput = {
  baseCount?: InputMaybe<Scalars['Int']>;
  cartId?: InputMaybe<Scalars['String']>;
  geneId?: InputMaybe<Scalars['String']>;
  geneSymbol?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isCurrentForm?: InputMaybe<Scalars['Boolean']>;
  isOverridden?: InputMaybe<Scalars['Boolean']>;
  itemId?: InputMaybe<Scalars['String']>;
  listSubModelErrors?: InputMaybe<Array<InputMaybe<ListErrorInput>>>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['String']>;
};

export type ListSystem = {
  __typename?: 'ListSystem';
  features: Array<Scalars['String']>;
  mostRecommended?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  productDetails: Array<Product>;
  productNumber: Scalars['String'];
};

export type ListTopFeatures = {
  __typename?: 'ListTopFeatures';
  features?: Maybe<Array<Maybe<Scalars['String']>>>;
  productId?: Maybe<Scalars['String']>;
};

export type ListWarning = {
  __typename?: 'ListWarning';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ListWarningInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type LockableValue = {
  __typename?: 'LockableValue';
  locked?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type LoggedInUser = {
  __typename?: 'LoggedInUser';
  abilities: Abilities;
  adminPartnersInfo?: Maybe<Array<Maybe<AdminPartnersInfo>>>;
  allowBlankStreet: Scalars['Boolean'];
  approvalConsolidationModel?: Maybe<ApprovalConsolidationModel>;
  b2bChannelInformation: B2BChannelInformation;
  billingAddress?: Maybe<UserAddress>;
  cartPreferences: CartPreferences;
  contactAddress: UserAddress;
  createdDate?: Maybe<Scalars['Date']>;
  email: Scalars['String'];
  fax: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  inviteCoWorkerAffiliation?: Maybe<Scalars['Boolean']>;
  isDarmstadtUser: Scalars['Boolean'];
  isDirectCustomer?: Maybe<Scalars['String']>;
  isPreferredDealerCustomer?: Maybe<Scalars['Boolean']>;
  isPriceUpliftCustomer?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  logonId: Scalars['String'];
  memberId: Scalars['String'];
  metadata: UserMetadata;
  mobilePhone: Scalars['String'];
  mobileVerifiedFlag?: Maybe<Scalars['Boolean']>;
  multiCountryPartnersInfo: Array<Maybe<MultiCountryPartnersInfo>>;
  organizationId?: Maybe<Scalars['String']>;
  organizationName: Scalars['String'];
  participantCommunicationSetting: UserParticipantCommunicationSetting;
  participantComplianceInformation: UserParticipantComplianceInformation;
  participantShippingPreference: UserParticipantShippingPreference;
  participantSitePreference: UserParticipantSitePreference;
  paymentMethods: UserPaymentMethods;
  profileType: ProfileType;
  roleNames: Scalars['String'];
  roles: Roles;
  savedRegistration?: Maybe<UserSavedRegistration>;
  shippingAddress?: Maybe<UserAddress>;
  soldToNumber: Scalars['String'];
  soldToPartners: Array<SoldToPartner>;
  telephone: Scalars['String'];
  title: Scalars['String'];
  type: UserType;
  userAdLink?: Maybe<Scalars['String']>;
  userErpType: UserErpType;
  useShipToAddressForAllOrders: Scalars['Boolean'];
  weChatId?: Maybe<Scalars['String']>;
};

export type Login = {
  __typename?: 'Login';
  accessToken?: Maybe<Scalars['String']>;
  isGeneralTermsConditionDateExpired?: Maybe<Scalars['Boolean']>;
  isSiteUseTermsDateExpired?: Maybe<Scalars['Boolean']>;
  participantId?: Maybe<Scalars['String']>;
  piiDataConsentAcceptance?: Maybe<Scalars['Boolean']>;
  user?: Maybe<LoginUserRes>;
};

export type LoginUserRes = {
  __typename?: 'LoginUserRes';
  userAdLink?: Maybe<Scalars['String']>;
};

export type MappedProductComponent = {
  __typename?: 'MappedProductComponent';
  analyte?: Maybe<Array<Maybe<ProductComponent>>>;
  bulletin?: Maybe<Array<Maybe<ProductComponent>>>;
  kitOnly?: Maybe<Array<Maybe<ProductComponent>>>;
  kitSoldSeparate?: Maybe<Array<Maybe<ProductComponent>>>;
  solvent?: Maybe<Array<Maybe<ProductComponent>>>;
};

export type MarketplaceInvoice = {
  __typename?: 'MarketplaceInvoice';
  id?: Maybe<Scalars['String']>;
  marketplaceOrderId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  url: Scalars['String'];
};

export type MarketplaceShippingPreference = {
  __typename?: 'MarketplaceShippingPreference';
  participantId?: Maybe<Scalars['String']>;
  participantShippingMethods: Array<ParticipantShippingMethods>;
  shippingMethod?: Maybe<Scalars['String']>;
};

export type Material = {
  __typename?: 'Material';
  brand: Brand;
  casNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displaySellerName?: Maybe<Scalars['String']>;
  empiricalFormula?: Maybe<Scalars['String']>;
  erpType?: Maybe<Array<Maybe<Scalars['String']>>>;
  freeFreight?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  image: CatalogImage;
  isMarketplace?: Maybe<Scalars['Boolean']>;
  linearFormula?: Maybe<Scalars['String']>;
  marketplaceOfferId?: Maybe<Scalars['String']>;
  marketplaceSellerId?: Maybe<Scalars['String']>;
  molecularWeight?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  packageQuantity?: Maybe<Scalars['String']>;
  packageSize?: Maybe<Scalars['String']>;
  product: Scalars['String'];
  productKey?: Maybe<Scalars['String']>;
  promotionalMessage?: Maybe<PromotionalMessage>;
  shipsToday?: Maybe<Scalars['Boolean']>;
  substanceKey?: Maybe<Scalars['String']>;
  vendorName?: Maybe<Scalars['String']>;
  vendorNumber?: Maybe<Scalars['String']>;
};

export type MaterialAvailabilities = {
  __typename?: 'MaterialAvailabilities';
  materialAvailabilities?: Maybe<ValidMaterialAvailability>;
};

export type MaterialAvailability = {
  __typename?: 'MaterialAvailability';
  availabilityOverwriteMessage?: Maybe<AvailabilityOverwriteMessage>;
  contactInfo?: Maybe<MaterialContactInfo>;
  date?: Maybe<Scalars['Date']>;
  displayFromLink?: Maybe<Scalars['Boolean']>;
  displayInquireLink?: Maybe<Scalars['Boolean']>;
  key: MaterialAvailabilityKey;
  messageType?: Maybe<Scalars['String']>;
  plantLoc?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplementaryMessage?: Maybe<MaterialSupplementaryMessage>;
};

export enum MaterialAvailabilityKey {
  ApoNoStock = 'APO_NO_STOCK',
  AvailabilityUnknown = 'AVAILABILITY_UNKNOWN',
  AvailableToShipOn = 'AVAILABLE_TO_SHIP_ON',
  AvailableToShipOnDynamic = 'AVAILABLE_TO_SHIP_ON_DYNAMIC',
  AvailableToShipOnDynamicWithoutPlantLoc = 'AVAILABLE_TO_SHIP_ON_DYNAMIC_WITHOUT_PLANT_LOC',
  CheckCartForAvailability = 'CHECK_CART_FOR_AVAILABILITY',
  ContactForAvailability = 'CONTACT_FOR_AVAILABILITY',
  EstimatedDeliveryOn = 'ESTIMATED_DELIVERY_ON',
  EstimatedToShipOn = 'ESTIMATED_TO_SHIP_ON',
  EstimatedToShipOnDynamic = 'ESTIMATED_TO_SHIP_ON_DYNAMIC',
  EstimatedToShipOnDynamicWithoutPlantLoc = 'ESTIMATED_TO_SHIP_ON_DYNAMIC_WITHOUT_PLANT_LOC',
  FulfilmentDeliveryDelayed = 'FULFILMENT_DELIVERY_DELAYED',
  InquireForPriceAndAvailability = 'INQUIRE_FOR_PRICE_AND_AVAILABILITY',
  LimitedAvailability = 'LIMITED_AVAILABILITY',
  OnlyFewLeftInStock = 'ONLY_FEW_LEFT_IN_STOCK',
  OutOfStockKey = 'OUT_OF_STOCK_KEY',
  PricingAndAvailabilityUnavailable = 'PRICING_AND_AVAILABILITY_UNAVAILABLE',
  ShippingDateNotAvailable = 'SHIPPING_DATE_NOT_AVAILABLE',
  ShipsOnFrom = 'SHIPS_ON_FROM',
  TppAvailabilityMessage = 'TPP_AVAILABILITY_MESSAGE',
  UnknownAvailability = 'UNKNOWN_AVAILABILITY'
}

export type MaterialContactInfo = {
  __typename?: 'MaterialContactInfo';
  contactEmail?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
};

/**
 * Pricing for a single material, which will return one pricing object and
 * optionally one object describing the material's replacement materials, if it
 * is discontinued.
 */
export type MaterialPricing = {
  __typename?: 'MaterialPricing';
  discontinuedPricingInfo?: Maybe<DiscontinuedMaterialPricing>;
  materialPricing?: Maybe<ValidMaterialPricing>;
};

export type MaterialSupplementaryMessage = {
  __typename?: 'MaterialSupplementaryMessage';
  messageKey?: Maybe<Scalars['String']>;
  messageValue?: Maybe<Scalars['String']>;
  messageVariable1?: Maybe<Scalars['String']>;
  messageVariable2?: Maybe<Scalars['String']>;
  messageVariable3?: Maybe<Scalars['String']>;
};

export type MililiQsku = {
  __typename?: 'MililiQSKU';
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type MilliplexAssayModel = {
  assays?: InputMaybe<Array<InputMaybe<AssayModel>>>;
  count?: InputMaybe<Scalars['Int']>;
  requestDate?: InputMaybe<Scalars['String']>;
  responseDate?: InputMaybe<Scalars['String']>;
  term?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  termCount?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

export type MilliplexDataInput = {
  additionalComments?: InputMaybe<Scalars['String']>;
  analytes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  attributes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  Bovineplatform?: InputMaybe<Scalars['String']>;
  brand?: InputMaybe<Scalars['String']>;
  cartId?: InputMaybe<Scalars['String']>;
  checkedProductIdList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  comments?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  currencySymbol?: InputMaybe<Scalars['String']>;
  errors?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id?: InputMaybe<Scalars['Int']>;
  itemId?: InputMaybe<Scalars['String']>;
  listAnalytes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listError?: InputMaybe<Array<InputMaybe<ListErrorInput>>>;
  listMilliplexAssayModel?: InputMaybe<Array<InputMaybe<MilliplexAssayModel>>>;
  listResearchAreas?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listSensitivity?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listSpecies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listWarning?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mapMates?: InputMaybe<Scalars['String']>;
  milliplexcheckedProductIdList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  oligoId?: InputMaybe<Scalars['Int']>;
  platform?: InputMaybe<Scalars['String']>;
  priceHeaderError?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  researchAreas?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  researcher?: InputMaybe<Scalars['String']>;
  sampleVolume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sensitivity?: InputMaybe<Scalars['String']>;
  singlePlexPlatform?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  species?: InputMaybe<Scalars['String']>;
};

export type MilliplexDealerConfirmationEmailInput = {
  comments?: InputMaybe<Scalars['String']>;
  emailId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type MilliplexInput = {
  analytes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  count?: InputMaybe<Scalars['Int']>;
  instrumentTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  kits?: InputMaybe<Array<InputMaybe<MilliplexKitInput>>>;
  page?: InputMaybe<Scalars['Int']>;
  panelId?: InputMaybe<Scalars['String']>;
  panelIdList?: InputMaybe<Array<InputMaybe<MilliplexKitPanelListInput>>>;
  plateType?: InputMaybe<Scalars['String']>;
  previouslyViewedPanelId?: InputMaybe<Scalars['String']>;
  researchAreas?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  selectedAssayType?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Scalars['String']>;
};

export type MilliplexKitAndSampleTypeInput = {
  allAnalytesSelected?: InputMaybe<Scalars['String']>;
  analytes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  appendToMaterialNumber?: InputMaybe<Scalars['String']>;
  assayTypeSelection?: InputMaybe<Scalars['String']>;
  customPremixSelection?: InputMaybe<Scalars['String']>;
  instrumentTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  panelId?: InputMaybe<Scalars['String']>;
  plateType?: InputMaybe<Scalars['String']>;
  priceCallRequired?: InputMaybe<Scalars['Boolean']>;
  researchAreas?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sampleType?: InputMaybe<Scalars['String']>;
  selectedAnalytes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  spaceSaverSelection?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type MilliplexKitAndSampleTypeResponse = {
  __typename?: 'MilliplexKitAndSampleTypeResponse';
  instrumentTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  listAnalytes?: Maybe<Array<Scalars['String']>>;
  listSampleType?: Maybe<Array<Scalars['String']>>;
  listWarnings?: Maybe<Array<ListWarning>>;
  panelId: Scalars['String'];
  priceData?: Maybe<MilliplexKitPrice>;
  sampleType?: Maybe<Scalars['String']>;
  selectedAnalytes?: Maybe<Array<Scalars['String']>>;
  selectedInstrumentTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MilliplexKitAndSampleTypeResponsePriceDataArgs = {
  input?: InputMaybe<MilliplexKitAndSampleTypeInput>;
};

export type MilliplexKitInput = {
  material?: InputMaybe<Scalars['String']>;
  panelId: Scalars['String'];
  panelName: Scalars['String'];
};

export type MilliplexKitPanelListInput = {
  analytes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  customPremixSelection?: InputMaybe<Scalars['String']>;
  panelId?: InputMaybe<Scalars['String']>;
};

export type MilliplexKitPrice = {
  __typename?: 'MilliplexKitPrice';
  currency?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
};

export type MilliplexKitsResponse = {
  __typename?: 'MilliplexKitsResponse';
  count: Scalars['Int'];
  listKits: Array<ListKits>;
};


export type MilliplexKitsResponseListKitsArgs = {
  input?: InputMaybe<MilliplexInput>;
};

export type MilliplexPriceResponse = {
  __typename?: 'MilliplexPriceResponse';
  brand?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  listMilliplexAssayModel?: Maybe<Array<Maybe<MilliplexSearchResponse>>>;
  priceHeaderError?: Maybe<Scalars['String']>;
  serverError?: Maybe<ListError>;
};

export type MilliplexProductInput = {
  sapNextEnabled?: InputMaybe<Scalars['Boolean']>;
  store?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
};

export type MilliplexResponse = {
  __typename?: 'MilliplexResponse';
  listAnalytes: Array<Maybe<Scalars['String']>>;
  listResearchAreas: Array<Maybe<Scalars['String']>>;
  listSpecies: Array<ListSpecies>;
};

export type MilliplexSearchCriteriaResponse = {
  __typename?: 'MilliplexSearchCriteriaResponse';
  assayTypePlateType: Array<AssayTypeItem>;
  listSpeciesResearchAreas: Array<SpeciesItem>;
  plateInstrumentType: Array<InstrumentTypeItem>;
};

export type MilliplexSearchResponse = {
  __typename?: 'MilliplexSearchResponse';
  assays?: Maybe<Array<Maybe<KitsItem>>>;
  count?: Maybe<Scalars['Int']>;
  requestDate?: Maybe<Scalars['String']>;
  responseDate?: Maybe<Scalars['String']>;
  term?: Maybe<Array<Maybe<Scalars['String']>>>;
  termCount?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type MilliplexSessionData = {
  __typename?: 'MilliplexSessionData';
  analytes?: Maybe<Array<Maybe<Scalars['String']>>>;
  attributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  Bovineplatform?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  checkedProductIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['Int']>;
  listMilliplexAssayModel?: Maybe<Array<Maybe<MilliplexSearchResponse>>>;
  mapMates?: Maybe<Scalars['String']>;
  milliplexcheckedProductIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
  oligoId?: Maybe<Scalars['Int']>;
  platform?: Maybe<Scalars['String']>;
  priceHeaderError?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  researchAreas?: Maybe<Array<Maybe<Scalars['String']>>>;
  sampleVolume?: Maybe<Array<Maybe<Scalars['String']>>>;
  sensitivity?: Maybe<Scalars['String']>;
  singlePlexPlatform?: Maybe<Array<Maybe<Scalars['String']>>>;
  species?: Maybe<Scalars['String']>;
};

export type MilliplexSessionDataResponse = {
  __typename?: 'MilliplexSessionDataResponse';
  milliplexData?: Maybe<Array<Maybe<MilliplexSessionData>>>;
};

export type MilliplexTotalValidationResponse = {
  __typename?: 'milliplexTotalValidationResponse';
  additionalComments?: Maybe<Scalars['String']>;
  analytes?: Maybe<Array<Maybe<Scalars['String']>>>;
  brand?: Maybe<Scalars['String']>;
  cartId?: Maybe<Scalars['String']>;
  checkedProductIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
  comments?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['String']>;
  listAnalytes?: Maybe<Array<Maybe<Scalars['String']>>>;
  listError?: Maybe<Array<Maybe<ListError>>>;
  listMilliplexAssayModel?: Maybe<Array<Maybe<MilliplexSearchResponse>>>;
  listResearchAreas?: Maybe<Array<Maybe<Scalars['String']>>>;
  listSensitivity?: Maybe<Array<Maybe<Scalars['String']>>>;
  listSpecies?: Maybe<Array<Maybe<Scalars['String']>>>;
  listWarning?: Maybe<Array<Maybe<Scalars['String']>>>;
  mapMates?: Maybe<Scalars['String']>;
  oligoId?: Maybe<Scalars['Int']>;
  priceHeaderError?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  researchAreas?: Maybe<Array<Maybe<Scalars['String']>>>;
  researcher?: Maybe<Scalars['String']>;
  sensitivity?: Maybe<Scalars['String']>;
  serverError?: Maybe<ListError>;
  species?: Maybe<Scalars['String']>;
};

export type MilliQSqBasePriceData = {
  __typename?: 'MilliQSqBasePriceData';
  price?: Maybe<MilliQSqPriceData>;
};

export type MilliQSqBasePriceInput = {
  productList: Array<GetPricingForProductInput>;
};

export type MilliQSqData = {
  __typename?: 'MilliQSqData';
  configuratorData?: Maybe<MilliQSqProductData>;
};

export type MilliQSqInput = {
  filter?: InputMaybe<Scalars['String']>;
  numberOfModules?: InputMaybe<Scalars['Int']>;
  participantId?: InputMaybe<Scalars['String']>;
  pwTankQuantity?: InputMaybe<Scalars['String']>;
  systemType: Scalars['String'];
  upwModuleQuantity?: InputMaybe<Scalars['String']>;
};

export type MilliQSqPriceData = {
  __typename?: 'MilliQSqPriceData';
  errorMsg?: Maybe<Scalars['String']>;
  priceData?: Maybe<Array<Maybe<BasePrice>>>;
};

export type MilliQSqProductData = {
  __typename?: 'MilliQSqProductData';
  accessories: Array<MilliQSqProductDataItem>;
  powerCodes: Array<MilliQSqProductDataItem>;
  services: Array<MilliQSqProductDataItem>;
};

export type MilliQSqProductDataItem = {
  __typename?: 'MilliQSqProductDataItem';
  catalogNo: Scalars['String'];
  fixedQuantity: Scalars['Int'];
  imageId: Scalars['String'];
  maxQuantity?: Maybe<Scalars['Int']>;
  price?: Maybe<MaterialPricing>;
  productDetails?: Maybe<Product>;
  productGroup: Scalars['String'];
  requirement: Scalars['String'];
};

export type MilliqSqSendEmailInput = {
  milliqSqSession?: InputMaybe<MilliqSqSessionInput>;
  participantId?: InputMaybe<Scalars['String']>;
};

export type MilliqSqSessionInput = {
  accessories?: InputMaybe<Array<InputMaybe<AccessoryServicePowerCordItemInput>>>;
  commentForMSQSpecialist?: InputMaybe<Scalars['String']>;
  finalFilter?: InputMaybe<WaterSystemInput>;
  milliQWaterSystem?: InputMaybe<MilliQWaterSystemInput>;
  milliQWaterSystemSkuList?: InputMaybe<Array<MilliQWaterSystemSkuItemInput>>;
  powerCord?: InputMaybe<AccessoryServicePowerCordItemInput>;
  preFilter?: InputMaybe<WaterSystemInput>;
  pureWaterSystem?: InputMaybe<WaterSystemInput>;
  selectedPlacement?: InputMaybe<Scalars['String']>;
  services?: InputMaybe<Array<InputMaybe<AccessoryServicePowerCordItemInput>>>;
  ultraPureWaterSystem?: InputMaybe<WaterSystemInput>;
  upwCartridge?: InputMaybe<WaterSystemInput>;
};

export type MilliqSqSessionResponse = {
  __typename?: 'MilliqSqSessionResponse';
  accessories?: Maybe<Array<Maybe<AccessoryServicePowerCordItem>>>;
  commentForMSQSpecialist?: Maybe<Scalars['String']>;
  finalFilter?: Maybe<WaterSystem>;
  powerCord?: Maybe<AccessoryServicePowerCordItem>;
  preFilter?: Maybe<WaterSystem>;
  pureWaterSystem?: Maybe<WaterSystem>;
  selectedPlacement?: Maybe<Scalars['String']>;
  services?: Maybe<Array<Maybe<AccessoryServicePowerCordItem>>>;
  ultraPureWaterSystem?: Maybe<WaterSystem>;
  upwCartridge?: Maybe<WaterSystem>;
};

export type MilliQWaterSystemInput = {
  currency?: InputMaybe<Scalars['String']>;
  filterAndCatridges: Array<Scalars['String']>;
  netPrice?: InputMaybe<Scalars['Float']>;
  pointOfDispense: Array<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Int']>;
  waterSystem?: InputMaybe<Scalars['String']>;
};

export type MilliQWaterSystemSkuItemInput = {
  brand?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  listPrice?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  productName?: InputMaybe<Scalars['String']>;
  productNumber?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

export type Modification = {
  __typename?: 'Modification';
  no?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['String']>;
  yes?: Maybe<Scalars['String']>;
};

export type Modifications = {
  __typename?: 'Modifications';
  category: Array<Category>;
  modificationType: Scalars['String'];
  modificationTypeDisplayName: Scalars['String'];
};

export type ModificationsAndSpecificationsResponse = {
  __typename?: 'ModificationsAndSpecificationsResponse';
  errorType?: Maybe<Scalars['String']>;
  modifications: Array<Modifications>;
  specifications: Array<Specifications>;
};

export type MpayChargePaymentInput = {
  cardType?: InputMaybe<Scalars['String']>;
  encryptedCVV?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
  transactionId: Scalars['String'];
};

export type MpayInvoiceInput = {
  fromDate?: InputMaybe<Scalars['String']>;
  payer?: InputMaybe<Scalars['String']>;
  soldTo?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['String']>;
};

export type MpayInvoicePaymentUpdateInput = {
  data?: InputMaybe<Scalars['String']>;
  length?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['String']>;
};

export type MpayInvoicePaymentUpdateResponse = {
  __typename?: 'MpayInvoicePaymentUpdateResponse';
  errorMessage?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Boolean']>;
};

export type MpayInvoiceResponse = {
  __typename?: 'MpayInvoiceResponse';
  invoiceLineItems?: Maybe<Array<Maybe<InvoiceLineItem>>>;
  openInvoiceHeaderInfoModel?: Maybe<OpenInvoiceHeaderInfoModel>;
};

export type MpayPaymentHistoryResponse = {
  __typename?: 'MpayPaymentHistoryResponse';
  invoiceTransactionHistoryList: Array<PaymentHistoryLineItem>;
};

export type MpayPaymentUrlInput = {
  currency?: InputMaybe<Scalars['String']>;
  payer?: InputMaybe<Scalars['String']>;
  salesOrg?: InputMaybe<Scalars['String']>;
  soldTo?: InputMaybe<Scalars['String']>;
  submittedInvoiceList: Array<MpayPaymentUrlListItem>;
  totalAmount?: InputMaybe<Scalars['Float']>;
};

export type MpayPaymentUrlListItem = {
  comments?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  dbCrIndicator?: InputMaybe<Scalars['String']>;
  docType?: InputMaybe<Scalars['String']>;
  invoiceAmount?: InputMaybe<Scalars['String']>;
  invoiceDate?: InputMaybe<Scalars['String']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  netAmount?: InputMaybe<Scalars['Float']>;
  openInvoiceAccountingDocumentNumber?: InputMaybe<Scalars['String']>;
  paymentTerm?: InputMaybe<Scalars['String']>;
  postingDate?: InputMaybe<Scalars['String']>;
  residualAmount?: InputMaybe<Scalars['Float']>;
  sapOrderNumber?: InputMaybe<Scalars['String']>;
  withHoldingTax?: InputMaybe<Scalars['Float']>;
};

export type MpayPaymentUrlResponse = {
  __typename?: 'MpayPaymentUrlResponse';
  transactionId: Scalars['String'];
};

export type MpayProcessPaymentResponse = {
  __typename?: 'MpayProcessPaymentResponse';
  status: Scalars['String'];
};

export type MPortalLinks = {
  __typename?: 'MPortalLinks';
  invoice?: Maybe<Scalars['String']>;
  orderTracking?: Maybe<Scalars['String']>;
};

export type MultiCountryPartnersInfo = {
  __typename?: 'MultiCountryPartnersInfo';
  billTo: Scalars['String'];
  country: Scalars['String'];
  participantId: Scalars['String'];
  payer: Scalars['String'];
  pricingAvailabilitySetting: Scalars['String'];
  quantity: Scalars['Int'];
  salesOrg?: Maybe<Scalars['String']>;
  shipTo: Scalars['String'];
  soldTo: Scalars['String'];
  storeId: Scalars['Int'];
};

export type MultipleProductInput = {
  catalogType?: InputMaybe<CatalogType>;
  orgId?: InputMaybe<Scalars['String']>;
  productInputs: Array<ProductInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** dummy value required for schema merging */
  _?: Maybe<Scalars['String']>;
  acceptInviteApprover: DealerApprover;
  acceptInviteRequester: Requester;
  addCoworkerToGroup?: Maybe<Scalars['Boolean']>;
  addItemsToCart: Cart;
  addItemsToSavedCart: Scalars['Boolean'];
  addItemToSavedDocs: Scalars['Boolean'];
  addQuickOrderItems: Array<QuickOrderItemsResponse>;
  addSaveForLaterItem: Scalars['Boolean'];
  addSoftwareItemsToSavedCart: NewSavedCart;
  addToProtocolsLibrary: Scalars['Boolean'];
  addUserActivity?: Maybe<UserActivityEntry>;
  antiseraEmailCart?: Maybe<Scalars['Boolean']>;
  antiseraSaveToSession?: Maybe<SessionResponse>;
  antiseraTotalValidation?: Maybe<Array<AntiseraTotalValidationResponse>>;
  approveDealerOrder: CartSubmission;
  biTubePriceCall?: Maybe<Array<BitubeTotalValidationResponse>>;
  bitubeTotalValidation?: Maybe<Array<BitubeTotalValidationResponse>>;
  cancelCart: Scalars['Boolean'];
  cancelMarketplaceOrder: Scalars['Boolean'];
  cancelSapOrder: Scalars['Boolean'];
  changePassword?: Maybe<Scalars['Boolean']>;
  chatWithAssistant: AssistantResponse;
  clearRedisCache?: Maybe<Scalars['String']>;
  clearSession?: Maybe<Login>;
  clearSharedListItems: Scalars['Boolean'];
  confAddToMiniCart?: Maybe<ConfiguratorMiniCartResponse>;
  confirmAffiliation?: Maybe<Scalars['Boolean']>;
  confirmOrderQuote: Scalars['Boolean'];
  confMilliplexAddToCart?: Maybe<ConfiguratorMilliplexAddToCartResponse>;
  consolidateOrdersIntoActiveCart: Scalars['Boolean'];
  convertQuoteToOrder?: Maybe<Scalars['String']>;
  copyPasteTube?: Maybe<UploadCopyPasteResponse>;
  createApproverCart: Scalars['Boolean'];
  createContractCart: Scalars['Boolean'];
  createCustomPromotionalBundle?: Maybe<Scalars['Boolean']>;
  createDealerCart: Scalars['Boolean'];
  createElavonSessionToken: Scalars['String'];
  createGroup?: Maybe<CreateGroupResponse>;
  createMpayTransaction: MpayPaymentUrlResponse;
  createOrderEvaluation: Scalars['Boolean'];
  createParallelSavedCart: Scalars['Boolean'];
  createSharedList: SharedListDetail;
  createSharedListItems: Scalars['Boolean'];
  crisprgrnaAddToCart?: Maybe<CrisprgrnaAddToCartResponse>;
  crisprgRnaPriceCall?: Maybe<CrisprgRnaPriceCall>;
  customCrisprSaveToSession: SessionResponse;
  customCrisprTotalValidation?: Maybe<Array<CustomCrisprTotalValidationResponse>>;
  deactivateCoworker?: Maybe<Scalars['Boolean']>;
  delegateApprover?: Maybe<Scalars['Boolean']>;
  deleteCartItem: Scalars['Boolean'];
  deleteConsolidationOrders: Scalars['Boolean'];
  deleteCoworkerGroup?: Maybe<Scalars['Boolean']>;
  deleteMyNetworkGroup?: Maybe<Scalars['Boolean']>;
  deleteParallelCart: Scalars['Boolean'];
  deleteQuickOrderItem?: Maybe<Scalars['Boolean']>;
  deleteQuickOrderItems?: Maybe<Scalars['Boolean']>;
  deleteRequester: Scalars['Boolean'];
  deleteSavedCart: Scalars['Boolean'];
  deleteSaveForLaterItem: Scalars['Boolean'];
  deleteSharedList: Scalars['Boolean'];
  deleteSharedListInvitations: Scalars['Boolean'];
  deleteSharedListMembers: Scalars['Boolean'];
  deleteUserPushSubscription: Array<UserPushSubscription>;
  duplexSpecificationValidation?: Maybe<WellSpecificationResponse>;
  emailCart: Scalars['Boolean'];
  emailVerification?: Maybe<Scalars['Boolean']>;
  fastduplexRuleValidation: FastDuplexValidateResponse;
  fedexNotificationSubscription: FedexNotificationSubscriptionResponse;
  fetchOauthSessionExchange?: Maybe<Oauth2SessionResponse>;
  fetchPosPaymentData: PosPaymentDataResponse;
  fetchThirdPartyToken?: Maybe<ThirdPartyTokenResponse>;
  fetchWechatInfo?: Maybe<WechatInfo>;
  fileUploadPlate?: Maybe<UploadCopyPasteResponse>;
  forgotPassword?: Maybe<ForgotPasswordResponseType>;
  fullRegistration?: Maybe<Scalars['Boolean']>;
  generateQuote: QuoteGenerationResponse;
  genomicsFileUpload?: Maybe<UploadFileResponse>;
  getAntiseraPriceCall?: Maybe<AntiseraPriceResponse>;
  getForgotPin: Scalars['Boolean'];
  getMobileSecurityCode?: Maybe<Scalars['Boolean']>;
  inviteRequester: Scalars['Boolean'];
  kicqStartAddToCart?: Maybe<KicqStartAddToCartResponse>;
  kicqStartGeneArraySaveToSession?: Maybe<SaveToSessionResponse>;
  kicqStartPrimerSaveToSession?: Maybe<SaveToSessionResponse>;
  login?: Maybe<Login>;
  logout?: Maybe<Login>;
  mergeEmailCart: Scalars['Boolean'];
  mergeSavedCart: Scalars['Boolean'];
  milliplexPriceCall?: Maybe<MilliplexPriceResponse>;
  milliplexSaveToSession?: Maybe<SessionResponse>;
  milliplexTotalValidation?: Maybe<Array<Maybe<MilliplexTotalValidationResponse>>>;
  ngsoCopyPaste?: Maybe<UploadCopyPasteResponse>;
  ngsoFileUpload?: Maybe<UploadFileResponse>;
  openIncidentMessage?: Maybe<Array<Maybe<IncidentMessageResponse>>>;
  orfAddToCart?: Maybe<OrfAddToCartResponse>;
  orfPriceCall?: Maybe<OrfPriceCall>;
  patchCartItem: Cart;
  patchCcLegalTerms?: Maybe<Scalars['Boolean']>;
  patchQuoteWithUpdateData: Scalars['Boolean'];
  patchSitePreference?: Maybe<Scalars['Boolean']>;
  plateSessionField: Scalars['Boolean'];
  plateSpecificationValidation?: Maybe<WellSpecificationResponse>;
  plateTotalValidation?: Maybe<PlatesDataResponse>;
  postBigQueryData?: Maybe<Scalars['Boolean']>;
  processMpayPayment: MpayProcessPaymentResponse;
  readyToShipNotification: ReadyToShipNotificationResponse;
  registerBlanketPo: RegisteredBlanketPoResponse;
  registerNewUser?: Maybe<RegisteredUserProfile>;
  rejectDealerRequests: Scalars['Boolean'];
  removeCoworker?: Maybe<Scalars['Boolean']>;
  removeItemFromSavedDocs: Scalars['Boolean'];
  removeOmsAccess?: Maybe<Scalars['Boolean']>;
  removeSharedListItem: Scalars['Boolean'];
  reorderCart: Scalars['Boolean'];
  requestApproval: Scalars['Boolean'];
  requestQuoteForMilliplex?: Maybe<Scalars['Boolean']>;
  resendEmail?: Maybe<Scalars['Boolean']>;
  resetPassword?: Maybe<Scalars['Boolean']>;
  resubmitOrderQuote: Scalars['Boolean'];
  ruleValidateDuplex: RuleValidateResponsePlate;
  ruleValidateFields: RuleValidateResponse;
  ruleValidatePlate: RuleValidateResponsePlate;
  ruleValidateScreen: RuleValidateResponsePlate;
  sangerAddToCart?: Maybe<SangerCrisprAddToCartResponse>;
  sangerPriceCall?: Maybe<SangerCrisprPriceCall>;
  saveCart: NewSavedCart;
  saveLabWaterSession?: Maybe<Scalars['Boolean']>;
  saveMilliqSqSession?: Maybe<Scalars['Boolean']>;
  saveUserPushSubscription: Array<UserPushSubscription>;
  screenSessionField: SessionResponse;
  ScreenSpecificationValidation?: Maybe<ScreenWellSpecificationResponse>;
  screenTotalValidation?: Maybe<PlatesDataResponse>;
  selectDealer: Scalars['Boolean'];
  semiConfAddToMiniCart?: Maybe<SemiConfiguratorMiniCartResponse>;
  semiConfSaveToSession?: Maybe<SaveToSessionResponse>;
  sendInvite?: Maybe<Scalars['Boolean']>;
  sendLabWaterEmail?: Maybe<Scalars['Boolean']>;
  sendMilliplexDealerConfirmationEmail?: Maybe<Scalars['Boolean']>;
  sendMilliqSqEmail?: Maybe<Scalars['Boolean']>;
  sendOrderShippedNotification: SendNotificationResult;
  sessionFields: SessionResponse;
  shareOrderDetails: Scalars['Boolean'];
  shareProductDetails?: Maybe<Scalars['Boolean']>;
  shareQuoteDetails: Scalars['Boolean'];
  shrnaAddToCart?: Maybe<ShrnaAddToCartResponse>;
  shrnaPriceCall?: Maybe<ShrnaPriceCall>;
  simpleAntiseraTotalValidation?: Maybe<Array<SimpleAntiseraTotalValidationResponse>>;
  sirnaAddToCart?: Maybe<SirnaAddToCartResponse>;
  submitCart: CartSubmission;
  submitContactSeller?: Maybe<ContactSellerResponse>;
  submitFullRegistration?: Maybe<SubmitFullRegistrationResponse>;
  tubePriceCall?: Maybe<TubePriceCall>;
  tubeTotalValidation?: Maybe<Array<TubeTotalValidationResponse>>;
  updateCart: Cart;
  updateCartItems: Cart;
  updateCoworkerGroup?: Maybe<CreateGroupResponse>;
  updateCoworkerRoles?: Maybe<Scalars['Boolean']>;
  updateCreditCardPaymentInfo: CreditCardPaymentUpdateResponse;
  updateDefaultAddress?: Maybe<Scalars['Boolean']>;
  updateDeliveryDate: Scalars['Boolean'];
  updateDirectShipment: Scalars['Boolean'];
  updateEmproveUserTermsConditions?: Maybe<Scalars['Boolean']>;
  updateMembersDoneAddingToSharedList: Scalars['Boolean'];
  updateMobile?: Maybe<Scalars['Boolean']>;
  updateMpayInvoicePaymentInfo: MpayInvoicePaymentUpdateResponse;
  updateOrdersPendingApproval: Scalars['Boolean'];
  updatePinnedAddresses?: Maybe<Scalars['Boolean']>;
  updateQuickOrderItem?: Maybe<Scalars['Boolean']>;
  updateRequester: Scalars['Boolean'];
  updateSavedCart: Scalars['Boolean'];
  updateSavedCartItems: Scalars['Boolean'];
  updateSharedList: Scalars['Boolean'];
  updateSharedListItem: Scalars['Boolean'];
  updateTermCondition?: Maybe<TermConditionResponse>;
  updateTermsAndConditions?: Maybe<Scalars['Boolean']>;
  updateUserProfile?: Maybe<Scalars['Boolean']>;
  updateUserSitePreference?: Maybe<Scalars['Boolean']>;
  updateWIMSAccessCode?: Maybe<UpdateWimsAccessCodeResponse>;
  uploadBusinessLicense: Scalars['Boolean'];
  uploadCopyPasteText?: Maybe<UploadCopyPasteResponse>;
  uploadTubesFromFile?: Maybe<UploadFileResponse>;
  validateMobileSecurityCode?: Maybe<ValidateMobileSecurityCodeResponse>;
  validatePlateShipping?: Maybe<PlateShippingResponse>;
  verifyEmail?: Maybe<VerificationResponse>;
  wechatBind?: Maybe<WechatLoginResponse>;
  wechatLogin?: Maybe<WechatLoginResponse>;
  wechatUnbind?: Maybe<Scalars['Boolean']>;
};


export type MutationAcceptInviteApproverArgs = {
  token: Scalars['String'];
};


export type MutationAcceptInviteRequesterArgs = {
  token: Scalars['String'];
};


export type MutationAddCoworkerToGroupArgs = {
  input: AddCoworkerToGroupInput;
};


export type MutationAddItemsToCartArgs = {
  input: AddItemsToCartInput;
};


export type MutationAddItemsToSavedCartArgs = {
  input: AddSavedCartItemsInput;
};


export type MutationAddItemToSavedDocsArgs = {
  input: SavedDocumentInput;
};


export type MutationAddQuickOrderItemsArgs = {
  input: AddQuickOrderItemsInput;
};


export type MutationAddSaveForLaterItemArgs = {
  input: SaveForLaterInput;
};


export type MutationAddSoftwareItemsToSavedCartArgs = {
  input: AddSoftwareSavedCartItemsInput;
};


export type MutationAddToProtocolsLibraryArgs = {
  input: AddToProtocolsLibraryInput;
};


export type MutationAddUserActivityArgs = {
  input?: InputMaybe<UserActivityInput>;
};


export type MutationAntiseraEmailCartArgs = {
  input?: InputMaybe<Array<InputMaybe<AntiseraSessionInput>>>;
};


export type MutationAntiseraSaveToSessionArgs = {
  input?: InputMaybe<Array<InputMaybe<AntiseraSessionInput>>>;
  productInput: ConfiguratorProductInput;
};


export type MutationAntiseraTotalValidationArgs = {
  input?: InputMaybe<Array<InputMaybe<AntiseraSessionInput>>>;
  productInput: ConfiguratorProductInput;
};


export type MutationApproveDealerOrderArgs = {
  cartId: Scalars['ID'];
};


export type MutationBiTubePriceCallArgs = {
  product: ConfiguratorProductInput;
};


export type MutationBitubeTotalValidationArgs = {
  input: Array<InputMaybe<SessionInput>>;
  product: ConfiguratorProductInput;
};


export type MutationCancelCartArgs = {
  action: CartRequestAction;
};


export type MutationCancelMarketplaceOrderArgs = {
  orderNumber?: InputMaybe<Scalars['String']>;
};


export type MutationCancelSapOrderArgs = {
  comment: Scalars['String'];
  orderItemLineNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sapOrderNumber: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  data: ChangePasswordInput;
};


export type MutationChatWithAssistantArgs = {
  input: AssistantInput;
};


export type MutationClearRedisCacheArgs = {
  key: Scalars['String'];
};


export type MutationClearSharedListItemsArgs = {
  listId: Scalars['ID'];
};


export type MutationConfAddToMiniCartArgs = {
  input?: InputMaybe<ConfiguratorAddToMiniCartInput>;
};


export type MutationConfirmAffiliationArgs = {
  data: ConfirmAffiliationInput;
  isSwitchAccount?: InputMaybe<Scalars['Boolean']>;
};


export type MutationConfirmOrderQuoteArgs = {
  input: ConfirmOrderQuoteInput;
};


export type MutationConfMilliplexAddToCartArgs = {
  input: Array<InputMaybe<ConfiguratorMilliplexAddToCartInput>>;
  productInput: MilliplexProductInput;
};


export type MutationConsolidateOrdersIntoActiveCartArgs = {
  input: ConsolidateOrdersIntoActiveCartInput;
};


export type MutationConvertQuoteToOrderArgs = {
  input: QuoteConversionInput;
  isCsr?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCopyPasteTubeArgs = {
  input: Scalars['String'];
  isDForm?: InputMaybe<Scalars['Boolean']>;
  isOverride?: InputMaybe<Scalars['Boolean']>;
  productInput: ConfiguratorProductInput;
};


export type MutationCreateApproverCartArgs = {
  input: CreateApproverCartInput;
};


export type MutationCreateContractCartArgs = {
  input: CreateContractCartInput;
};


export type MutationCreateCustomPromotionalBundleArgs = {
  input: CustomPromotionalBundleInput;
};


export type MutationCreateDealerCartArgs = {
  orderNumber: Scalars['ID'];
};


export type MutationCreateGroupArgs = {
  input?: InputMaybe<CreateGroupInput>;
};


export type MutationCreateMpayTransactionArgs = {
  input: MpayPaymentUrlInput;
};


export type MutationCreateOrderEvaluationArgs = {
  orderEvaluation: OrderEvaluationInput;
};


export type MutationCreateParallelSavedCartArgs = {
  cartId: Scalars['ID'];
};


export type MutationCreateSharedListArgs = {
  input: CreateSharedListInput;
};


export type MutationCreateSharedListItemsArgs = {
  listIds: Array<Scalars['ID']>;
  listItems: Array<SharedListItemInput>;
};


export type MutationCrisprgrnaAddToCartArgs = {
  input?: InputMaybe<CrisprgrnaAddToCartInput>;
};


export type MutationCrisprgRnaPriceCallArgs = {
  input?: InputMaybe<CrisprgRnaPriceCallInput>;
  productInput?: InputMaybe<SemiConfiguratorProductInput>;
};


export type MutationCustomCrisprSaveToSessionArgs = {
  input?: InputMaybe<Array<InputMaybe<CustomCrisprSessionInput>>>;
  productInput: ConfiguratorProductInput;
};


export type MutationCustomCrisprTotalValidationArgs = {
  input: Array<InputMaybe<CustomCrisprSessionInput>>;
  productInput: ConfiguratorProductInput;
};


export type MutationDeactivateCoworkerArgs = {
  participantIds?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationDelegateApproverArgs = {
  input: DelegateApproverInput;
};


export type MutationDeleteCartItemArgs = {
  action: CartRequestAction;
  cartId: Scalars['ID'];
  cartItemId: Scalars['ID'];
};


export type MutationDeleteConsolidationOrdersArgs = {
  input: ConsolidateOrdersIntoActiveCartInput;
};


export type MutationDeleteCoworkerGroupArgs = {
  input: DeleteCoworkerGroupInput;
};


export type MutationDeleteMyNetworkGroupArgs = {
  input: DeleteMyNetworkGroupInput;
};


export type MutationDeleteParallelCartArgs = {
  action: CartRequestAction;
};


export type MutationDeleteQuickOrderItemArgs = {
  input: DeleteQuickOrderItemInput;
};


export type MutationDeleteRequesterArgs = {
  input: UpdateOrDeleteRequesterInput;
};


export type MutationDeleteSavedCartArgs = {
  cartId: Scalars['ID'];
};


export type MutationDeleteSaveForLaterItemArgs = {
  input: DeleteSaveForLaterInput;
};


export type MutationDeleteSharedListArgs = {
  listId: Scalars['ID'];
};


export type MutationDeleteSharedListInvitationsArgs = {
  invitations: Array<Scalars['String']>;
  listId: Scalars['ID'];
};


export type MutationDeleteSharedListMembersArgs = {
  listId: Scalars['ID'];
  members: Array<Scalars['ID']>;
};


export type MutationDeleteUserPushSubscriptionArgs = {
  subscriptionId: Scalars['ID'];
};


export type MutationDuplexSpecificationValidationArgs = {
  input?: InputMaybe<Array<InputMaybe<BiTubeWellListInput>>>;
  productInput: ConfiguratorProductInput;
};


export type MutationEmailCartArgs = {
  input: EmailCartInput;
};


export type MutationFastduplexRuleValidationArgs = {
  input: FastDuplexValidateInput;
  productInput: ConfiguratorProductInput;
};


export type MutationFedexNotificationSubscriptionArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  trackingNumber: Scalars['String'];
};


export type MutationFetchOauthSessionExchangeArgs = {
  extAccessToken: Scalars['String'];
};


export type MutationFetchPosPaymentDataArgs = {
  input: PosPaymentDataInput;
};


export type MutationFetchThirdPartyTokenArgs = {
  data: ThirdPartyTokenInput;
};


export type MutationFetchWechatInfoArgs = {
  code: Scalars['String'];
};


export type MutationFileUploadPlateArgs = {
  autogenerateAntisense?: InputMaybe<Scalars['Boolean']>;
  input?: InputMaybe<Array<InputMaybe<PlateListInput>>>;
  isOverride?: InputMaybe<Scalars['Boolean']>;
  productInput: ConfiguratorProductInput;
  startRow?: InputMaybe<Scalars['Int']>;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
  userName?: InputMaybe<Scalars['String']>;
};


export type MutationFullRegistrationArgs = {
  cartType?: InputMaybe<Scalars['String']>;
  data: FullRegistrationInput;
};


export type MutationGenerateQuoteArgs = {
  addressInfo?: InputMaybe<AddressInfoType>;
  input: Array<ItemToQuote>;
};


export type MutationGenomicsFileUploadArgs = {
  input?: InputMaybe<Scalars['String']>;
  productInput: ConfiguratorProductInput;
  startRow?: InputMaybe<Scalars['Int']>;
};


export type MutationGetAntiseraPriceCallArgs = {
  product: ConfiguratorProductInput;
};


export type MutationGetForgotPinArgs = {
  input: ForgotPinInput;
};


export type MutationGetMobileSecurityCodeArgs = {
  mobile: Scalars['String'];
};


export type MutationInviteRequesterArgs = {
  input: InviteRequesterInput;
};


export type MutationKicqStartAddToCartArgs = {
  input?: InputMaybe<KicqStartAddToCartInput>;
};


export type MutationKicqStartGeneArraySaveToSessionArgs = {
  input?: InputMaybe<KicqStartGeneArraySessionInput>;
  productInput?: InputMaybe<SemiConfiguratorProductInput>;
};


export type MutationKicqStartPrimerSaveToSessionArgs = {
  input?: InputMaybe<KicqStartPrimerSessionInput>;
  productInput?: InputMaybe<SemiConfiguratorProductInput>;
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  rememberMe?: InputMaybe<Scalars['Boolean']>;
  userName: Scalars['String'];
};


export type MutationMergeEmailCartArgs = {
  emailCartId: Scalars['ID'];
};


export type MutationMergeSavedCartArgs = {
  cartId: Scalars['ID'];
};


export type MutationMilliplexPriceCallArgs = {
  input?: InputMaybe<Array<InputMaybe<MilliplexDataInput>>>;
  productInput: ConfiguratorProductInput;
};


export type MutationMilliplexSaveToSessionArgs = {
  input?: InputMaybe<Array<InputMaybe<MilliplexDataInput>>>;
  productInput: ConfiguratorProductInput;
};


export type MutationMilliplexTotalValidationArgs = {
  input?: InputMaybe<Array<InputMaybe<MilliplexDataInput>>>;
  productInput?: InputMaybe<ConfiguratorProductInput>;
};


export type MutationNgsoCopyPasteArgs = {
  input: Scalars['String'];
  isDForm?: InputMaybe<Scalars['Boolean']>;
  isOverride?: InputMaybe<Scalars['Boolean']>;
  productInput: ConfiguratorProductInput;
};


export type MutationNgsoFileUploadArgs = {
  input: Scalars['String'];
  isDForm?: InputMaybe<Scalars['Boolean']>;
  isOverride?: InputMaybe<Scalars['Boolean']>;
  productInput: ConfiguratorProductInput;
  startRow?: InputMaybe<Scalars['Int']>;
};


export type MutationOpenIncidentMessageArgs = {
  input?: InputMaybe<IncidentMessageInput>;
};


export type MutationOrfAddToCartArgs = {
  input?: InputMaybe<OrfAddToCartInput>;
};


export type MutationOrfPriceCallArgs = {
  input?: InputMaybe<OrfPriceCallInput>;
  productInput?: InputMaybe<SemiConfiguratorProductInput>;
};


export type MutationPatchCartItemArgs = {
  action: CartRequestAction;
  input: PatchCartItemInput;
};


export type MutationPatchCcLegalTermsArgs = {
  data?: InputMaybe<Array<PatchCcLegalTermsInput>>;
};


export type MutationPatchQuoteWithUpdateDataArgs = {
  input: PatchQuoteInput;
};


export type MutationPatchSitePreferenceArgs = {
  data?: InputMaybe<Array<PatchSitePreferenceInput>>;
};


export type MutationPlateSessionFieldArgs = {
  input: Array<InputMaybe<PlatesInput>>;
  productInput: ConfiguratorProductInput;
};


export type MutationPlateSpecificationValidationArgs = {
  input?: InputMaybe<Array<InputMaybe<WelllistInput>>>;
  productInput: ConfiguratorProductInput;
};


export type MutationPlateTotalValidationArgs = {
  input: Array<InputMaybe<PlatesInput>>;
  productInput: ConfiguratorProductInput;
};


export type MutationPostBigQueryDataArgs = {
  input: BigDataQueryInput;
};


export type MutationProcessMpayPaymentArgs = {
  input: MpayChargePaymentInput;
};


export type MutationReadyToShipNotificationArgs = {
  params: ReadyToShipNotificationInput;
};


export type MutationRegisterBlanketPoArgs = {
  input: RegisterBlanketPoInput;
};


export type MutationRegisterNewUserArgs = {
  newUser: NewUser;
};


export type MutationRejectDealerRequestsArgs = {
  input: RejectDealerRequestsInput;
};


export type MutationRemoveCoworkerArgs = {
  coworkerId: Scalars['String'];
};


export type MutationRemoveItemFromSavedDocsArgs = {
  input: SavedDocumentInput;
};


export type MutationRemoveOmsAccessArgs = {
  input?: InputMaybe<OmsGuestInput>;
};


export type MutationRemoveSharedListItemArgs = {
  itemId: Scalars['ID'];
  listId: Scalars['ID'];
};


export type MutationReorderCartArgs = {
  input: UpdateCartInput;
};


export type MutationRequestApprovalArgs = {
  input: RequestApprovalInput;
};


export type MutationRequestQuoteForMilliplexArgs = {
  input: RequestQuoteForMilliplexInput;
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String'];
  newPasswordConfirm: Scalars['String'];
  token: Scalars['String'];
};


export type MutationResubmitOrderQuoteArgs = {
  input: ConfirmOrderQuoteInput;
};


export type MutationRuleValidateDuplexArgs = {
  input: RuleValidateDuplexInput;
  productInput: ConfiguratorProductInput;
};


export type MutationRuleValidateFieldsArgs = {
  input: RuleValidateInput;
  productInput: ConfiguratorProductInput;
};


export type MutationRuleValidatePlateArgs = {
  input: RuleValidatePlateInput;
  productInput: ConfiguratorProductInput;
};


export type MutationRuleValidateScreenArgs = {
  input: RuleValidateScreenInput;
  productInput: ConfiguratorProductInput;
};


export type MutationSangerAddToCartArgs = {
  input?: InputMaybe<SangerCrisprAddToCartInput>;
};


export type MutationSangerPriceCallArgs = {
  input?: InputMaybe<SangerCrisprPriceCallInput>;
  productInput?: InputMaybe<SemiConfiguratorProductInput>;
};


export type MutationSaveCartArgs = {
  input: SaveCartInput;
};


export type MutationSaveLabWaterSessionArgs = {
  input?: InputMaybe<SaveLabWaterSessionInput>;
};


export type MutationSaveMilliqSqSessionArgs = {
  input?: InputMaybe<SaveMilliqSqSessionInput>;
};


export type MutationSaveUserPushSubscriptionArgs = {
  input: PushSubscriptionInput;
};


export type MutationScreenSessionFieldArgs = {
  input: Array<InputMaybe<PepScreenSessionDataInput>>;
  productInput: ConfiguratorProductInput;
};


export type MutationScreenSpecificationValidationArgs = {
  input?: InputMaybe<Array<InputMaybe<WelllistScreenInput>>>;
  productInput: ConfiguratorProductInput;
};


export type MutationScreenTotalValidationArgs = {
  input: Array<InputMaybe<PepScreenSessionDataInput>>;
  productInput: ConfiguratorProductInput;
};


export type MutationSelectDealerArgs = {
  input: SelectedDealerInput;
};


export type MutationSemiConfAddToMiniCartArgs = {
  input?: InputMaybe<SemiConfiguratorAddToMiniCartInput>;
};


export type MutationSemiConfSaveToSessionArgs = {
  input?: InputMaybe<SemiConfSaveToSessionInput>;
  productInput?: InputMaybe<SemiConfiguratorProductInput>;
};


export type MutationSendInviteArgs = {
  input: InvitationRequestBody;
};


export type MutationSendLabWaterEmailArgs = {
  input?: InputMaybe<LabWaterSendEmailInput>;
};


export type MutationSendMilliplexDealerConfirmationEmailArgs = {
  input?: InputMaybe<MilliplexDealerConfirmationEmailInput>;
};


export type MutationSendMilliqSqEmailArgs = {
  input?: InputMaybe<MilliqSqSendEmailInput>;
};


export type MutationSendOrderShippedNotificationArgs = {
  notification: OrderShippedNotification;
  subscriptionId: Scalars['ID'];
};


export type MutationSessionFieldsArgs = {
  input?: InputMaybe<Array<InputMaybe<SessionInput>>>;
  productInput: ConfiguratorProductInput;
};


export type MutationShareOrderDetailsArgs = {
  orderNumber: Scalars['String'];
  sharedLink: Scalars['String'];
  toEmails: Array<Scalars['String']>;
};


export type MutationShareProductDetailsArgs = {
  input: ShareProductDetailsInput;
};


export type MutationShareQuoteDetailsArgs = {
  quoteNumber: Scalars['String'];
  sharedLink: Scalars['String'];
  toEmails: Array<Scalars['String']>;
};


export type MutationShrnaAddToCartArgs = {
  input?: InputMaybe<ShrnaAddToCartInput>;
};


export type MutationShrnaPriceCallArgs = {
  input?: InputMaybe<ShrnaPriceCallInput>;
  productInput?: InputMaybe<SemiConfiguratorProductInput>;
};


export type MutationSimpleAntiseraTotalValidationArgs = {
  input?: InputMaybe<Array<InputMaybe<SimpleAntiseraSessionInput>>>;
  productInput: ConfiguratorProductInput;
};


export type MutationSirnaAddToCartArgs = {
  input?: InputMaybe<SirnaAddToCartInput>;
};


export type MutationSubmitCartArgs = {
  action: CartRequestAction;
  cvv?: InputMaybe<Scalars['String']>;
  isDuplicateCheck?: InputMaybe<Scalars['Boolean']>;
  isQuote: Scalars['Boolean'];
  totalProduct?: InputMaybe<Scalars['Int']>;
};


export type MutationSubmitContactSellerArgs = {
  input?: InputMaybe<ContactSellerInput>;
};


export type MutationSubmitFullRegistrationArgs = {
  data: FullRegistrationInput;
  input?: InputMaybe<RegistrationInput>;
};


export type MutationTubePriceCallArgs = {
  input: Array<InputMaybe<SessionInput>>;
  productInput: ConfiguratorProductInput;
};


export type MutationTubeTotalValidationArgs = {
  input: Array<InputMaybe<SessionInput>>;
  productInput: ConfiguratorProductInput;
};


export type MutationUpdateCartArgs = {
  action: CartRequestAction;
  input: UpdateCartInput;
};


export type MutationUpdateCartItemsArgs = {
  action: CartRequestAction;
  input: Array<UpdateCartItemInput>;
};


export type MutationUpdateCoworkerGroupArgs = {
  input: UpdateCoworkerGroupInput;
};


export type MutationUpdateCoworkerRolesArgs = {
  input: UpdateCoworkerRolesInput;
};


export type MutationUpdateCreditCardPaymentInfoArgs = {
  input: CreditCardPaymentUpdateInput;
};


export type MutationUpdateDefaultAddressArgs = {
  input?: InputMaybe<AdminPartnersInfoInput>;
  participantId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDeliveryDateArgs = {
  cartId: Scalars['String'];
  cartItemId?: InputMaybe<Scalars['String']>;
  fddLevelB2B?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};


export type MutationUpdateDirectShipmentArgs = {
  cartId: Scalars['String'];
  value: Scalars['Boolean'];
};


export type MutationUpdateMembersDoneAddingToSharedListArgs = {
  listId: Scalars['ID'];
};


export type MutationUpdateMobileArgs = {
  input?: InputMaybe<OmsGuestInput>;
  lastBindMobile: Scalars['String'];
  mobile: Scalars['String'];
  participantId: Scalars['String'];
};


export type MutationUpdateMpayInvoicePaymentInfoArgs = {
  input: MpayInvoicePaymentUpdateInput;
};


export type MutationUpdateOrdersPendingApprovalArgs = {
  input: UpdateOrdersPendingApprovalInput;
};


export type MutationUpdatePinnedAddressesArgs = {
  input: UpdatePinnedAddress;
  soldTo: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationUpdateQuickOrderItemArgs = {
  input: UpdateQuickOrderItemInput;
};


export type MutationUpdateRequesterArgs = {
  input: UpdateOrDeleteRequesterInput;
};


export type MutationUpdateSavedCartArgs = {
  cartId: Scalars['ID'];
  input: UpdateSavedCartInput;
};


export type MutationUpdateSavedCartItemsArgs = {
  cartId: Scalars['ID'];
  cartItemsUpdate: Array<CartItemUpdateInput>;
};


export type MutationUpdateSharedListArgs = {
  input: UpdateSharedListInput;
};


export type MutationUpdateSharedListItemArgs = {
  input: UpdateSharedListItemInput;
};


export type MutationUpdateTermConditionArgs = {
  terms: Array<Scalars['String']>;
};


export type MutationUpdateTermsAndConditionsArgs = {
  data: UpdateTermsAndConditionsInput;
};


export type MutationUpdateUserProfileArgs = {
  data: UpdateUserProfileInput;
  userId: Scalars['String'];
};


export type MutationUpdateUserSitePreferenceArgs = {
  data: UpdateUserSitePreferenceInput;
  userId: Scalars['String'];
};


export type MutationUpdateWimsAccessCodeArgs = {
  accessCode: Scalars['String'];
  participantId: Scalars['String'];
};


export type MutationUploadBusinessLicenseArgs = {
  file: Scalars['Upload'];
  input?: InputMaybe<OmsGuestInput>;
  orderId?: InputMaybe<Scalars['String']>;
  participantId: Scalars['String'];
};


export type MutationUploadCopyPasteTextArgs = {
  autogenerateAntisense?: InputMaybe<Scalars['Boolean']>;
  input?: InputMaybe<Scalars['String']>;
  isOverride?: InputMaybe<Scalars['Boolean']>;
  productInput: ConfiguratorProductInput;
};


export type MutationUploadTubesFromFileArgs = {
  input: Scalars['String'];
  isDForm?: InputMaybe<Scalars['Boolean']>;
  isOverride?: InputMaybe<Scalars['Boolean']>;
  productInput: ConfiguratorProductInput;
  startRow?: InputMaybe<Scalars['Int']>;
};


export type MutationValidateMobileSecurityCodeArgs = {
  input?: InputMaybe<OmsGuestInput>;
  mobile: Scalars['String'];
  sceneId: Scalars['String'];
  securityCode: Scalars['String'];
};


export type MutationValidatePlateShippingArgs = {
  input: Array<InputMaybe<PlatesInput>>;
  productInput: ConfiguratorProductInput;
};


export type MutationVerifyEmailArgs = {
  accessToken: Scalars['String'];
};


export type MutationWechatBindArgs = {
  password: Scalars['String'];
  unionId: Scalars['String'];
  userName: Scalars['String'];
};


export type MutationWechatLoginArgs = {
  code: Scalars['String'];
};

export type MynetworkResponse = {
  __typename?: 'MynetworkResponse';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  invitationId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  partners?: Maybe<PartnersResponse>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type NewCreditCardInput = {
  cardExpiryDate?: InputMaybe<Scalars['String']>;
  cardName?: InputMaybe<Scalars['String']>;
  cardNumber?: InputMaybe<Scalars['String']>;
  cardSecret?: InputMaybe<Scalars['String']>;
  cardType?: InputMaybe<Scalars['String']>;
  ccToken?: InputMaybe<Scalars['String']>;
  expiryMonth?: InputMaybe<Scalars['String']>;
  expiryYear?: InputMaybe<Scalars['String']>;
  maskedCardNumber?: InputMaybe<Scalars['String']>;
  transientCCToken?: InputMaybe<Scalars['String']>;
};

export type NewSavedCart = {
  __typename?: 'NewSavedCart';
  savedCart: SaveCartId;
};

export type NewUser = {
  acceptPromos: Scalars['Boolean'];
  acceptTerms: Scalars['Boolean'];
  confirmPassword: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  generalTermsCondition: Scalars['Boolean'];
  inviteeId?: InputMaybe<Scalars['String']>;
  isDirectCustomer?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  locale: Scalars['String'];
  organizationId: Scalars['String'];
  organizationName: Scalars['String'];
  password: Scalars['String'];
  piiDataConsentAcceptance: Scalars['Boolean'];
  siteAgreement: Scalars['Boolean'];
  telephone: Scalars['String'];
};

export type NgsoPlatePrice = {
  __typename?: 'NgsoPlatePrice';
  countryCode?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  pageNo?: Maybe<Scalars['Int']>;
  plateId?: Maybe<Scalars['Int']>;
  productType?: Maybe<Scalars['String']>;
  wellList?: Maybe<Array<Maybe<WellListPrice>>>;
};

export type NgsoPriceResponse = {
  __typename?: 'NgsoPriceResponse';
  response?: Maybe<Array<Maybe<NgsoPlatePrice>>>;
  serverError?: Maybe<ListError>;
};

export type NotificationsResponse = {
  __typename?: 'NotificationsResponse';
  eventDate: Scalars['Date'];
  notificationType: NotificationType;
  orderNumber: Scalars['String'];
};

export enum NotificationType {
  Shipped = 'SHIPPED'
}

export type Oauth2SessionResponse = {
  __typename?: 'Oauth2SessionResponse';
  accessToken?: Maybe<Scalars['String']>;
};

export type OfferMessage = {
  body: Scalars['String'];
  company: Scalars['String'];
  country: Scalars['String'];
  customerId: Scalars['String'];
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  offerId: Scalars['String'];
  reasonCode: Scalars['String'];
  subject: Scalars['String'];
};

export type OmsContactAddress = {
  __typename?: 'OmsContactAddress';
  country: Scalars['String'];
  countryName: Scalars['String'];
};

export type OmsCurrentUserResponse = {
  __typename?: 'OmsCurrentUserResponse';
  contactAddress: OmsContactAddress;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  mobilePhone: Scalars['String'];
  mobileVerifiedFlag: Scalars['Boolean'];
};

export type OmsGuestInput = {
  cartType?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  omsGuestToken?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<Scalars['String']>;
};

export type OpenIncidentMessage = {
  body: Scalars['String'];
  line: Scalars['String'];
  orderId: Scalars['String'];
  quantity: Scalars['Int'];
  reasonCode: Scalars['String'];
};

export type OpenInvoiceHeaderInfoModel = {
  __typename?: 'OpenInvoiceHeaderInfoModel';
  payer?: Maybe<Scalars['String']>;
  salesOrg?: Maybe<Scalars['String']>;
  soldTo?: Maybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  billingBlock?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  deliveryBlock?: Maybe<Scalars['String']>;
  orderDate: Scalars['Date'];
  orderedBy: Scalars['String'];
  orderId?: Maybe<Scalars['ID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderType?: Maybe<Scalars['String']>;
  poNumber?: Maybe<Scalars['String']>;
  quotePriceItemExists?: Maybe<Scalars['Boolean']>;
  readableOrderId?: Maybe<Scalars['String']>;
  salesDocNumber?: Maybe<Scalars['ID']>;
  soldTo?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  statusAlias: Scalars['String'];
  statusCategory?: Maybe<Scalars['String']>;
  sysId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  totalCompletedItems?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  transSurchargeMessage?: Maybe<Scalars['String']>;
};

export type OrderAdditionalInfo = {
  __typename?: 'OrderAdditionalInfo';
  carrierRestriction?: Maybe<Scalars['String']>;
  casNumber?: Maybe<Scalars['String']>;
  jfcCode?: Maybe<Scalars['String']>;
  pdcCode?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  tariff?: Maybe<Scalars['String']>;
  unNumber?: Maybe<Scalars['String']>;
};

export type OrderAssessment = {
  __typename?: 'OrderAssessment';
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type OrderAssessmentsResponse = {
  __typename?: 'OrderAssessmentsResponse';
  orderAssessments?: Maybe<Array<Maybe<OrderAssessment>>>;
};

export enum OrderByType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrderConfirmationPdfInput = {
  sapOrderId: Scalars['String'];
  soldTo: Scalars['String'];
};

export type OrderContactDetail = {
  __typename?: 'OrderContactDetail';
  ccEmail?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  orderedBy: Scalars['String'];
  partnerNumber?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneNumber1?: Maybe<Scalars['String']>;
  phoneNumber2?: Maybe<Scalars['String']>;
};

export type OrderContactInfo = {
  __typename?: 'OrderContactInfo';
  attentionTo?: Maybe<Scalars['String']>;
  buildingRoom?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  partnerNumber?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneNumber1?: Maybe<Scalars['String']>;
  phoneNumber2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  recipientName?: Maybe<Scalars['String']>;
  stateName?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
  street4?: Maybe<Scalars['String']>;
};

export type OrderDealerOrganizationName = {
  __typename?: 'OrderDealerOrganizationName';
  email?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type OrderDetails = {
  __typename?: 'OrderDetails';
  alcTax?: Maybe<Scalars['Float']>;
  approvers: Array<Approver>;
  bankDetails?: Maybe<BankDetails>;
  billTo: OrderContactInfo;
  blanketPoNumber?: Maybe<Scalars['String']>;
  canCancelOrder?: Maybe<Scalars['Boolean']>;
  cardType?: Maybe<Scalars['String']>;
  /**
   * Indicate if the order is a FTB order. Value is null or FTB.
   * NOTE:This FTB value won't change after order becomes a normal order
   */
  category?: Maybe<Scalars['String']>;
  chargebackCodes: Array<ChargebackCode>;
  complianceDocuments?: Maybe<Array<Maybe<ComplianceDocument>>>;
  complianceFee?: Maybe<Scalars['Float']>;
  consolidatorName: Scalars['String'];
  contactAddress: OrderContactInfo;
  contactDetail: OrderContactDetail;
  contractNumber?: Maybe<Scalars['String']>;
  contractType?: Maybe<Scalars['String']>;
  creditCardNumber?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  customerServiceContactInfo?: Maybe<CustomerServiceContactInfo>;
  customerServiceNote?: Maybe<Scalars['String']>;
  dealers?: Maybe<Array<Dealer>>;
  domainStatus?: Maybe<OrderEmproveDomainStatus>;
  dutyTotal?: Maybe<Scalars['Float']>;
  electricalFee?: Maybe<Scalars['Float']>;
  eurVolatileMassCharge?: Maybe<Scalars['Float']>;
  hasQuotePriceItem: Scalars['Boolean'];
  ice?: Maybe<Scalars['Float']>;
  invoices?: Maybe<Array<MarketplaceInvoice>>;
  isApprovedOrder: Scalars['Boolean'];
  isBlueInvoiceLinkEnabled: Scalars['Boolean'];
  isComplianceDocumentRequired?: Maybe<Scalars['Boolean']>;
  isDealerOrder: Scalars['Boolean'];
  isEmproveProductExists: Scalars['Boolean'];
  isEndUserWithZuPartner: Scalars['Boolean'];
  isReOrderEnabled?: Maybe<Scalars['Boolean']>;
  items: Array<OrderItem>;
  mPortalLinks?: Maybe<MPortalLinks>;
  mpowerNumber?: Maybe<Scalars['String']>;
  orderDate: Scalars['Date'];
  orderedBy: Scalars['String'];
  orderId?: Maybe<Scalars['ID']>;
  orderInfo?: Maybe<OrderInfo>;
  orderNumber?: Maybe<Scalars['String']>;
  orderType?: Maybe<Scalars['String']>;
  packingSlipNote?: Maybe<Scalars['String']>;
  payer?: Maybe<OrderContactInfo>;
  paymentInfo?: Maybe<PaymentInfo>;
  paymentTerms: Scalars['String'];
  pkg?: Maybe<Scalars['Float']>;
  poNumber?: Maybe<Scalars['String']>;
  poNumberLabel?: Maybe<Scalars['String']>;
  rapidRedNumber?: Maybe<Scalars['String']>;
  readableOrderId?: Maybe<Scalars['String']>;
  reqNumber?: Maybe<Scalars['String']>;
  requestedDeliveryDate?: Maybe<Scalars['String']>;
  requisitionNumber?: Maybe<Scalars['String']>;
  requisitionNumberLabel?: Maybe<Scalars['String']>;
  salesDocNumber?: Maybe<Scalars['ID']>;
  salesTax?: Maybe<Scalars['Float']>;
  shippingTransportation?: Maybe<Scalars['Float']>;
  shipTo: OrderContactInfo;
  soldTo: Scalars['String'];
  status: Scalars['String'];
  statusAlias: Scalars['String'];
  subtotal: Scalars['Float'];
  total: Scalars['Float'];
  transHandlingCharge?: Maybe<Scalars['Float']>;
  transSurchargeMessage?: Maybe<Scalars['String']>;
};

export enum OrderEmproveDomainStatus {
  Approved = 'Approved',
  Blocked = 'Blocked',
  New = 'New',
  Pending = 'Pending'
}

export type OrderEvaluationInput = {
  evaluation: Assessments;
  orderId: Scalars['String'];
};

export type OrderForApproval = {
  __typename?: 'OrderForApproval';
  approvalAmount?: Maybe<Scalars['Int']>;
  approverTypeId: Scalars['String'];
  createdDate: Scalars['Date'];
  currency: Scalars['String'];
  inReview?: Maybe<Scalars['Boolean']>;
  lastUpdatedDate: Scalars['Date'];
  numOfItems: Scalars['Int'];
  orderedBy?: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  orderNumber?: Maybe<Scalars['String']>;
  orderTotal?: Maybe<Scalars['Float']>;
  orderType?: Maybe<Scalars['String']>;
  originator: Scalars['String'];
  participantId: Scalars['String'];
  poNumber?: Maybe<Scalars['String']>;
  submittedBy: Scalars['String'];
};

export type OrderInfo = {
  __typename?: 'OrderInfo';
  billingBlock?: Maybe<Scalars['String']>;
  customerInfo?: Maybe<CustomerInfo>;
  dealerInformation?: Maybe<OrderDealerOrganizationName>;
  deliveryBlock?: Maybe<Scalars['String']>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  additionalInfo?: Maybe<OrderAdditionalInfo>;
  availabilityMessages: Array<Maybe<OrderItemAvailabilityMessages>>;
  availabilityOverwriteMessage?: Maybe<BbeMaterialAvailability>;
  batchNumber?: Maybe<Scalars['String']>;
  brandId: Scalars['String'];
  canCancelItem?: Maybe<Scalars['Boolean']>;
  canReturnItem?: Maybe<Scalars['Boolean']>;
  complianceMessages: Array<Scalars['String']>;
  confidenceLevel?: Maybe<Scalars['String']>;
  configuration: Array<ProductConfiguration>;
  configurationId?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  customerOrderInfo?: Maybe<CustomerOrderInfo>;
  customerReference?: Maybe<Scalars['String']>;
  dealerId?: Maybe<Scalars['String']>;
  distrChainStatus?: Maybe<Scalars['String']>;
  emproveDossierInfo?: Maybe<EmproveDossierItem>;
  hidePriceMessageKey?: Maybe<Scalars['String']>;
  isQuotePriceItem?: Maybe<Scalars['Boolean']>;
  isSingleLotSelected?: Maybe<Scalars['Boolean']>;
  kitItems: Array<CartKitItem>;
  lineNumber: Scalars['String'];
  listPrice?: Maybe<Scalars['Float']>;
  listPriceCurrency?: Maybe<Scalars['String']>;
  marketplaceAvailableToReturnQty?: Maybe<Scalars['Int']>;
  marketplaceCanEvaluate?: Maybe<Scalars['Boolean']>;
  marketplaceCanOpenIncident?: Maybe<Scalars['Boolean']>;
  marketplaceIncidentOpen?: Maybe<Scalars['Boolean']>;
  marketPlaceOfferId?: Maybe<Scalars['String']>;
  marketPlaceOrderId?: Maybe<Scalars['String']>;
  marketPlaceOrderLineId?: Maybe<Scalars['String']>;
  marketplaceSellerId?: Maybe<Scalars['String']>;
  material: Material;
  materialNumber: Scalars['String'];
  pricePerUnit: Scalars['Float'];
  product?: Maybe<Scalars['String']>;
  productDetails?: Maybe<Product>;
  productNote?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  promoCode?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  quoteNumber?: Maybe<Scalars['String']>;
  remainingReservedQuantity?: Maybe<Scalars['Int']>;
  requestedDeliveryDate?: Maybe<Scalars['String']>;
  returnableQuantity?: Maybe<Scalars['Int']>;
  routeInfo?: Maybe<Scalars['String']>;
  scheduleLines: Array<OrderItemScheduleLine>;
  shippingFrom?: Maybe<Scalars['String']>;
  shippingMethod?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  totalPrice: Scalars['Float'];
  totalReservedQuantity?: Maybe<Scalars['Int']>;
  type: CartItemType;
  vendorNumber?: Maybe<Scalars['String']>;
  yourRef?: Maybe<Scalars['String']>;
};

export type OrderItemAvailabilityMessages = {
  __typename?: 'OrderItemAvailabilityMessages';
  date?: Maybe<Scalars['Date']>;
  key: Scalars['String'];
  plantLoc?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type OrderItemLotNumber = {
  __typename?: 'OrderItemLotNumber';
  batch: Scalars['String'];
  cOfALink?: Maybe<Scalars['String']>;
  cOfOLink?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type OrderItemScheduleLine = {
  __typename?: 'OrderItemScheduleLine';
  billingBlock?: Maybe<Scalars['String']>;
  confirmedQuantity?: Maybe<Scalars['Int']>;
  deliveryBlock?: Maybe<Scalars['String']>;
  deliveryDate: Scalars['Date'];
  deliveryId?: Maybe<Scalars['String']>;
  displayableDate: Scalars['Date'];
  hasFapiaoList: Scalars['Boolean'];
  invoiceNumber?: Maybe<Scalars['String']>;
  lotNumber: Array<OrderItemLotNumber>;
  status?: Maybe<Scalars['String']>;
  trackingInfo: Array<OrderItemTrackingInfo>;
};

export type OrderItemTrackingInfo = {
  __typename?: 'OrderItemTrackingInfo';
  estimatedDeliveryDate?: Maybe<Scalars['Date']>;
  trackingNumber: Scalars['String'];
  trackingUrl: Scalars['String'];
};

export type OrderMessage = {
  body: Scalars['String'];
  orderId: Scalars['String'];
  to: Array<Scalars['String']>;
  topic?: InputMaybe<OrderMessageTopic>;
};

export type OrderMessageTopic = {
  type: Scalars['String'];
  value: Scalars['String'];
};

export type OrderNotesInput = {
  locale?: InputMaybe<Scalars['String']>;
  pageName?: InputMaybe<Scalars['String']>;
};

export type OrderNotesResponse = {
  __typename?: 'OrderNotesResponse';
  language?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type OrderSearchInput = {
  contact?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  orderBy?: InputMaybe<OrderByType>;
  orderNumber?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  poNumber?: InputMaybe<Scalars['String']>;
  productNumber?: InputMaybe<Scalars['String']>;
  shipTo?: InputMaybe<Scalars['String']>;
  soldTo?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<OrderStatusType>;
  statusCategory?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type OrderSearchPayload = {
  __typename?: 'OrderSearchPayload';
  billingBlock?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
  customerServicePhone?: Maybe<Scalars['String']>;
  deliveryBlock?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['Date']>;
  orderBy?: Maybe<OrderByType>;
  orderNumber?: Maybe<Scalars['String']>;
  orders: Array<Order>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  poNumber?: Maybe<Scalars['String']>;
  productNumber?: Maybe<Scalars['String']>;
  soldTo?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusCategory?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['Date']>;
};

export type OrdersForApproval = {
  __typename?: 'OrdersForApproval';
  count: Scalars['Int'];
  ordersForApproval: Array<OrderForApproval>;
  perPage: Scalars['Int'];
};

export type OrderShareableLinkInput = {
  orderNumber: Scalars['String'];
};

/** TODO: Triggering notifications from a graphql mutation is only needed for PoC, not needed for production */
export type OrderShippedNotification = {
  orderId: Scalars['String'];
  type: Scalars['String'];
};

export enum OrderStatusAlias {
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  Denied = 'DENIED',
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
  New = 'NEW',
  NotAvailable = 'NOT_AVAILABLE',
  OnHold = 'ON_HOLD',
  PartiallyCancelled = 'PARTIALLY_CANCELLED',
  PartiallyClosed = 'PARTIALLY_CLOSED',
  PartiallyReceived = 'PARTIALLY_RECEIVED',
  PartiallyShipped = 'PARTIALLY_SHIPPED',
  Pending = 'PENDING',
  PendingApproval = 'PENDING_APPROVAL',
  PendingReview = 'PENDING_REVIEW',
  Processing = 'PROCESSING',
  Received = 'RECEIVED',
  Rejected = 'REJECTED',
  Shipped = 'SHIPPED',
  ShippingInProgress = 'SHIPPING_IN_PROGRESS'
}

/**
 * Order status/category/alias mappings can be found here:
 * https://wiki.merckgroup.com/confluence/display/EBPM/Order+Status+Mapping
 */
export enum OrderStatusCategoryType {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  New = 'NEW'
}

export enum OrderStatusType {
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE',
  Completed = 'COMPLETED',
  Denied = 'DENIED',
  Empty = 'EMPTY',
  Error = 'ERROR',
  Expired = 'EXPIRED',
  InProgress = 'IN_PROGRESS',
  New = 'NEW',
  Open = 'OPEN',
  Pending = 'PENDING',
  PendingApproval = 'PENDING_APPROVAL',
  PendingReview = 'PENDING_REVIEW',
  Rejected = 'REJECTED',
  SearchComplete = 'SEARCH_COMPLETE',
  SearchInProgress = 'SEARCH_IN_PROGRESS',
  SearchOpen = 'SEARCH_OPEN',
  WimsOrderStatusComplete = 'WIMS_ORDER_STATUS_COMPLETE',
  WimsOrderStatusInProcess = 'WIMS_ORDER_STATUS_IN_PROCESS',
  WimsOrderStatusMap = 'WIMS_ORDER_STATUS_MAP',
  WimsOrderStatusMc = 'WIMS_ORDER_STATUS_MC',
  WimsOrderStatusMg = 'WIMS_ORDER_STATUS_MG',
  WimsOrderStatusMoc = 'WIMS_ORDER_STATUS_MOC',
  WimsOrderStatusMpc = 'WIMS_ORDER_STATUS_MPC',
  WimsOrderStatusMpoc = 'WIMS_ORDER_STATUS_MPOC',
  WimsOrderStatusMpre = 'WIMS_ORDER_STATUS_MPRE',
  WimsOrderStatusMps = 'WIMS_ORDER_STATUS_MPS',
  WimsOrderStatusMre = 'WIMS_ORDER_STATUS_MRE',
  WimsOrderStatusMs = 'WIMS_ORDER_STATUS_MS',
  WimsOrderStatusMsinp = 'WIMS_ORDER_STATUS_MSINP',
  WimsOrderStatusOh = 'WIMS_ORDER_STATUS_OH',
  WimsOrderStatusOpen = 'WIMS_ORDER_STATUS_OPEN'
}

export type OrfAddToCartInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  physicalFormat?: InputMaybe<PhysicalFormatInput>;
  results?: InputMaybe<Array<InputMaybe<OrfPriceCallInputData>>>;
  sapNextEnabled?: InputMaybe<Scalars['Boolean']>;
  store?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
};

export type OrfAddToCartResponse = {
  __typename?: 'OrfAddToCartResponse';
  result?: Maybe<Scalars['String']>;
  serverError?: Maybe<ListError>;
};

export type OrfDropDownListInput = {
  aliquot?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['String']>;
  countryCode: Scalars['String'];
  physicalMaterial?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<Scalars['String']>;
  titer?: InputMaybe<Scalars['String']>;
  vector?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['String']>;
};

export type OrfPhysicalMaterial = {
  __typename?: 'OrfPhysicalMaterial';
  DNA?: Maybe<Scalars['String']>;
  GLYCEROL?: Maybe<Scalars['String']>;
  noSelection?: Maybe<Scalars['String']>;
  VIRUS?: Maybe<Scalars['String']>;
};

export type OrfPriceCall = {
  __typename?: 'OrfPriceCall';
  result?: Maybe<Array<Maybe<OrfPriceCallResponse>>>;
  serverError?: Maybe<ServerError>;
};

export type OrfPriceCallInput = {
  country?: InputMaybe<Scalars['String']>;
  physicalFormat?: InputMaybe<PhysicalFormatInput>;
  results?: InputMaybe<Array<InputMaybe<OrfPriceCallInputData>>>;
};

export type OrfPriceCallInputData = {
  brand?: InputMaybe<Scalars['String']>;
  cloneId?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  currencySymbol?: InputMaybe<Scalars['String']>;
  material?: InputMaybe<Scalars['String']>;
  orfLength?: InputMaybe<Scalars['String']>;
  orfSequence?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  subSetInfo?: InputMaybe<Array<InputMaybe<SubSetInfoInput>>>;
  vector?: InputMaybe<Scalars['String']>;
};

export type OrfPriceCallResponse = {
  __typename?: 'OrfPriceCallResponse';
  brand?: Maybe<Scalars['String']>;
  cloneId?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  orfLength?: Maybe<Scalars['String']>;
  orfSequence?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  subSetInfo?: Maybe<Array<Maybe<SubSetInfo>>>;
  vector?: Maybe<Scalars['String']>;
};

export type OrfProductSearchTermInput = {
  count?: InputMaybe<Scalars['Int']>;
  facet?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<Scalars['String']>;
  term: Scalars['String'];
};

export type OrfSearchResponse = {
  __typename?: 'OrfSearchResponse';
  count?: Maybe<Scalars['Int']>;
  requestDate?: Maybe<Scalars['String']>;
  responseDate?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<OrfSearchResults>>>;
  serverError?: Maybe<ServerError>;
  term?: Maybe<Array<Maybe<Scalars['String']>>>;
  version?: Maybe<Scalars['String']>;
};

export type OrfSearchResults = {
  __typename?: 'OrfSearchResults';
  cloneId?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  orfLength?: Maybe<Scalars['String']>;
  orfSequence?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  subSetInfo: Array<SubSetInfo>;
  vector?: Maybe<Scalars['String']>;
};

export type OrfSpecifications = {
  __typename?: 'OrfSpecifications';
  aliquot?: Maybe<Array<Maybe<Aliquot>>>;
  amount?: Maybe<Array<Maybe<Amount>>>;
  physicalMaterial?: Maybe<Array<Maybe<OrfPhysicalMaterial>>>;
  serverError?: Maybe<ServerError>;
  titer?: Maybe<Array<Maybe<Titer>>>;
  volume?: Maybe<Array<Maybe<Volume>>>;
};

export type OrganizationApprover = {
  __typename?: 'OrganizationApprover';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  participantId: Scalars['String'];
};

export type Pagination = {
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
};

export type Paper = {
  __typename?: 'Paper';
  abstract?: Maybe<Scalars['String']>;
  authors: Array<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  endPage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  issue?: Maybe<Scalars['String']>;
  journalName?: Maybe<Scalars['String']>;
  pubMedId?: Maybe<Scalars['String']>;
  relatedProducts: Array<Product>;
  startPage?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['String']>;
};

export type PaperInput = {
  paperIds: Array<Scalars['String']>;
};

export type Papers = {
  __typename?: 'Papers';
  count: Scalars['Int'];
  papers: Array<Paper>;
};

export type PaperSearchInput = {
  facets?: InputMaybe<Array<FacetInput>>;
  pagination?: Pagination;
  searchTerm: Scalars['String'];
  sort?: InputMaybe<Sort>;
  type?: InputMaybe<Scalars['String']>;
};

export type PaperSearchResponse = SearchResults & {
  __typename?: 'PaperSearchResponse';
  facets: Array<Facet>;
  items: Array<Paper>;
  metadata: SearchResultsMetadata;
};

export type ParticipantApprover = {
  __typename?: 'ParticipantApprover';
  approvalAmount?: Maybe<Scalars['Int']>;
  approvalProductType?: Maybe<Scalars['String']>;
  approvalTypeId?: Maybe<Scalars['String']>;
  approverId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  participantId?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
};

export type ParticipantShippingMethods = {
  __typename?: 'ParticipantShippingMethods';
  description: Scalars['String'];
  isDefault: Scalars['Boolean'];
  key?: Maybe<Scalars['String']>;
  shippingMethodCode?: Maybe<Scalars['String']>;
};

export type ParticipantSitePreferenceInput = {
  preferredDealerId?: InputMaybe<Scalars['String']>;
};

export type PartnerAccount = {
  __typename?: 'PartnerAccount';
  accountNumber: Scalars['String'];
  attentionTo: Scalars['String'];
  buildingRoom: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  customerServiceNotes?: Maybe<Scalars['String']>;
  department: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  organizationName: Scalars['String'];
  partnerNumber: Scalars['String'];
  postalCode: Scalars['String'];
  salesOrg: Scalars['String'];
  state: Scalars['String'];
  streetAddresses: Array<Scalars['String']>;
  type: Scalars['String'];
};

export enum PartnerAccountType {
  Ag = 'AG',
  Ap = 'AP',
  Re = 'RE',
  Rg = 'RG',
  We = 'WE'
}

export type PartnerAddresses = {
  __typename?: 'PartnerAddresses';
  billTos: Array<PartnerAccount>;
  payers: Array<PartnerAccount>;
  shipTos: Array<PartnerAccount>;
};

export type PartnerObject = {
  __typename?: 'PartnerObject';
  restrictedPartners?: Maybe<Array<Maybe<RestrictedPartnersObject>>>;
  value?: Maybe<Scalars['String']>;
};

export type PartnerObjectInput = {
  restrictedPartners?: InputMaybe<Array<InputMaybe<RestrictedPartnersObjectInput>>>;
  value?: InputMaybe<Scalars['String']>;
};

export type PartnersResponse = {
  __typename?: 'PartnersResponse';
  billTo?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  payer?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  soldTo?: Maybe<Scalars['String']>;
};

export type PartnerType = {
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
};

export type PasswordPatternModelType = {
  __typename?: 'passwordPatternModelType';
  mandatoryPattern: Scalars['String'];
  minimumCountMatch: Scalars['String'];
  patterns: Array<Scalars['String']>;
};

/** Used to patch an existing cart item (Fast) */
export type PatchCartItemInput = {
  cartItemId: Scalars['ID'];
  isSingleLotSelected?: InputMaybe<Scalars['Boolean']>;
  marketplaceShippingMethod?: InputMaybe<Scalars['String']>;
  materialNumber?: InputMaybe<Scalars['String']>;
  productNote?: InputMaybe<Scalars['String']>;
  requestedDeliveryDate?: InputMaybe<Scalars['String']>;
  yourReference?: InputMaybe<Scalars['String']>;
};

export type PatchCcLegalTermsInput = {
  op: Scalars['String'];
  path: Scalars['String'];
  value: Scalars['Boolean'];
};

export type PatchQuoteInput = {
  itemsToUpdate: Array<InputMaybe<QuoteItemToUpdate>>;
  quoteId: Scalars['String'];
};

export type PatchSitePreferenceInput = {
  op: Scalars['String'];
  path: Scalars['String'];
  value: Scalars['Boolean'];
};

export type PaymentHistory = {
  __typename?: 'PaymentHistory';
  approvalNumber: Scalars['String'];
  approvalTime: Scalars['Date'];
  cancel?: Maybe<Scalars['String']>;
  cardType: Scalars['String'];
  cardTypeDescription: Scalars['String'];
  currency: Scalars['String'];
  invoices: Array<Scalars['String']>;
  participantId?: Maybe<Scalars['String']>;
  payer: Scalars['String'];
  receiptUrl: Scalars['String'];
  result: Scalars['Int'];
  resultDate: Scalars['Date'];
  sequenceNumber?: Maybe<Scalars['Int']>;
  sigmaBusinessId?: Maybe<Scalars['String']>;
  soldTo?: Maybe<Scalars['String']>;
  totalAmount: Scalars['Int'];
  transactionNumber: Scalars['String'];
  vanIdTitle?: Maybe<Scalars['String']>;
};

export type PaymentHistoryLineItem = {
  __typename?: 'PaymentHistoryLineItem';
  comptopTransId?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  docType?: Maybe<Scalars['String']>;
  invoiceAccDocNumber?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  netAmount?: Maybe<Scalars['Float']>;
  sapOrderNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['Date']>;
  transId?: Maybe<Scalars['String']>;
};

export type PaymentHistoryResponse = {
  __typename?: 'PaymentHistoryResponse';
  lineItemList: Array<PaymentHistory>;
};

export type PaymentHistorySearchInput = {
  fromDate?: InputMaybe<Scalars['Date']>;
  payer?: InputMaybe<Scalars['String']>;
  salesOrg?: InputMaybe<Scalars['String']>;
  soldTo?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type PaymentInfo = {
  __typename?: 'PaymentInfo';
  paymentMethod?: Maybe<Scalars['String']>;
};

export type PaymentLabelValue = {
  __typename?: 'PaymentLabelValue';
  label: Scalars['String'];
  mask?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type PaymentListItemInput = {
  amount: Scalars['Float'];
  currency?: InputMaybe<Scalars['String']>;
  dbCrIND?: InputMaybe<Scalars['String']>;
  formattedAmount?: InputMaybe<Scalars['String']>;
  invoiceDate: Scalars['Date'];
  invoiceNo: Scalars['String'];
  migratedInvoiceNo?: InputMaybe<Scalars['String']>;
  migratedTaxInvoiceNo?: InputMaybe<Scalars['String']>;
  payer?: InputMaybe<Scalars['String']>;
  poNumber?: InputMaybe<Scalars['String']>;
  taxInvoiceNo?: InputMaybe<Scalars['String']>;
};

export type PaymentMetadataInput = {
  transactionId?: InputMaybe<Scalars['String']>;
};

export type PaymentMetadataResponse = {
  __typename?: 'PaymentMetadataResponse';
  creditCardTypes?: Maybe<Array<Scalars['String']>>;
  isMpayEnabled: Scalars['Boolean'];
  pspIntegrationInfo?: Maybe<PspInformation>;
  pspIntegrationType?: Maybe<Scalars['String']>;
  pspName?: Maybe<Scalars['String']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  displayable: Scalars['Boolean'];
  editable: Scalars['Boolean'];
  isRequired?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  mask?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PaymentMethodsInput = {
  chargebacks?: InputMaybe<UserChargebackInput>;
  creditCard?: InputMaybe<Array<CreditCardInput>>;
  defaultPaymentMethod: Scalars['String'];
  purchaseOrderNumber: Scalars['String'];
  purchaseOrderNumberMask?: InputMaybe<Scalars['String']>;
  requisitionNumber: Scalars['String'];
  requisitionNumberMask?: InputMaybe<Scalars['String']>;
};

export type PdfDownloadInput = {
  docid?: InputMaybe<Scalars['String']>;
  lotnumber?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
  term?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type PepScreenDataFromSession = {
  __typename?: 'PepScreenDataFromSession';
  data: Array<PepScreenSessionData>;
};

export type PepScreenSessionData = {
  __typename?: 'PepScreenSessionData';
  applyToAll?: Maybe<Scalars['Boolean']>;
  autoCorrectedWells?: Maybe<Array<Maybe<Scalars['Int']>>>;
  countryCode?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  isAmountSelected?: Maybe<Scalars['Boolean']>;
  isCSR?: Maybe<Scalars['Boolean']>;
  isCSRSupervisor?: Maybe<Scalars['Boolean']>;
  isOverridden?: Maybe<Scalars['Boolean']>;
  isPackageTypeOther?: Maybe<Scalars['Boolean']>;
  isQuoteItem?: Maybe<Scalars['Boolean']>;
  isShippingOverridden?: Maybe<Scalars['Boolean']>;
  isSpecificationsOverridden?: Maybe<Scalars['Boolean']>;
  pageNo?: Maybe<Scalars['Int']>;
  plateId?: Maybe<Scalars['Int']>;
  totalBaseCount?: Maybe<Scalars['Int']>;
  tubeSequences?: Maybe<Scalars['String']>;
  wellList: Array<WelllistScreen>;
};

export type PepScreenSessionDataInput = {
  applyToAll?: InputMaybe<Scalars['Boolean']>;
  autoCorrectedWells?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  countryCode?: InputMaybe<Scalars['String']>;
  error?: InputMaybe<Scalars['String']>;
  isAmountSelected?: InputMaybe<Scalars['Boolean']>;
  isCSR?: InputMaybe<Scalars['Boolean']>;
  isCSRSupervisor?: InputMaybe<Scalars['Boolean']>;
  isOverridden?: InputMaybe<Scalars['Boolean']>;
  isPackageTypeOther?: InputMaybe<Scalars['Boolean']>;
  isQuoteItem?: InputMaybe<Scalars['Boolean']>;
  isShippingOverridden?: InputMaybe<Scalars['Boolean']>;
  isSpecificationsOverridden?: InputMaybe<Scalars['Boolean']>;
  pageNo?: InputMaybe<Scalars['Int']>;
  plateId?: InputMaybe<Scalars['Int']>;
  totalBaseCount?: InputMaybe<Scalars['Int']>;
  tubeSequences?: InputMaybe<Scalars['String']>;
  wellList: Array<WelllistScreenInput>;
};

export type PeptideErrors = {
  __typename?: 'PeptideErrors';
  listError?: Maybe<Array<Maybe<ListError>>>;
};

export type PhysicalFormatInput = {
  aliquot?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  lentivirusBackbone?: InputMaybe<Scalars['String']>;
  modification?: InputMaybe<Scalars['String']>;
  physicalMaterial?: InputMaybe<Scalars['String']>;
  purification?: InputMaybe<Scalars['String']>;
  scale?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Scalars['String']>;
  structure?: InputMaybe<Scalars['String']>;
  titer?: InputMaybe<Scalars['String']>;
  vector?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['String']>;
};

export type PhysicalFormatItem = {
  aliquot?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['String']>;
  physicalMaterial?: InputMaybe<Scalars['String']>;
  titer?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['String']>;
};

export type PhysicalMaterial = {
  __typename?: 'PhysicalMaterial';
  DNA?: Maybe<Scalars['String']>;
  noSelection?: Maybe<Scalars['String']>;
  RNA?: Maybe<Scalars['String']>;
  VIRUS?: Maybe<Scalars['String']>;
};

export type PinnedAddress = {
  __typename?: 'PinnedAddress';
  pinnedAddress?: Maybe<Array<Maybe<PinnedPartnerAccount>>>;
  unPinnedAddresses?: Maybe<Array<Maybe<PartnerAccount>>>;
};

export type PinnedAddresses = {
  __typename?: 'PinnedAddresses';
  addresses?: Maybe<Array<Maybe<PinnedAddressItem>>>;
};

export type PinnedAddressInputItem = {
  index: Scalars['Int'];
  partnerNumber: Scalars['String'];
  partnerType: Scalars['String'];
};

export type PinnedAddressItem = {
  __typename?: 'PinnedAddressItem';
  index: Scalars['Int'];
  partnerNumber: Scalars['String'];
  partnerType: Scalars['String'];
};

export type PinnedAddressSearchInput = {
  attentionTo?: InputMaybe<Scalars['String']>;
  buildingRoom?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  contactFirstName?: InputMaybe<Scalars['String']>;
  contactLastName?: InputMaybe<Scalars['String']>;
  customerNumber: Scalars['String'];
  department?: InputMaybe<Scalars['String']>;
  organizationName?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  requestType: RequestType;
  salesOrderSource?: InputMaybe<SalesOrderSource>;
  searchTerm?: InputMaybe<Scalars['String']>;
  soldToCountry?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
};

export type PinnedPartnerAccount = {
  __typename?: 'PinnedPartnerAccount';
  accountNumber: Scalars['String'];
  attentionTo: Scalars['String'];
  buildingRoom: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  customerServiceNotes?: Maybe<Scalars['String']>;
  department: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isDefault: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  organizationName: Scalars['String'];
  partnerNumber: Scalars['String'];
  postalCode: Scalars['String'];
  salesOrg: Scalars['String'];
  state: Scalars['String'];
  streetAddresses: Array<Scalars['String']>;
  type: Scalars['String'];
};

export type PinnedPartnerAddresses = {
  __typename?: 'PinnedPartnerAddresses';
  billTos?: Maybe<PinnedAddress>;
  contact?: Maybe<PinnedAddress>;
  payers?: Maybe<PinnedAddress>;
  shipTos?: Maybe<PinnedAddress>;
  soldTos?: Maybe<PinnedAddress>;
};

export type PipelineOrganizationDepartments = {
  __typename?: 'PipelineOrganizationDepartments';
  pipelineOrganizationDepartments: Array<PipelineOrganizationDepartmentsFields>;
};

export type PipelineOrganizationDepartmentsFields = {
  __typename?: 'PipelineOrganizationDepartmentsFields';
  languages: Scalars['String'];
  location: Scalars['String'];
  name: Scalars['String'];
  organizationId: Scalars['String'];
  parentOrg?: Maybe<Scalars['Boolean']>;
};

export type PlateListInput = {
  biTubeWellList?: InputMaybe<Array<InputMaybe<BiTubeWellListUploadInput>>>;
  plateName?: InputMaybe<Scalars['String']>;
  wellList?: InputMaybe<Array<InputMaybe<WellListUploadInput>>>;
};

export type PlatePrice = {
  __typename?: 'PlatePrice';
  baseFee?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  errorType?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  modificationFee?: Maybe<Scalars['String']>;
  plateCopyFee?: Maybe<Scalars['String']>;
  plateFee?: Maybe<Scalars['String']>;
  plateId?: Maybe<Scalars['Int']>;
  plateName?: Maybe<Scalars['String']>;
  priceErrorHeader?: Maybe<Scalars['String']>;
  processFee?: Maybe<Scalars['String']>;
  purificationFee?: Maybe<Scalars['String']>;
  remainingMaterials?: Maybe<Scalars['String']>;
  shippingOptionFee?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['String']>;
  wells?: Maybe<Scalars['String']>;
};

export type Plates = {
  __typename?: 'Plates';
  amount?: Maybe<Scalars['String']>;
  applyToAll?: Maybe<Scalars['Boolean']>;
  biTubeWellList?: Maybe<Array<Maybe<BiTubeWellList>>>;
  category?: Maybe<Scalars['String']>;
  channelType?: Maybe<Scalars['Int']>;
  concentration?: Maybe<Scalars['String']>;
  countOfWells?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  daughterPlateCount?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  isAmountSelected?: Maybe<Scalars['Boolean']>;
  isConcentrationOther?: Maybe<Scalars['Boolean']>;
  isConcentrationSelected?: Maybe<Scalars['Boolean']>;
  isCSR?: Maybe<Scalars['Boolean']>;
  isCSRSupervisor?: Maybe<Scalars['Boolean']>;
  isOverridden?: Maybe<Scalars['Boolean']>;
  isPackageTypeOther?: Maybe<Scalars['Boolean']>;
  isShippingOverridden?: Maybe<Scalars['Boolean']>;
  isSpecificationsOverridden?: Maybe<Scalars['Boolean']>;
  material?: Maybe<Scalars['String']>;
  maxAmount?: Maybe<Scalars['String']>;
  minAmount?: Maybe<Scalars['String']>;
  packageType?: Maybe<Scalars['String']>;
  pageNo?: Maybe<Scalars['Int']>;
  plateCopiesRange?: Maybe<Scalars['String']>;
  plateId?: Maybe<Scalars['Int']>;
  plateName?: Maybe<Scalars['String']>;
  plateNameError?: Maybe<Array<Maybe<Scalars['String']>>>;
  plateType?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  purification?: Maybe<Scalars['String']>;
  researcher?: Maybe<Scalars['String']>;
  researcherNameError?: Maybe<Array<Maybe<Scalars['String']>>>;
  scale?: Maybe<Scalars['String']>;
  selectedPackageType?: Maybe<Scalars['String']>;
  shipping?: Maybe<Scalars['String']>;
  stockPlate?: Maybe<Scalars['String']>;
  totalBaseCount?: Maybe<Scalars['Int']>;
  wellList: Array<Welllist>;
  wellType?: Maybe<Scalars['Int']>;
};

export type PlatesData = {
  __typename?: 'PlatesData';
  data: Array<Plates>;
};

export type PlatesDataResponse = {
  __typename?: 'PlatesDataResponse';
  response?: Maybe<Array<Maybe<PlatesResponse>>>;
  serverError?: Maybe<ListError>;
};

export type PlateShippingResponse = {
  __typename?: 'PlateShippingResponse';
  listExcessMaterial?: Maybe<Array<Maybe<Scalars['String']>>>;
  listProductPackage?: Maybe<Array<Maybe<Scalars['String']>>>;
  listShipment?: Maybe<Array<Maybe<Scalars['String']>>>;
  serverError?: Maybe<ListError>;
};

export type PlatesInput = {
  amount?: InputMaybe<Scalars['String']>;
  applyToAll?: InputMaybe<Scalars['Boolean']>;
  biTubeWellList?: InputMaybe<Array<InputMaybe<BiTubeWellListInput>>>;
  category?: InputMaybe<Scalars['String']>;
  channelType?: InputMaybe<Scalars['Int']>;
  concentration?: InputMaybe<Scalars['String']>;
  countOfWells?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<Scalars['String']>;
  daughterPlateCount?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  isAmountSelected?: InputMaybe<Scalars['Boolean']>;
  isConcentrationOther?: InputMaybe<Scalars['Boolean']>;
  isConcentrationSelected?: InputMaybe<Scalars['Boolean']>;
  isCSR?: InputMaybe<Scalars['Boolean']>;
  isCSRSupervisor?: InputMaybe<Scalars['Boolean']>;
  isOverridden?: InputMaybe<Scalars['Boolean']>;
  isPackageTypeOther?: InputMaybe<Scalars['Boolean']>;
  isShippingOverridden?: InputMaybe<Scalars['Boolean']>;
  isSpecificationsOverridden?: InputMaybe<Scalars['Boolean']>;
  material?: InputMaybe<Scalars['String']>;
  maxAmount?: InputMaybe<Scalars['String']>;
  minAmount?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  pageNo?: InputMaybe<Scalars['Int']>;
  plateCopiesRange?: InputMaybe<Scalars['String']>;
  plateId?: InputMaybe<Scalars['Int']>;
  plateName?: InputMaybe<Scalars['String']>;
  plateNameError?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  plateType?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  purification?: InputMaybe<Scalars['String']>;
  researcher?: InputMaybe<Scalars['String']>;
  researcherNameError?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  scale?: InputMaybe<Scalars['String']>;
  selectedPackageType?: InputMaybe<Scalars['String']>;
  shipping?: InputMaybe<Scalars['String']>;
  stockPlate?: InputMaybe<Scalars['String']>;
  totalBaseCount?: InputMaybe<Scalars['Int']>;
  wellList: Array<WelllistInput>;
  wellType?: InputMaybe<Scalars['Int']>;
};

export type PlatesPriceResponse = {
  __typename?: 'PlatesPriceResponse';
  response?: Maybe<Array<Maybe<PlatePrice>>>;
  serverError?: Maybe<ListError>;
};

export type PlatesResponse = {
  __typename?: 'PlatesResponse';
  biTubeWellList?: Maybe<Array<Maybe<BiTubeWelllistResponse>>>;
  error?: Maybe<Scalars['String']>;
  wellList?: Maybe<Array<Maybe<WelllistResponse>>>;
};

export type PosPaymentDataInput = {
  currency?: InputMaybe<Scalars['String']>;
  lineItemList: Array<PaymentListItemInput>;
  payerNo?: InputMaybe<Scalars['String']>;
  paymentCard: Scalars['String'];
  soldTo?: InputMaybe<Scalars['String']>;
  totalFormattedAmount?: InputMaybe<Scalars['String']>;
};

export type PosPaymentDataResponse = {
  __typename?: 'PosPaymentDataResponse';
  lineItemList: Array<Invoice>;
  paymentCardURL: Scalars['String'];
  transactionNo: Scalars['String'];
};

export type PriceInputKicqstart = {
  accessToken?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<Scalars['String']>;
};

export type PriceInputSiRna = {
  accessToken?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  purification?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type PriceResponseKicqStart = {
  __typename?: 'PriceResponseKicqStart';
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  defaultValue?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  serverError?: Maybe<ServerError>;
  variant?: Maybe<Scalars['String']>;
};

export type PriceResponseSiRna = {
  __typename?: 'PriceResponseSiRNA';
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  serverError?: Maybe<ServerError>;
};

export type Pricing = {
  __typename?: 'Pricing';
  brand?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  variant?: Maybe<Scalars['String']>;
};

export type Primer = {
  __typename?: 'Primer';
  ampliconSize?: Maybe<Scalars['String']>;
  exons?: Maybe<Scalars['String']>;
  geneId?: Maybe<Scalars['String']>;
  geneSymbol?: Maybe<Scalars['String']>;
  materialNumber?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  primerPairId?: Maybe<Scalars['String']>;
  primerSeqFwd?: Maybe<Scalars['String']>;
  primerSeqRev?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  refSeq?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
};

export type PrintInvoiceInput = {
  payer: Scalars['String'];
  resultDate: Scalars['String'];
  transactionNumber: Scalars['String'];
};

export type PrintInvoiceItem = {
  __typename?: 'PrintInvoiceItem';
  billingDoc: Scalars['String'];
  currency: Scalars['String'];
  invoiceQty: Scalars['String'];
  itemNo?: Maybe<Scalars['String']>;
  materialDesc?: Maybe<Scalars['String']>;
  materialNo?: Maybe<Scalars['String']>;
  netValue: Scalars['Float'];
  salesUnit?: Maybe<Scalars['String']>;
  shipHandCrg: Scalars['Float'];
  tax: Scalars['Float'];
  totalPrice: Scalars['Float'];
  unitPrice: Scalars['Float'];
};

export type PrintInvoiceResponse = {
  __typename?: 'PrintInvoiceResponse';
  currency: Scalars['String'];
  invoiceItems: Array<PrintInvoiceItem>;
  netTotal?: Maybe<Scalars['Float']>;
  otherCost?: Maybe<Scalars['Float']>;
  payerName?: Maybe<Scalars['String']>;
  paymentDate: Scalars['Date'];
  subTotal: Scalars['Float'];
  total: Scalars['Float'];
  vat: Scalars['Float'];
};

export enum Priority {
  Critical = 'Critical',
  Informational = 'Informational',
  Promotional = 'Promotional'
}

export type Product = {
  __typename?: 'Product';
  aliases: Array<Alias>;
  applications: Array<Scalars['String']>;
  attributeDifferences?: Maybe<Array<Scalars['String']>>;
  attributes: Array<ProductAttribute>;
  badge?: Maybe<Scalars['String']>;
  brand: Brand;
  browserMetadata?: Maybe<BrowserMetadata>;
  cardAttribute?: Maybe<CardAttribute>;
  cardCategory?: Maybe<Scalars['String']>;
  casNumber?: Maybe<Scalars['String']>;
  catalogId?: Maybe<CatalogType>;
  compliance: Array<Compliance>;
  complianceJp: Array<ComplianceJp>;
  complianceReach: Array<ComplianceReach>;
  components: MappedProductComponent;
  customersAlsoViewedProducts: Array<Product>;
  customPdpId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  descriptions: Array<ProductDescription>;
  discount?: Maybe<Scalars['String']>;
  displaySellerName?: Maybe<Scalars['String']>;
  empiricalFormula?: Maybe<Scalars['String']>;
  erp_type?: Maybe<Array<Scalars['String']>>;
  erpProductKey: Scalars['String'];
  faqs: Array<Faq>;
  featuredProducts: Array<Product>;
  features: Array<Scalars['String']>;
  forms?: Maybe<Array<Form>>;
  fullName: Scalars['String'];
  gaProductCode?: Maybe<Scalars['String']>;
  geneVariants: Array<GeneVariant>;
  id?: Maybe<Scalars['ID']>;
  images: Array<CatalogImage>;
  isBBE?: Maybe<Scalars['Boolean']>;
  isMarketplace: Scalars['Boolean'];
  isSial: Scalars['Boolean'];
  keyAttributes?: Maybe<Array<ProductAttribute>>;
  lastBoughtUnix?: Maybe<Scalars['String']>;
  legalName?: Maybe<Scalars['String']>;
  legalNameSuffix?: Maybe<Scalars['String']>;
  linearFormula?: Maybe<Scalars['String']>;
  links: Array<DocumentLink>;
  marketplaceOfferId?: Maybe<Scalars['String']>;
  marketplaceSellerId?: Maybe<Scalars['String']>;
  materialIds: Array<Scalars['String']>;
  mdlNumber?: Maybe<Scalars['String']>;
  molecularWeight?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  paMessage?: Maybe<Scalars['String']>;
  peerPapers: Papers;
  productCategories: Array<Maybe<ProductCategory>>;
  productKey: Scalars['String'];
  productNumber: Scalars['String'];
  productRating?: Maybe<ProductRating>;
  productSku?: Maybe<Scalars['String']>;
  protocolsAndArticles: ProtocolsArticles;
  recentlyViewedProducts: Array<Product>;
  recommendedProducts: Array<Product>;
  relatedProducts: Array<RelatedProduct>;
  sampleCertificate?: Maybe<Certificate>;
  sdsLanguages: Array<Maybe<Scalars['String']>>;
  sdsPnoKey: Scalars['String'];
  similarity?: Maybe<Scalars['String']>;
  speciesReactivity: Array<Scalars['String']>;
  specificationSheet: SpecificationSheet;
  status?: Maybe<Scalars['String']>;
  substance: Substance;
  substanceCount: Scalars['Int'];
  synonyms: Array<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ProductAttribute = {
  __typename?: 'ProductAttribute';
  key: Scalars['String'];
  label: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  category: Scalars['String'];
  url: Scalars['String'];
};

/**
 * comparisonAttributes is added to a new type that's composed of a Product
 * rather than to the Product type itself in order to avoid cache collisions in
 * clients (e.g. with dataIdFromObject)
 */
export type ProductComparate = {
  __typename?: 'ProductComparate';
  attributeDifferences?: Maybe<Array<Scalars['String']>>;
  comparisonAttributes: Array<AnalyticsProductAttribute>;
  product: Product;
};

export type ProductComparisonResult = {
  __typename?: 'ProductComparisonResult';
  comparates: Array<ProductComparate>;
};

export type ProductComponent = {
  __typename?: 'ProductComponent';
  brand?: Maybe<Scalars['String']>;
  erpBrandKey?: Maybe<Scalars['String']>;
  erpPnoKey?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  pId?: Maybe<Scalars['String']>;
  pno?: Maybe<Scalars['String']>;
  seq?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type ProductConfiguration = {
  __typename?: 'ProductConfiguration';
  name?: Maybe<Scalars['String']>;
  sapCharc?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ProductConfigurationDetail = {
  __typename?: 'ProductConfigurationDetail';
  configuration: Array<ProductConfiguration>;
  confType?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
};

export type ProductConfigurationInput = {
  name?: InputMaybe<Scalars['String']>;
  sapCharc?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type ProductDescription = {
  __typename?: 'ProductDescription';
  label?: Maybe<Scalars['String']>;
  values: Array<Scalars['String']>;
};

export type ProductInfoMessage = {
  __typename?: 'ProductInfoMessage';
  message?: Maybe<Scalars['String']>;
  messageType?: Maybe<Scalars['String']>;
  productNumber?: Maybe<Scalars['String']>;
};

export type ProductInformationResponse = {
  __typename?: 'ProductInformationResponse';
  brand?: Maybe<Scalars['String']>;
  companionProducts?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyFormat?: Maybe<Scalars['String']>;
  decimalSeparator?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  packageType?: Maybe<Scalars['String']>;
  prefixCurrecny?: Maybe<Scalars['Boolean']>;
  productType?: Maybe<Scalars['String']>;
  shippingOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  thousandSeparator?: Maybe<Scalars['String']>;
  thousandSpacing?: Maybe<Scalars['String']>;
  typeCode?: Maybe<Scalars['String']>;
};

export type ProductInput = {
  brandKey: Scalars['String'];
  catalogType?: InputMaybe<CatalogType>;
  productKey: Scalars['String'];
};

export type ProductNumberSuggestions = {
  __typename?: 'ProductNumberSuggestions';
  brand: Brand;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  images: Array<CatalogImage>;
  name: Scalars['String'];
  productKey: Scalars['String'];
  productNumber: Scalars['String'];
};

/**
 * Pricing for a single product, which will return pricing for multiple materials
 * and optionally a single object describing the product's replacement materials,
 * if it is discontinued.
 *
 * Will also return the product number and country the pricing is valid for.
 */
export type ProductPricing = {
  __typename?: 'ProductPricing';
  country: Scalars['String'];
  dchainMessage?: Maybe<Scalars['String']>;
  dealerId?: Maybe<Scalars['String']>;
  discontinuedPricingInfo?: Maybe<DiscontinuedMaterialPricing>;
  materialPricing: Array<ValidMaterialPricing>;
  productGroupSBU?: Maybe<Scalars['String']>;
  productInfo?: Maybe<Array<Maybe<ProductInfoMessage>>>;
  productNumber: Scalars['String'];
};

export type ProductPricingCustomerSpecific = {
  __typename?: 'ProductPricingCustomerSpecific';
  products?: Maybe<Array<Maybe<ProductPricingCustomerSpecificProduct>>>;
};

export type ProductPricingCustomerSpecificItem = {
  __typename?: 'ProductPricingCustomerSpecificItem';
  brand?: Maybe<Scalars['String']>;
  cartItemId?: Maybe<Scalars['String']>;
  listPrice?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  qty?: Maybe<Scalars['Int']>;
  sku: Scalars['String'];
};

export type ProductPricingCustomerSpecificProduct = {
  __typename?: 'ProductPricingCustomerSpecificProduct';
  dealerId: Scalars['String'];
  items?: Maybe<Array<Maybe<ProductPricingCustomerSpecificItem>>>;
};

export type ProductRating = {
  __typename?: 'ProductRating';
  ratingEnabled?: Maybe<Scalars['Boolean']>;
};

export enum ProductSearchGroup {
  Product = 'product',
  Substance = 'substance'
}

export type ProductSearchInput = {
  catalogType?: InputMaybe<CatalogType>;
  displayLanguage?: InputMaybe<Scalars['String']>;
  facets?: InputMaybe<Array<FacetInput>>;
  facetSet?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<ProductSearchGroup>;
  orgId?: InputMaybe<Scalars['String']>;
  pagination?: Pagination;
  region?: InputMaybe<Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
  type?: InputMaybe<ProductSearchType>;
  variation?: InputMaybe<Scalars['String']>;
};

export type ProductSearchKicqStart = {
  accessToken?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  store?: InputMaybe<Scalars['String']>;
  term: Array<InputMaybe<Scalars['String']>>;
};

export type ProductSearchKicqStartPrimers = {
  count?: InputMaybe<Scalars['Int']>;
  facet?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<Scalars['String']>;
  term: Scalars['String'];
};

export type ProductSearchResults = SearchResults & {
  __typename?: 'ProductSearchResults';
  didYouMeanTerms: Array<Maybe<DidYouMeanTerm>>;
  facets: Array<Facet>;
  items: Array<SearchResultsItems>;
  metadata: SearchResultsMetadata;
};

export type ProductSearchSiRna = {
  accessToken?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  defaultPurification?: InputMaybe<Scalars['Int']>;
  defaultSize?: InputMaybe<Scalars['Int']>;
  facet?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  locale?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<Scalars['String']>;
  term: Array<InputMaybe<Scalars['String']>>;
};

export type ProductSearchTermInput = {
  count?: InputMaybe<Scalars['Int']>;
  facet?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<Scalars['String']>;
  term: Scalars['String'];
};

export enum ProductSearchType {
  CasNumber = 'CAS_NUMBER',
  ColorIndex = 'COLOR_INDEX',
  EcNumber = 'EC_NUMBER',
  EgecNumber = 'EGEC_NUMBER',
  FemaNumber = 'FEMA_NUMBER',
  MdlNumber = 'MDL_NUMBER',
  MolForm = 'MOL_FORM',
  Product = 'PRODUCT',
  ProductName = 'PRODUCT_NAME',
  ProductNumber = 'PRODUCT_NUMBER',
  Protocol = 'PROTOCOL',
  SubstanceId = 'SUBSTANCE_ID'
}

export enum ProfileType {
  B2B = 'B2B',
  Business = 'Business'
}

export type PromotionalBundle = {
  __typename?: 'PromotionalBundle';
  bundleDescription?: Maybe<Scalars['String']>;
  bundleEndDate: Scalars['String'];
  bundleId: Scalars['String'];
  bundleItems: Array<PromotionalBundleItem>;
  bundleName?: Maybe<Scalars['String']>;
  bundleStartDate: Scalars['String'];
  countries: Array<Scalars['String']>;
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  lastUpdatedDate?: Maybe<Scalars['String']>;
  noOfItems: Scalars['Int'];
  promotionalBundleId: Scalars['String'];
  status: Scalars['Boolean'];
};

export type PromotionalBundleItem = {
  __typename?: 'PromotionalBundleItem';
  discount?: Maybe<Scalars['Float']>;
  materialIds?: Maybe<Array<Scalars['String']>>;
  materialNumber: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  showOnPdp: Scalars['Boolean'];
};

export type PromotionalBundleItemPricing = {
  __typename?: 'PromotionalBundleItemPricing';
  availabilityMessages?: Maybe<Array<Maybe<MaterialAvailability>>>;
  brand?: Maybe<Scalars['String']>;
  brandKey?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Float']>;
  listPrice?: Maybe<Scalars['Float']>;
  material?: Maybe<Material>;
  materialDescription?: Maybe<Scalars['String']>;
  materialId?: Maybe<Scalars['String']>;
  materialName?: Maybe<Scalars['String']>;
  materialNumber: Scalars['String'];
  product?: Maybe<Scalars['String']>;
  promoCode?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  yourPrice?: Maybe<Scalars['Float']>;
};

export type PromotionalBundlePricing = {
  __typename?: 'PromotionalBundlePricing';
  bundleItems: Array<PromotionalBundleItemPricing>;
  currency: Scalars['String'];
  listPrice?: Maybe<Scalars['Float']>;
  promotionalBundleId?: Maybe<Scalars['String']>;
  yourPrice?: Maybe<Scalars['Float']>;
};

export type PromotionalMessage = {
  __typename?: 'PromotionalMessage';
  messageKey?: Maybe<Scalars['String']>;
  messageValue?: Maybe<Scalars['String']>;
  messageVariable1?: Maybe<Scalars['String']>;
  messageVariable2?: Maybe<Scalars['String']>;
  messageVariable3?: Maybe<Scalars['String']>;
};

export type ProtocolArticleDocument = {
  __typename?: 'ProtocolArticleDocument';
  contentType: Scalars['String'];
  id: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type ProtocolsAndArticlesInput = {
  term: Scalars['String'];
};

export type ProtocolsArticles = {
  __typename?: 'ProtocolsArticles';
  articles: Array<Maybe<ProtocolArticleDocument>>;
  protocols: Array<Maybe<ProtocolArticleDocument>>;
  relatedContent: Array<Maybe<ProtocolArticleDocument>>;
};

export enum ProtocolsArticlesContentType {
  Article = 'article',
  Protocol = 'protocol',
  RelatedContent = 'relatedContent'
}

export type PspInformation = {
  __typename?: 'PSPInformation';
  captureContext?: Maybe<Scalars['String']>;
  hostedCheckoutUrl?: Maybe<Scalars['String']>;
  paymentEnvironment?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
};

export type PurchasingPartnerAccount = {
  __typename?: 'PurchasingPartnerAccount';
  accountNumber: Scalars['String'];
  attentionTo: Scalars['String'];
  buildingRoom: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  customerServiceNotes?: Maybe<Scalars['String']>;
  department: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  isPinned?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  organizationName: Scalars['String'];
  partnerNumber: Scalars['String'];
  pinnedIndex?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  salesOrg: Scalars['String'];
  state: Scalars['String'];
  streetAddresses: Array<Scalars['String']>;
  type: Scalars['String'];
};

export type Purification = {
  __typename?: 'Purification';
  Desalt?: Maybe<Scalars['String']>;
  HPLC?: Maybe<Scalars['String']>;
  Puredit?: Maybe<Scalars['String']>;
  Standard?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['String']>;
};

export type PushSubscription = {
  __typename?: 'PushSubscription';
  endpoint: Scalars['String'];
  keys: PushSubscriptionKeys;
};

export type PushSubscriptionInput = {
  endpoint: Scalars['String'];
  expirationTime?: InputMaybe<Scalars['Float']>;
  keys: PushSubscriptionKeysInput;
};

export type PushSubscriptionKeys = {
  __typename?: 'PushSubscriptionKeys';
  auth: Scalars['String'];
  p256dh: Scalars['String'];
};

export type PushSubscriptionKeysInput = {
  auth: Scalars['String'];
  p256dh: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** dummy value required for schema merging */
  _?: Maybe<Scalars['String']>;
  aemHeaderFooter?: Maybe<AemHeaderFooter>;
  aemHomepage?: Maybe<Scalars['String']>;
  aemStaticContent?: Maybe<Scalars['String']>;
  affiliateAccount?: Maybe<AffiliateAccountResponse>;
  amsQuotes: AmsQuotesPayload;
  antiseraEditLink?: Maybe<AntiseraSessionDataResponse>;
  bitubeEditLink?: Maybe<BiTubeData>;
  checkTermCondition?: Maybe<TermConditionResponse>;
  contractCardTransactions?: Maybe<ContractCardTransactionsPayload>;
  contractPaymentMethod: ContractPaymentMethod;
  customCrisprEditLink?: Maybe<CrisprSessionResponse>;
  customerSearch: Array<PartnerAccount>;
  fapiaoSearch: FapiaoSearchPayload;
  filters?: Maybe<FiltersResponse>;
  geneArrayPrimerSearch?: Maybe<GeneArrayWellSearchData>;
  get2c2pInquirePayment: Get2c2pPaymentResponse;
  get2c2pPaymentResponse: Get2c2pPaymentResponse;
  getAEMDiscountedProducts?: Maybe<AssociatedProducts>;
  getAllCarts: Array<Cart>;
  getAntisenceSeq?: Maybe<AntisenceSeq>;
  getAntiseraSessionData?: Maybe<AntiseraSessionDataResponse>;
  getAutoCompleteCertificate?: Maybe<AutoCompleteCertificateResponse>;
  getAvailabilityForMaterial: MaterialAvailabilities;
  getAzureJWTToken?: Maybe<AzureTokenResponse>;
  getB2BPunchOutLogoffData: B2bShoppingCartDataResponse;
  getB2bShoppingCart: B2bShoppingCartDataResponse;
  getB2bUserDetails?: Maybe<B2bUserResponse>;
  getBestSellerProducts?: Maybe<AssociatedProducts>;
  getBestSellerProductsByRegion?: Maybe<AssociatedProducts>;
  getBitubeData?: Maybe<BiTubeData>;
  getBuyItAgainProducts?: Maybe<AssociatedProducts>;
  getCart: Cart;
  getCartRecommendedProducts?: Maybe<AssociatedProducts>;
  getCertificateContent?: Maybe<CertificateContent>;
  getCertificateOfAnalysis: Certificate;
  getCertificateOfOrigin: Certificate;
  getChatConfig?: Maybe<Array<Maybe<ChatConfigResponse>>>;
  getComplianceRegulationMappingList: Array<ComplianceObj>;
  getConfContactDetails?: Maybe<ConfContactDetailsResponse>;
  getConsolidationOrders: Array<ConsolidationOrder>;
  getContactAccounts: Array<PartnerAccount>;
  getContentDocuments: ContentDocumentSearchResponse;
  getCountryDetails?: Maybe<CountryAccountSetup>;
  getCountryGuestShoppingType?: Maybe<Scalars['String']>;
  getCountryStates: Array<Maybe<CountryStates>>;
  getCoworkerDetails?: Maybe<MynetworkResponse>;
  getCoworkersGroup?: Maybe<Array<Maybe<CoworkerGroupResponse>>>;
  getCoworkersGroupDetails?: Maybe<CoworkerGroupDetailResponse>;
  getCoworkersList?: Maybe<Array<Maybe<MynetworkResponse>>>;
  getCreditCardPaymentInvoices: CreditCardPaymentInvoiceResponse;
  getCreditCards: CreditCardResponse;
  getCrisprPriceCall?: Maybe<CrisprPriceResponse>;
  getCrisprSessionData?: Maybe<CrisprSessionResponse>;
  getCustomerPricingForProduct: ProductPricingCustomerSpecific;
  getCustomersAlsoViewedProducts?: Maybe<AssociatedProducts>;
  getCustomOligoProducts?: Maybe<CustomOligoProductResponse>;
  getDealerApprovers: Array<Maybe<DealerApprover>>;
  getDealerById: Dealer;
  getDealerRequests: GetDealerRequestsPayload;
  getDealers: Array<Dealer>;
  getDealerTerms: Array<DealerTermsResponse>;
  getDocLib: DocLibPayload;
  getDocLibSavedDocs: DocLibSavedDocsResponse;
  getDropDownList: CrisprSpecificatios;
  getEmproveProductDocs: Array<Maybe<EmproveDoc>>;
  getEmproveSuiteDocs: EmproveSuiteDocsResponse;
  getEmproveUserData?: Maybe<EmproveUserData>;
  getEmproveUserDataV2?: Maybe<EmproveUserData>;
  getFacets?: Maybe<FacetSearchResponse>;
  getFapiaoList: GetFapiaoListPayload;
  getFedexTrackingInfo: ShipmentTrackingResponse;
  getGeneArraySessionData?: Maybe<GeneArraySearchData>;
  getGeneBySymbol?: Maybe<Gene>;
  getGeneSearchResults: GeneSearchResults;
  getGTINMaterials?: Maybe<Material>;
  getGuestToken: Scalars['String'];
  getHolidayCalendar: Array<HolidayCalendar>;
  getInvitationDetails?: Maybe<InviteCoworkerResponse>;
  getInviteEligibility?: Maybe<Scalars['Boolean']>;
  getInvoiceAutoSuggest?: Maybe<InvoiceAutoSuggestResponse>;
  getInvoiceList: InvoiceListResponse;
  getInvoices: InvoiceResponse;
  getLabWaterAccessoriesConsumablesServices?: Maybe<LabWaterAccessoriesConsumablesServices>;
  getLabWaterSelection: LabWaterSelection;
  getLabWaterSession?: Maybe<LabWaterSession>;
  getLabWaterSubApplications: Array<LabWaterSubApplications>;
  getLabWaterSystemFeatures: Array<LabWaterSystemFeatures>;
  getLabWaterValidation?: Maybe<LabWaterValidation>;
  getMaterialDetail?: Maybe<Material>;
  getMaterialsDetail: Array<Maybe<Material>>;
  getMetadataForFFCertificates?: Maybe<FfCertificateProduct>;
  getMilliplex?: Maybe<MilliplexResponse>;
  getMilliplexKitAnalytesAndSampleType?: Maybe<MilliplexKitAndSampleTypeResponse>;
  getMilliplexKits?: Maybe<MilliplexKitsResponse>;
  getMilliplexSearchCriteriaList?: Maybe<MilliplexSearchCriteriaResponse>;
  getMilliplexSessionData?: Maybe<MilliplexSessionDataResponse>;
  getMilliQSqBasePrice?: Maybe<MilliQSqBasePriceData>;
  getMilliQSqData?: Maybe<MilliQSqData>;
  getMilliqSqSession?: Maybe<MilliqSqSessionResponse>;
  getModificationAndSpecifications?: Maybe<ModificationsAndSpecificationsResponse>;
  getModifications?: Maybe<ConfiguratorPlates>;
  getMpayInvoices: MpayInvoiceResponse;
  getMpayPaymentHistory: MpayPaymentHistoryResponse;
  getMultipleProductDetails: Array<Maybe<Product>>;
  getNgsoPriceCall?: Maybe<NgsoPriceResponse>;
  getNotifications: Array<NotificationsResponse>;
  getOrderAssessments: OrderAssessmentsResponse;
  getOrderNotes?: Maybe<OrderNotesResponse>;
  getOrfDropDownList: OrfSpecifications;
  getOrfSearchTerm: OrfSearchResponse;
  getOrganizationApprovers: FetchOrganizationResponse;
  getOrganizationNameByEmailDomain?: Maybe<Scalars['String']>;
  getOrganizationValidity?: Maybe<Scalars['Boolean']>;
  getPapers: Array<Paper>;
  getPartnerAddresses: PartnerAddresses;
  getPartnerAddressOnPinnedOrder: PinnedPartnerAddresses;
  getPaymentHistory: PaymentHistoryResponse;
  getPaymentMetadata: PaymentMetadataResponse;
  getPinnedAddresses: PinnedAddresses;
  getPipelineDepartments: PipelineOrganizationDepartments;
  getPlatePriceCall?: Maybe<PlatesPriceResponse>;
  getPriceKicqStart?: Maybe<PriceResponseKicqStart>;
  getPriceSiRNA?: Maybe<PriceResponseSiRna>;
  getPricingForMaterial: MaterialPricing;
  getPricingForMultiCountryProducts: Array<Maybe<ProductPricing>>;
  getPricingForMultipleProducts: Array<ProductPricing>;
  getPricingForProduct: ProductPricing;
  getPricingForPromotionalBundle: PromotionalBundlePricing;
  getPrimerSessionData?: Maybe<KicqStartPrimerSessionData>;
  getPrintInvoice: PrintInvoiceResponse;
  getProductComparison?: Maybe<ProductComparisonResult>;
  getProductDetail?: Maybe<Product>;
  getProductFaqs: Array<Faq>;
  getProductInformation?: Maybe<ProductInformationResponse>;
  getProductNumberSearchSuggestions?: Maybe<GetProductNumberSearchSuggestions>;
  getProductPapers: Papers;
  getProductProtocolsAndArticles: ProtocolsArticles;
  getProductSearchResults: ProductSearchResults;
  getPromotionalBundlesForProduct?: Maybe<Array<PromotionalBundle>>;
  getQuickOrderItems: Array<QuickOrderItemsResponse>;
  getQuickOrderRecommendedProducts?: Maybe<AssociatedProducts>;
  getQuotesCount: GetQuotesCountPayload;
  getRecentlyViewedProducts?: Maybe<AssociatedProducts>;
  getRecentOrdersCount: GetRecentOrdersCountPayload;
  getRecommendedProducts?: Maybe<AssociatedProducts>;
  getRecommendedProductsAlt?: Maybe<AssociatedProducts>;
  getRegions: Array<RegionResponse>;
  getRelatedProducts?: Maybe<AssociatedProducts>;
  getRequesters: GetRequestersPayload;
  getSampleCertificate: Certificate;
  getSangerDropDownList: SangerCrisprSpecificatios;
  getSangerSearchTerm: SangerConfigurations;
  getSavedCart: SavedCartData;
  getSavedCarts: SavedCartsDataResponse;
  getSavedForLater: Array<SaveForLaterResponse>;
  getSavedProtocols: Array<Maybe<SavedProtocolsResponse>>;
  getSavedRegistrationInfo?: Maybe<UserSavedRegistration>;
  getScreenPriceCall?: Maybe<ScreenPriceResponse>;
  getSdsBulkCertificateSearch?: Maybe<SdsBulkSearchResponse>;
  getSdsCertificateRetrieve?: Maybe<SdsRetrieveResponse>;
  getSdsCertificateSearch?: Maybe<SdsSearchResponse>;
  getSearchKicqStart?: Maybe<SearchResponseKicqStart>;
  getSearchKicqStartPrimersData?: Maybe<SearchResponseKicqStartPrimers>;
  getSearchSiRNA?: Maybe<SearchResponseSiRna>;
  getSearchSuggestions?: Maybe<GetSearchSuggestionResponse>;
  getSearchTerm: SemiConfigurations;
  getSemiConfSessionData?: Maybe<SemiConfSessionData>;
  getSessionData?: Maybe<TubeDataFromSession>;
  getSessionDataForPlates?: Maybe<PlatesData>;
  getSessionDataPepScreen?: Maybe<PepScreenDataFromSession>;
  getSharedList?: Maybe<SharedListDetail>;
  getSharedListInvitations: SharedListsPayload;
  getSharedLists: SharedListsPayload;
  getShipmentTrackingInfo: ShipmentTrackingResponse;
  getShipmentTrackingRecommendedProducts?: Maybe<AssociatedProducts>;
  getShrnaDropDownList: ShrnaSpecifications;
  getShrnaSearchTerm: ShrnaSemiConfigurations;
  getSimpleAntiseraSessionData?: Maybe<SimpleAntiseraSessionResponse>;
  getSoftwareLicenses?: Maybe<SoftwareLicensesResponse>;
  getSoldToAccounts: Array<PartnerAccount>;
  getSpecificationCalculation?: Maybe<SpecificationsResponse>;
  getSpecifications?: Maybe<Configurations>;
  getSpecificationsForCustomCrispr?: Maybe<Configurations>;
  getSpecificationSheet?: Maybe<SpecificationSheet>;
  getSrpFeaturedProducts?: Maybe<AssociatedProducts>;
  getSrpRecommendedProducts?: Maybe<AssociatedProducts>;
  getStockAvailability: StockAvailabilityResults;
  getStructureSearchResults: StructureSearchResults;
  getSubItemPriceSiRNA?: Maybe<SubsetItemPrice>;
  getSubstanceById?: Maybe<Substance>;
  getTrackingInfo: GetTrackingInfoPayload;
  getTubeRuleValidateFields: TubeRuleValidateResponse;
  getTubeTotalValidation?: Maybe<Array<TubeTotalValidationResponse>>;
  getUserActivity?: Maybe<Array<UserActivityEntry>>;
  getUserDetailByToken?: Maybe<UserByToken>;
  getUserPushSubscriptions: Array<UserPushSubscription>;
  getVectorDocument?: Maybe<VectorDocument>;
  getWIMSTokenInfo?: Maybe<WimsTokenInfoResponse>;
  invoicePDF: Scalars['String'];
  me: User;
  milliplexSearchAnalytes?: Maybe<MilliplexSearchResponse>;
  omsAccessToken?: Maybe<Login>;
  omsCurrentUser?: Maybe<OmsCurrentUserResponse>;
  orderConfirmationPDF: Scalars['String'];
  orderDetail: GetOrderDetailPayload;
  orderSearch: OrderSearchPayload;
  orderShareableLink: Scalars['String'];
  ordersPendingApproval: OrdersForApproval;
  pdfDownload?: Maybe<Scalars['String']>;
  quoteDetail: QuoteDetailPayload;
  quotePDF: Scalars['String'];
  quoteSearch: QuoteSearchPayload;
  quoteShareableLink: Scalars['String'];
  recentOrderItems: RecentOrderItemsPayload;
  registeredUser?: Maybe<RegisteredUser>;
  searchGeneArrayData?: Maybe<GeneArraySearchData>;
  searchPapers: PaperSearchResponse;
  sellerContactContent?: Maybe<SellerContactContent>;
  sellerStaticContent: SellerResponse;
  simpleAntiseraEditLink?: Maybe<SimpleAntiseraSessionResponse>;
  siteWideMessages: Array<SitewideMessage>;
  submitTrialLicense?: Maybe<Scalars['Boolean']>;
  tubeEditLink?: Maybe<TubeDataFromSession>;
  verifySoftwareLicense?: Maybe<Scalars['Boolean']>;
  webOrders: WebOrdersPayload;
  webQuotes: WebQuotesPayload;
};


export type QueryAemHomepageArgs = {
  path: Scalars['String'];
};


export type QueryAemStaticContentArgs = {
  path: Scalars['String'];
};


export type QueryAffiliateAccountArgs = {
  customernumber?: InputMaybe<Scalars['String']>;
  customerpin?: InputMaybe<Scalars['String']>;
  invoicenumber?: InputMaybe<Scalars['String']>;
  ordernumber?: InputMaybe<Scalars['String']>;
  packingslipnumber?: InputMaybe<Scalars['String']>;
  quotereferencenumber?: InputMaybe<Scalars['String']>;
};


export type QueryAmsQuotesArgs = {
  input: AmsQuotesInput;
};


export type QueryAntiseraEditLinkArgs = {
  product: ConfiguratorProductInput;
};


export type QueryBitubeEditLinkArgs = {
  product?: InputMaybe<TubeEditInput>;
};


export type QueryCheckTermConditionArgs = {
  input?: InputMaybe<OmsGuestInput>;
};


export type QueryContractCardTransactionsArgs = {
  input: ContractCardTransactionsInput;
};


export type QueryCustomCrisprEditLinkArgs = {
  product: ConfiguratorProductInput;
};


export type QueryCustomerSearchArgs = {
  input: CustomerSearchInput;
};


export type QueryFapiaoSearchArgs = {
  input: FapiaoSearchInput;
};


export type QueryFiltersArgs = {
  input?: InputMaybe<OmsGuestInput>;
};


export type QueryGeneArrayPrimerSearchArgs = {
  input?: InputMaybe<GeneArrayWellSearchInput>;
  product?: InputMaybe<SemiConfiguratorProductInput>;
};


export type QueryGet2c2pInquirePaymentArgs = {
  tempOrderId: Scalars['String'];
};


export type QueryGet2c2pPaymentResponseArgs = {
  tempOrderId: Scalars['String'];
};


export type QueryGetAemDiscountedProductsArgs = {
  input: GetAemDiscountedProductsInput;
};


export type QueryGetAntisenceSeqArgs = {
  product: ConfiguratorProductInput;
  seq: Scalars['String'];
};


export type QueryGetAntiseraSessionDataArgs = {
  product: ConfiguratorProductInput;
};


export type QueryGetAutoCompleteCertificateArgs = {
  input: AutoCompleteCertificateInput;
};


export type QueryGetAvailabilityForMaterialArgs = {
  input: GetPricingForMaterialInput;
};


export type QueryGetAzureJwtTokenArgs = {
  input: AzureTokenInput;
};


export type QueryGetB2bShoppingCartArgs = {
  cartType?: InputMaybe<Scalars['String']>;
};


export type QueryGetB2bUserDetailsArgs = {
  token: Scalars['String'];
};


export type QueryGetBestSellerProductsArgs = {
  input: GetBestSellerProductsInput;
};


export type QueryGetBestSellerProductsByRegionArgs = {
  input: GetBestSellerProductsByRegionInput;
};


export type QueryGetBitubeDataArgs = {
  product: ConfiguratorProductInput;
};


export type QueryGetBuyItAgainProductsArgs = {
  input: GetBuyItAgainProductsInput;
};


export type QueryGetCartArgs = {
  id: CartRequestAction;
};


export type QueryGetCartRecommendedProductsArgs = {
  input: GetCartRecommendedProductsInput;
};


export type QueryGetCertificateContentArgs = {
  input?: InputMaybe<CertificateContentInput>;
};


export type QueryGetCertificateOfAnalysisArgs = {
  input: CertificateInput;
};


export type QueryGetCertificateOfOriginArgs = {
  input: CertificateInput;
};


export type QueryGetComplianceRegulationMappingListArgs = {
  input: GetComplianceRegMappingListInput;
};


export type QueryGetConfContactDetailsArgs = {
  country?: InputMaybe<Scalars['String']>;
};


export type QueryGetContactAccountsArgs = {
  cartType?: InputMaybe<CartRequestAction>;
  input: GetPartnersInput;
};


export type QueryGetContentDocumentsArgs = {
  input: ContentSearchInput;
};


export type QueryGetCountryDetailsArgs = {
  country?: InputMaybe<Scalars['String']>;
};


export type QueryGetCountryGuestShoppingTypeArgs = {
  country?: InputMaybe<Scalars['String']>;
};


export type QueryGetCountryStatesArgs = {
  country: Scalars['String'];
  locale: Scalars['String'];
};


export type QueryGetCoworkerDetailsArgs = {
  input?: InputMaybe<GetCoworkerDetailsInput>;
};


export type QueryGetCoworkersGroupDetailsArgs = {
  input?: InputMaybe<GetGroupInput>;
};


export type QueryGetCoworkersListArgs = {
  input?: InputMaybe<GetMynetworkInput>;
};


export type QueryGetCreditCardPaymentInvoicesArgs = {
  input?: InputMaybe<CreditCardPaymentSearchInput>;
};


export type QueryGetCrisprPriceCallArgs = {
  product: ConfiguratorProductInput;
};


export type QueryGetCrisprSessionDataArgs = {
  product: ConfiguratorProductInput;
};


export type QueryGetCustomerPricingForProductArgs = {
  input: GetCustomerPricingForProductInput;
};


export type QueryGetCustomersAlsoViewedProductsArgs = {
  input: GetCustomersAlsoViewedProductsInput;
};


export type QueryGetCustomOligoProductsArgs = {
  input: ConfiguratorProductInput;
};


export type QueryGetDealerByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetDealerRequestsArgs = {
  input?: InputMaybe<DealerRequestsSearchInput>;
};


export type QueryGetDealersArgs = {
  countryCode: Scalars['String'];
  searchTerm?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
};


export type QueryGetDealerTermsArgs = {
  dealerIds: Array<Scalars['String']>;
};


export type QueryGetDocLibArgs = {
  input: DocLibInput;
};


export type QueryGetDropDownListArgs = {
  input?: InputMaybe<DropDownListInput>;
};


export type QueryGetEmproveProductDocsArgs = {
  input: EmproveProductDocsInput;
};


export type QueryGetEmproveSuiteDocsArgs = {
  options?: InputMaybe<EmproveSuiteDocsInput>;
};


export type QueryGetEmproveUserDataArgs = {
  input: EmproveUserDataInput;
};


export type QueryGetFacetsArgs = {
  input: FacetSearchInput;
};


export type QueryGetFapiaoListArgs = {
  input: GetFapiaoListInput;
};


export type QueryGetFedexTrackingInfoArgs = {
  trackingNumber?: InputMaybe<Scalars['String']>;
};


export type QueryGetGeneArraySessionDataArgs = {
  product?: InputMaybe<SemiConfiguratorProductInput>;
};


export type QueryGetGeneBySymbolArgs = {
  input: SymbolInput;
};


export type QueryGetGeneSearchResultsArgs = {
  input: GeneSearchInput;
};


export type QueryGetGtinMaterialsArgs = {
  input: GtinMaterialInput;
};


export type QueryGetGuestTokenArgs = {
  country: Scalars['String'];
};


export type QueryGetHolidayCalendarArgs = {
  plant: Array<InputMaybe<Scalars['String']>>;
};


export type QueryGetInvitationDetailsArgs = {
  invitationId: Scalars['String'];
};


export type QueryGetInviteEligibilityArgs = {
  input?: InputMaybe<InviteEligibilityInput>;
};


export type QueryGetInvoiceAutoSuggestArgs = {
  input?: InputMaybe<OmsGuestInput>;
  keyword: Scalars['String'];
};


export type QueryGetInvoiceListArgs = {
  input?: InputMaybe<InvoiceListInput>;
};


export type QueryGetInvoicesArgs = {
  input: InvoiceInput;
};


export type QueryGetLabWaterAccessoriesConsumablesServicesArgs = {
  input?: InputMaybe<LabWaterAccessoriesConsumablesServicesInput>;
};


export type QueryGetLabWaterSessionArgs = {
  input?: InputMaybe<LabWaterSessionInput>;
};


export type QueryGetLabWaterSystemFeaturesArgs = {
  input?: InputMaybe<LabWaterSystemFeaturesInput>;
};


export type QueryGetLabWaterValidationArgs = {
  input?: InputMaybe<LabWaterValidationInput>;
};


export type QueryGetMaterialDetailArgs = {
  input: GetMaterialDetailInput;
};


export type QueryGetMaterialsDetailArgs = {
  input: GetMaterialsDetailInput;
};


export type QueryGetMetadataForFfCertificatesArgs = {
  input: GetProductInput;
};


export type QueryGetMilliplexArgs = {
  input?: InputMaybe<MilliplexInput>;
};


export type QueryGetMilliplexKitAnalytesAndSampleTypeArgs = {
  input?: InputMaybe<MilliplexKitAndSampleTypeInput>;
};


export type QueryGetMilliplexKitsArgs = {
  input?: InputMaybe<MilliplexInput>;
};


export type QueryGetMilliplexSessionDataArgs = {
  product: ConfiguratorProductInput;
};


export type QueryGetMilliQSqBasePriceArgs = {
  input?: InputMaybe<MilliQSqBasePriceInput>;
};


export type QueryGetMilliQSqDataArgs = {
  input?: InputMaybe<MilliQSqInput>;
};


export type QueryGetMilliqSqSessionArgs = {
  input?: InputMaybe<GetMilliqSqSessionInput>;
};


export type QueryGetModificationAndSpecificationsArgs = {
  product: ConfiguratorProductUserInput;
};


export type QueryGetModificationsArgs = {
  product: ConfiguratorProductInput;
};


export type QueryGetMpayInvoicesArgs = {
  input?: InputMaybe<MpayInvoiceInput>;
};


export type QueryGetMultipleProductDetailsArgs = {
  input: MultipleProductInput;
};


export type QueryGetNgsoPriceCallArgs = {
  product: ConfiguratorProductInput;
};


export type QueryGetOrderNotesArgs = {
  input?: InputMaybe<OrderNotesInput>;
};


export type QueryGetOrfDropDownListArgs = {
  input?: InputMaybe<OrfDropDownListInput>;
};


export type QueryGetOrfSearchTermArgs = {
  input?: InputMaybe<OrfProductSearchTermInput>;
};


export type QueryGetOrganizationApproversArgs = {
  id: Scalars['String'];
};


export type QueryGetOrganizationNameByEmailDomainArgs = {
  emailDomain: Scalars['String'];
};


export type QueryGetOrganizationValidityArgs = {
  id: Scalars['String'];
};


export type QueryGetPapersArgs = {
  input: PaperInput;
};


export type QueryGetPartnerAddressesArgs = {
  addressBook?: InputMaybe<Scalars['Boolean']>;
  cartType?: InputMaybe<CartRequestAction>;
  country?: InputMaybe<Scalars['String']>;
  input: GetPartnersInput;
  requestSource?: InputMaybe<RequestSource>;
};


export type QueryGetPartnerAddressOnPinnedOrderArgs = {
  input: PinnedAddressSearchInput;
  participantId: Scalars['String'];
};


export type QueryGetPaymentHistoryArgs = {
  input: PaymentHistorySearchInput;
};


export type QueryGetPaymentMetadataArgs = {
  input?: InputMaybe<PaymentMetadataInput>;
};


export type QueryGetPinnedAddressesArgs = {
  soldTo: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryGetPipelineDepartmentsArgs = {
  parentOrgID: Scalars['String'];
};


export type QueryGetPlatePriceCallArgs = {
  product: ConfiguratorProductInput;
};


export type QueryGetPriceKicqStartArgs = {
  input?: InputMaybe<PriceInputKicqstart>;
};


export type QueryGetPriceSiRnaArgs = {
  input?: InputMaybe<PriceInputSiRna>;
};


export type QueryGetPricingForMaterialArgs = {
  input: GetPricingForMaterialInput;
};


export type QueryGetPricingForMultiCountryProductsArgs = {
  input: GetPricingForProductInput;
};


export type QueryGetPricingForMultipleProductsArgs = {
  input: GetPricingForMultipleProductInput;
};


export type QueryGetPricingForProductArgs = {
  input: GetPricingForProductInput;
};


export type QueryGetPricingForPromotionalBundleArgs = {
  input: GetPricingForPromotionalBundle;
};


export type QueryGetPrimerSessionDataArgs = {
  product?: InputMaybe<SemiConfiguratorProductInput>;
};


export type QueryGetPrintInvoiceArgs = {
  input: PrintInvoiceInput;
};


export type QueryGetProductComparisonArgs = {
  input: GetProductComparisonInput;
};


export type QueryGetProductDetailArgs = {
  input: GetProductInput;
};


export type QueryGetProductFaqsArgs = {
  input: GetProductFaqsInput;
};


export type QueryGetProductInformationArgs = {
  input?: InputMaybe<GetProductInformationInput>;
};


export type QueryGetProductNumberSearchSuggestionsArgs = {
  input: GetProductNumberSearchSuggestionsInput;
};


export type QueryGetProductPapersArgs = {
  input: GetProductPapersInput;
};


export type QueryGetProductProtocolsAndArticlesArgs = {
  input: GetProductProtocolsAndArticlesInput;
};


export type QueryGetProductSearchResultsArgs = {
  input: ProductSearchInput;
};


export type QueryGetPromotionalBundlesForProductArgs = {
  input: GetPromotionalBundlesForProduct;
};


export type QueryGetQuickOrderRecommendedProductsArgs = {
  input?: InputMaybe<GetQuickOrderRecommendedProductsInput>;
};


export type QueryGetRecentlyViewedProductsArgs = {
  input: GetRecentlyViewedProductsInput;
};


export type QueryGetRecommendedProductsArgs = {
  input: GetRecommendedProductsInput;
};


export type QueryGetRecommendedProductsAltArgs = {
  input: GetRecommendedProductsAltInput;
};


export type QueryGetRelatedProductsArgs = {
  input: Array<InputMaybe<Scalars['String']>>;
};


export type QueryGetRequestersArgs = {
  input?: InputMaybe<GetRequestersInput>;
};


export type QueryGetSampleCertificateArgs = {
  input: SampleCertificateInput;
};


export type QueryGetSangerDropDownListArgs = {
  input?: InputMaybe<SangerDropDownListInput>;
};


export type QueryGetSangerSearchTermArgs = {
  input?: InputMaybe<SangerProductSearchTermInput>;
};


export type QueryGetSavedCartArgs = {
  action?: InputMaybe<SavedCartAction>;
  cartId: Scalars['ID'];
};


export type QueryGetSavedCartsArgs = {
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<Scalars['String']>;
};


export type QueryGetSavedForLaterArgs = {
  input?: InputMaybe<SavedForLaterInput>;
};


export type QueryGetSavedRegistrationInfoArgs = {
  input?: InputMaybe<RegistrationInput>;
};


export type QueryGetScreenPriceCallArgs = {
  product: ConfiguratorProductInput;
};


export type QueryGetSdsBulkCertificateSearchArgs = {
  input: SdsBulkSearchInput;
};


export type QueryGetSdsCertificateRetrieveArgs = {
  input: SdsRetrieveInput;
};


export type QueryGetSdsCertificateSearchArgs = {
  input: SdsSearchInput;
};


export type QueryGetSearchKicqStartArgs = {
  input?: InputMaybe<ProductSearchKicqStart>;
};


export type QueryGetSearchKicqStartPrimersDataArgs = {
  input?: InputMaybe<ProductSearchKicqStartPrimers>;
};


export type QueryGetSearchSiRnaArgs = {
  input?: InputMaybe<ProductSearchSiRna>;
};


export type QueryGetSearchSuggestionsArgs = {
  input: GetSearchSuggestionsInput;
};


export type QueryGetSearchTermArgs = {
  input?: InputMaybe<ProductSearchTermInput>;
};


export type QueryGetSemiConfSessionDataArgs = {
  product?: InputMaybe<SemiConfiguratorProductInput>;
};


export type QueryGetSessionDataArgs = {
  product: ConfiguratorProductInput;
};


export type QueryGetSessionDataForPlatesArgs = {
  product: ConfiguratorProductInput;
};


export type QueryGetSessionDataPepScreenArgs = {
  product: ConfiguratorProductInput;
};


export type QueryGetSharedListArgs = {
  listId: Scalars['ID'];
  showPricing?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetSharedListsArgs = {
  input?: InputMaybe<GetSharedListsInput>;
};


export type QueryGetShipmentTrackingInfoArgs = {
  carrierId: CarrierId;
  trackingNumber: Scalars['String'];
};


export type QueryGetShipmentTrackingRecommendedProductsArgs = {
  input: GetShipmentTrackingRecommendedProductsInput;
};


export type QueryGetShrnaDropDownListArgs = {
  input?: InputMaybe<ShrnaDropDownListInput>;
};


export type QueryGetShrnaSearchTermArgs = {
  input?: InputMaybe<ShrnaProductSearchTermInput>;
};


export type QueryGetSimpleAntiseraSessionDataArgs = {
  product: ConfiguratorProductInput;
};


export type QueryGetSoldToAccountsArgs = {
  addressBook?: InputMaybe<Scalars['Boolean']>;
  cartType?: InputMaybe<CartRequestAction>;
  requestSource?: InputMaybe<RequestSource>;
  requestType?: InputMaybe<RequestType>;
  soldTos?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryGetSpecificationCalculationArgs = {
  input: SpecificationsInput;
  product: ConfiguratorProductInput;
};


export type QueryGetSpecificationsArgs = {
  product: ConfiguratorProductInput;
};


export type QueryGetSpecificationsForCustomCrisprArgs = {
  product: ConfiguratorProductInput;
};


export type QueryGetSpecificationSheetArgs = {
  input?: InputMaybe<SpecSheetInput>;
};


export type QueryGetSrpFeaturedProductsArgs = {
  input: GetSrpFeaturedProductsInput;
};


export type QueryGetSrpRecommendedProductsArgs = {
  input: GetSrpRecommendedProductsInput;
};


export type QueryGetStockAvailabilityArgs = {
  input: StockAvailabilityInput;
};


export type QueryGetStructureSearchResultsArgs = {
  input: StructureSearchInput;
};


export type QueryGetSubItemPriceSiRnaArgs = {
  input?: InputMaybe<SubItemPriceInputSiRna>;
};


export type QueryGetSubstanceByIdArgs = {
  input: GetSubstanceByIdInput;
};


export type QueryGetTrackingInfoArgs = {
  input: GetTrackingInfoInput;
};


export type QueryGetTubeRuleValidateFieldsArgs = {
  input: RuleValidateInput;
  productInput: ConfiguratorProductUserInput;
};


export type QueryGetTubeTotalValidationArgs = {
  input: Array<InputMaybe<SessionInput>>;
  productInput: ConfiguratorProductInput;
};


export type QueryGetUserDetailByTokenArgs = {
  input: GetUserByToken;
};


export type QueryGetVectorDocumentArgs = {
  input: VectorDocumentInput;
};


export type QueryGetWimsTokenInfoArgs = {
  participantId: Scalars['String'];
};


export type QueryInvoicePdfArgs = {
  input: InvoicePdfInput;
};


export type QueryMilliplexSearchAnalytesArgs = {
  input?: InputMaybe<Scalars['String']>;
  product: ConfiguratorProductInput;
};


export type QueryOmsAccessTokenArgs = {
  logonId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  orderNumber?: InputMaybe<Scalars['String']>;
};


export type QueryOmsCurrentUserArgs = {
  input?: InputMaybe<OmsGuestInput>;
};


export type QueryOrderConfirmationPdfArgs = {
  input: OrderConfirmationPdfInput;
};


export type QueryOrderDetailArgs = {
  input: GetOrderDetailInput;
};


export type QueryOrderSearchArgs = {
  input?: InputMaybe<OrderSearchInput>;
};


export type QueryOrderShareableLinkArgs = {
  input: OrderShareableLinkInput;
};


export type QueryPdfDownloadArgs = {
  input: PdfDownloadInput;
};


export type QueryQuoteDetailArgs = {
  input: QuoteDetailInput;
};


export type QueryQuotePdfArgs = {
  input: QuotePdfInput;
};


export type QueryQuoteSearchArgs = {
  input?: InputMaybe<QuoteSearchInput>;
};


export type QueryQuoteShareableLinkArgs = {
  input: QuoteShareableLinkInput;
};


export type QueryRecentOrderItemsArgs = {
  input?: InputMaybe<RecentOrderItemsInput>;
};


export type QuerySearchGeneArrayDataArgs = {
  input?: InputMaybe<GeneArraySearchInput>;
};


export type QuerySearchPapersArgs = {
  input: PaperSearchInput;
};


export type QuerySellerContactContentArgs = {
  type?: InputMaybe<Scalars['String']>;
};


export type QuerySellerStaticContentArgs = {
  sellerId: Scalars['String'];
};


export type QuerySimpleAntiseraEditLinkArgs = {
  product: ConfiguratorProductInput;
};


export type QuerySiteWideMessagesArgs = {
  input: SitewideMessageInput;
};


export type QuerySubmitTrialLicenseArgs = {
  serialNumber?: InputMaybe<Scalars['String']>;
};


export type QueryTubeEditLinkArgs = {
  product?: InputMaybe<TubeEditInput>;
};


export type QueryVerifySoftwareLicenseArgs = {
  serialNumber?: InputMaybe<Scalars['String']>;
};


export type QueryWebOrdersArgs = {
  input: WebOrdersInput;
};


export type QueryWebQuotesArgs = {
  input: WebQuotesInput;
};

export type QuickOrderItem = {
  materialNumber: Scalars['String'];
  promoCode: Scalars['String'];
  quantity: Scalars['Int'];
};

export type QuickOrderItemsResponse = {
  __typename?: 'QuickOrderItemsResponse';
  itemId: Scalars['String'];
  materialNumber: Scalars['String'];
  participantId?: Maybe<Scalars['String']>;
  promoCode: Scalars['String'];
  quantity: Scalars['Int'];
  storeId?: Maybe<Scalars['Int']>;
};

export type Quote = {
  __typename?: 'Quote';
  currency?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['Date']>;
  orderedBy: Scalars['String'];
  orderTotal?: Maybe<Scalars['Float']>;
  poNumber?: Maybe<Scalars['String']>;
  quoteId?: Maybe<Scalars['ID']>;
  quoteName?: Maybe<Scalars['String']>;
  quotePriceItemExists?: Maybe<Scalars['Boolean']>;
  quoteType?: Maybe<Scalars['String']>;
  readableQuoteId?: Maybe<Scalars['String']>;
  salesDocNumber?: Maybe<Scalars['ID']>;
  sfdcReference?: Maybe<Scalars['String']>;
  soldTo?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  validFrom: Scalars['Date'];
  validTo: Scalars['Date'];
};

export type QuoteConversionInput = {
  isCsr?: InputMaybe<Scalars['Boolean']>;
  isFullQuoteConversion?: InputMaybe<Scalars['Boolean']>;
  items: Array<CartItemInput>;
  quoteIdentifier: Scalars['String'];
};

export type QuoteDetailInput = {
  isConfirmation: Scalars['Boolean'];
  quoteIdentifier: Scalars['String'];
  sharedToken?: InputMaybe<Scalars['String']>;
  sysId?: InputMaybe<Scalars['String']>;
};

export type QuoteDetailPayload = {
  __typename?: 'QuoteDetailPayload';
  quote: QuoteDetails;
};

export type QuoteDetails = {
  __typename?: 'QuoteDetails';
  alcTax?: Maybe<Scalars['Float']>;
  billingInfo: OrderContactInfo;
  canReturnOrder: Scalars['Boolean'];
  complianceFee?: Maybe<Scalars['Float']>;
  contactInfo: OrderContactDetail;
  createdDate: Scalars['Date'];
  currency: Scalars['String'];
  customerServiceNote?: Maybe<Scalars['String']>;
  documentType?: Maybe<Scalars['String']>;
  dutyTotal?: Maybe<Scalars['Float']>;
  electricalFee?: Maybe<Scalars['Float']>;
  eligibleForBuyItNow?: Maybe<Scalars['Boolean']>;
  eurVolatileMassCharge?: Maybe<Scalars['Float']>;
  expirationDate: Scalars['Date'];
  hasQuotePriceItem: Scalars['Boolean'];
  ice?: Maybe<Scalars['Float']>;
  isConvertQuoteToOrderEnabled: Scalars['Boolean'];
  isReorderEnabled: Scalars['Boolean'];
  isRequestNewQuoteEnabled: Scalars['Boolean'];
  message: Scalars['String'];
  orderedBy: Scalars['String'];
  packingSlipNote?: Maybe<Scalars['String']>;
  pkg?: Maybe<Scalars['Float']>;
  poNumber?: Maybe<Scalars['String']>;
  quoteId?: Maybe<Scalars['ID']>;
  quoteItems: Array<QuoteItem>;
  quoteName?: Maybe<Scalars['String']>;
  quoteType?: Maybe<Scalars['String']>;
  quoteUUID?: Maybe<Scalars['ID']>;
  requisitionNumber?: Maybe<Scalars['String']>;
  salesTax?: Maybe<Scalars['Float']>;
  sfdcReference?: Maybe<Scalars['String']>;
  shippingInfo: OrderContactInfo;
  shippingTransportation?: Maybe<Scalars['Float']>;
  soldTo?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  subtotal: Scalars['Float'];
  total: Scalars['Float'];
  transHandlingCharge?: Maybe<Scalars['Float']>;
  transSurchargeMessage?: Maybe<Scalars['String']>;
};

export type QuoteGenerationResponse = {
  __typename?: 'QuoteGenerationResponse';
  orderNumber: Scalars['String'];
  orderStatus: Scalars['String'];
  sapOrderNumber: Scalars['String'];
};

export type QuoteItem = {
  __typename?: 'QuoteItem';
  additionalInfo?: Maybe<OrderAdditionalInfo>;
  availabilityMessages?: Maybe<Array<Maybe<OrderItemAvailabilityMessages>>>;
  brandId?: Maybe<Scalars['String']>;
  complianceMessages?: Maybe<Array<Scalars['String']>>;
  configuration: Array<ProductConfiguration>;
  configurationId?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  hidePriceMessageKey?: Maybe<Scalars['String']>;
  isQuotePriceItem?: Maybe<Scalars['Boolean']>;
  itemPrice: Scalars['Float'];
  kitItems: Array<CartKitItem>;
  lineNumber?: Maybe<Scalars['String']>;
  linePrice: Scalars['Float'];
  listPrice?: Maybe<Scalars['Float']>;
  listPriceCurrency?: Maybe<Scalars['String']>;
  materialInfo: Material;
  materialNumber: Scalars['String'];
  originalQty?: Maybe<Scalars['Int']>;
  pricePerUnit: Scalars['Float'];
  product?: Maybe<Scalars['String']>;
  productDetails?: Maybe<Product>;
  productNote?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  promoCode?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  remainingQty?: Maybe<Scalars['Int']>;
  scheduleLines: Array<OrderItemScheduleLine>;
  status?: Maybe<Scalars['String']>;
  type: CartItemType;
  yourPrice?: Maybe<Scalars['Float']>;
};

export type QuoteItemToUpdate = {
  itemToUpdate: Scalars['String'];
  value: Scalars['String'];
};

export type QuotePdfInput = {
  quoteNumber: Scalars['String'];
  soldTo: Scalars['String'];
};

export type QuoteSearchInput = {
  contact?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<OrderByType>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  poNumber?: InputMaybe<Scalars['String']>;
  productNumber?: InputMaybe<Scalars['String']>;
  quoteNumber?: InputMaybe<Scalars['String']>;
  shipTo?: InputMaybe<Scalars['String']>;
  soldTo?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<OrderStatusType>;
  validFrom?: InputMaybe<Scalars['Date']>;
  validTo?: InputMaybe<Scalars['Date']>;
};

export type QuoteSearchPayload = {
  __typename?: 'QuoteSearchPayload';
  count: Scalars['Int'];
  currency?: Maybe<Scalars['String']>;
  orderBy?: Maybe<OrderByType>;
  orderTotal?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  poNumber?: Maybe<Scalars['String']>;
  productNumber?: Maybe<Scalars['String']>;
  quoteNumber?: Maybe<Scalars['String']>;
  quotes: Array<Quote>;
  soldTo?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['Date']>;
  validTo?: Maybe<Scalars['Date']>;
};

export type QuoteShareableLinkInput = {
  quoteNumber: Scalars['String'];
};

export type ReadyToShipNotificationInput = {
  brand: Scalars['String'];
  imageUrl: Scalars['String'];
  materialNumber: Scalars['String'];
  productNumber: Scalars['String'];
};

export type ReadyToShipNotificationResponse = {
  __typename?: 'ReadyToShipNotificationResponse';
  brand?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  materialNumber?: Maybe<Scalars['String']>;
  participantId?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type Reagents = {
  __typename?: 'Reagents';
  currency?: Maybe<Scalars['String']>;
  efficiencyScore?: Maybe<Scalars['String']>;
  geneId?: Maybe<Scalars['String']>;
  geneSymbol?: Maybe<Scalars['String']>;
  grnaId?: Maybe<Scalars['String']>;
  moreInformation?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  recommendedStatus?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  specificityScore?: Maybe<Scalars['String']>;
};

export type ReagentsInput = {
  brand?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  currencySymbol?: InputMaybe<Scalars['String']>;
  efficiencyScore?: InputMaybe<Scalars['String']>;
  geneId?: InputMaybe<Scalars['String']>;
  geneSymbol?: InputMaybe<Scalars['String']>;
  grnaId?: InputMaybe<Scalars['String']>;
  material?: InputMaybe<Scalars['String']>;
  moreInformation?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  recommendedStatus?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Scalars['String']>;
  specificityScore?: InputMaybe<Scalars['String']>;
};

export type Reasons = {
  __typename?: 'Reasons';
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type RecentOrderItem = {
  __typename?: 'RecentOrderItem';
  brand: Scalars['String'];
  material: Material;
  orderDate: Scalars['Date'];
  orderId: Scalars['String'];
  orderItemId: Scalars['String'];
  participantId: Scalars['String'];
  product: Scalars['String'];
  purchaseCount: Scalars['Int'];
  quantity: Scalars['String'];
  storeId?: Maybe<Scalars['String']>;
  type: CartItemType;
};

export type RecentOrderItemsInput = {
  brand?: InputMaybe<Scalars['String']>;
  createdfromdate?: InputMaybe<Scalars['Date']>;
  createdtodate?: InputMaybe<Scalars['Date']>;
  material?: InputMaybe<Scalars['String']>;
  orderby?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perpage?: InputMaybe<Scalars['Int']>;
  product?: InputMaybe<Scalars['String']>;
  sortcolumn?: InputMaybe<Scalars['String']>;
};

export type RecentOrderItemsPayload = {
  __typename?: 'RecentOrderItemsPayload';
  count: Scalars['Int'];
  orderItems: Array<RecentOrderItem>;
};

export type RecommendedProduct = {
  __typename?: 'RecommendedProduct';
  brand: Scalars['String'];
  description: Scalars['String'];
  displayProductNumber?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  productNumber: Scalars['String'];
};

export type RegionInput = {
  region: Scalars['String'];
};

export type RegionResponse = {
  __typename?: 'RegionResponse';
  countries: Array<CountryResponse>;
  regionCode: Scalars['String'];
  regionName: Scalars['String'];
};

export type RegisterBlanketPoInput = {
  billTo?: InputMaybe<Scalars['String']>;
  blanketPoNumber: Scalars['String'];
  contractAmount: Scalars['String'];
  contractPin: Scalars['String'];
  contractValidTo: Scalars['String'];
  email: Scalars['String'];
  extension?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  payer?: InputMaybe<Scalars['String']>;
  soldTo?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};

export type RegisteredBlanketPoResponse = {
  __typename?: 'RegisteredBlanketPoResponse';
  errorMessage?: Maybe<Scalars['String']>;
  orderNumber: Scalars['String'];
  orderStatus: Scalars['String'];
  sapOrderNumber: Scalars['String'];
};

export type RegisteredUser = {
  __typename?: 'RegisteredUser';
  billingAddress?: Maybe<UserAddress>;
  contactAddress?: Maybe<UserAddress>;
  officeAddress?: Maybe<UserAddress>;
  participantComplianceInformation?: Maybe<UserParticipantComplianceInformation>;
  paymentAddress?: Maybe<UserAddress>;
  shippingAddress?: Maybe<UserAddress>;
  userProfile?: Maybe<UserProfile>;
};

export type RegisteredUserProfile = {
  __typename?: 'RegisteredUserProfile';
  accessToken?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Date']>;
  guestShoppingType?: Maybe<Scalars['String']>;
  isOffersAndPromotionsEmail?: Maybe<Scalars['Boolean']>;
  logonId?: Maybe<Scalars['String']>;
  profileType?: Maybe<ProfileType>;
  userId?: Maybe<Scalars['String']>;
};

export type RegistrationInput = {
  cartType?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  omsGuestToken?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<Scalars['String']>;
};

export enum RejectDealerRequestAction {
  Reject = 'reject',
  RejectAll = 'rejectAll'
}

export type RejectDealerRequestsInput = {
  action: RejectDealerRequestAction;
  cartId: Scalars['ID'];
  rejectionComments: Scalars['String'];
  rejectionReason: Scalars['String'];
};

export type RelatedCategory = {
  __typename?: 'RelatedCategory';
  facetId: Scalars['String'];
  value: Scalars['String'];
};

export type RelatedProduct = {
  __typename?: 'RelatedProduct';
  productId: Scalars['String'];
  type: Scalars['String'];
};

export type RequestApprovalInput = {
  emailId: Scalars['String'];
};

export type Requester = {
  __typename?: 'Requester';
  approvalAmount?: Maybe<Scalars['Float']>;
  approvalProductType?: Maybe<Scalars['String']>;
  approvalTypeId: Scalars['String'];
  approverId: Scalars['String'];
  emailId: Scalars['String'];
  logonId: Scalars['String'];
  participantId: Scalars['String'];
  sequence?: Maybe<Scalars['Int']>;
};

export type RequestQuoteForMilliplexInput = {
  comments?: InputMaybe<Scalars['String']>;
  emailId: Scalars['String'];
  name: Scalars['String'];
  participantId: Scalars['String'];
  phoneNumber: Scalars['String'];
  selectedAssayList: Array<SelectedAssayInput>;
};

export enum RequestSource {
  Order = 'order',
  Quote = 'quote'
}

export enum RequestType {
  All = 'ALL',
  Contact = 'CONTACT',
  Search = 'SEARCH',
  Soldto = 'SOLDTO'
}

export type RestrictedPartnersObject = {
  __typename?: 'restrictedPartnersObject';
  restricedHiddenpartnersIndexId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type RestrictedPartnersObjectInput = {
  restricedHiddenpartnersIndexId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Results = {
  __typename?: 'Results';
  refseqId?: Maybe<Scalars['String']>;
  siRNAType?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  subSetInfo?: Maybe<Array<Maybe<SemiConfSubSetInfo>>>;
};

export type ResultsInput = {
  refseqId?: InputMaybe<Scalars['String']>;
  siRNAType?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Scalars['String']>;
  subSetInfo?: InputMaybe<Array<InputMaybe<SemiConfSubSetInfoInput>>>;
};

export type Roles = {
  __typename?: 'Roles';
  isApprovee: Scalars['Boolean'];
  isBuyingNetworkManager: Scalars['Boolean'];
  isCCRUser: Scalars['Boolean'];
  isCustomerServiceRep: Scalars['Boolean'];
  isCustomProductCSR: Scalars['Boolean'];
  isDealer: Scalars['Boolean'];
  isDTAQFTBUser: Scalars['Boolean'];
  isExternalSalesAgent: Scalars['Boolean'];
  isInventoryViewUser: Scalars['Boolean'];
  isInvoicePaymentCustomer: Scalars['Boolean'];
  isKoreaPrePayment: Scalars['Boolean'];
  isKoreaStandardCustomer: Scalars['Boolean'];
  isMultiCountryUser: Scalars['Boolean'];
  isOrderApprover: Scalars['Boolean'];
  isOrderConsolidationRequester: Scalars['Boolean'];
  isOrderConsolidator: Scalars['Boolean'];
  isOrderRequester: Scalars['Boolean'];
  isOrderStatusCustomer: Scalars['Boolean'];
  isPackForExportUser: Scalars['Boolean'];
  isPublicOrderingCustomer: Scalars['Boolean'];
  isPurchasingAgent: Scalars['Boolean'];
  isQuoteConverter: Scalars['Boolean'];
  isQuoteRequester: Scalars['Boolean'];
  isRegisteredCustomer: Scalars['Boolean'];
  isSourcingUser: Scalars['Boolean'];
};

export type RuleValidateDuplexInput = {
  antisenceBaseCount?: InputMaybe<Scalars['Int']>;
  antisenceFiveMod?: InputMaybe<Scalars['String']>;
  antisenceName?: InputMaybe<Scalars['String']>;
  antisenceSequence?: InputMaybe<Scalars['String']>;
  antisenceThreeMod?: InputMaybe<Scalars['String']>;
  autoGenerateOligo?: InputMaybe<Scalars['Boolean']>;
  baseCount?: InputMaybe<Scalars['Int']>;
  customerAntisenseOverHang?: InputMaybe<Scalars['String']>;
  customerOverhang?: InputMaybe<Scalars['String']>;
  fiveMod?: InputMaybe<Scalars['String']>;
  isOverridden?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  pageNo?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['String']>;
  threeMod?: InputMaybe<Scalars['String']>;
  tubes?: InputMaybe<Scalars['Int']>;
};

export type RuleValidateInput = {
  additionalCarrierProtein?: InputMaybe<Scalars['String']>;
  additionalServices?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  amount?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  concentration?: InputMaybe<Scalars['String']>;
  conjugation?: InputMaybe<Scalars['String']>;
  conjugationAmount?: InputMaybe<Scalars['String']>;
  conjugationChemistry?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  cterminalMod?: InputMaybe<Scalars['String']>;
  fiveMod?: InputMaybe<Scalars['String']>;
  flag?: InputMaybe<Scalars['Int']>;
  format?: InputMaybe<Scalars['String']>;
  fullSOligoBackbone?: InputMaybe<Scalars['Boolean']>;
  internalMod?: InputMaybe<Scalars['String']>;
  isLabelledAminoAcid?: InputMaybe<Scalars['Boolean']>;
  isOverridden?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  nterminalMod?: InputMaybe<Scalars['String']>;
  otherValue?: InputMaybe<Scalars['String']>;
  probeType?: InputMaybe<Scalars['String']>;
  purification?: InputMaybe<Scalars['String']>;
  purityLevel?: InputMaybe<Scalars['String']>;
  quantitation?: InputMaybe<Scalars['String']>;
  researcher?: InputMaybe<Scalars['String']>;
  scale?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['String']>;
  threeMod?: InputMaybe<Scalars['String']>;
  tubes?: InputMaybe<Scalars['String']>;
};

export type RuleValidatePlateInput = {
  fiveMod?: InputMaybe<Scalars['String']>;
  isOverridden?: InputMaybe<Scalars['Boolean']>;
  mixed?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  pageNo?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['String']>;
  threeMod?: InputMaybe<Scalars['String']>;
  tubes?: InputMaybe<Scalars['Int']>;
  wellType?: InputMaybe<Scalars['Int']>;
};

export type RuleValidateResponse = {
  __typename?: 'RuleValidateResponse';
  additionalServices?: Maybe<Array<Maybe<Scalars['String']>>>;
  applyToAll?: Maybe<Scalars['Boolean']>;
  baseCount?: Maybe<Scalars['Int']>;
  brand?: Maybe<Scalars['String']>;
  cartId?: Maybe<Scalars['String']>;
  concentration?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  epsilon?: Maybe<Scalars['Float']>;
  fiveMod?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  fullSOligoBackbone?: Maybe<Scalars['Boolean']>;
  gcContent?: Maybe<Scalars['String']>;
  internalMod?: Maybe<Scalars['String']>;
  isLNABaseEnable?: Maybe<Scalars['Boolean']>;
  isOverridden?: Maybe<Scalars['Boolean']>;
  itemId?: Maybe<Scalars['String']>;
  listAdditionalCarrierProtein?: Maybe<Array<Maybe<Scalars['String']>>>;
  listAdditionalServices?: Maybe<Array<Maybe<Scalars['String']>>>;
  listAmount?: Maybe<Array<Maybe<Scalars['String']>>>;
  listConcentration?: Maybe<Array<Maybe<Scalars['String']>>>;
  listConjugation?: Maybe<Array<Maybe<Scalars['String']>>>;
  listConjugationAmount?: Maybe<Array<Maybe<Scalars['String']>>>;
  listConjugationChemistry?: Maybe<Array<Maybe<Scalars['String']>>>;
  listCterminalMod?: Maybe<Array<Maybe<Scalars['String']>>>;
  listErrors?: Maybe<Array<Maybe<ListError>>>;
  listFiveMod?: Maybe<Array<Maybe<Scalars['String']>>>;
  listFormat?: Maybe<Array<Maybe<Scalars['String']>>>;
  listInternalMod?: Maybe<Array<Maybe<Scalars['String']>>>;
  listLabeledAminoAcids?: Maybe<Array<Maybe<Scalars['String']>>>;
  listMinimumYield?: Maybe<Scalars['Float']>;
  listModification?: Maybe<Array<Maybe<Scalars['String']>>>;
  listNterminalMod?: Maybe<Array<Maybe<Scalars['String']>>>;
  listPurification?: Maybe<Array<Maybe<Scalars['String']>>>;
  listPurityLevel?: Maybe<Array<Maybe<Scalars['String']>>>;
  listQuantitation?: Maybe<Array<Maybe<Scalars['String']>>>;
  listScale?: Maybe<Array<Maybe<Scalars['String']>>>;
  listServices?: Maybe<Array<Maybe<Scalars['String']>>>;
  listThreeMod?: Maybe<Array<Maybe<Scalars['String']>>>;
  listTubes?: Maybe<Array<Maybe<Scalars['String']>>>;
  listWarnings?: Maybe<Array<Maybe<ListWarning>>>;
  material?: Maybe<Scalars['String']>;
  meltingTemperature?: Maybe<Scalars['String']>;
  molecularWeight?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  oligoId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['String']>;
  priceHeaderError?: Maybe<Scalars['String']>;
  primerDimer?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  purification?: Maybe<Scalars['String']>;
  scale?: Maybe<Scalars['String']>;
  secondaryStructure?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['String']>;
  serverError?: Maybe<ListError>;
  threeMod?: Maybe<Scalars['String']>;
  tubes?: Maybe<Scalars['String']>;
};

export type RuleValidateResponsePlate = {
  __typename?: 'RuleValidateResponsePlate';
  antisenseBaseCount?: Maybe<Scalars['Int']>;
  antisenseGcContent?: Maybe<Scalars['String']>;
  antisenseMeltingTemperature?: Maybe<Scalars['String']>;
  antisenseMolecularWeight?: Maybe<Scalars['String']>;
  antisensePrimerDimer?: Maybe<Scalars['String']>;
  antisenseSecondaryStructure?: Maybe<Scalars['String']>;
  baseCount?: Maybe<Scalars['Int']>;
  gcContent?: Maybe<Scalars['String']>;
  listAmount?: Maybe<Array<Maybe<Scalars['String']>>>;
  listConcentration?: Maybe<Array<Maybe<Scalars['String']>>>;
  listCterminalMod?: Maybe<Array<Maybe<Scalars['String']>>>;
  listErrors?: Maybe<Array<Maybe<ListError>>>;
  listFiveMod?: Maybe<Array<Maybe<Scalars['String']>>>;
  listFormat?: Maybe<Array<Maybe<Scalars['String']>>>;
  listInternalMod?: Maybe<Array<Maybe<Scalars['String']>>>;
  listMinimumYield?: Maybe<Scalars['Float']>;
  listModification?: Maybe<Array<Maybe<Scalars['String']>>>;
  listNterminalMod?: Maybe<Array<Maybe<Scalars['String']>>>;
  listOverhang?: Maybe<Array<Maybe<Scalars['String']>>>;
  listPurification?: Maybe<Array<Maybe<Scalars['String']>>>;
  listPurityLevel?: Maybe<Array<Maybe<Scalars['String']>>>;
  listQuantitation?: Maybe<Array<Maybe<Scalars['String']>>>;
  listScale?: Maybe<Array<Maybe<Scalars['String']>>>;
  listThreeMod?: Maybe<Array<Maybe<Scalars['String']>>>;
  listTubes?: Maybe<Array<Maybe<Scalars['String']>>>;
  listWarning?: Maybe<Array<Maybe<ListWarning>>>;
  meltingTemperature?: Maybe<Scalars['String']>;
  molecularWeight?: Maybe<Scalars['String']>;
  primerDimer?: Maybe<Scalars['String']>;
  secondaryStructure?: Maybe<Scalars['String']>;
  serverError?: Maybe<ListError>;
  technicalSequence?: Maybe<Scalars['String']>;
};

export type RuleValidateScreenInput = {
  analyticalHPLC?: InputMaybe<Scalars['String']>;
  baseCount?: InputMaybe<Scalars['Int']>;
  cterminalMod?: InputMaybe<Scalars['String']>;
  isOverridden?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  nterminalMod?: InputMaybe<Scalars['String']>;
  pageNo?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['String']>;
};

export enum SalesOrderSource {
  SalesOrderSrcEmailVal = 'SALES_ORDER_SRC_EMAIL_VAL',
  SalesOrderSrcFaxVal = 'SALES_ORDER_SRC_FAX_VAL',
  SalesOrderSrcPhoneVal = 'SALES_ORDER_SRC_PHONE_VAL'
}

export type SampleCertificateInput = {
  brand?: InputMaybe<Scalars['String']>;
  control?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  documentType?: InputMaybe<Scalars['String']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  productNumber: Scalars['String'];
  useCount?: InputMaybe<Scalars['Boolean']>;
  useFilters?: InputMaybe<Scalars['Boolean']>;
};

export type SangerConfigurations = {
  __typename?: 'SangerConfigurations';
  count?: Maybe<Scalars['Int']>;
  reagents?: Maybe<Array<Maybe<SangerReagents>>>;
  requestDate?: Maybe<Scalars['String']>;
  responseDate?: Maybe<Scalars['String']>;
  serverError?: Maybe<ServerError>;
  term?: Maybe<Array<Maybe<Scalars['String']>>>;
  version?: Maybe<Scalars['String']>;
};

export type SangerCrisprAddToCartInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  physicalFormat?: InputMaybe<PhysicalFormatInput>;
  reagents?: InputMaybe<Array<InputMaybe<SangerReagentsInput>>>;
  sapNextEnabled?: InputMaybe<Scalars['Boolean']>;
  store?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
};

export type SangerCrisprAddToCartResponse = {
  __typename?: 'SangerCrisprAddToCartResponse';
  result?: Maybe<Scalars['String']>;
  serverError?: Maybe<ListError>;
};

export type SangerCrisprPriceCall = {
  __typename?: 'SangerCrisprPriceCall';
  result?: Maybe<Array<Maybe<SangerCrisprPriceCallResponse>>>;
  serverError?: Maybe<ServerError>;
};

export type SangerCrisprPriceCallInput = {
  country?: InputMaybe<Scalars['String']>;
  physicalFormat?: InputMaybe<PhysicalFormatInput>;
  reagents?: InputMaybe<Array<InputMaybe<SangerReagentsInput>>>;
};

export type SangerCrisprPriceCallResponse = {
  __typename?: 'SangerCrisprPriceCallResponse';
  brand?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  ensemblGeneId?: Maybe<Scalars['String']>;
  ensemblGeneSymbol?: Maybe<Scalars['String']>;
  exonId?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  ncbiGeneId?: Maybe<Scalars['String']>;
  ncbiGeneSymbol?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  sangerCloneId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  targetSeq?: Maybe<Scalars['String']>;
};

export type SangerCrisprSpecificatios = {
  __typename?: 'SangerCrisprSpecificatios';
  aliquot?: Maybe<Array<Maybe<Aliquot>>>;
  amount?: Maybe<Array<Maybe<Amount>>>;
  physicalMaterial?: Maybe<Array<Maybe<SangerPhysicalMaterial>>>;
  serverError?: Maybe<ServerError>;
  titer?: Maybe<Array<Maybe<Titer>>>;
  volume?: Maybe<Array<Maybe<Volume>>>;
};

export type SangerDropDownListInput = {
  aliquot?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['String']>;
  countryCode: Scalars['String'];
  physicalMaterial?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<Scalars['String']>;
  titer?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['String']>;
};

export type SangerPhysicalMaterial = {
  __typename?: 'SangerPhysicalMaterial';
  DNA?: Maybe<Scalars['String']>;
  GLYCEROL?: Maybe<Scalars['String']>;
  noSelection?: Maybe<Scalars['String']>;
  VIRUS?: Maybe<Scalars['String']>;
};

export type SangerProductSearchTermInput = {
  count?: InputMaybe<Scalars['Int']>;
  facet?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<Scalars['String']>;
  term: Scalars['String'];
};

export type SangerReagents = {
  __typename?: 'SangerReagents';
  currency?: Maybe<Scalars['String']>;
  ensemblGeneId?: Maybe<Scalars['String']>;
  ensemblGeneSymbol?: Maybe<Scalars['String']>;
  exonId?: Maybe<Scalars['String']>;
  ncbiGeneId?: Maybe<Scalars['String']>;
  ncbiGeneSymbol?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  sangerCloneId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  targetSeq?: Maybe<Scalars['String']>;
};

export type SangerReagentsInput = {
  brand?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  currencySymbol?: InputMaybe<Scalars['String']>;
  ensemblGeneId?: InputMaybe<Scalars['String']>;
  ensemblGeneSymbol?: InputMaybe<Scalars['String']>;
  exonId?: InputMaybe<Scalars['String']>;
  material?: InputMaybe<Scalars['String']>;
  ncbiGeneId?: InputMaybe<Scalars['String']>;
  ncbiGeneSymbol?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  sangerCloneId?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Scalars['String']>;
  targetSeq?: InputMaybe<Scalars['String']>;
};

export type SapError = {
  __typename?: 'SAPError';
  errorCode?: Maybe<Scalars['String']>;
  errorNumber?: Maybe<Scalars['String']>;
  materialNumber?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type SaveCartId = {
  __typename?: 'SaveCartId';
  cartId: Scalars['String'];
};

export type SaveCartInput = {
  cartComment?: InputMaybe<Scalars['String']>;
  cartName: Scalars['String'];
  cartType?: InputMaybe<Scalars['String']>;
};

export type SavedCart = {
  __typename?: 'SavedCart';
  cartId: Scalars['String'];
  cartName: Scalars['String'];
  cartType?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  hiddenCheckoutButton?: Maybe<Scalars['Boolean']>;
  participantId?: Maybe<Scalars['String']>;
  products: Scalars['Int'];
  savedDate: Scalars['Float'];
  storeId?: Maybe<Scalars['Int']>;
};

export enum SavedCartAction {
  Price = 'price'
}

export type SavedCartData = {
  __typename?: 'SavedCartData';
  billingAddress?: Maybe<CartAddress>;
  cartType?: Maybe<Scalars['String']>;
  ccCardNumber?: Maybe<Scalars['String']>;
  ccCardType?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
  createdByName?: Maybe<Scalars['String']>;
  createdDate: Scalars['Date'];
  currency?: Maybe<Scalars['String']>;
  customerServiceInstructions?: Maybe<Scalars['String']>;
  dealers: Array<Dealer>;
  displayPromoCode: Scalars['Boolean'];
  hasQuotePriceItem: Scalars['Boolean'];
  hiddenCheckoutButton?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  items: Array<SavedCartItem>;
  lastUpdatedDate: Scalars['Date'];
  name: Scalars['String'];
  orderingPartyAddress?: Maybe<CartAddress>;
  packingSlipNote?: Maybe<Scalars['String']>;
  partners?: Maybe<CartPartners>;
  poNumber?: Maybe<Scalars['String']>;
  requisitionNumber?: Maybe<Scalars['String']>;
  salesOrderSource?: Maybe<SalesOrderSource>;
  shippingAddress?: Maybe<CartAddress>;
  totals?: Maybe<CartTotals>;
};

export type SavedCartItem = {
  __typename?: 'SavedCartItem';
  additionalInfo?: Maybe<CartAdditionalInfo>;
  availabilityMessages: Array<Maybe<CartItemAvailabilityMessages>>;
  availabilityOverwriteMessage?: Maybe<BbeMaterialAvailability>;
  batchNumber?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  bundleItems: Array<Maybe<PromotionalBundleItemPricing>>;
  complianceMessages: Array<Maybe<Scalars['String']>>;
  configuration: Array<ProductConfiguration>;
  configurationId?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  dealerId?: Maybe<Scalars['String']>;
  displayEditButton: Scalars['Boolean'];
  displaySellerName?: Maybe<Scalars['String']>;
  erpType?: Maybe<Array<Maybe<Scalars['String']>>>;
  hidePriceMessageKey?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image: CatalogImage;
  isQuotePriceItem?: Maybe<Scalars['Boolean']>;
  itemSAPError: Array<Maybe<SapError>>;
  kitItems?: Maybe<Array<Maybe<CartKitItem>>>;
  listPrice?: Maybe<Scalars['Float']>;
  materialBrand: Brand;
  materialDescription?: Maybe<Scalars['String']>;
  materialId: Scalars['String'];
  materialName?: Maybe<Scalars['String']>;
  minimumOrderQuantity?: Maybe<Scalars['Int']>;
  netPrice?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product: Scalars['String'];
  productNote?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  promoCode?: Maybe<Scalars['String']>;
  promotionalMessage?: Maybe<CartItemPromotionalMessage>;
  quantity: Scalars['Int'];
  quoteNumber?: Maybe<Scalars['String']>;
  redirectLinkForEdit?: Maybe<Scalars['String']>;
  remainingReserveQuantity?: Maybe<Scalars['Int']>;
  supplementaryMessage?: Maybe<SupplementaryMessage>;
  totalReserveQuantity?: Maybe<Scalars['Int']>;
  type: CartItemType;
  vendorNumber?: Maybe<Scalars['String']>;
  yourReference?: Maybe<Scalars['String']>;
};

export type SavedCartItemInput = {
  materialNumber: Scalars['String'];
  quantity: Scalars['Int'];
};

export type SavedCartsDataResponse = {
  __typename?: 'SavedCartsDataResponse';
  count?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  savedCartSearchModels?: Maybe<Array<SavedCart>>;
};

/** The same inputs are needed to modify any given saved document. Be it to add it, or remove it from your library. Was: SavedDocumentInput */
export type SavedDocumentInput = {
  brand: Scalars['String'];
  lotNumber?: InputMaybe<Scalars['String']>;
  product: Scalars['String'];
};

export type SavedForLaterInput = {
  cartType?: InputMaybe<Scalars['String']>;
};

export type SavedProtocolsResponse = {
  __typename?: 'SavedProtocolsResponse';
  participantId?: Maybe<Scalars['String']>;
  savedDate?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type SaveForLaterInput = {
  items: Array<SaveForLaterItem>;
};

export type SaveForLaterItem = {
  cartType: Scalars['String'];
  itemType: Scalars['String'];
  marketplaceOfferId?: InputMaybe<Scalars['String']>;
  materialNumber: Scalars['String'];
};

export type SaveForLaterResponse = {
  __typename?: 'SaveForLaterResponse';
  brand?: Maybe<Scalars['String']>;
  cartType: Scalars['String'];
  catalogId?: Maybe<Scalars['String']>;
  configurationDetail?: Maybe<Scalars['String']>;
  configurationId?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Date']>;
  itemType?: Maybe<Scalars['String']>;
  material: Material;
  materialDescription?: Maybe<Scalars['String']>;
  materialName?: Maybe<Scalars['String']>;
  materialNumber: Scalars['String'];
};

export type SaveLabWaterSession = {
  accessories?: InputMaybe<Array<InputMaybe<LabWaterAccessoryConsumableAndService>>>;
  applications?: InputMaybe<Array<InputMaybe<LabWaterSessionApplicationsInput>>>;
  commentForLWSpecialist?: InputMaybe<Scalars['String']>;
  consumables?: InputMaybe<Array<InputMaybe<LabWaterAccessoryConsumableAndService>>>;
  countryCode?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  dailyWaterUsage?: InputMaybe<Scalars['String']>;
  feedWaterType?: InputMaybe<Scalars['String']>;
  listApplication?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listErrors?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listfeed?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listMaxwaterUsage?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listOutputWater?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listRegulated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listSubapplication?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listSystem?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listWarnings?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  otherApplication?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  regulatedEnvironment?: InputMaybe<Scalars['String']>;
  regulatoryRequirement?: InputMaybe<Scalars['String']>;
  services?: InputMaybe<Array<InputMaybe<LabWaterAccessoryConsumableAndService>>>;
  subApplication?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  system?: InputMaybe<Scalars['String']>;
  systemPrice?: InputMaybe<Scalars['Float']>;
  typeOfWater?: InputMaybe<Scalars['String']>;
};

export type SaveLabWaterSessionInput = {
  isCSR?: InputMaybe<Scalars['Boolean']>;
  isSialCSRUser?: InputMaybe<Scalars['Boolean']>;
  labWaterSession?: InputMaybe<SaveLabWaterSession>;
  participantId?: InputMaybe<Scalars['String']>;
};

export type SaveMilliqSqSessionInput = {
  isCSR?: InputMaybe<Scalars['Boolean']>;
  isSialCSRUser?: InputMaybe<Scalars['Boolean']>;
  milliqSqSession?: InputMaybe<MilliqSqSessionInput>;
  participantId?: InputMaybe<Scalars['String']>;
};

export type SaveToSessionResponse = {
  __typename?: 'SaveToSessionResponse';
  result?: Maybe<Scalars['Int']>;
};

export type Scale = {
  __typename?: 'Scale';
  five?: Maybe<Scalars['String']>;
  three?: Maybe<Scalars['String']>;
  two?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['String']>;
};

export enum ScheduleLineStatusType {
  Cancelled = 'CANCELLED',
  CancelledDropShip = 'CANCELLED_DROP_SHIP',
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  CompletedDropShip = 'COMPLETED_DROP_SHIP',
  Delayed = 'DELAYED',
  Empty = 'EMPTY',
  FulfilmentDeliveryDelayed = 'FULFILMENT_DELIVERY_DELAYED',
  InProcess = 'IN_PROCESS',
  Invoiced = 'INVOICED',
  Open = 'OPEN',
  PartiallyCancelled = 'PARTIALLY_CANCELLED',
  PartiallyReceived = 'PARTIALLY_RECEIVED',
  PartiallyShipped = 'PARTIALLY_SHIPPED',
  Pending = 'PENDING',
  PendingApproval = 'PENDING_APPROVAL',
  PendingDropShip = 'PENDING_DROP_SHIP',
  PendingRefund = 'PENDING_REFUND',
  PendingReview = 'PENDING_REVIEW',
  Processing = 'PROCESSING',
  Received = 'RECEIVED',
  Refunded = 'REFUNDED',
  Rejected = 'REJECTED',
  Shipped = 'SHIPPED',
  ShippingInProgress = 'SHIPPING_IN_PROGRESS'
}

export type ScreenPrice = {
  __typename?: 'ScreenPrice';
  analyticalHPLC?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  column?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  material?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  priceHeaderError?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['String']>;
};

export type ScreenPriceResponse = {
  __typename?: 'ScreenPriceResponse';
  response?: Maybe<Array<Maybe<ScreenPrice>>>;
  serverError?: Maybe<ListError>;
};

export type ScreenWellSpecificationResponse = {
  __typename?: 'ScreenWellSpecificationResponse';
  response?: Maybe<Array<Maybe<ScreenWellSpecifications>>>;
  serverError?: Maybe<ListError>;
};

export type ScreenWellSpecifications = {
  __typename?: 'ScreenWellSpecifications';
  id?: Maybe<Scalars['Int']>;
  listAmount?: Maybe<Array<Maybe<Scalars['String']>>>;
  listError?: Maybe<Array<Maybe<ListError>>>;
  listFormat?: Maybe<Array<Maybe<Scalars['String']>>>;
  listPurification?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SdsBulkSearchInput = {
  products: Array<SdsBulkSearchProduct>;
};

export type SdsBulkSearchProduct = {
  brand: Scalars['String'];
  productNumber: Scalars['String'];
};

export type SdsBulkSearchResponse = {
  __typename?: 'SdsBulkSearchResponse';
  items: Array<SdsSearchResponse>;
};

export type SdsData = {
  __typename?: 'SdsData';
  brand: Scalars['String'];
  countryCode: Scalars['String'];
  languages: SdsLanuguageOptions;
  productNumber: Scalars['String'];
};

export type SdsLanuguageOptions = {
  __typename?: 'SDSLanuguageOptions';
  altLanguages: Array<Scalars['String']>;
  primaryLanguage: Scalars['String'];
};

export type SdsRetrieveData = {
  __typename?: 'SdsRetrieveData';
  brand: Scalars['String'];
  countryCode: Scalars['String'];
  fileName: Scalars['String'];
  languages?: Maybe<SdsLanuguageOptions>;
  pdfDocument: Scalars['String'];
  productNumber: Scalars['String'];
};

export type SdsRetrieveInput = {
  brand: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
  productNumber: Scalars['String'];
};

export type SdsRetrieveResponse = {
  __typename?: 'SdsRetrieveResponse';
  locale: Scalars['String'];
  region: Scalars['String'];
  sds: Array<Maybe<SdsRetrieveData>>;
};

export type SdsSearchInput = {
  brand?: InputMaybe<Scalars['String']>;
  productNumber: Scalars['String'];
};

export type SdsSearchResponse = {
  __typename?: 'SdsSearchResponse';
  brands: Array<Maybe<Scalars['String']>>;
  locale: Scalars['String'];
  productNumber: Scalars['String'];
  region: Scalars['String'];
  sds: Array<Maybe<SdsData>>;
};

export type SearchResponseKicqStart = {
  __typename?: 'SearchResponseKicqStart';
  assays?: Maybe<Array<Maybe<Assay>>>;
  configurable_options?: Maybe<Array<Maybe<ConfigurableOption>>>;
  count?: Maybe<Scalars['Int']>;
  pricing?: Maybe<Pricing>;
  serverError?: Maybe<ServerError>;
  term?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SearchResponseKicqStartPrimers = {
  __typename?: 'SearchResponseKicqStartPrimers';
  count?: Maybe<Scalars['Int']>;
  pricing?: Maybe<Pricing>;
  results?: Maybe<Array<Maybe<Primer>>>;
  serverError?: Maybe<ServerError>;
  term?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SearchResponseSiRna = {
  __typename?: 'SearchResponseSiRNA';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<SiRnaResults>>>;
  serverError?: Maybe<ServerError>;
  term?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SearchResults = {
  facets: Array<Facet>;
  items: Array<SearchResultsItems>;
  metadata: SearchResultsMetadata;
};

export type SearchResultsItems = Gene | Paper | Product | Substance | TechnicalDocument;

export type SearchResultsMetadata = {
  __typename?: 'SearchResultsMetadata';
  itemCount?: Maybe<Scalars['Int']>;
  numPages: Scalars['Int'];
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  redirect?: Maybe<Scalars['String']>;
  setsCount?: Maybe<Scalars['Int']>;
  suggestedType?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum SearchSuggestionType {
  CasNumber = 'CAS_NUMBER',
  Category = 'CATEGORY',
  MaterialNumber = 'MATERIAL_NUMBER',
  Pinyin = 'PINYIN',
  ProductName = 'PRODUCT_NAME',
  ProductNumber = 'PRODUCT_NUMBER'
}

export type SelectedAssayInput = {
  customPremixSelection?: InputMaybe<Scalars['String']>;
  panelId?: InputMaybe<Scalars['String']>;
  panelName?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  researchAreas?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sampleType?: InputMaybe<Scalars['String']>;
  selectedAnalytes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  spaceSaverSelection?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Scalars['String']>;
};

export type SelectedDealerInput = {
  cartId: Scalars['ID'];
  selectedDealer?: InputMaybe<DealerInput>;
};

export type SellerContactContent = {
  __typename?: 'SellerContactContent';
  reasons?: Maybe<Array<Maybe<Reasons>>>;
};

export type SellerDocuments = {
  __typename?: 'SellerDocuments';
  mktBanner1: Scalars['String'];
  mktBanner2: Scalars['String'];
  mktBanner3: Scalars['String'];
  pdfPrivacyPolicy: Scalars['String'];
  pdfReturnPolicy: Scalars['String'];
  pdfTermsConditions: Scalars['String'];
};

export type SellerMessage = {
  body: Scalars['String'];
  company: Scalars['String'];
  country: Scalars['String'];
  customerId: Scalars['String'];
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  reasonCode: Scalars['String'];
  sellerId: Scalars['String'];
  subject: Scalars['String'];
};

export type SellerResponse = {
  __typename?: 'SellerResponse';
  city: Scalars['String'];
  civility: Scalars['String'];
  country: Scalars['String'];
  createdDate: Scalars['String'];
  description: Scalars['String'];
  documents: SellerDocuments;
  email: Scalars['String'];
  fax: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  logo: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  phoneSecondary: Scalars['String'];
  shopAdditionalFields: ShopAdditionalFields;
  state: Scalars['String'];
  street1: Scalars['String'];
  street2: Scalars['String'];
  updatedDate: Scalars['String'];
  webSite: Scalars['String'];
  zipCode: Scalars['String'];
};

export type SemiConfigurations = {
  __typename?: 'SemiConfigurations';
  controls?: Maybe<Array<Maybe<Controls>>>;
  count?: Maybe<Scalars['Int']>;
  reagents?: Maybe<Array<Maybe<Reagents>>>;
  requestDate?: Maybe<Scalars['String']>;
  responseDate?: Maybe<Scalars['String']>;
  serverError?: Maybe<ServerError>;
  term?: Maybe<Array<Maybe<Scalars['String']>>>;
  version?: Maybe<Scalars['String']>;
};

export type SemiConfiguratorAddToMiniCartInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  dealerId?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  sapNextEnabled?: InputMaybe<Scalars['Boolean']>;
  store?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
};

export type SemiConfiguratorMiniCartResponse = {
  __typename?: 'SemiConfiguratorMiniCartResponse';
  result?: Maybe<Scalars['String']>;
  serverError?: Maybe<ListError>;
};

export type SemiConfiguratorProductInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  cartId?: InputMaybe<Scalars['String']>;
  cartItemId?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  guestParticipantId?: InputMaybe<Scalars['String']>;
  isCSR?: InputMaybe<Scalars['Boolean']>;
  isCSRSupervisor?: InputMaybe<Scalars['Boolean']>;
  isRegUser?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  sapNextEnabled?: InputMaybe<Scalars['Boolean']>;
  store?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
};

export type SemiConfSaveToSessionInput = {
  count?: InputMaybe<Scalars['Int']>;
  pageNo?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['String']>;
  results?: InputMaybe<Array<InputMaybe<ResultsInput>>>;
  selectedItems?: InputMaybe<Array<InputMaybe<ResultsInput>>>;
  stepNo?: InputMaybe<Scalars['Int']>;
  term?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SemiConfSessionData = {
  __typename?: 'SemiConfSessionData';
  count?: Maybe<Scalars['Int']>;
  pageNo?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<Results>>>;
  selectedItems?: Maybe<Array<Maybe<Results>>>;
  serverError?: Maybe<ServerError>;
  stepNo?: Maybe<Scalars['Int']>;
  term?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SemiConfSubSetInfo = {
  __typename?: 'SemiConfSubSetInfo';
  brand?: Maybe<Scalars['String']>;
  checked?: Maybe<Scalars['Boolean']>;
  count?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  fuzzyEnd?: Maybe<Scalars['Int']>;
  fuzzyStart?: Maybe<Scalars['Int']>;
  geneId?: Maybe<Scalars['Int']>;
  geneSymbol?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  purification?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  refseqEnd?: Maybe<Scalars['Int']>;
  refseqId?: Maybe<Scalars['String']>;
  refseqStart?: Maybe<Scalars['Int']>;
  siRNAAntiSenseSeq?: Maybe<Scalars['String']>;
  siRNAId?: Maybe<Scalars['String']>;
  sirnaProdType?: Maybe<Scalars['String']>;
  siRNASenseSeq?: Maybe<Scalars['String']>;
  siRNATargetSeq?: Maybe<Scalars['String']>;
  siRNAType?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
};

export type SemiConfSubSetInfoInput = {
  brand?: InputMaybe<Scalars['String']>;
  checked?: InputMaybe<Scalars['Boolean']>;
  count?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
  currencySymbol?: InputMaybe<Scalars['String']>;
  fuzzyEnd?: InputMaybe<Scalars['Int']>;
  fuzzyStart?: InputMaybe<Scalars['Int']>;
  geneId?: InputMaybe<Scalars['Int']>;
  geneSymbol?: InputMaybe<Scalars['String']>;
  material?: InputMaybe<Scalars['String']>;
  method?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  purification?: InputMaybe<Scalars['String']>;
  ranking?: InputMaybe<Scalars['Int']>;
  refseqEnd?: InputMaybe<Scalars['Int']>;
  refseqId?: InputMaybe<Scalars['String']>;
  refseqStart?: InputMaybe<Scalars['Int']>;
  siRNAAntiSenseSeq?: InputMaybe<Scalars['String']>;
  siRNAId?: InputMaybe<Scalars['String']>;
  sirnaProdType?: InputMaybe<Scalars['String']>;
  siRNASenseSeq?: InputMaybe<Scalars['String']>;
  siRNATargetSeq?: InputMaybe<Scalars['String']>;
  siRNAType?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Scalars['String']>;
};

export type SendNotificationResult = {
  __typename?: 'SendNotificationResult';
  result?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ServerError = {
  __typename?: 'ServerError';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type SessionInput = {
  additionalCarrierProtein?: InputMaybe<Scalars['String']>;
  additionalComments?: InputMaybe<Scalars['String']>;
  additionalServices?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  amount?: InputMaybe<Scalars['String']>;
  animalType?: InputMaybe<Scalars['String']>;
  antisenceBaseCount?: InputMaybe<Scalars['Int']>;
  antisenceFiveMod?: InputMaybe<Scalars['String']>;
  antisenceName?: InputMaybe<Scalars['String']>;
  antisenceSequence?: InputMaybe<Scalars['String']>;
  antisenceThreeMod?: InputMaybe<Scalars['String']>;
  applyToAll?: InputMaybe<Scalars['Boolean']>;
  autoGenerateOligo?: InputMaybe<Scalars['Boolean']>;
  autoValidation?: InputMaybe<Scalars['String']>;
  baseCount?: InputMaybe<Scalars['Int']>;
  carrierProtein?: InputMaybe<Scalars['String']>;
  cartId?: InputMaybe<Scalars['String']>;
  columnConstruction?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  concentration?: InputMaybe<Scalars['String']>;
  conjugation?: InputMaybe<Scalars['String']>;
  conjugationAmount?: InputMaybe<Scalars['String']>;
  conjugationChemistry?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  crossContamination?: InputMaybe<Scalars['String']>;
  crRnaType?: InputMaybe<Scalars['String']>;
  cterminalMod?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  currencySymbol?: InputMaybe<Scalars['String']>;
  customerAntisenseOverHang?: InputMaybe<Scalars['String']>;
  customerOverhang?: InputMaybe<Scalars['String']>;
  customPeptideType?: InputMaybe<Scalars['String']>;
  elisaAnalysis?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  epsilon?: InputMaybe<Scalars['Float']>;
  firstName?: InputMaybe<Scalars['String']>;
  fiveMod?: InputMaybe<Scalars['String']>;
  fiveModCategory?: InputMaybe<Scalars['String']>;
  flag?: InputMaybe<Scalars['Int']>;
  format?: InputMaybe<Scalars['String']>;
  fullSOligoBackbone?: InputMaybe<Scalars['Boolean']>;
  gcContent?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  immunizationTerm?: InputMaybe<Scalars['String']>;
  internalMod?: InputMaybe<Scalars['String']>;
  internalModCategory?: InputMaybe<Scalars['String']>;
  isCSR?: InputMaybe<Scalars['Boolean']>;
  isCSRSupervisor?: InputMaybe<Scalars['Boolean']>;
  isLabelledAminoAcid?: InputMaybe<Scalars['Boolean']>;
  isLNABaseEnable?: InputMaybe<Scalars['Boolean']>;
  isoCode?: InputMaybe<Scalars['Boolean']>;
  isOverridden?: InputMaybe<Scalars['Boolean']>;
  itemId?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  listAntiseraPeptideModel?: InputMaybe<Array<InputMaybe<AntiseraPeptideModel>>>;
  listError?: InputMaybe<Array<InputMaybe<ListErrorInput>>>;
  listProductPackage?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listShippingOptions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listWarning?: InputMaybe<Array<InputMaybe<ListWarningInput>>>;
  material?: InputMaybe<Scalars['String']>;
  meltingTemparature?: InputMaybe<Scalars['String']>;
  minimumYield?: InputMaybe<Scalars['String']>;
  molecularWeight?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  noOfColumns?: InputMaybe<Scalars['String']>;
  nterminalMod?: InputMaybe<Scalars['String']>;
  numberOfAnimals?: InputMaybe<Scalars['String']>;
  numberOfPeptides?: InputMaybe<Scalars['String']>;
  oligoId?: InputMaybe<Scalars['Int']>;
  orderItemId?: InputMaybe<Scalars['String']>;
  orgName?: InputMaybe<Scalars['String']>;
  otherValue?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  peptide?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  plateType?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  priceHeaderError?: InputMaybe<Scalars['String']>;
  primerDimer?: InputMaybe<Scalars['String']>;
  probeType?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  projectName?: InputMaybe<Scalars['String']>;
  projectType?: InputMaybe<Scalars['String']>;
  purification?: InputMaybe<Scalars['String']>;
  purificationAntisera?: InputMaybe<Scalars['String']>;
  purificationBuffer?: InputMaybe<Scalars['String']>;
  purificationColQuantity?: InputMaybe<Scalars['String']>;
  purificationColumn?: InputMaybe<Scalars['String']>;
  purificationGlycAddition?: InputMaybe<Scalars['String']>;
  purificationOption?: InputMaybe<Scalars['String']>;
  purificationProcAddition?: InputMaybe<Scalars['String']>;
  purificationType?: InputMaybe<Scalars['String']>;
  purityLevel?: InputMaybe<Scalars['String']>;
  quantitation?: InputMaybe<Scalars['String']>;
  scale?: InputMaybe<Scalars['String']>;
  secondaryStructure?: InputMaybe<Scalars['String']>;
  senseNameCheck?: InputMaybe<Scalars['Boolean']>;
  sequence?: InputMaybe<Scalars['String']>;
  sequenceLocation?: InputMaybe<Scalars['String']>;
  shipping?: InputMaybe<Scalars['String']>;
  threeMod?: InputMaybe<Scalars['String']>;
  threeModCategory?: InputMaybe<Scalars['String']>;
  tubes?: InputMaybe<Scalars['String']>;
};

export type SessionResponse = {
  __typename?: 'SessionResponse';
  result?: Maybe<Scalars['Int']>;
};

export type SessionResponseResults = {
  __typename?: 'sessionResponseResults';
  countryCode?: Maybe<Scalars['String']>;
  exons?: Maybe<Scalars['String']>;
  geneId?: Maybe<Scalars['String']>;
  geneSymbol?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  materialNumber?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  oligoId?: Maybe<Scalars['Int']>;
  packageType?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  primerPairId?: Maybe<Scalars['String']>;
  primerSeqFwd?: Maybe<Scalars['String']>;
  primerSeqRev?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  purification?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  refSeqId?: Maybe<Scalars['String']>;
  scale?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  technicalSequence?: Maybe<Scalars['String']>;
};

export type SessionResults = {
  countryCode?: InputMaybe<Scalars['String']>;
  exons?: InputMaybe<Scalars['String']>;
  geneId?: InputMaybe<Scalars['String']>;
  geneSymbol?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isChecked?: InputMaybe<Scalars['Boolean']>;
  materialNumber?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['String']>;
  oligoId?: InputMaybe<Scalars['Int']>;
  packageType?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  primerPairId?: InputMaybe<Scalars['String']>;
  primerSeqFwd?: InputMaybe<Scalars['String']>;
  primerSeqRev?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  purification?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['String']>;
  rank?: InputMaybe<Scalars['String']>;
  refSeqId?: InputMaybe<Scalars['String']>;
  scale?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Scalars['String']>;
  technicalSequence?: InputMaybe<Scalars['String']>;
};

export type SharedList = {
  __typename?: 'SharedList';
  coOwners?: Maybe<Array<Scalars['String']>>;
  createdDate: Scalars['Date'];
  deadline?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  invitations?: Maybe<Array<Scalars['String']>>;
  lastUpdatedDate: Scalars['Date'];
  listId: Scalars['ID'];
  members?: Maybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  ownerId: Scalars['ID'];
  ownerName: Scalars['String'];
};

export type SharedListDetail = {
  __typename?: 'SharedListDetail';
  coOwners?: Maybe<Array<Scalars['String']>>;
  createdDate: Scalars['Date'];
  currency?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  displaySubmitQuoteButton?: Maybe<Scalars['Boolean']>;
  invitations?: Maybe<Array<Scalars['String']>>;
  lastUpdatedDate: Scalars['Date'];
  listId: Scalars['ID'];
  membersDetail?: Maybe<Array<SharedListMember>>;
  membersDone?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  ownerDetail: SharedListMember;
  ownerId: Scalars['ID'];
  reminderFrequencyDays?: Maybe<Array<Scalars['Int']>>;
  sharedListItems: Array<SharedListItem>;
};

export type SharedListItem = {
  __typename?: 'SharedListItem';
  availabilityMessages?: Maybe<Array<MaterialAvailability>>;
  brand?: Maybe<Scalars['String']>;
  configurationDetail?: Maybe<ProductConfigurationDetail>;
  configurationId?: Maybe<Scalars['String']>;
  createdBy: Scalars['ID'];
  createdByName: Scalars['String'];
  createdDate: Scalars['Date'];
  currency?: Maybe<Scalars['String']>;
  hidePriceMessageKey?: Maybe<Scalars['String']>;
  itemNote?: Maybe<Scalars['String']>;
  lastUpdatedBy: Scalars['ID'];
  lastUpdatedDate: Scalars['Date'];
  listItemId: Scalars['ID'];
  listPrice?: Maybe<Scalars['Float']>;
  material: Material;
  materialDescription?: Maybe<Scalars['String']>;
  materialName?: Maybe<Scalars['String']>;
  materialNumber: Scalars['String'];
  netPrice?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  productConfiguration?: Maybe<Scalars['JSONObject']>;
  quantity: Scalars['Int'];
  type: CartItemType;
};

export type SharedListItemInput = {
  brand?: InputMaybe<Scalars['String']>;
  configurationDetail?: InputMaybe<Scalars['JSONObject']>;
  configurationId?: InputMaybe<Scalars['String']>;
  itemNote?: InputMaybe<Scalars['String']>;
  materialDescription?: InputMaybe<Scalars['String']>;
  materialId?: InputMaybe<Scalars['String']>;
  materialName?: InputMaybe<Scalars['String']>;
  materialNumber: Scalars['String'];
  productConfiguration?: InputMaybe<Scalars['JSONObject']>;
  quantity: Scalars['Int'];
  type?: InputMaybe<CartItemType>;
};

export type SharedListMember = {
  __typename?: 'SharedListMember';
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SharedListsPayload = {
  __typename?: 'SharedListsPayload';
  count: Scalars['Int'];
  sharedLists: Array<SharedList>;
};

export type ShareProductDetailsInput = {
  brand: Scalars['String'];
  catalogType: CatalogType;
  fromEmail?: InputMaybe<Scalars['String']>;
  productNumber: Scalars['String'];
  toEmail: Array<Scalars['String']>;
};

export type ShipmentTrackingResponse = {
  __typename?: 'ShipmentTrackingResponse';
  deliveryDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  estimatedDeliveryDate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ShippingPreference = {
  __typename?: 'ShippingPreference';
  participantId: Scalars['String'];
  participantShippingMethods?: Maybe<Array<Maybe<ParticipantShippingMethods>>>;
  shippingMethod?: Maybe<Scalars['String']>;
};

export type ShopAdditionalFields = {
  __typename?: 'ShopAdditionalFields';
  colDesc1: Scalars['String'];
  colDesc2: Scalars['String'];
  colDesc3: Scalars['String'];
  colHeading1: Scalars['String'];
  colHeading2: Scalars['String'];
  colHeading3: Scalars['String'];
  contentPanel: Scalars['String'];
  csDesc1: Scalars['String'];
  csDesc2: Scalars['String'];
  csTitle1: Scalars['String'];
  csTitle2: Scalars['String'];
  customerServiceEmail: Scalars['String'];
  customerServicePhone: Scalars['String'];
};

export type ShrnaAddToCartInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  cloneInformation?: InputMaybe<Array<InputMaybe<ShrnaCloneInformationInput>>>;
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  physicalFormat?: InputMaybe<PhysicalFormatInput>;
  sapNextEnabled?: InputMaybe<Scalars['Boolean']>;
  store?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
};

export type ShrnaAddToCartResponse = {
  __typename?: 'ShrnaAddToCartResponse';
  result?: Maybe<Scalars['String']>;
  serverError?: Maybe<ListError>;
};

export type ShrnaCloneInformationInput = {
  brand?: InputMaybe<Scalars['String']>;
  cellType?: InputMaybe<Scalars['String']>;
  cloneName?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  currencySymbol?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  geneId?: InputMaybe<Scalars['String']>;
  geneSymbol?: InputMaybe<Scalars['String']>;
  knockdown?: InputMaybe<Scalars['String']>;
  material?: InputMaybe<Scalars['String']>;
  method?: InputMaybe<Scalars['String']>;
  oligoSeq?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  refSeq?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  searchTrcVersion?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Scalars['String']>;
  synonyms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targetSeq?: InputMaybe<Scalars['String']>;
  trcId?: InputMaybe<Scalars['String']>;
  trcVersion?: InputMaybe<Scalars['String']>;
  validated?: InputMaybe<Scalars['String']>;
};

export type ShrnaDropDownListInput = {
  aliquot?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['String']>;
  countryCode: Scalars['String'];
  physicalMaterial?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<Scalars['String']>;
  titer?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['String']>;
};

export type ShrnaPhysicalMaterial = {
  __typename?: 'ShrnaPhysicalMaterial';
  DNA?: Maybe<Scalars['String']>;
  GLYCEROL?: Maybe<Scalars['String']>;
  noSelection?: Maybe<Scalars['String']>;
  VIRUS?: Maybe<Scalars['String']>;
};

export type ShrnaPriceCall = {
  __typename?: 'ShrnaPriceCall';
  serverError?: Maybe<ServerError>;
  shrnaResult?: Maybe<Array<Maybe<ShrnaPriceCallResponse>>>;
};

export type ShrnaPriceCallInput = {
  cloneInformation?: InputMaybe<Array<InputMaybe<ShrnaCloneInformationInput>>>;
  country?: InputMaybe<Scalars['String']>;
  physicalFormat?: InputMaybe<PhysicalFormatInput>;
};

export type ShrnaPriceCallResponse = {
  __typename?: 'ShrnaPriceCallResponse';
  brand?: Maybe<Scalars['String']>;
  cellType?: Maybe<Scalars['String']>;
  cloneName?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  geneId?: Maybe<Scalars['String']>;
  geneSymbol?: Maybe<Scalars['String']>;
  knockdown?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  oligoSeq?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  refSeq?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  searchTrcVersion?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  synonyms?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetSeq?: Maybe<Scalars['String']>;
  trcId?: Maybe<Scalars['String']>;
  trcVersion?: Maybe<Scalars['String']>;
  validated?: Maybe<Scalars['String']>;
};

export type ShrnaProductSearchTermInput = {
  count?: InputMaybe<Scalars['Int']>;
  facet?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<Scalars['String']>;
  term: Scalars['String'];
};

export type ShrnaSemiConfigurations = {
  __typename?: 'ShrnaSemiConfigurations';
  cloneInformation?: Maybe<Array<Maybe<CloneInformation>>>;
  count?: Maybe<Scalars['Int']>;
  requestDate?: Maybe<Scalars['String']>;
  responseDate?: Maybe<Scalars['String']>;
  serverError?: Maybe<ServerError>;
  term?: Maybe<Array<Maybe<Scalars['String']>>>;
  version?: Maybe<Scalars['String']>;
};

export type ShrnaSpecifications = {
  __typename?: 'ShrnaSpecifications';
  aliquot?: Maybe<Array<Maybe<Aliquot>>>;
  amount?: Maybe<Array<Maybe<Amount>>>;
  physicalMaterial?: Maybe<Array<Maybe<ShrnaPhysicalMaterial>>>;
  serverError?: Maybe<ServerError>;
  titer?: Maybe<Array<Maybe<Titer>>>;
  vector?: Maybe<Array<Maybe<ShrnaVector>>>;
  volume?: Maybe<Array<Maybe<Volume>>>;
};

export type ShrnaVector = {
  __typename?: 'ShrnaVector';
  CMVNeo?: Maybe<Scalars['String']>;
  CMVtGFP?: Maybe<Scalars['String']>;
  hPGKNeo?: Maybe<Scalars['String']>;
  hPGKNeoCMVtGFP?: Maybe<Scalars['String']>;
  hPGKPuroCMVtGFP?: Maybe<Scalars['String']>;
  pLKO1?: Maybe<Scalars['String']>;
};

export type SimpleAntiseraSessionData = {
  __typename?: 'SimpleAntiseraSessionData';
  additionalServices?: Maybe<Array<Maybe<Scalars['String']>>>;
  animalType?: Maybe<Scalars['String']>;
  cartId?: Maybe<Scalars['String']>;
  colConstructionFee?: Maybe<Scalars['String']>;
  columnConstruction?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  elisaAnalysis?: Maybe<Scalars['String']>;
  elisaFee?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  immunizationTerm?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['String']>;
  listAntigenForColumn?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  listAntiseraPeptideModel?: Maybe<Array<Maybe<AntiseraPeptideModelResponse>>>;
  listError?: Maybe<Array<Maybe<ListError>>>;
  name?: Maybe<Scalars['String']>;
  noOfColumns?: Maybe<Scalars['String']>;
  numberOfAnimals?: Maybe<Scalars['String']>;
  numberOfPeptides?: Maybe<Scalars['String']>;
  oligoId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  projectBaseFee?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  purificationColumn?: Maybe<Scalars['String']>;
  purificationFee?: Maybe<Scalars['String']>;
  purificationOption?: Maybe<Scalars['String']>;
  purificationType?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['String']>;
};

export type SimpleAntiseraSessionInput = {
  additionalServices?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adsorptionFee?: InputMaybe<Scalars['String']>;
  animalType?: InputMaybe<Scalars['String']>;
  cartId?: InputMaybe<Scalars['String']>;
  colConstructionFee?: InputMaybe<Scalars['String']>;
  columnConstruction?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  currencySymbol?: InputMaybe<Scalars['String']>;
  elisaAnalysis?: InputMaybe<Scalars['String']>;
  elisaFee?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  immunizationTerm?: InputMaybe<Scalars['String']>;
  itemId?: InputMaybe<Scalars['String']>;
  listAntigenForColumn?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['String']>>>>>;
  listAntiseraPeptideModel?: InputMaybe<Array<InputMaybe<AntiseraPeptideModel>>>;
  listError?: InputMaybe<Array<InputMaybe<ListErrorInput>>>;
  name?: InputMaybe<Scalars['String']>;
  noOfColumns?: InputMaybe<Scalars['String']>;
  numberOfAnimals?: InputMaybe<Scalars['String']>;
  numberOfPeptides?: InputMaybe<Scalars['String']>;
  oligoId?: InputMaybe<Scalars['Int']>;
  peptide?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  projectBaseFee?: InputMaybe<Scalars['String']>;
  projectName?: InputMaybe<Scalars['String']>;
  purificationColumn?: InputMaybe<Scalars['String']>;
  purificationFee?: InputMaybe<Scalars['String']>;
  purificationOption?: InputMaybe<Scalars['String']>;
  purificationType?: InputMaybe<Scalars['String']>;
  subtotal?: InputMaybe<Scalars['String']>;
};

export type SimpleAntiseraSessionResponse = {
  __typename?: 'SimpleAntiseraSessionResponse';
  simpleAntiseraData?: Maybe<Array<Maybe<SimpleAntiseraSessionData>>>;
};

export type SimpleAntiseraTotalValidationResponse = {
  __typename?: 'SimpleAntiseraTotalValidationResponse';
  listAntiseraPeptideModel?: Maybe<Array<Maybe<AntiseraPeptideModelResponse>>>;
  listError?: Maybe<Array<Maybe<ListError>>>;
};

export type SirnaAddToCartInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['String']>;
  results?: InputMaybe<Array<InputMaybe<SirnaAddToCartResults>>>;
  sapNextEnabled?: InputMaybe<Scalars['Boolean']>;
  store?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
};

export type SirnaAddToCartResponse = {
  __typename?: 'SirnaAddToCartResponse';
  result?: Maybe<Scalars['String']>;
  serverError?: Maybe<ListError>;
};

export type SirnaAddToCartResults = {
  fuzzyEnd?: InputMaybe<Scalars['String']>;
  fuzzyStart?: InputMaybe<Scalars['String']>;
  geneId?: InputMaybe<Scalars['String']>;
  geneSymbol?: InputMaybe<Scalars['String']>;
  method?: InputMaybe<Scalars['String']>;
  purification?: InputMaybe<Scalars['String']>;
  ranking?: InputMaybe<Scalars['String']>;
  refseqEnd?: InputMaybe<Scalars['String']>;
  refseqId?: InputMaybe<Scalars['String']>;
  refseqStart?: InputMaybe<Scalars['String']>;
  siRNAAntiSenseSeq?: InputMaybe<Scalars['String']>;
  siRNAId?: InputMaybe<Scalars['String']>;
  sirnaProdType?: InputMaybe<Scalars['String']>;
  siRNASenseSeq?: InputMaybe<Scalars['String']>;
  siRNATargetSeq?: InputMaybe<Scalars['String']>;
  siRNAType?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Scalars['String']>;
};

export type SiRnaResults = {
  __typename?: 'SiRNAResults';
  refseqId?: Maybe<Scalars['String']>;
  siRNAType?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  subSetInfo?: Maybe<Array<Maybe<SirnaSubSetInfo>>>;
};

export type SirnaSubSetInfo = {
  __typename?: 'SirnaSubSetInfo';
  brand?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  fuzzyEnd?: Maybe<Scalars['Int']>;
  fuzzyStart?: Maybe<Scalars['Int']>;
  geneId?: Maybe<Scalars['Int']>;
  geneSymbol?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  refseqEnd?: Maybe<Scalars['Int']>;
  refseqId?: Maybe<Scalars['String']>;
  refseqStart?: Maybe<Scalars['Int']>;
  siRNAAntiSenseSeq?: Maybe<Scalars['String']>;
  siRNAId?: Maybe<Scalars['String']>;
  sirnaProdType?: Maybe<Scalars['String']>;
  siRNASenseSeq?: Maybe<Scalars['String']>;
  siRNATargetSeq?: Maybe<Scalars['String']>;
  siRNAType?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
};

export type SitewideMessage = {
  __typename?: 'SitewideMessage';
  id: Scalars['ID'];
  priority: Priority;
  text: Scalars['String'];
};

export type SitewideMessageInput = {
  loggedIn: Scalars['Boolean'];
  path: Scalars['String'];
};

export type SkuItem = {
  __typename?: 'SKUItem';
  description?: Maybe<Scalars['String']>;
  netPrice?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SkuItemInput = {
  description?: InputMaybe<Scalars['String']>;
  netPrice?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Int']>;
  sku?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type SoftwareLicense = {
  __typename?: 'SoftwareLicense';
  createdDate: Scalars['String'];
  expiryDate?: Maybe<Scalars['String']>;
  isTrialLicense: Scalars['Boolean'];
  licenseKey: Scalars['String'];
  orderId?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  participantId: Scalars['String'];
  productName?: Maybe<Scalars['String']>;
  productNumber?: Maybe<Scalars['String']>;
  serialNumber: Scalars['String'];
  storeId?: Maybe<Scalars['Int']>;
};

export type SoftwareLicensesResponse = {
  __typename?: 'SoftwareLicensesResponse';
  purchasedLicenses: Array<SoftwareLicense>;
  trialLicenses: Array<SoftwareLicense>;
};

export type SoldToPartner = {
  __typename?: 'SoldToPartner';
  billTo: Scalars['String'];
  contact?: Maybe<Scalars['String']>;
  e1Partner?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  participantId: Scalars['String'];
  payer: Scalars['String'];
  salesOrg?: Maybe<Scalars['String']>;
  shipTo: Scalars['String'];
  soldTo: Scalars['String'];
  storeId: Scalars['Int'];
};

export enum Sort {
  Genenameasc = 'genenameasc',
  Genenamedesc = 'genenamedesc',
  Molwtasc = 'molwtasc',
  Molwtdesc = 'molwtdesc',
  Nameasc = 'nameasc',
  Namedesc = 'namedesc',
  Relevance = 'relevance',
  StorageDateDesc = 'storageDateDesc'
}

export type Species = {
  __typename?: 'Species';
  FnCas9?: Maybe<Scalars['String']>;
  SpCas9?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['String']>;
};

export type SpeciesItem = {
  __typename?: 'SpeciesItem';
  researchAreasList: Array<Scalars['String']>;
  species: Scalars['String'];
};

export type SpecificationModifications = {
  __typename?: 'SpecificationModifications';
  displayName: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type SpecificationResponse = {
  __typename?: 'SpecificationResponse';
  brandName: Scalars['String'];
  lineNumber?: Maybe<Scalars['Int']>;
  productNumber: Scalars['Int'];
  source?: Maybe<Scalars['String']>;
  specCd?: Maybe<Scalars['Int']>;
  specId?: Maybe<Scalars['Int']>;
  testSpecTable?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Specifications = {
  __typename?: 'Specifications';
  categoryDisplayName: Scalars['String'];
  categoryName: Scalars['String'];
  categoryType?: Maybe<Scalars['String']>;
  specificationModifications?: Maybe<Array<SpecificationModifications>>;
};

export type SpecificationSheet = {
  __typename?: 'SpecificationSheet';
  documentType?: Maybe<Scalars['String']>;
  materialId?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SpecificationsInput = {
  amount?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  concentration?: InputMaybe<Scalars['String']>;
  daughterPlateNumber?: InputMaybe<Scalars['String']>;
  fiveMod?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  isAmountChanged?: InputMaybe<Scalars['Boolean']>;
  isamountValidate?: InputMaybe<Scalars['Boolean']>;
  isConcentrationChanged?: InputMaybe<Scalars['Boolean']>;
  isConcentrationValidate?: InputMaybe<Scalars['Boolean']>;
  isOverriden?: InputMaybe<Scalars['Boolean']>;
  isVolumeChanged?: InputMaybe<Scalars['Boolean']>;
  listScaleFromRules?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  maxAmount?: InputMaybe<Scalars['Float']>;
  maxConcentration?: InputMaybe<Scalars['Float']>;
  maxVolume?: InputMaybe<Scalars['Float']>;
  plateType?: InputMaybe<Scalars['String']>;
  purification?: InputMaybe<Scalars['String']>;
  scale?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['String']>;
  threeMod?: InputMaybe<Scalars['String']>;
  wellType?: InputMaybe<Scalars['Int']>;
};

export type SpecificationsResponse = {
  __typename?: 'SpecificationsResponse';
  calculatedValues?: Maybe<CalculatedValues>;
  errorValues?: Maybe<Array<Maybe<ErrorValues>>>;
};

export type SpecSheetInput = {
  brand?: InputMaybe<Scalars['String']>;
  control?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  documentType?: InputMaybe<Scalars['String']>;
  lotNumber?: InputMaybe<Scalars['String']>;
  materialId?: InputMaybe<Scalars['String']>;
  productNumber?: InputMaybe<Scalars['String']>;
  useCount?: InputMaybe<Scalars['Boolean']>;
  useFilters?: InputMaybe<Scalars['Boolean']>;
};

export type Standard = {
  additionalComments?: InputMaybe<Scalars['String']>;
  antisenceBaseCount?: InputMaybe<Scalars['String']>;
  antisenceName?: InputMaybe<Scalars['String']>;
  antisenceSequence?: InputMaybe<Scalars['String']>;
  applyToAll?: InputMaybe<Scalars['Boolean']>;
  autoGenerateOligo?: InputMaybe<Scalars['Boolean']>;
  autoValidation?: InputMaybe<Scalars['String']>;
  baseCount?: InputMaybe<Scalars['String']>;
  carrierProtein?: InputMaybe<Scalars['String']>;
  cartId?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  concentration?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  crossContamination?: InputMaybe<Scalars['String']>;
  crRnaType?: InputMaybe<Scalars['String']>;
  cterminalMod?: InputMaybe<Scalars['String']>;
  customerAntisenseOverHang?: InputMaybe<Scalars['String']>;
  customerOverhang?: InputMaybe<Scalars['String']>;
  customPeptideType?: InputMaybe<Scalars['String']>;
  fiveMod?: InputMaybe<Scalars['String']>;
  fiveModCategory?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  fullSOligoBackbone?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internalModCategory?: InputMaybe<Scalars['String']>;
  isCSR?: InputMaybe<Scalars['Boolean']>;
  isCSRSupervisor?: InputMaybe<Scalars['Boolean']>;
  isLabelledAminoAcid?: InputMaybe<Scalars['Boolean']>;
  isoCode?: InputMaybe<Scalars['Boolean']>;
  isOverridden?: InputMaybe<Scalars['Boolean']>;
  itemId?: InputMaybe<Scalars['String']>;
  listProductPackage?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listShippingOptions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  material?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nterminalMod?: InputMaybe<Scalars['String']>;
  oligoId?: InputMaybe<Scalars['Int']>;
  orderItemId?: InputMaybe<Scalars['String']>;
  otherValue?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  plateType?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  purification?: InputMaybe<Scalars['String']>;
  scale?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['String']>;
  sequenceLocation?: InputMaybe<Scalars['String']>;
  threeMod?: InputMaybe<Scalars['String']>;
  threeModCategory?: InputMaybe<Scalars['String']>;
  tubes?: InputMaybe<Scalars['String']>;
};

export type StockAvailabilityInput = {
  product: Scalars['String'];
};

export type StockAvailabilityResults = {
  __typename?: 'StockAvailabilityResults';
  errorMessage?: Maybe<Scalars['String']>;
  items: Array<Maybe<StockItem>>;
};

export type StockItem = {
  __typename?: 'StockItem';
  brand: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  materials: Array<StockItemMaterial>;
  product: Scalars['String'];
};

export type StockItemMaterial = {
  __typename?: 'StockItemMaterial';
  description: Scalars['String'];
  inventoryDetails: Array<InventoryDetail>;
  materialNumber: Scalars['String'];
};

export type Structure = {
  __typename?: 'Structure';
  crRNA?: Maybe<Scalars['String']>;
  sgRNA?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['String']>;
};

export type StructureSearchFilters = {
  cutoff?: InputMaybe<Scalars['Int']>;
  maxhits?: InputMaybe<Scalars['Int']>;
  stereo?: InputMaybe<Scalars['Boolean']>;
};

export enum StructureSearchFormat {
  Molfile = 'molfile',
  Smarts = 'smarts',
  Smiles = 'smiles'
}

export type StructureSearchFormattedInput = {
  catalogType?: InputMaybe<CatalogType>;
  facets?: InputMaybe<Array<FacetInput>>;
  filters?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  format: StructureSearchFormat;
  pagination?: Pagination;
  sort?: InputMaybe<Sort>;
  term: Scalars['String'];
  type: Array<StructureSearchType>;
};

export type StructureSearchInput = {
  catalogType?: InputMaybe<CatalogType>;
  facets?: InputMaybe<Array<FacetInput>>;
  filters?: InputMaybe<StructureSearchFilters>;
  format: StructureSearchFormat;
  pagination?: Pagination;
  sort?: InputMaybe<Sort>;
  term: Scalars['String'];
  type: Array<StructureSearchType>;
};

export type StructureSearchResults = {
  __typename?: 'StructureSearchResults';
  facets: Array<Facet>;
  items: Array<Substance>;
  metadata: SearchResultsMetadata;
};

export enum StructureSearchType {
  Exact = 'exact',
  Fragment = 'fragment',
  Identity = 'identity',
  Similarity = 'similarity',
  Substructure = 'substructure'
}

export type SubItemPriceInputSiRna = {
  countryCode?: InputMaybe<Scalars['String']>;
  subSetItems?: InputMaybe<Array<InputMaybe<SubItemSiRnaArray>>>;
};

export type SubItemSiRnaArray = {
  purification?: InputMaybe<Scalars['String']>;
  siRNAId?: InputMaybe<Scalars['String']>;
  siRNAType?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['String']>;
};

export type SubmitFullRegistrationResponse = {
  __typename?: 'SubmitFullRegistrationResponse';
  participantId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SubSetInfo = {
  __typename?: 'SubSetInfo';
  geneId?: Maybe<Scalars['String']>;
  geneSymbol?: Maybe<Scalars['String']>;
  matchType?: Maybe<Scalars['String']>;
  refSeqId?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SubSetInfoInput = {
  geneId?: InputMaybe<Scalars['String']>;
  geneSymbol?: InputMaybe<Scalars['String']>;
  matchType?: InputMaybe<Scalars['String']>;
  refSeqId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SubsetItemPrice = {
  __typename?: 'SubsetItemPrice';
  result?: Maybe<Array<Maybe<SubsetPricingInfo>>>;
};

export type SubsetPricingInfo = {
  __typename?: 'SubsetPricingInfo';
  checked?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  purification?: Maybe<Scalars['String']>;
  siRNAId?: Maybe<Scalars['String']>;
  siRNAType?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
};

export type Substance = {
  __typename?: 'Substance';
  _id: Scalars['ID'];
  aliases: Array<Alias>;
  attributeList: Array<KeyValue>;
  casNumber?: Maybe<Scalars['String']>;
  empiricalFormula?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images: Array<CatalogImage>;
  key: Scalars['String'];
  keyAttributes?: Maybe<Array<ProductAttribute>>;
  legalName?: Maybe<Scalars['String']>;
  legalNameSuffix?: Maybe<Scalars['String']>;
  linearFormula?: Maybe<Scalars['String']>;
  molecularWeight?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  products: Array<Product>;
  synonyms: Array<Scalars['String']>;
};

export type Suggestion = {
  __typename?: 'Suggestion';
  brandKey?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  productKey?: Maybe<Scalars['String']>;
  substanceKey?: Maybe<Scalars['String']>;
};

export type SupplementaryMessage = {
  __typename?: 'SupplementaryMessage';
  messageKey?: Maybe<Scalars['String']>;
  messageValue?: Maybe<Scalars['String']>;
  messageVariable1?: Maybe<Scalars['String']>;
  messageVariable2?: Maybe<Scalars['String']>;
};

export type SymbolInput = {
  symbol: Scalars['String'];
};

export type TechnicalDocument = {
  __typename?: 'TechnicalDocument';
  documentType: Scalars['String'];
  id: Scalars['ID'];
  text: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type TermConditionItem = {
  __typename?: 'termConditionItem';
  agree: Scalars['Boolean'];
  termKey: Scalars['String'];
  termUrl: Scalars['String'];
};

export type TermConditionResponse = {
  __typename?: 'TermConditionResponse';
  requireTermConditionUpdate: Scalars['Boolean'];
  terms: Array<Maybe<TermConditionItem>>;
};

export type TermsCount = {
  __typename?: 'TermsCount';
  term?: Maybe<Scalars['String']>;
};

export type TestSpec = {
  __typename?: 'TestSpec';
  name: Scalars['String'];
  result?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ThirdPartyTokenInput = {
  auth_token?: InputMaybe<Scalars['String']>;
  client_id: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  redirect_uri: Scalars['String'];
  response_type: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type ThirdPartyTokenResponse = {
  __typename?: 'ThirdPartyTokenResponse';
  client_name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  error_description?: Maybe<Scalars['String']>;
  response_status_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type Titer = {
  __typename?: 'Titer';
  powerof6?: Maybe<Scalars['String']>;
  powerof7?: Maybe<Scalars['String']>;
  powerof8?: Maybe<Scalars['String']>;
  powerof9?: Maybe<Scalars['String']>;
};

export type TrackingTracesDetail = {
  __typename?: 'TrackingTracesDetail';
  acceptStation: Scalars['String'];
  acceptTime: Scalars['String'];
  action: Scalars['String'];
  location: Scalars['String'];
};

export type TransactionHistory = {
  __typename?: 'TransactionHistory';
  amount: Scalars['Float'];
  date: Scalars['Date'];
  displayAmtInParenthesis: Scalars['Boolean'];
  orderNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  transaction?: Maybe<Scalars['String']>;
};

export type TubeDataFromSession = {
  __typename?: 'TubeDataFromSession';
  data: Array<TubeSessionData>;
};

export type TubeEditInput = {
  cartId?: InputMaybe<Scalars['String']>;
  cartItemId?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<Scalars['String']>;
};

export type TubeListError = {
  __typename?: 'TubeListError';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type TubePriceCall = {
  __typename?: 'TubePriceCall';
  result?: Maybe<Array<Maybe<RuleValidateResponse>>>;
};

export type TubeRuleValidateResponse = {
  __typename?: 'TubeRuleValidateResponse';
  additionalServices?: Maybe<Array<Scalars['String']>>;
  applyToAll?: Maybe<Scalars['Boolean']>;
  baseCount?: Maybe<Scalars['Int']>;
  brand?: Maybe<Scalars['String']>;
  cartId?: Maybe<Scalars['String']>;
  concentration?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  epsilon?: Maybe<Scalars['Float']>;
  fiveMod?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  fullSOligoBackbone?: Maybe<Scalars['Boolean']>;
  gcContent?: Maybe<Scalars['String']>;
  internalMod?: Maybe<Scalars['String']>;
  isLNABaseEnable?: Maybe<Scalars['Boolean']>;
  isOverridden?: Maybe<Scalars['Boolean']>;
  itemId?: Maybe<Scalars['String']>;
  listAdditionalCarrierProtein?: Maybe<Array<Scalars['String']>>;
  listAdditionalServices?: Maybe<Array<Scalars['String']>>;
  listAmount?: Maybe<Array<Scalars['String']>>;
  listConcentration?: Maybe<Array<Scalars['String']>>;
  listConjugation?: Maybe<Array<Scalars['String']>>;
  listConjugationAmount?: Maybe<Array<Scalars['String']>>;
  listConjugationChemistry?: Maybe<Array<Scalars['String']>>;
  listCterminalMod?: Maybe<Array<Scalars['String']>>;
  listErrors?: Maybe<Array<TubeListError>>;
  listFiveMod?: Maybe<Array<Scalars['String']>>;
  listFormat?: Maybe<Array<Scalars['String']>>;
  listInternalMod?: Maybe<Array<Scalars['String']>>;
  listLabeledAminoAcids?: Maybe<Array<Maybe<Scalars['String']>>>;
  listMinimumYield?: Maybe<Scalars['Float']>;
  listModification?: Maybe<Array<Scalars['String']>>;
  listNterminalMod?: Maybe<Array<Scalars['String']>>;
  listPurification?: Maybe<Array<Scalars['String']>>;
  listPurityLevel?: Maybe<Array<Scalars['String']>>;
  listQuantitation?: Maybe<Array<Scalars['String']>>;
  listScale?: Maybe<Array<Scalars['String']>>;
  listServices?: Maybe<Array<Scalars['String']>>;
  listThreeMod?: Maybe<Array<Scalars['String']>>;
  listTubes?: Maybe<Array<Scalars['String']>>;
  listWarnings?: Maybe<Array<ListWarning>>;
  material?: Maybe<Scalars['String']>;
  meltingTemperature?: Maybe<Scalars['String']>;
  molecularWeight?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  priceHeaderError?: Maybe<Scalars['String']>;
  primerDimer?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  purification?: Maybe<Scalars['String']>;
  scale?: Maybe<Scalars['String']>;
  secondaryStructure?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['String']>;
  serverError?: Maybe<ListError>;
  threeMod?: Maybe<Scalars['String']>;
  tubes?: Maybe<Scalars['String']>;
};

export type TubeSessionData = {
  __typename?: 'TubeSessionData';
  additionalCarrierProtein?: Maybe<Scalars['String']>;
  additionalComments?: Maybe<Scalars['String']>;
  additionalServices?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount?: Maybe<Scalars['String']>;
  applyToAll?: Maybe<Scalars['Boolean']>;
  autoValidation?: Maybe<Scalars['String']>;
  baseCount?: Maybe<Scalars['Int']>;
  carrierProtein?: Maybe<Scalars['String']>;
  cartId?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  concentration?: Maybe<Scalars['String']>;
  conjugation?: Maybe<Scalars['String']>;
  conjugationAmount?: Maybe<Scalars['String']>;
  conjugationChemistry?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  crossContamination?: Maybe<Scalars['String']>;
  crRnaType?: Maybe<Scalars['String']>;
  cterminalMod?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  customPeptideType?: Maybe<Scalars['String']>;
  epsilon?: Maybe<Scalars['Float']>;
  fiveMod?: Maybe<Scalars['String']>;
  fiveModCategory?: Maybe<Scalars['String']>;
  flag?: Maybe<Scalars['Int']>;
  format?: Maybe<Scalars['String']>;
  fullSOligoBackbone?: Maybe<Scalars['Boolean']>;
  gcContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalMod?: Maybe<Scalars['String']>;
  internalModCategory?: Maybe<Scalars['String']>;
  isCSR?: Maybe<Scalars['Boolean']>;
  isCSRSupervisor?: Maybe<Scalars['Boolean']>;
  isLabelledAminoAcid?: Maybe<Scalars['Boolean']>;
  isoCode?: Maybe<Scalars['Boolean']>;
  isOverridden?: Maybe<Scalars['Boolean']>;
  itemId?: Maybe<Scalars['String']>;
  listError?: Maybe<Array<Maybe<ListError>>>;
  listProductPackage?: Maybe<Array<Maybe<Scalars['String']>>>;
  listShippingOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  material?: Maybe<Scalars['String']>;
  meltingTemparature?: Maybe<Scalars['String']>;
  minimumYield?: Maybe<Scalars['String']>;
  molecularWeight?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nterminalMod?: Maybe<Scalars['String']>;
  oligoId?: Maybe<Scalars['Int']>;
  orderItemId?: Maybe<Scalars['String']>;
  otherValue?: Maybe<Scalars['String']>;
  packageType?: Maybe<Scalars['String']>;
  plateType?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  primerDimer?: Maybe<Scalars['String']>;
  probeType?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  purification?: Maybe<Scalars['String']>;
  purityLevel?: Maybe<Scalars['String']>;
  quantitation?: Maybe<Scalars['String']>;
  scale?: Maybe<Scalars['String']>;
  secondaryStructure?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['String']>;
  sequenceLocation?: Maybe<Scalars['String']>;
  threeMod?: Maybe<Scalars['String']>;
  threeModCategory?: Maybe<Scalars['String']>;
  tubes?: Maybe<Scalars['String']>;
};

export type TubeTotalValidationResponse = {
  __typename?: 'tubeTotalValidationResponse';
  epsilon?: Maybe<Scalars['Float']>;
  listErrors?: Maybe<Array<Maybe<ListError>>>;
  oligoId?: Maybe<Scalars['Int']>;
  serverError?: Maybe<ListError>;
};

export type UpdateCartInput = {
  billingAddress?: InputMaybe<CartAddressInput>;
  blanketPoNumber?: InputMaybe<Scalars['String']>;
  chargebackCodes?: InputMaybe<ChargebackCodeInput>;
  contractNumber?: InputMaybe<Scalars['String']>;
  creditCard?: InputMaybe<NewCreditCardInput>;
  customerServiceInstructions?: InputMaybe<Scalars['String']>;
  destination?: InputMaybe<Scalars['String']>;
  orderingPartyAddress?: InputMaybe<CartAddressInput>;
  packingSlipNote?: InputMaybe<Scalars['String']>;
  partners?: InputMaybe<CartPartnersInput>;
  paymentMethods?: InputMaybe<CartPaymentMethodsInput>;
  pin?: InputMaybe<Scalars['String']>;
  requestedDeliveryDate?: InputMaybe<Scalars['String']>;
  salesOrderSource?: InputMaybe<SalesOrderSource>;
  selectedPaymentMethod?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<CartAddressInput>;
  shippingMethod?: InputMaybe<Scalars['String']>;
};

/** Used to update an existing cart item's quantity or promo code (Slow) */
export type UpdateCartItemInput = {
  cartItemId: Scalars['ID'];
  deviceSerialNumber?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isSingleLotSelected?: InputMaybe<Scalars['Boolean']>;
  /** Not required, but if null, will override the previous value to null */
  promoCode?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Int'];
};

export type UpdateCoworkerGroupInput = {
  description?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  groupName?: InputMaybe<Scalars['String']>;
  partners?: InputMaybe<GroupPartner>;
};

export type UpdateCoworkerRolesInput = {
  roles?: InputMaybe<Array<Scalars['String']>>;
  userId: Scalars['String'];
};

export type UpdateOrDeleteRequesterInput = {
  approvalAmount?: InputMaybe<Scalars['Float']>;
  approvalProductType?: InputMaybe<Scalars['String']>;
  approvalTypeId?: InputMaybe<Scalars['String']>;
  approverId?: InputMaybe<Scalars['String']>;
  emailId?: InputMaybe<Scalars['String']>;
  logonId?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
};

export type UpdateOrdersPendingApprovalInput = {
  action: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  orderIds: Array<Scalars['String']>;
};

export type UpdatePinnedAddress = {
  addresses: Array<PinnedAddressInputItem>;
};

export type UpdateQuickOrderItemInput = {
  itemId: Scalars['String'];
  quickOrderItems: Array<QuickOrderItem>;
};

export type UpdateSavedCartInput = {
  comment: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateSharedListInput = {
  coOwners?: InputMaybe<Array<Scalars['String']>>;
  deadline?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  invitations?: InputMaybe<Array<Scalars['String']>>;
  listId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  reminderFrequencyDays?: InputMaybe<Array<Scalars['Int']>>;
};

export type UpdateSharedListItemInput = {
  itemNote?: InputMaybe<Scalars['String']>;
  listId: Scalars['ID'];
  listItemId: Scalars['ID'];
  quantity?: InputMaybe<Scalars['Int']>;
};

export type UpdateTermsAndConditionsInput = {
  generalTermsCondition: Scalars['Boolean'];
  participantId: Scalars['String'];
  siteAgreement: Scalars['Boolean'];
};

export type UpdateUserProfileInput = {
  billingAddress?: InputMaybe<AddressInput>;
  contactAddress?: InputMaybe<AddressInput>;
  inviteeId?: InputMaybe<Scalars['String']>;
  participantCommunicationSetting?: InputMaybe<UserParticipantCommunicationSettingInput>;
  participantShippingPreference?: InputMaybe<UserParticipantShippingPreferenceInput>;
  participantSitePreference?: InputMaybe<UserParticipantSitePreferenceInput>;
  paymentMethods?: InputMaybe<PaymentMethodsInput>;
  shippingAddress?: InputMaybe<AddressInput>;
  updateApproverRole?: InputMaybe<Scalars['Boolean']>;
  useShipToAddressForAllOrders?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateUserSitePreferenceInput = {
  dealerId: Scalars['String'];
  dealerTcs?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateWimsAccessCodeResponse = {
  __typename?: 'UpdateWIMSAccessCodeResponse';
  success: Scalars['Boolean'];
};

export type UploadCopyPasteResponse = {
  __typename?: 'UploadCopyPasteResponse';
  key?: Maybe<Scalars['String']>;
  serverError?: Maybe<ListError>;
  value?: Maybe<Scalars['String']>;
};

export type UploadFileResponse = {
  __typename?: 'UploadFileResponse';
  key?: Maybe<Scalars['String']>;
  serverError?: Maybe<ListError>;
  value?: Maybe<Scalars['String']>;
};

export type User = GuestUser | LoggedInUser;

export type UserActivityEntry = {
  __typename?: 'UserActivityEntry';
  featureType: Scalars['String'];
  trackingData?: Maybe<Scalars['JSONObject']>;
};

export type UserActivityInput = {
  featureType: Scalars['String'];
  trackingData: Scalars['JSONObject'];
};

export type UserAddress = {
  __typename?: 'UserAddress';
  addressId: Scalars['String'];
  attentionTo: LockableValue;
  buildingRoom: LockableValue;
  city: Scalars['String'];
  cityLockable: LockableValue;
  country: Scalars['String'];
  countryLockable: LockableValue;
  countryName: Scalars['String'];
  countryNameLockable: LockableValue;
  department: LockableValue;
  district: Scalars['String'];
  email: Scalars['String'];
  extension: Scalars['String'];
  fax: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName: Scalars['String'];
  mobilePhone: Scalars['String'];
  officeRegistrationNumber: Scalars['String'];
  organizationName: Scalars['String'];
  organizationNameLockable: LockableValue;
  organizationProperty?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  postalCodeLockable: LockableValue;
  postOfficeBox: Scalars['String'];
  postOfficeBoxLockable: LockableValue;
  principalInvestigator?: Maybe<Scalars['String']>;
  salesRepresentative?: Maybe<Scalars['String']>;
  salutation: Scalars['String'];
  state: Scalars['String'];
  stateLockable: LockableValue;
  street: Scalars['String'];
  street2: Scalars['String'];
  street3: Scalars['String'];
  street4: Scalars['String'];
  streetLockable: LockableValue;
  telephone: Scalars['String'];
  title: Scalars['String'];
};

export type UserByToken = {
  __typename?: 'UserByToken';
  metadata: UserMetadata;
  participantSitePreference?: Maybe<UserParticipantSitePreference>;
  userProfile?: Maybe<UserByTokenProflie>;
};

export type UserByTokenProflie = {
  __typename?: 'UserByTokenProflie';
  logonId?: Maybe<Scalars['String']>;
};

export type UserChargebackInput = {
  chargebacks?: InputMaybe<Array<InputMaybe<ChargebackInputList>>>;
};

export type UserChargebackPartner = {
  __typename?: 'UserChargebackPartner';
  chargeback_bill_to?: Maybe<Scalars['String']>;
  chargeback_payer?: Maybe<Scalars['String']>;
  chargeback_ship_to?: Maybe<Scalars['String']>;
  chargeback_sold_to?: Maybe<Scalars['String']>;
};

export type UserChargebacks = {
  __typename?: 'UserChargebacks';
  chargebackCode?: Maybe<Scalars['String']>;
  chargebackLabel?: Maybe<Scalars['String']>;
  isDisplay?: Maybe<Scalars['Boolean']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  isOnEmail?: Maybe<Scalars['Boolean']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  partners?: Maybe<UserChargebackPartner>;
  value?: Maybe<Scalars['String']>;
};

export type UserCreditCard = {
  __typename?: 'UserCreditCard';
  cardExpiryDate?: Maybe<Scalars['String']>;
  cardNameLabel?: Maybe<Scalars['String']>;
  cardNameValue?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  ccLegalTermsAcceptance?: Maybe<Scalars['Boolean']>;
  displayableInCart?: Maybe<Scalars['Boolean']>;
  displayableInEmail?: Maybe<Scalars['Boolean']>;
  editableInCart?: Maybe<Scalars['Boolean']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  mask?: Maybe<Scalars['String']>;
  maskedCardNumber?: Maybe<Scalars['String']>;
  tokenizedCCNumber?: Maybe<Scalars['String']>;
  transientCCToken?: Maybe<Scalars['String']>;
};

export enum UserErpType {
  Anonymous = 'ANONYMOUS',
  Blue = 'BLUE',
  NewUser = 'NEW_USER',
  Purple = 'PURPLE',
  Red = 'RED'
}

export type UserMessage = {
  content: Scalars['String'];
};

export type UserMetadata = {
  __typename?: 'UserMetadata';
  canTryNewCartExperience?: Maybe<Scalars['Boolean']>;
  country: Scalars['String'];
  emproveDomainStatus?: Maybe<Scalars['String']>;
  fddLevel?: Maybe<Scalars['String']>;
  guestShoppingType?: Maybe<Scalars['String']>;
  hasOnlyBlueERP?: Maybe<Scalars['Boolean']>;
  helpLinkURL?: Maybe<Scalars['String']>;
  isBigFormFilled?: Maybe<Scalars['Boolean']>;
  isBlanketPOEnabled?: Maybe<Scalars['Boolean']>;
  isBlueErpIntegrationEnabled?: Maybe<Scalars['Boolean']>;
  isBuildingBlockEnabled?: Maybe<Scalars['Boolean']>;
  isChatEnabled?: Maybe<Scalars['Boolean']>;
  isContractEnabled?: Maybe<Scalars['Boolean']>;
  isCreditCardEnabled?: Maybe<Scalars['Boolean']>;
  isDejaEnabled: Scalars['Boolean'];
  isDigitalProductEnabled?: Maybe<Scalars['Boolean']>;
  isDTAQZuCustomer: Scalars['Boolean'];
  isFddEnabled?: Maybe<Scalars['Boolean']>;
  isFullQuoteConversionEnabled?: Maybe<Scalars['Boolean']>;
  isInvoiceCenterEnabled?: Maybe<Scalars['Boolean']>;
  isMarketplaceCartEnabled: Scalars['Boolean'];
  isMarketplaceCatalogEnabled: Scalars['Boolean'];
  isNewPNASrpEnabled?: Maybe<Scalars['Boolean']>;
  isOrderNotesEnabled?: Maybe<Scalars['Boolean']>;
  isQuoteEnabled?: Maybe<Scalars['Boolean']>;
  isRandRenabled?: Maybe<Scalars['Boolean']>;
  isRememberMeEnabled?: Maybe<Scalars['Boolean']>;
  isSAPNextEnabled?: Maybe<Scalars['Boolean']>;
  isScanNowEnabled?: Maybe<Scalars['Boolean']>;
  isSingleLotEnabled?: Maybe<Scalars['Boolean']>;
  isSwitchAccountsEnabled?: Maybe<Scalars['Boolean']>;
  locale: Scalars['String'];
  passwordPatternModel?: Maybe<PasswordPatternModelType>;
  wimsBlueOnRedEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserParticipantCommunicationSetting = {
  __typename?: 'UserParticipantCommunicationSetting';
  attachmentConfirmationEmail: Scalars['Boolean'];
  bccOrderConfirmationEmailIds: Array<Scalars['String']>;
  ccOrderConfirmationEmailIds: Array<Scalars['String']>;
  emproveEmailVerification: Scalars['Boolean'];
  emproveTermsConditions: Scalars['Boolean'];
  invitationSent: Scalars['Boolean'];
  invoiceEmail?: Maybe<Scalars['String']>;
  isOffersPromotionsEmail: Scalars['Boolean'];
  orderConfirmationEmail: Scalars['Boolean'];
  repromiseTrackingEmail: Scalars['Boolean'];
  shipmentTrackingEmail: Scalars['Boolean'];
  unpricedCcEmails: Array<Scalars['String']>;
};

export type UserParticipantCommunicationSettingInput = {
  attachmentConfirmationEmail: Scalars['Boolean'];
  bccOrderConfirmationEmailIds: Array<Scalars['String']>;
  ccOrderConfirmationEmailIds: Array<Scalars['String']>;
  emproveEmailVerification: Scalars['Boolean'];
  invitationSent: Scalars['Boolean'];
  invoiceEmail?: InputMaybe<Scalars['String']>;
  isOffersPromotionsEmail: Scalars['Boolean'];
  orderConfirmationEmail: Scalars['Boolean'];
  repromiseTrackingEmail: Scalars['Boolean'];
  shipmentTrackingEmail: Scalars['Boolean'];
  unpricedCcEmails: Array<Scalars['String']>;
};

export type UserParticipantComplianceInformation = {
  __typename?: 'UserParticipantComplianceInformation';
  bankName?: Maybe<Scalars['String']>;
  bankNo?: Maybe<Scalars['String']>;
  complianceTerms?: Maybe<Scalars['Boolean']>;
  dealerTerms?: Maybe<Scalars['Boolean']>;
  invoiceAddress?: Maybe<Scalars['String']>;
  invoiceContactPhoneNo?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  invoiceType?: Maybe<Scalars['String']>;
  invoiceTypeReason?: Maybe<Scalars['String']>;
  labAndWarehouseFacilities?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  organizationType?: Maybe<Scalars['String']>;
  organizationWebsite?: Maybe<Scalars['String']>;
  participantId?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  primaryBusinessActivity?: Maybe<UserPrimaryBusinessActivity>;
  productUsage?: Maybe<Scalars['String']>;
  salesVATTaxApplicable?: Maybe<Scalars['Boolean']>;
  termsOfSale?: Maybe<Scalars['Boolean']>;
  vatTaxNumber?: Maybe<Scalars['String']>;
};

export type UserParticipantComplianceInformationInput = {
  bankName?: InputMaybe<Scalars['String']>;
  bankNo?: InputMaybe<Scalars['String']>;
  complianceTerms?: InputMaybe<Scalars['Boolean']>;
  dealerTerms?: InputMaybe<Scalars['Boolean']>;
  invoiceAddress?: InputMaybe<Scalars['String']>;
  invoiceContactPhoneNo?: InputMaybe<Scalars['String']>;
  invoiceName?: InputMaybe<Scalars['String']>;
  invoiceType?: InputMaybe<Scalars['String']>;
  invoiceTypeReason?: InputMaybe<Scalars['String']>;
  labAndWarehouseFacilities?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  organizationType?: InputMaybe<Scalars['String']>;
  organizationWebsite?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  primaryBusinessActivity?: InputMaybe<UserPrimaryBusinessActivityInput>;
  productUsage?: InputMaybe<Scalars['String']>;
  salesVATTaxApplicable?: InputMaybe<Scalars['Boolean']>;
  termsOfSale?: InputMaybe<Scalars['Boolean']>;
  vatTaxNumber?: InputMaybe<Scalars['String']>;
};

export type UserParticipantShippingMethods = {
  __typename?: 'UserParticipantShippingMethods';
  description: Scalars['String'];
  isDefault: Scalars['Boolean'];
  isDisplay: Scalars['Boolean'];
  key: Scalars['String'];
  shipModeId: Scalars['Int'];
  shippingMethodCode?: Maybe<Scalars['String']>;
};

export type UserParticipantShippingMethodsInput = {
  description?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isDisplay?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  shipModeId: Scalars['Int'];
  shippingMethodCode?: InputMaybe<Scalars['String']>;
};

export type UserParticipantShippingPreference = {
  __typename?: 'UserParticipantShippingPreference';
  countryDefaultShippingEnabled: Scalars['Boolean'];
  countryDefaultShippingLocked: Scalars['Boolean'];
  participantId: Scalars['String'];
  participantShippingMethods?: Maybe<Array<Maybe<UserParticipantShippingMethods>>>;
};

export type UserParticipantShippingPreferenceInput = {
  countryDefaultShippingEnabled?: InputMaybe<Scalars['Boolean']>;
  countryDefaultShippingLocked?: InputMaybe<Scalars['Boolean']>;
  participantId: Scalars['String'];
  participantShippingMethods?: InputMaybe<Array<InputMaybe<UserParticipantShippingMethodsInput>>>;
};

export type UserParticipantSitePreference = {
  __typename?: 'UserParticipantSitePreference';
  catalogFilter?: Maybe<Scalars['String']>;
  dealerTcs?: Maybe<Scalars['Boolean']>;
  hideAddToCartForPrepackItems: Scalars['Boolean'];
  hideBuildingBlocks?: Maybe<Scalars['Boolean']>;
  hideContractOption?: Maybe<Scalars['Boolean']>;
  hideCustomProductBlanketPoOrdering?: Maybe<Scalars['Boolean']>;
  hideOligoPeptideDesign?: Maybe<Scalars['Boolean']>;
  isRedirectToProductDetail: Scalars['Boolean'];
  numberOfEntryFields: Scalars['Int'];
  numberOfRecentOrders: Scalars['Int'];
  numberOfRecentQuotes: Scalars['Int'];
  preferredDealerId?: Maybe<Scalars['String']>;
  preferredDealerInfo?: Maybe<UserPreferredDealerInfo>;
  productEntryPreference: Scalars['String'];
  sdsLocale: Scalars['String'];
  websiteLocale: Scalars['String'];
};

export type UserParticipantSitePreferenceInput = {
  isRedirectToProductDetail: Scalars['Boolean'];
  numberOfEntryFields: Scalars['Int'];
  numberOfRecentOrders: Scalars['Int'];
  numberOfRecentQuotes: Scalars['Int'];
  productEntryPreference: Scalars['String'];
  sdsLocale: Scalars['String'];
  websiteLocale: Scalars['String'];
};

export type UserPaymentMethods = {
  __typename?: 'UserPaymentMethods';
  chargebacks: Array<Maybe<UserChargebacks>>;
  creditCard: Array<UserCreditCard>;
  defaultPaymentMethod: Scalars['String'];
  purchaseOrderNumber: PaymentLabelValue;
  requisitionNumber: PaymentLabelValue;
};

export type UserPreferredDealerInfo = {
  __typename?: 'UserPreferredDealerInfo';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  dealerId: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  orgName?: Maybe<Scalars['String']>;
  stateName?: Maybe<Scalars['String']>;
};

export type UserPrimaryBusinessActivity = {
  __typename?: 'UserPrimaryBusinessActivity';
  cellCulture?: Maybe<Scalars['Boolean']>;
  consultant?: Maybe<Scalars['Boolean']>;
  cosmetics?: Maybe<Scalars['Boolean']>;
  diagnosticsInVitro?: Maybe<Scalars['Boolean']>;
  dogTrainer?: Maybe<Scalars['Boolean']>;
  drugsAnimal?: Maybe<Scalars['Boolean']>;
  drugsHuman?: Maybe<Scalars['Boolean']>;
  electronics?: Maybe<Scalars['Boolean']>;
  exporter?: Maybe<Scalars['Boolean']>;
  exporterText?: Maybe<Scalars['String']>;
  foods?: Maybe<Scalars['Boolean']>;
  government?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['Boolean']>;
  manufacturerText?: Maybe<Scalars['String']>;
  medical?: Maybe<Scalars['Boolean']>;
  other?: Maybe<Scalars['Boolean']>;
  otherText?: Maybe<Scalars['String']>;
  resaleUnderOtherLabel?: Maybe<Scalars['Boolean']>;
  resaleUnderSigmaAldrich?: Maybe<Scalars['Boolean']>;
  resellerDistributor?: Maybe<Scalars['Boolean']>;
  retailer?: Maybe<Scalars['Boolean']>;
  testing?: Maybe<Scalars['Boolean']>;
  university?: Maybe<Scalars['Boolean']>;
  veterinaryClinic?: Maybe<Scalars['Boolean']>;
};

export type UserPrimaryBusinessActivityInput = {
  cellCulture?: InputMaybe<Scalars['Boolean']>;
  consultant?: InputMaybe<Scalars['Boolean']>;
  cosmetics?: InputMaybe<Scalars['Boolean']>;
  diagnosticsInVitro?: InputMaybe<Scalars['Boolean']>;
  dogTrainer?: InputMaybe<Scalars['Boolean']>;
  drugsAnimal?: InputMaybe<Scalars['Boolean']>;
  drugsHuman?: InputMaybe<Scalars['Boolean']>;
  electronics?: InputMaybe<Scalars['Boolean']>;
  exporter?: InputMaybe<Scalars['Boolean']>;
  exporterText?: InputMaybe<Scalars['String']>;
  foods?: InputMaybe<Scalars['Boolean']>;
  government?: InputMaybe<Scalars['Boolean']>;
  laboratory?: InputMaybe<Scalars['Boolean']>;
  manufacturer?: InputMaybe<Scalars['Boolean']>;
  manufacturerText?: InputMaybe<Scalars['String']>;
  medical?: InputMaybe<Scalars['Boolean']>;
  other?: InputMaybe<Scalars['Boolean']>;
  otherText?: InputMaybe<Scalars['String']>;
  resaleUnderOtherLabel?: InputMaybe<Scalars['Boolean']>;
  resaleUnderSigmaAldrich?: InputMaybe<Scalars['Boolean']>;
  resellerDistributor?: InputMaybe<Scalars['Boolean']>;
  retailer?: InputMaybe<Scalars['Boolean']>;
  testing?: InputMaybe<Scalars['Boolean']>;
  university?: InputMaybe<Scalars['Boolean']>;
  veterinaryClinic?: InputMaybe<Scalars['Boolean']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  accountNumber?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
};

export type UserPushSubscription = {
  __typename?: 'UserPushSubscription';
  /** TODO: deviceLabel only needed for PoC, not needed for production */
  deviceLabel: Scalars['String'];
  id: Scalars['ID'];
  subscription: PushSubscription;
  userId: Scalars['String'];
};

export type UserSavedRegistration = {
  __typename?: 'UserSavedRegistration';
  accountNumber?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<UserAddress>;
  contactAddress?: Maybe<UserAddress>;
  invoiceNumber?: Maybe<Scalars['String']>;
  logonId?: Maybe<Scalars['String']>;
  officeAddress?: Maybe<UserAddress>;
  participantComplianceInformation?: Maybe<UserParticipantComplianceInformation>;
  paymentAddress?: Maybe<UserAddress>;
  shippingAddress?: Maybe<UserAddress>;
};

export enum UserType {
  Guest = 'Guest',
  LoggedIn = 'LoggedIn',
  RememberMe = 'RememberMe'
}

export type ValidateMobileSecurityCodeResponse = {
  __typename?: 'validateMobileSecurityCodeResponse';
  accessToken?: Maybe<Scalars['String']>;
};

export type ValidMaterialAvailability = {
  __typename?: 'ValidMaterialAvailability';
  availabilities: Array<MaterialAvailability>;
};

export type ValidMaterialPricing = {
  __typename?: 'ValidMaterialPricing';
  additionalInfo: CartAdditionalInfo;
  availabilities: Array<MaterialAvailability>;
  availableQtyInStock?: Maybe<Scalars['Int']>;
  brand: Scalars['String'];
  catalogType?: Maybe<CatalogType>;
  currency: Scalars['String'];
  dealerId?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  freeFreight?: Maybe<Scalars['Boolean']>;
  hidePriceMessageKey?: Maybe<Scalars['String']>;
  isBlockedProduct: Scalars['Boolean'];
  isBuyNow?: Maybe<Scalars['Boolean']>;
  isPBAvailable: Scalars['Boolean'];
  listPrice?: Maybe<Scalars['Float']>;
  listPriceCurrency?: Maybe<Scalars['String']>;
  marketplaceOfferId?: Maybe<Scalars['String']>;
  marketplaceSellerId?: Maybe<Scalars['String']>;
  materialDescription: Scalars['String'];
  materialId?: Maybe<Scalars['String']>;
  materialNumber: Scalars['String'];
  minOrderQuantity?: Maybe<Scalars['Int']>;
  netPrice?: Maybe<Scalars['Float']>;
  packageQuantity?: Maybe<Scalars['String']>;
  packageSize?: Maybe<Scalars['String']>;
  packageType?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  product: Scalars['String'];
  productGroupSBU?: Maybe<Scalars['String']>;
  productHierarchy?: Maybe<Scalars['String']>;
  promotionalMessage?: Maybe<PromotionalMessage>;
  quantity: Scalars['Int'];
  sdsLanguages: Array<Maybe<Scalars['String']>>;
  shipsToday?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CartItemType>;
  vendorSKU?: Maybe<Scalars['String']>;
};

export type VatInfoItem = {
  __typename?: 'VatInfoItem';
  companyName: Scalars['String'];
  vatNumber: Scalars['String'];
};

export type Vector = {
  __typename?: 'Vector';
  LV01?: Maybe<Scalars['String']>;
  LV02?: Maybe<Scalars['String']>;
  LV03?: Maybe<Scalars['String']>;
  LV04?: Maybe<Scalars['String']>;
  LV05?: Maybe<Scalars['String']>;
  LV08?: Maybe<Scalars['String']>;
  LV13?: Maybe<Scalars['String']>;
  LV14?: Maybe<Scalars['String']>;
  LV15?: Maybe<Scalars['String']>;
  LV16?: Maybe<Scalars['String']>;
  LV17?: Maybe<Scalars['String']>;
  p01?: Maybe<Scalars['String']>;
  p02?: Maybe<Scalars['String']>;
  p03?: Maybe<Scalars['String']>;
  p04?: Maybe<Scalars['String']>;
  p05?: Maybe<Scalars['String']>;
};

export type VectorDocument = {
  __typename?: 'VectorDocument';
  fileContent?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export type VectorDocumentInput = {
  owner: Scalars['String'];
  term: Scalars['String'];
  type: Scalars['String'];
  unit: Scalars['String'];
};

export type VectorDocumentResponse = {
  __typename?: 'VectorDocumentResponse';
  file_name?: Maybe<Scalars['String']>;
  responseStream?: Maybe<Scalars['String']>;
};

export type VerificationResponse = {
  __typename?: 'VerificationResponse';
  emailAddress: Scalars['String'];
  userId: Scalars['String'];
};

export type Volume = {
  __typename?: 'Volume';
  pointOne?: Maybe<Scalars['String']>;
  pointTwo?: Maybe<Scalars['String']>;
  volFive?: Maybe<Scalars['String']>;
  volOne?: Maybe<Scalars['String']>;
  volTen?: Maybe<Scalars['String']>;
  volTwo?: Maybe<Scalars['String']>;
};

export type WaterSystem = {
  __typename?: 'WaterSystem';
  imageId?: Maybe<Scalars['String']>;
  skuList?: Maybe<Array<Maybe<SkuItem>>>;
};

export type WaterSystemInput = {
  imageId?: InputMaybe<Scalars['String']>;
  skuList?: InputMaybe<Array<InputMaybe<SkuItemInput>>>;
};

export type WebOrdersInput = {
  numberOfRecentOrders: Scalars['Int'];
  statusCategory?: InputMaybe<Scalars['String']>;
};

export type WebOrdersPayload = {
  __typename?: 'WebOrdersPayload';
  count?: Maybe<Scalars['Int']>;
  orders: Array<Order>;
};

export type WebQuotesInput = {
  numberOfRecentQuotes: Scalars['Int'];
  productNumber?: InputMaybe<Scalars['String']>;
};

export type WebQuotesPayload = {
  __typename?: 'WebQuotesPayload';
  count: Scalars['Int'];
  quotes: Array<Quote>;
};

export type WechatInfo = {
  __typename?: 'WechatInfo';
  unionId?: Maybe<Scalars['String']>;
};

export type WechatLoginResponse = {
  __typename?: 'WechatLoginResponse';
  accessToken?: Maybe<Scalars['String']>;
};

export type Welllist = {
  __typename?: 'Welllist';
  additionalComments?: Maybe<Scalars['String']>;
  additionalServices?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount?: Maybe<Scalars['String']>;
  analyticalHPLC?: Maybe<Scalars['String']>;
  baseCount?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  certificateOfAnalysis?: Maybe<Scalars['String']>;
  column?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  concentration?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  crossContamination?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  epsilon?: Maybe<Scalars['Float']>;
  excessMaterial?: Maybe<Scalars['Boolean']>;
  fiveMod?: Maybe<Scalars['String']>;
  flag?: Maybe<Scalars['Int']>;
  format?: Maybe<Scalars['String']>;
  gcContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isOverridden?: Maybe<Scalars['Boolean']>;
  listAdditionalServices?: Maybe<Array<Maybe<Scalars['String']>>>;
  listError?: Maybe<Array<Maybe<ListError>>>;
  listISO?: Maybe<Array<Maybe<Scalars['String']>>>;
  listNgso?: Maybe<Array<Maybe<Scalars['String']>>>;
  listWarning?: Maybe<Array<Maybe<ListWarning>>>;
  meltingTemparature?: Maybe<Scalars['String']>;
  mixed?: Maybe<Scalars['Int']>;
  molecularWeight?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ngsoType?: Maybe<Scalars['String']>;
  oligoId?: Maybe<Scalars['Int']>;
  orderItemId?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  packageType?: Maybe<Scalars['String']>;
  pageNo?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['String']>;
  priceHeaderError?: Maybe<Scalars['String']>;
  primerDimer?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  purification?: Maybe<Scalars['String']>;
  qualitySystem?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['String']>;
  scale?: Maybe<Scalars['String']>;
  secondaryStructure?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['String']>;
  shipping?: Maybe<Scalars['String']>;
  technicalSequence?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['Boolean']>;
  threeMod?: Maybe<Scalars['String']>;
  tubes?: Maybe<Scalars['Int']>;
  wellType?: Maybe<Scalars['Int']>;
};

export type WelllistInput = {
  additionalComments?: InputMaybe<Scalars['String']>;
  additionalServices?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  amount?: InputMaybe<Scalars['String']>;
  analyticalHPLC?: InputMaybe<Scalars['String']>;
  baseCount?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Scalars['String']>;
  certificateOfAnalysis?: InputMaybe<Scalars['String']>;
  column?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  concentration?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  crossContamination?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  currencySymbol?: InputMaybe<Scalars['String']>;
  epsilon?: InputMaybe<Scalars['Float']>;
  excessMaterial?: InputMaybe<Scalars['Boolean']>;
  fiveMod?: InputMaybe<Scalars['String']>;
  flag?: InputMaybe<Scalars['Int']>;
  format?: InputMaybe<Scalars['String']>;
  gcContent?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  isOverridden?: InputMaybe<Scalars['Boolean']>;
  listAdditionalServices?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listError?: InputMaybe<Array<InputMaybe<ListErrorInput>>>;
  listISO?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listNgso?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listWarning?: InputMaybe<Array<InputMaybe<ListWarningInput>>>;
  meltingTemparature?: InputMaybe<Scalars['String']>;
  mixed?: InputMaybe<Scalars['Int']>;
  molecularWeight?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ngsoType?: InputMaybe<Scalars['String']>;
  oligoId?: InputMaybe<Scalars['Int']>;
  orderItemId?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  pageNo?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['String']>;
  priceHeaderError?: InputMaybe<Scalars['String']>;
  primerDimer?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  purification?: InputMaybe<Scalars['String']>;
  qualitySystem?: InputMaybe<Scalars['String']>;
  row?: InputMaybe<Scalars['String']>;
  scale?: InputMaybe<Scalars['String']>;
  secondaryStructure?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['String']>;
  shipping?: InputMaybe<Scalars['String']>;
  technicalSequence?: InputMaybe<Scalars['String']>;
  terms?: InputMaybe<Scalars['Boolean']>;
  threeMod?: InputMaybe<Scalars['String']>;
  tubes?: InputMaybe<Scalars['Int']>;
  wellType?: InputMaybe<Scalars['Int']>;
};

export type WellListPrice = {
  __typename?: 'WellListPrice';
  baseCount?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  formattedPrice?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  material?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  oligoId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['String']>;
  priceHeaderError?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['String']>;
  shipping?: Maybe<Scalars['String']>;
};

export type WelllistResponse = {
  __typename?: 'WelllistResponse';
  countryCode?: Maybe<Scalars['String']>;
  errorType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  listError?: Maybe<Array<Maybe<ListError>>>;
  listNgso?: Maybe<Array<Maybe<Scalars['String']>>>;
  oligoId?: Maybe<Scalars['Int']>;
};

export type WelllistScreen = {
  __typename?: 'WelllistScreen';
  additionalComments?: Maybe<Scalars['String']>;
  aliquot?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  analyticalHPLC?: Maybe<Scalars['String']>;
  antisenceBaseCount?: Maybe<Scalars['Int']>;
  baseCount?: Maybe<Scalars['Int']>;
  chloridesalt?: Maybe<Scalars['String']>;
  column?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  cterminalMod?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  gcContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isCSR?: Maybe<Scalars['Boolean']>;
  isCSRSupervisor?: Maybe<Scalars['Boolean']>;
  isDForm?: Maybe<Scalars['Boolean']>;
  isLabelledAminoAcid?: Maybe<Scalars['Boolean']>;
  isOverridden?: Maybe<Scalars['Boolean']>;
  listError?: Maybe<Array<Maybe<ListError>>>;
  listWarning?: Maybe<Array<Maybe<ListWarning>>>;
  meltingTemparature?: Maybe<Scalars['String']>;
  molecularWeight?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nterminalMod?: Maybe<Scalars['String']>;
  oligoId?: Maybe<Scalars['Int']>;
  other?: Maybe<Scalars['String']>;
  packageType?: Maybe<Scalars['String']>;
  pageNo?: Maybe<Scalars['Int']>;
  primerDimer?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  purification?: Maybe<Scalars['String']>;
  purityLevel?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['String']>;
  secondaryStructure?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['String']>;
  shipping?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  tubes?: Maybe<Scalars['Int']>;
};

export type WelllistScreenInput = {
  additionalComments?: InputMaybe<Scalars['String']>;
  aliquot?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['String']>;
  analyticalHPLC?: InputMaybe<Scalars['String']>;
  antisenceBaseCount?: InputMaybe<Scalars['Int']>;
  baseCount?: InputMaybe<Scalars['Int']>;
  chloridesalt?: InputMaybe<Scalars['String']>;
  column?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  cterminalMod?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  gcContent?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isCSR?: InputMaybe<Scalars['Boolean']>;
  isCSRSupervisor?: InputMaybe<Scalars['Boolean']>;
  isDForm?: InputMaybe<Scalars['Boolean']>;
  isLabelledAminoAcid?: InputMaybe<Scalars['Boolean']>;
  isOverridden?: InputMaybe<Scalars['Boolean']>;
  listError?: InputMaybe<Array<InputMaybe<ListErrorInput>>>;
  listWarning?: InputMaybe<Array<InputMaybe<ListWarningInput>>>;
  meltingTemparature?: InputMaybe<Scalars['String']>;
  molecularWeight?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nterminalMod?: InputMaybe<Scalars['String']>;
  oligoId?: InputMaybe<Scalars['Int']>;
  other?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  pageNo?: InputMaybe<Scalars['Int']>;
  primerDimer?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  purification?: InputMaybe<Scalars['String']>;
  purityLevel?: InputMaybe<Scalars['String']>;
  row?: InputMaybe<Scalars['String']>;
  secondaryStructure?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['String']>;
  shipping?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
  tubes?: InputMaybe<Scalars['Int']>;
};

export type WellListUploadInput = {
  column?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  fiveMod?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  row?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['String']>;
  threeMod?: InputMaybe<Scalars['String']>;
};

export type WellSpecificationResponse = {
  __typename?: 'WellSpecificationResponse';
  response?: Maybe<Array<Maybe<WellSpecifications>>>;
  serverError?: Maybe<ListError>;
};

export type WellSpecifications = {
  __typename?: 'WellSpecifications';
  id: Scalars['Int'];
  listAmount?: Maybe<Array<Maybe<Scalars['String']>>>;
  listFormat?: Maybe<Array<Maybe<Scalars['String']>>>;
  listPurification?: Maybe<Array<Maybe<Scalars['String']>>>;
  listScale?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type WimsTokenInfoResponse = {
  __typename?: 'WimsTokenInfoResponse';
  blueOAuthURL: Scalars['String'];
  clientId: Scalars['String'];
  userIsAuthenticatedBlueSystem?: Maybe<Scalars['Boolean']>;
  userOAuthTokenExpired?: Maybe<Scalars['Boolean']>;
  wimsBlueOnRedEnabled?: Maybe<Scalars['Boolean']>;
};
